import React from 'react'
import { Button, ButtonPreview, StyledUpload } from './styled'
import { theme } from '@tops/global_config'
import { i18n } from '@tops/services';
import Icon, { IconNames } from '../Icons';
import { MessageError } from '../MessageError';

export const UploadButton = props => {

  return (
    <StyledUpload {...props}
      listType={props.withPreview ? "picture-card" : "text"}
      isImage={props.isImage}
      isAttach={props.isAttach}
      accept={props.accept ? props.accept : props.isImage ? ".jpg,.png," : props.isPdf ? "application/pdf" :
        ".jpg,.png,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"}
      showUploadList={props.hidePreview ? false : { removeIcon: <Icon name={[IconNames['TrashBin']]} color={props.withPreview ? theme.colors.white : theme.colors.darkGray} /> }}>

      {props.withPreview ?
        <ButtonPreview>
          <Icon name="Image" color={props.color} />
          {/* {props.isImage ? i18n('USER').WISE.RISK_NOTICE.CREATE_RISK_NOTICE.TITLES.ADD_IMAGE : i18n('ATTACH_FILE')} */}
        </ButtonPreview>
        : props.isAttach && !props.isImage && !props.withPreview ?
          <Button color={props.color} background={props.background} padding={props.padding} minWidthAbsence={props.minWidthAbsence} minWidth={props.minWidth} fontSize={props.fontSize} fontWeight={props.fontWeight} borderButton={props.borderButton} widthButton={props.widthButton} marginBottom={props.marginBottom}
            style={{ display: "inline-flex", justifyContent: "center", alignItems: 'center' }}>
            <Icon name="Attach" size={25} color={props.isAttachFile ? theme.colors.white : props.color} />
            {!props.isMasiveRise ?<p>Añadir archivo</p> : <p style={{color: props.isAttachFile ? 'white' : props.color}}>Añadir archivo .xls/csv</p>} 
          </Button>
          :
          <Button color={props.color} background={props.background}>
            {props.isImage ? i18n('USER').WISE.RISK_NOTICE.CREATE_RISK_NOTICE.TITLES.ADD_IMAGE : i18n('ATTACH_FILE')}
          </Button>}

      {props.error && <MessageError errorname={props.error} />}


    </StyledUpload>
  )
}