import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchSectorsStart, fetchSectorsSuccess, fetchSectorsError, submitSectorStart, submitSectorSuccess, submitSectorError,
  editSectorStart, editSectorSuccess, editSectorError, selectedSector, fetchSectorsByAccountStart, fetchSectorsByAccountSuccess,
  fetchSectorsByAccountError, fetchSectorsByBusinessStart, fetchSectorsByBusinessSuccess, fetchSectorsByBusinessError
} = createActions({
  FETCH_SECTORS_START: () => { },
  FETCH_SECTORS_SUCCESS: values => ({ values }),
  FETCH_SECTORS_ERROR: error => ({ error }),
  SUBMIT_SECTOR_START: () => { },
  SUBMIT_SECTOR_SUCCESS: data => ({ data }),
  SUBMIT_SECTOR_ERROR: error => ({ error }),
  EDIT_SECTOR_START: () => { },
  EDIT_SECTOR_SUCCESS: data => ({ data }),
  EDIT_SECTOR_ERROR: error => ({ error }),
  SELECTED_SECTOR: value => ({ value }),
  FETCH_SECTORS_BY_ACCOUNT_START: () => { },
  FETCH_SECTORS_BY_ACCOUNT_SUCCESS: allSectorsByAccount => ({ allSectorsByAccount }),
  FETCH_SECTORS_BY_ACCOUNT_ERROR: error => ({ error }),
  FETCH_SECTORS_BY_BUSINESS_START: () => { },
  FETCH_SECTORS_BY_BUSINESS_SUCCESS: allSectorsByBusiness => ({ allSectorsByBusiness }),
  FETCH_SECTORS_BY_BUSINESS_ERROR: error => ({ error }),
})

const fetchSectors = (page, accountId, active) => {
  return async dispatch => {
    const api = getApi(API_ROUTES)
    const status = active ? 'paginated' : 'inactive'
    dispatch(fetchSectorsStart())
    await api.get(`${API_ROUTES.SECTORS}/${status}/${page}/${10}/${accountId}`)
      .then((response) => dispatch(fetchSectorsSuccess(response.data))
      )
      .catch((error) => dispatch(fetchSectorsError(error.response !== undefined ? error.response.data.business_errors : error.message)))
  }
}

const submitSector = sector => {
  return async dispatch => {
    dispatch(submitSectorStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(API_ROUTES.SECTORS, sector)
        .then((response) => {
          dispatch(submitSectorSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitSectorError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    })
  }
}

const editSector = (sector) => {
  return async dispatch => {
    dispatch(submitSectorStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.SECTORS, sector)
        .then((response) => {
          dispatch(submitSectorSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitSectorError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    })
  }
}

const selectSector = (sectorId) => {
  return async dispatch => {
    dispatch(selectedSector(sectorId))
  }
}

const fetchSectorsByAccount = (accountId, businessId) => {
  return async dispatch => {
    const api = getApi(API_ROUTES)
    dispatch(fetchSectorsByAccountStart)
    await api.get(`${API_ROUTES.SECTORS}/simple/${accountId}${businessId ? `?businessId=${businessId}` : ''}`)
      .then((response) => dispatch(fetchSectorsByAccountSuccess(response.data)))
      .catch((error) => dispatch(fetchSectorsByAccountError(error.response !== undefined ? error.response.data.business_errors : error.message)))
  }
}

const fetchSectorsByBusiness = (accountId, businessId) => {
  return async dispatch => {
    const api = getApi(API_ROUTES)
    dispatch(fetchSectorsByBusinessStart)
    await api.get(`${API_ROUTES.SECTORS}/simple/${accountId}?businessId=${businessId}`)
      .then((response) => dispatch(fetchSectorsByBusinessSuccess(response.data)))
      .catch((error) => dispatch(fetchSectorsByBusinessError(error.response !== undefined ? error.response.data.business_errors : error.message)))
  }
}

export {
  fetchSectors, fetchSectorsStart, fetchSectorsSuccess, fetchSectorsError,
  submitSector, submitSectorStart, submitSectorSuccess, submitSectorError,
  editSector, editSectorStart, editSectorSuccess, editSectorError,
  selectedSector, selectSector,
  fetchSectorsByAccount, fetchSectorsByAccountStart, fetchSectorsByAccountSuccess, fetchSectorsByAccountError,
  fetchSectorsByBusiness, fetchSectorsByBusinessStart, fetchSectorsByBusinessSuccess, fetchSectorsByBusinessError,
}
