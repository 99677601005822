import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchAccountsStart,
  fetchAccountsSuccess,
  fetchAccountsError,
  submitAccountStart,
  submitAccountSuccess,
  submitAccountError,
  editAccountStart,
  editAccountSuccess,
  editAccountError,
} = createActions({
  FETCH_ACCOUNTS_START: () => { },
  FETCH_ACCOUNTS_SUCCESS: values => ({ values }),
  FETCH_ACCOUNTS_ERROR: error => ({ error }),
  SUBMIT_ACCOUNT_START: () => { },
  SUBMIT_ACCOUNT_SUCCESS: data => ({ data }),
  SUBMIT_ACCOUNT_ERROR: error => ({ error }),
  EDIT_ACCOUNT_START: () => { },
  EDIT_ACCOUNT_SUCCESS: data => ({ data }),
  EDIT_ACCOUNT_ERROR: error => ({ error }),
})

const fetchAccounts = (page, active) => {
  return async dispatch => {

    const status = active ? 'paginated' : 'inactive'

    dispatch(fetchAccountsStart())

    const api = getApi(API_ROUTES)

    await api.get(`${API_ROUTES.ACCOUNTS}/${status}/${page}/${10}`)
      .then(
        (response) => dispatch(fetchAccountsSuccess(response.data)),
        (error) => dispatch(fetchAccountsError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const searchAccounts = (query, active) => {
  return async dispatch => {
    const status = active ? 'paginated' : 'inactive'
    dispatch(fetchAccountsStart())
    const api = getApi(API_ROUTES)
    await api.get(`${API_ROUTES.ACCOUNTS}/${status}/1/20?name=${query}`)
      .then(
        (response) => dispatch(fetchAccountsSuccess(response.data)),
        (error) => dispatch(fetchAccountsError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const submitAccount = account => {
  return async dispatch => {
    dispatch(submitAccountStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(API_ROUTES.ACCOUNTS, account)
        .then((response) => {
          dispatch(submitAccountSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitAccountError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editAccount = (account) => {
  return async dispatch => {
    dispatch(editAccountStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.ACCOUNTS, account)
        .then(() => {
          dispatch(editAccountSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editAccountError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

export {
  fetchAccounts,
  searchAccounts,
  fetchAccountsStart,
  fetchAccountsSuccess,
  fetchAccountsError,
  submitAccount,
  submitAccountStart,
  submitAccountSuccess,
  submitAccountError,
  editAccount,
  editAccountStart,
  editAccountSuccess,
  editAccountError,
}
