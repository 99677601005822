import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { ConfirmationButton, CancelButton, PageTitleDashboard, Select, DashboardContainer, DashboardInput, StyledSwitch, AddFieldsButton, Loader, MessageBanner, EditFieldsButton } from '@tops/components'
import { Form } from 'antd'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { editAccountLevel, fetchAccountLevelsFields, fetchAllAccountLevels } from '../../../../actions/accountLevels'
import * as yup from 'yup'

const EditAccountLevels = ({ history, location, savedInfo }) => {
  const [fields, setFields] = useState([])
  const [error, setError] = useState(false)
  const [editInfo, setEditInfo] = useState({})
  const [load, setLoad] = useState(false)
  const [success, setSuccess] = useState(false)
  const [validateError, setValidateError] = useState(false)

  const { id } = useParams()

  const dispatch = useDispatch()
  const accountLevels = useSelector(state => state.accountLevels)

  useEffect(() => {
    try {
      dispatch(fetchAllAccountLevels(savedInfo.id, false))
    } catch (error) {
      setError(true)
    }

    if (accountLevels.values.length !== 0) {
      accountLevels.values.forEach((item) => {
        if (item.id === parseFloat(id)) {
          setEditInfo(item)
          setLoad(false)
        }
      })
    } else {
      history.push(`/dashboard/${savedInfo.name.toLowerCase()}/niveles-de-cuenta`)
    }

    dispatch(fetchAccountLevelsFields(id))
    setFields(accountLevels.fields)
  }, [])

  const onSubmit = async (values) => {
    const filtered = Object.fromEntries(Object.entries(values).filter(([key, value]) => {
      if (key === 'id') {
        return values[key]
      }
      return values[key] !== initial[key]
    }))

    await dispatch(editAccountLevel(filtered)).then(
      () => {
        setSuccess(true)
        setTimeout(() => setLoad(true), 1000)
        setTimeout(() => {
          history.goBack()
        }, 1000)

      },
      () => setError(true)
    )
  }

  const validationSchema = yup.object({
    name: yup.string().trim().required(i18n('ERRORS').NAME_ERROR),
    functional_key_name: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    description: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    add_custom_fields: yup.array().of(yup.object().shape({
      name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
      field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
      enum_values: yup.array().when('field_type', {
        is: 6,
        then: yup.array().of(yup.string().trim()
          .min(1, i18n('ERRORS').PARAM_MIN).max(100, i18n('ERRORS').PARAM_MAX)).required(i18n('ERRORS').NEED_CONFIGURATION),
      })
    })),
  })

  const initial = {
    id: id,
    name: editInfo.name || '',
    functional_key_name: editInfo.functional_key_name || '',
    description: editInfo.description || '',
    // has_location: true,
    // has_image: true,
    is_active: location.state.isActive,
    parent_id: editInfo.parent_id || null,
    remove_custom_field_ids: [],
    update_custom_fields: [],
    add_custom_fields: []
  }

  if (accountLevels.isFetching || load) {
    return <Loader />
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LABEL, i18n('SIDEBAR_LINKS').EDIT_LEVEL.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').EDIT_LEVEL} />
        <MessageBanner visible={error} status="error" message={accountLevels.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>

      {accountLevels.isSubmitting && <Loader />}

      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={initial}
        onSubmit={(values) => onSubmit(validationSchema.cast(values))}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit}>
              <DashboardContainer width="600px">
                <div style={{ marginBottom: "30px" }}>
                  <StyledSwitch id="editAccountLevels_isActive" onChange={() => setFieldValue('is_active', !values.is_active)}
                    checked={values.is_active} name="is_active" label={i18n('ACTIVE')} horizontal="true" />
                </div>
                <div style={{ marginBottom: 30 }}>
                  <DashboardInput label={i18n('NAME')} horizontal="true" isRequired={true} error={errors.name} border="true" name="name"
                    value={values.name} onChange={handleChange} width="350px" />
                </div>
                <div style={{ marginBottom: "25px" }}>
                  <StyledSwitch id="editAccountLevels_hasParentLevel" checked={values.parent_id !== null ? true : false}
                    label={i18n('HAS_PARENT_LEVEL')} horizontal="true" name="has_parent" disabled={true} />
                </div>
                {values.parent_id !== null &&
                  <Select items={accountLevels.all} label={i18n('WHICH')} selected={values.parent_id} value={values.parent_id}
                    name="parent_id" horizontal="true" width="350px" border="true" disabled={true} />
                }
                <div style={{ fontWeight: "600" }}>{i18n('FIELDS')}</div>
                <div style={{ marginBottom: "15px" }}>
                  <DashboardInput width="330px" isRequired={true} error={errors.functional_key_name} value={values.functional_key_name}
                    name="functional_key_name" label={i18n('FUNCTIONAL_KEY')} onChange={handleChange} horizontal="true" />
                </div>
                <DashboardInput width="330px" isRequired={true} error={errors.description} value={values.description}
                  name="description" label={i18n('FUNCTIONAL_KEY_IDENTIFIER')} onChange={handleChange} horizontal="true" />
              </DashboardContainer>
              <div style={{ width: 730, padding: '0 15px' }}>
                <EditFieldsButton data={accountLevels.fields} addStructure={'add_custom_fields'} updateStructure={'update_custom_fields'}
                  removeStructure={'remove_custom_field_ids'} />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default EditAccountLevels
