import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { useParams } from 'react-router-dom'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, IconButton, DashboardContainer, DashboardInput,
  SmallIconButton, Loader, MessageBanner, ButtonWithIcon
} from '@tops/components'
import { theme } from '@tops/global_config'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Divider } from 'antd'
import { Formik, FieldArray, getIn } from 'formik'
import * as yup from 'yup'
import { fetchStandardConfiguration, submitStandardConfiguration, editStandardConfiguration } from '../../../../actions/vctAdmin'

const StandardConfiguration = ({ history, savedInfo, location }) => {

  const [error, setError] = useState(false)
  const [load, setLoad] = useState(true)
  const [validateError, setValidateError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [isEditing, setisEditing] = useState(false)
  const businessId = location.state.businessId

  const dispatch = useDispatch()
  const { id } = useParams()

  const vctAdmin = useSelector(state => state.vctAdmin)
  var haveData = vctAdmin.values.length > 0

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchStandardConfiguration(businessId)).then(() => setLoad(false))
      } catch (error) {
        setError(error)
        setLoad(false)
      }
    }
    fetchData()
  }, [])

  const initialValues = {
    add_vct_configuration_dto: [{
      name: "",
      concrete_add_vct_configuration_dto: [
        { name: "" },
        { name: "" }
      ]
    }]
  }

  const validationSchema = yup.object().shape({
    add_vct_configuration_dto: yup.array().of(yup.object().shape({
      name: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
      concrete_add_vct_configuration_dto: yup.array().of(yup.object().shape({
        name: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
      }))
    }))
  })

  const onSubmit = async (values, id) => {
    setLoad(true)
    dispatch(submitStandardConfiguration(values, id))
      .then(() => {
        setLoad(false)
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      })
      .catch((error) => {
        setError(error)
        setLoad(false)
      })
  }

  const initialValuesEdit = {
    i_add_vct_configuration_dto: [],
    parameters_to_deactivate: [],
    parameters_to_re_activate: [],
    i_add_child_vct_configuration_dto: [],
  }

  const validationSchemaEdit = yup.object().shape({
    i_add_vct_configuration_dto: yup.array().of(yup.object().shape({
      name: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
      concrete_add_vct_configuration_dto: yup.array().of(yup.object().shape({
        name: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
      }))
    })),
    i_add_child_vct_configuration_dto: yup.array().of(yup.object().shape({
      name: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
    }))
  })

  const onSubmitEdit = async (values, id) => {
    setLoad(true)
    dispatch(editStandardConfiguration(values, id))
      .then(() => {
        setLoad(false)
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      })
      .catch(() => {
        setError(true)
        setLoad(false)
      })
  }

  const standardOptions = (groupIndex, test2, values, errors, handleChange) => {
    return test2.map((addDto, addDtoIndex) => (
      <div>
        <DashboardInput horizontal="true" border="true" width="295px" name={`add_vct_configuration_dto[${groupIndex}].concrete_add_vct_configuration_dto[${addDtoIndex}].name`} coloredDisabled
          value={values.add_vct_configuration_dto[groupIndex].concrete_add_vct_configuration_dto[addDtoIndex].name}
          error={getIn(errors, `add_vct_configuration_dto[${groupIndex}].concrete_add_vct_configuration_dto[${addDtoIndex}].name`)} onChange={handleChange} disabled={false} placeholder={"Opción"} />
        {addDto.concrete_add_vct_configuration_dto !== undefined && standardOptions(groupIndex, addDto.concrete_add_vct_configuration_dto, values, handleChange)}
      </div>
    ))
  }

  const standardGroup = (handleChange, setFieldValue, values, errors) => (
    <FieldArray name={`add_vct_configuration_dto`}>
      {(arrayHelper) => {
        return <>
          {values.add_vct_configuration_dto !== undefined && values.add_vct_configuration_dto.map((group, groupIndex) => (
            <div>
              <FieldArray name={`add_vct_configuration_dto[${groupIndex}].concrete_add_vct_configuration_dto`}>
                {(arrayHelperBeta) => {
                  return <>
                    <div style={{}}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Grupo de Estandar <div style={{ color: "red" }}>*</div></div>
                        {groupIndex >= 1 &&
                          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "max-content" }}>
                            <ButtonWithIcon small square type="button" text="Eliminar" background={"#ffffff"} color={theme.colors.darkGray} style={{ border: "1px solid #eaecef", fontWeight: "normal" }}
                              icon="Cancel" onClick={() => { arrayHelper.remove(groupIndex) }} />
                          </div>
                        }
                      </div>
                      <div>
                        <DashboardInput horizontal="true" border="true" width="350px" name={`add_vct_configuration_dto[${groupIndex}].name`} coloredDisabled
                          value={values.add_vct_configuration_dto[groupIndex].name} error={getIn(errors, `add_vct_configuration_dto[${groupIndex}].name`)} onChange={handleChange} disabled={false} placeholder={"Estandar"} />
                      </div>
                    </div>
                    <div style={{ paddingLeft: 55 }}>
                      <div>
                        <div style={{ color: "#49494E", display: "flex", fontWeight: "bold", marginBottom: 10 }}>Opciones Estandar Específico <div style={{ color: "red" }}>*</div></div>
                        {group.concrete_add_vct_configuration_dto.map((addDto, addDtoIndex) => (
                          <div style={{ display: "flex" }}>
                            <DashboardInput horizontal="true" border="true" width="295px" name={`add_vct_configuration_dto[${groupIndex}].concrete_add_vct_configuration_dto[${addDtoIndex}].name`} coloredDisabled
                              value={values.add_vct_configuration_dto[groupIndex].concrete_add_vct_configuration_dto[addDtoIndex].name} error={getIn(errors, `add_vct_configuration_dto[${groupIndex}].concrete_add_vct_configuration_dto[${addDtoIndex}].name`)} onChange={handleChange} disabled={false} placeholder={"Opción"} />
                            {addDto.concrete_add_vct_configuration_dto !== undefined && standardOptions(groupIndex, addDto.concrete_add_vct_configuration_dto, values, errors, handleChange)}
                            {addDtoIndex >= 2 &&
                              <IconButton size={34} background="transparent" icon={'TrashBin'} shadow={false}
                                onClick={() => {
                                  arrayHelperBeta.remove(addDtoIndex)
                                }}
                              />
                            }
                          </div>
                        ))}
                      </div>
                      <div style={{ width: "150px" }}>
                        <SmallIconButton text="Agregar opción" size={25} icon={'Add'} textSize={12} type="button" bold
                          onClick={() => {
                            arrayHelperBeta.push({
                              name: "",
                            })
                          }} />
                      </div>
                    </div>
                    <Divider />
                  </>
                }}
              </FieldArray>
            </div>
          ))}
          <div style={{ marginTop: 15, width: "150px" }}>
            <SmallIconButton text="Agregar GRUPO" size={25} icon={'Add'} textSize={12} type="button" bold
              onClick={() => {
                arrayHelper.push({
                  name: "",
                  concrete_add_vct_configuration_dto: [
                    { name: "" },
                    { name: "" }
                  ]
                })
              }} />
          </div>
        </>
      }}
    </FieldArray>
  )

  const standardGroupDetail = (handleChange, setFieldValue, values, errors) => (
    <>
      <FieldArray name={`i_add_vct_configuration_dto`}>
        {(arrayHelper) => {
          return <>
            {vctAdmin.values.map((item, itemIndex) => (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Grupo de Estandar <div style={{ color: "red" }}>*</div></div>
                </div>
                <div>{item.name}</div>
                <div style={{ paddingLeft: 55, marginTop: 10 }}>
                  <div>
                    <div style={{ color: "#49494E", display: "flex", fontWeight: "bold", marginBottom: 5 }}>Opciones Estandar Específico <div style={{ color: "red" }}>*</div></div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {item.children_dto !== undefined && item.children_dto.map((children, childrenIndex) => (<>
                        {children.is_active && /* Si se eliminan, is_active vendrá en false */
                          <FieldArray name={`parameters_to_deactivate`}>
                            {(desactivateHelper) => {
                              return <div style={{ display: "flex", justifyContent: "space-between", width: "175px" }}>
                                {(values?.parameters_to_deactivate?.find((element) => element == children.id) === children.id) ?
                                  <div style={{ marginBottom: 5, color: "red", textDecoration: "line-through" }}>{children.name}</div> :
                                  <div style={{ marginBottom: 5 }}>{children.name}</div>
                                }
                                {values?.parameters_to_deactivate?.find((element) => element == children.id) === children.id ? <div></div> :
                                  <div>
                                    {isEditing &&
                                      <IconButton size={24} background="transparent" icon={'TrashBin'} shadow={false}
                                        onClick={() => { desactivateHelper.push(children.id) }} />
                                    }
                                  </div>}
                              </div>
                            }}
                          </FieldArray>
                        }
                      </>))}
                      <FieldArray name={`i_add_child_vct_configuration_dto`}>
                        {(helperOptionsAdded) => {
                          return <>
                            {isEditing && <>
                              <div>
                                {values.i_add_child_vct_configuration_dto !== undefined && values.i_add_child_vct_configuration_dto.map((provicionalItem, provicionalIndex) => (<>
                                  <div style={{ display: "flex" }}>
                                    {values.i_add_child_vct_configuration_dto[provicionalIndex].index_of_group_detail === itemIndex && <>
                                      <DashboardInput horizontal="true" border="true" width="295px" name={`i_add_child_vct_configuration_dto[${provicionalIndex}].name`} coloredDisabled
                                        value={values.i_add_child_vct_configuration_dto[provicionalIndex].name} error={getIn(errors, `i_add_child_vct_configuration_dto[${provicionalIndex}].name`)} onChange={handleChange} disabled={false} placeholder={"Opción"} />
                                      <IconButton size={34} background="transparent" icon={'TrashBin'} shadow={false}
                                        onClick={() => {
                                          helperOptionsAdded.remove(provicionalIndex)
                                        }}
                                      />
                                    </>}
                                  </div>
                                </>))}
                              </div>
                              <div style={{ width: "150px" }}>
                                <SmallIconButton text="Agregar opción" size={25} icon={'Add'} textSize={12} type="button" bold
                                  onClick={() => {
                                    helperOptionsAdded.push({
                                      name: "",
                                      parent_id: item.id,
                                      concrete_add_vct_configuration_dto: [],
                                      index_of_group_detail: itemIndex
                                    })
                                  }} />
                              </div>
                            </>
                            }
                          </>
                        }}
                      </FieldArray>
                    </div>
                  </div>
                </div>
                <Divider />
              </>
            ))}


            {/* NUEVO GRUPO EDIT */}
            {values.i_add_vct_configuration_dto !== undefined && values.i_add_vct_configuration_dto.map((newItem, newItemIndex) => (<>
              <FieldArray name={`i_add_vct_configuration_dto[${newItemIndex}].concrete_add_vct_configuration_dto`}>
                {(arrayHelperBeta) => {
                  return <>
                    <div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Grupo de Estandar <div style={{ color: "red" }}>*</div></div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "max-content" }}>
                          <ButtonWithIcon small square type="button" text="Eliminar" background={"#ffffff"} color={theme.colors.darkGray} style={{ border: "1px solid #eaecef", fontWeight: "normal" }}
                            icon="Cancel" onClick={() => { arrayHelper.remove(newItemIndex) }} />
                        </div>
                      </div>
                      <DashboardInput horizontal="true" border="true" width="350px" name={`i_add_vct_configuration_dto[${newItemIndex}].name`} coloredDisabled
                        value={values.i_add_vct_configuration_dto[newItemIndex].name} error={getIn(errors, `i_add_vct_configuration_dto[${newItemIndex}].name`)} onChange={handleChange} disabled={false} placeholder={"Estandar"} />
                    </div>
                    <div style={{ paddingLeft: 55 }}>
                      <div>
                        <div style={{ color: "#49494E", display: "flex", fontWeight: "bold", marginBottom: 10 }}>Opciones Estandar Específico <div style={{ color: "red" }}>*</div></div>
                        {newItem.concrete_add_vct_configuration_dto !== undefined && newItem.concrete_add_vct_configuration_dto.map((addDto, addDtoIndex) => (
                          <div style={{ display: "flex" }}>
                            <DashboardInput horizontal="true" border="true" width="295px" name={`i_add_vct_configuration_dto[${newItemIndex}].concrete_add_vct_configuration_dto[${addDtoIndex}].name`} coloredDisabled
                              value={values.i_add_vct_configuration_dto[newItemIndex].concrete_add_vct_configuration_dto[addDtoIndex].name} error={getIn(errors, `i_add_vct_configuration_dto[${newItemIndex}].concrete_add_vct_configuration_dto[${addDtoIndex}].name`)} onChange={handleChange} disabled={false} placeholder={"Opción"} />
                            {addDto.concrete_add_vct_configuration_dto !== undefined && standardOptions(newItemIndex, addDto.concrete_add_vct_configuration_dto, values, errors, handleChange)}
                            {addDtoIndex >= 2 &&
                              <IconButton size={34} background="transparent" icon={'TrashBin'} shadow={false}
                                onClick={() => {
                                  arrayHelperBeta.remove(addDtoIndex)
                                }}
                              />
                            }
                          </div>
                        ))}
                      </div>
                      <div style={{ width: "150px" }}>
                        <SmallIconButton text="Agregar opción" size={25} icon={'Add'} textSize={12} type="button" bold
                          onClick={() => {
                            arrayHelperBeta.push({
                              name: "",
                            })
                          }} />
                      </div>
                    </div>
                    <Divider />
                  </>
                }}
              </FieldArray>
            </>))}

            {isEditing &&
              <div style={{ marginTop: 15, width: "150px" }}>
                <SmallIconButton text="Agregar GRUPO" size={25} icon={'Add'} textSize={12} type="button" bold
                  onClick={() => {
                    arrayHelper.push({
                      name: "",
                      parent_id: null,
                      concrete_add_vct_configuration_dto: [
                        { name: "" },
                        { name: "" }
                      ]
                    })
                  }} />
              </div>
            }
          </>
        }}
      </FieldArray>
    </>
  )

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[savedInfo.wiseName, "VCT", "Configuración Estandar"]} >
        <MessageBanner visible={success} status="success" message={"¡Configuración creada exitosamente!"} />
        <MessageBanner visible={error} status="error" message={"Hubo un error en la aplicación, por favor contáctse con soporte."} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ fontWeight: "bold" }}>Estandar</div>
        {haveData &&
          <div>
            {isEditing ? <>
              <ButtonWithIcon small square type="button" text="Editando..." background={"#ffffff"} color={theme.colors.darkGray} style={{ border: "1px solid #eaecef", fontWeight: "normal" }}
                icon="Edit" disabled={true} />
            </> :
              <ButtonWithIcon small square type="button" text="Editar" background={"#ffffff"} color={theme.colors.darkGray} style={{ border: "1px solid #eaecef", fontWeight: "normal" }}
                icon="Edit" onClick={() => setisEditing(true)} />
            }
          </div>
        }
      </div>
      <Divider />
      {(load || vctAdmin.isFetching || vctAdmin.isSubmiting) && <Loader />}
      <Formik initialValues={haveData ? initialValuesEdit : initialValues}
        enableReinitialize={true}
        validationSchema={haveData ? validationSchemaEdit : validationSchema}
        validateOnChange={false}
        onSubmit={haveData ? (values) => onSubmitEdit(values, id) : (values) => onSubmit(values, id)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="100%">
                {haveData ?
                  standardGroupDetail(handleChange, setFieldValue, values, errors)
                  :
                  standardGroup(handleChange, setFieldValue, values, errors)
                }
              </DashboardContainer>
              {!haveData &&
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                  <ConfirmationButton small type="submit">{i18n('SAVE')}</ConfirmationButton>
                </div>
              }
              {(haveData && !isEditing) &&
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('GO_BACK')}</CancelButton>
                </div>
              }
              {(haveData && isEditing) &&
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                  <ConfirmationButton small type="submit">{i18n('SAVE')}</ConfirmationButton>
                </div>
              }
            </Form>
          </>
        )}
      </Formik>
    </>
  )



}

export default StandardConfiguration