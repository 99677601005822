import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, Select, DashboardContainer, DashboardInput, StyledSwitch, AddFieldsButton, Loader,
  MessageBanner
} from '@tops/components'
import { Formik } from 'formik'
import Form from 'antd/lib/form/Form'
import { useDispatch, useSelector } from 'react-redux';
import { submitBusinessLevel, fetchAllBusinessLevels } from '../../../../actions/businessLevels'
import * as yup from 'yup'
import { Divider } from 'antd'

const CreateBusinessLevels = ({ history, savedInfo }) => {
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [haveWise, setHaveWise] = useState(-1)
  const [showMessage, setShowMessage] = useState(false)
  const [validateError, setValidateError] = useState(false)
  
  

  const dispatch = useDispatch()
  const business = useSelector(state => state.business)
  const businessLevels = useSelector(state => state.businessLevels)

  useEffect(() => {
    if (business.businessId) {
      const fetchData = async () => {
        try {
          dispatch(fetchAllBusinessLevels(business.businessId, false, true))
        } catch (error) {
          setError(true)
        }
      }
      fetchData()

      if (businessLevels.values.length > 0) {
        businessLevels.values.forEach((item) => {
          if (item.is_wise_level) {
            setHaveWise(item.id);
          }
        })
      }
    } else {
      history.push(`/dashboard/${savedInfo.name.toLowerCase()}/niveles-de-negocio`)
    }
    return () => { }
  }, [])

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(i18n('ERRORS').BUSINESS.NAME_ERROR),
    functional_key_name: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    description: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    fields: yup.array().of(yup.object().shape({
      name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
      field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
      enum_values: yup.array().when('field_type', {
        is: 6,
        then: yup.array().of(yup.string().trim()
          .min(1, i18n('ERRORS').PARAM_MIN).max(100, i18n('ERRORS').PARAM_MAX)).required(i18n('ERRORS').NEED_CONFIGURATION),
      })
    }))
  })

  const onSubmit = async (values) => {
    await dispatch(submitBusinessLevel(values)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  if (businessLevels.isFetching) {
    return <Loader />
  }

  const initial = {
    name: '',
    functional_key_name: '',
    description: '',
    fields: [],
    account_id: savedInfo.id,
    business_id: business.businessId,
    has_location: false,
    has_image: false,
    is_wise_level: false,
    is_payroll_level: false,
    
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LABEL, i18n('SIDEBAR_LINKS').CREATE_LEVEL.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').CREATE_LEVEL} />
        <MessageBanner visible={error} status="error" message={businessLevels.error} onAccept={() => setError(false)} />
        <MessageBanner visible={showMessage} onAccept={() => setShowMessage(false)} status="error" message={i18n('ERROR_HAVE_LEVEL_WISE')} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {businessLevels.isSubmitting && <Loader />}
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit}>
              <DashboardContainer width="600px">
                <div style={{ marginBottom: "15px" }}>
                  <DashboardInput label={i18n('NAME')} isRequired={true} error={errors.name} name="name" onChange={handleChange} horizontal="true"
                    border="true" bold="true" width="350px" />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <StyledSwitch id="createBusinessLevels_hasParentLevel" checked={values?.is_payroll_level ? setCheck(false) : check} style={{ fontWeight: '700'}} label={i18n('HAS_PARENT_LEVEL')} horizontal="true" disabled={values?.is_payroll_level}
                    name="active" onChange={(e) => {
                      setCheck(e.target.checked);
                      if (e.target.checked === false)
                        setFieldValue(`parent_id`, null);
                    }} />
                </div>

                {check &&
                  <div style={{ marginBottom: "20px" }}>
                    <Select items={businessLevels.all} label={i18n('WHICH')} onChange={(e) => setFieldValue("parent_id", e)} name="parent_id"
                      horizontal="true" width="350px" border="true" />
                  </div>}

                <div style={{ marginBottom: "20px" }}>
                  <StyledSwitch id="createBusinessLevels_IsWiseLevel" checked={values.is_wise_level} label={i18n('LEVEL_WISE')} horizontal="true" disabled={values?.is_payroll_level}
                    name="is_wise_level" style={{ fontWeight: '700'}} onChange={(e) => {
                      setFieldValue("is_wise_level", e.target.checked);
                      if (e.target.checked === true && haveWise !== -1) {
                        setShowMessage(true);
                        setFieldValue("is_wise_level", false)
                      }
                    }} />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <StyledSwitch id="is_payroll_level" checked={values?.is_payroll_level} label={i18n('ACTIVATE_PAYROLL')} bold="true" horizontal="true"
                    name="is_payroll_level" onChange={(e) => {
                      setFieldValue("is_payroll_level", e.target.checked)
                    }} style={{ fontWeight: '700'}} />
                    <span>El nivel duplicara los usuarios como valores, solo se puede activar un nivel <br/> nómina por negocio. No tendrá nivel padre.</span>
                </div>

                <Divider style={{ width: 700 }} />

                <div style={{ fontWeight: "600", marginBottom: 15 }}>{i18n('FIELDS')}</div>
                <div style={{ marginBottom: "15px" }}>
                  <DashboardInput name="functional_key_name" label={i18n('FUNCTIONAL_KEY')} isRequired={true} error={errors.functional_key_name}
                    onChange={handleChange} width="330px" horizontal="true" />
                </div>
                <DashboardInput name="description" label={i18n('FUNCTIONAL_KEY_IDENTIFIER')} isRequired={true} error={errors.description}
                  onChange={handleChange} width="330px" horizontal="true" />
              </DashboardContainer>

              {<div style={{ width: 730, padding: '0 15px' }}>
                <AddFieldsButton data={values.fields === null ? [] : values.fields } dataStructure="fields" />
              </div>}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default CreateBusinessLevels
