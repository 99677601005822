import { handleActions } from 'redux-actions'
import {
  fetchRolesStart, fetchRolesSuccess,fetchRolesError,
  submitRoleStart, submitRoleSuccess, submitRoleError,
  editRoleStart, editRoleSuccess, editRoleError,
  fetchRolesSimpleStart, fetchRolesSimpleSuccess,fetchRolesSimpleError
} from '../actions/roles'

export const defaultState = {
  values: [],
  simple_values :[],
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [fetchRolesStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchRolesSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchRolesError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitRoleStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitRoleSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitRoleError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editRoleStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editRoleSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editRoleError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [fetchRolesSimpleStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchRolesSimpleSuccess]: (state, { payload: { values } }) => ({
      ...state,
      simple_values: values,
      isFetching: false,
      error: null
    }),
    [fetchRolesSimpleError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
  },
  defaultState
)

export default reducer
