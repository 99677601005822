
import styled, { keyframes, css } from 'styled-components'
import { mediaQueries } from '@tops/global_config'

const sharedInputStyle = css`
  max-width: 100%;
  width: ${({ width }) => width || '300px'};
  ${({ login }) => login && "height: 40px"};
  border: ${({ theme, hasError, value }) => (!hasError) ? `1px solid ${theme.colors.gray}` : `1px solid ${theme.colors.red}`} ;
  font-family: ${({ theme }) => theme.fonts.lato};
  padding:  ${({ hasIcon }) => hasIcon ? "6px 40px" : "6px 12px"} ;
  font-size: 13px;
  color: ${({ textColor, theme }) => textColor || 'inherit'};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  box-shadow: 0 1.5px 2px 0 rgba(18, 20, 24, 0.05);
  transition: box-shadow 0.2s ease-out;
  margin-bottom: ${({ marginBottom }) => marginBottom ? "0px" : "8px"}
  position: relative;

  &:focus {
    border-color: ${({ theme, color, hasError }) => hasError ? theme.colors.red : color || theme.colors.green};

  }

  &:disabled {
    background: ${({ theme, coloredDisabled }) => coloredDisabled ? "#f5f5f5" : theme.colors.white};
    border-color: ${({ theme, coloredDisabled, hasError }) => coloredDisabled ? hasError ? theme.colors.red : "#f5f5f5" : theme.colors.gray};
  }

  &::after {
    content: '';
    background-color: ${({ theme }) => theme.colors.green};
  }

 
`

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  font-size: 14px;
  font-weight: 500;
  justify-content: ${({ horizontal }) => horizontal ? 'space-between' : 'flex-start'};
  ${({ horizontal, width }) => !horizontal && `width: ${width};`}
  ${({ horizontal }) => horizontal && `align-items: baseline;`}

  ${mediaQueries.mobile`
    flex-direction: column;
    width: ${({ width }) => width};
    max-width:100%;
  `}
`

export const SearchContainer = styled.div`
  display:flex;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-end;
  border-bottom: 1px solid #f1f6f9;
  padding: 15px 0;

  ${mediaQueries.mobile`
    flex-direction: column;
    justify-content: center;
    width: ${({ width }) => width};
    max-width:100%;
  `}
`

export const InputContainer = styled.div`
  position: relative;
  width: ${({ width }) => width};
`

export const StyledInput = styled.input`
  ${sharedInputStyle}
  border-radius: 2.5px;
`

export const StyledTextArea = styled.textarea`
  ${sharedInputStyle}
  border-radius: 5px;
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: ${({ sizeLabel }) => sizeLabel ? `${sizeLabel} !important` : 'inherit'};
  color: ${({ theme }) => theme.colors.darkGray};
  line-height: 40px;
  font-weight: ${({ bold }) => bold ? '600' : '500'};
  ${({ horizontal }) => horizontal && "margin-right: 20px"};
`
export const IconWrapper = styled.span`
  cursor: ${({ pointer }) => pointer ? "pointer" : "default"};
  height: fit-content;
  right: ${({ position }) => position === "right" && "10px"};
  left: ${({ position }) => position === "left" && "10px"};
  top:  ${({ withLabel, isLogin }) => withLabel ? "48px" : isLogin ? "12px" : "7px"};
  position: absolute;
  z-index: 1;
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const ErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  animation:${({ hasError }) => hasError ? fadeInDown : fadeInUp} 0.3s ease;
`