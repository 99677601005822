import React from 'react'

import IconNames from './iconNames'
import Icons from './icons'

import { StyledIcon } from './styled'

const Icon = props => {
  if (!props.name || !Icons[props.name]) {
    return null
  }

  const { className, name, color, size, flipIcon } = props
  const { element, height = 0, width = 0 } = Icons[name]

  return (
    <StyledIcon
      flipIcon={flipIcon}
      width={size || width}
      height={size || height}
      color={color}
      {...props}
    >
      {element}
    </StyledIcon>
  )
}

export default Icon

export { IconNames, Icons }
