import styled, { keyframes, css } from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: space-between;
  ${({ bold }) => bold ? 'font-weight: 600;' : 'font-weight: 500;'}
  ${mediaQueries.mobile`
    align-self: center;
    margin: 15px 0;
`}
`

export const YesNoWrapper = styled.div`
  display: flex;
  justify-content: ${({ pendingAll }) => pendingAll ? 'flex-end' : 'center'};
  align-items: center;
  margin-top: ${({ pendingAll }) => pendingAll ? '10px' : '0px'};
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 40px;
  font-size: ${({ fontSize }) => fontSize ? '13px !important' : '14px'};
  ${mediaQueries.mobile`
    margin-right: 20px;
`}
`
export const Yes = styled.div`
  border: ${({ theme }) => `2px solid ${theme.colors.gray}`};
  border-radius: 8px 0 0 8px;
  border-right: none;
  min-width: 40px;
  padding: ${({ yesNoTraining }) => yesNoTraining ? '3px 5px' : '8px 5px;'};
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  transition: border 0.08s linear;
  cursor: pointer;
  &:hover {
    background: #40c568;
    color: white;
  }
  ${({ checked, theme }) => checked && `color: white; background: #4ad474; border: 3px solid #acfdc4; border-right: none;`};
`

export const No = styled.div`
  border: ${({ theme }) => `2px solid ${theme.colors.gray}`};
  border-radius: 0 8px 8px 0;
  border-left: none;
  min-width: 40px;
  padding: ${({ yesNoTraining }) => yesNoTraining ? '3px 5px' : '8px 5px;'};
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  transition: border 0.08s linear;
  cursor: pointer;
  &:hover {
    background: #df5151;
    color: white;
  }
  ${({ checked, theme }) => !checked && `color: white; background: #fd6565; border: 3px solid #fcd6d6; border-left: none;`};
`


export const YesPendingAll = styled.div`
  border: ${({ theme }) => `2px solid ${theme.colors.gray}`};
  border-radius: 8px 0 0 8px;
  border-right: none;
  min-width: 40px;
  padding: 3px 15px;
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  transition: border 0.08s linear;
  cursor: pointer;
  &:hover {
    background:  #24a480 
    color: white;
  }
  ${({ checked, theme }) => checked && `color: white; background: #24a480; border: 3px solid #c2ffee; border-right: none;`};
`

export const NoPendingAll = styled.div`
  border: ${({ theme }) => `2px solid ${theme.colors.gray}`};
  border-radius: 0 8px 8px 0;
  border-left: none;
  min-width: 40px;
  padding:  3px 40px;
  font-size: 13px;
  text-align: center;
  font-weight: normal;
  transition: border 0.08s linear;
  cursor: pointer;
  &:hover {
    background: #24a480;
    color: white;
  }
  ${({ checked, theme }) => !checked && `color: white; background: #24a480; border: 3px solid #c2ffee; border-left: none;`};
`

