import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: space-between;
  ${({ bold }) => bold ? 'font-weight: 600;' : 'font-weight: 500;'}
  ${mediaQueries.mobile`
    align-self: center;
    margin: 15px 0;
`}
`

export const SwitchWrapper = styled.div`
  position:relative;
  padding-left: 30px;
`;

export const SwitchLabel = styled.label`
  position: absolute;
  margin-right: ${({ horizontal }) => horizontal && "20px"};
  left: 0;
  top: 10px;
  width: 42px;
  height: 21px;
  border-radius: 15px;
  background:  ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  box-shadow: inset 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 1px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

export const Switch = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  &:checked + ${SwitchLabel} {
    background:  ${({ theme }) => theme.colors.darkGreen};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      margin-left: 23px;
      transition: 0.2s;
    }
  }
`
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 40px;
  margin-right: ${({ horizontal }) => horizontal ? '10px' : '0px'};
  font-size: ${({ fontSize }) => fontSize ? '13px !important' : '14px'};
  font-weight: ${({ boldText }) => boldText ? 600 : 500}
  ${mediaQueries.mobile`
    margin-right: 20px;
`}
`