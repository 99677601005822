import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchBusinessStart,
  fetchBusinessSuccess,
  fetchBusinessError,
  submitBusinessStart,
  submitBusinessSuccess,
  submitBusinessError,
  editBusinessStart,
  editBusinessSuccess,
  editBusinessError,
  selectedBusiness } = createActions({
    FETCH_BUSINESS_START: () => { },
    FETCH_BUSINESS_SUCCESS: values => ({ values }),
    FETCH_BUSINESS_ERROR: error => ({ error }),
    SUBMIT_BUSINESS_START: () => { },
    SUBMIT_BUSINESS_SUCCESS: data => ({ data }),
    SUBMIT_BUSINESS_ERROR: error => ({ error }),
    EDIT_BUSINESS_START: () => { },
    EDIT_BUSINESS_SUCCESS: data => ({ data }),
    EDIT_BUSINESS_ERROR: error => ({ error }),
    SELECTED_BUSINESS: value => ({ value }),
  })

const fetchBusiness = (page, accountId, active) => {
  return async dispatch => {
    dispatch(fetchBusinessStart())
    const api = getApi(API_ROUTES)
    const status = active ? 'paginated' : 'inactive'

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.BUSINESS}/${status}/${page}/${10}/${accountId}`)
        .then((response) => {
          dispatch(fetchBusinessSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchBusinessError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchAllBusiness = (accountId) => {
  return async dispatch => {
    dispatch(fetchBusinessStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.BUSINESS}/simple/${accountId}`)
        .then((response) => {
          dispatch(fetchBusinessSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchBusinessError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const submitBusiness = business => {
  return async dispatch => {
    dispatch(submitBusinessStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(API_ROUTES.BUSINESS, business)
        .then((response) => {
          dispatch(submitBusinessSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitBusinessError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editBusiness = (business) => {
  return async dispatch => {
    dispatch(editBusinessStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.BUSINESS, business)
        .then((response) => {
          dispatch(editBusinessSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editBusinessError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const selectBusiness = (businessId) => {
  return async dispatch => {
    dispatch(selectedBusiness(businessId))
  }
}

const resetSelectedBusiness = () => {
  return async dispatch => {
    dispatch(selectedBusiness(undefined))
  }
}



export {
  fetchBusiness,
  fetchAllBusiness,
  fetchBusinessStart,
  fetchBusinessSuccess,
  fetchBusinessError,
  submitBusiness,
  submitBusinessStart,
  submitBusinessSuccess,
  submitBusinessError,
  editBusiness,
  editBusinessStart,
  editBusinessSuccess,
  editBusinessError,
  selectedBusiness,
  selectBusiness,
  resetSelectedBusiness,
}
