import React, { useState } from 'react'
import styled from 'styled-components'
import { Layout, Menu } from 'antd'
import { mediaQueries, theme, isMobile } from '@tops/global_config'
import Icon, { IconNames } from './../Icons';
import { IconButton } from './../IconButton';
import { Title } from '../Popup/styled';


export const SideBar = styled(Layout.Sider)`
  background: ${({ theme, background }) => background || theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.lato};
  .ant-menu-inline .ant-menu-item{
    width: 100%;
    font-size: 12px;
    margin-bottom: 1px;
    padding: 0;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: ${({ theme }) => theme.colors.primary};
  }

  .ant-menu-item::after{
    border:none;
  }

  .ant-menu-item:hover{
    color: ${({ theme }) => theme.colors.white};
  
  }

  .ant-layout-sider-zero-width-trigger {
    background-color: ${({ theme }) => theme.colors.primary};
    top: 50px;
    border-radius: 0 5px 5px 0;
    right: -28px;
    width: 28px;
    height: 38px;
  }

  ${mediaQueries.mobile`
  position: absolute;
  z-index: 1000;
`}
`

export const UserSideBar = styled.div`
  background: ${({ theme, background }) => background || theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.lato};
  height: auto;
  border-right: 1px solid ${({ theme }) => theme.colors.gray};
  width: 45px;
  &:hover {
    width: 190px;
  }
  transition: all 0.5s ease 1s;

  @media (max-width: 1365px){
    display: ${({ toggleMenu }) => toggleMenu ? "block" : "none"};
    position: absolute;
    z-index: 1000;
    top: 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
    width: 250px;
    &:hover {
      width: 250px;
    }
    padding-bottom: 10px;
  }

${mediaQueries.mobile`
  height: 100%;
`}
`

export const StyledMenu = styled(Menu)`
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.oldGray};
  border:none;
  padding-left: 40px;
  
  .ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.white};
    border:none;
  }

  ${mediaQueries.tablet`
    line-height: normal;
`}
`

const handleFillColor = icon => {
  switch (icon) {
    case "Home":
      return "#6247C0";
    case "Requests":
      return theme.colors.user.requests.primary;
    case "Expirations":
      return theme.colors.user.expiration_date.primary;
    case "Levels":
      return theme.colors.user.levels.primary;
    case "Wise":
      return theme.colors.user.wise.primary;
    case "Users":
      return theme.colors.user.users.primary;
    case "Trainings":
      return theme.colors.user.trainings.primary;
    case "Divisions":
      return theme.colors.user.divisions.primary;
    case "Checklists":
      return theme.colors.user.checklists.primary;
    case "Audits":
      return theme.colors.user.audits.primary;
    case "Schedule":
      return theme.colors.user.schedule.primary;
    case "Inventory":
      return theme.colors.user.inventory.primary;
    case "Dashboard":
      return theme.colors.user.dashboard.primary;
    default: "#fff";
  }
};

const handleBackgroundColor = icon => {

  switch (icon) {
    case "Home":
      return "#b9a5fd";
    case "Requests":
      return theme.colors.user.requests.primary;
    case "Expirations":
      return theme.colors.user.expiration_date.primary;
    case "Levels":
      return theme.colors.user.levels.primary;
    case "Wise":
      return theme.colors.user.wise.primary;
    case "Users":
      return theme.colors.user.users.primary;
    case "Trainings":
      return theme.colors.user.trainings.primary;
    case "Divisions":
      return theme.colors.user.divisions.primary;
    case "Checklists":
      return theme.colors.user.checklists.primary;
    case "Audits":
      return theme.colors.user.audits.primary;
    case "ConfigSurveys":
      return theme.colors.user.audits.primary;
    case "Ranking":
      return theme.colors.user.audits.primary;
    case "Schedule":
      return theme.colors.user.schedule.primary;
    case "Inventory":
      return theme.colors.user.inventory.primary;
    case "Dashboard":
      return theme.colors.user.dashboard.primary;
    case "Help":
      return "#8124ac";
    default: "#fff";
  }
};

export const UserMenu = styled(Menu)`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  @media (max-width: 1365px){
    padding-left: 0;
    margin-top: 0px;
  }
`

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  min-height: 30px;
  background-color: ${({ iconname, selectedkey }) => iconname === selectedkey ? handleBackgroundColor(iconname) : "transparent"};
  color: ${({ iconname, selectedkey, theme }) => iconname === selectedkey ? "white" : theme.colors.darkGray};
  font-weight: ${({ iconname, selectedkey }) => iconname === selectedkey ? "bold" : "normal"};
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  ${({ iconname, selectedkey, isWise }) => {
    if (iconname === "Wise" && selectedkey === "Wise") {
      if (isWise) {
        return `& div svg path:first-child {fill: #fff}
        & div svg path:nth-child(2) {fill: #fe9800}
        & div svg path:nth-child(3) {fill: #4AD474}`
      } else {
        // return `& div svg {
        //     fill:  ${handleFillColor(iconname)};
        //   }`
        return `& div svg {
             fill: white;
           }`
      }
    }
  }
  }}

  &:hover {
    color: white;
    font-weight: bold;
    background: ${({ iconname }) => handleBackgroundColor(iconname)};
    ${({ iconname, selectedkey, isWise }) => {
    if (isWise) {
      return `& div svg path:first-child {fill: #fff}
                  & div svg path:nth-child(2) {fill: #fe9800}
                  & div svg path:nth-child(3) {fill: #4AD474}`
    }
    // else if (!isWise) {
    //   return `& img {
    //       filter: none;
    //     }`
    else if (!isWise) {
      return `& div svg {
          fill: white;
        }`
    }
  }}
}

${mediaQueries.tablet`
    border-bottom: 1px solid #f0f0f0;
    min-height: 40px;
`}
`

export const LogoWrapper = styled.img`
  width: ${({ width }) => width && `${width}px`};
  padding: 20px 20px 40px 25px;
  margin-bottom: ${({ marginBottom }) => marginBottom && `${marginBottom}px`};
`

export const Back = styled.div`
  padding: 0 5px;
  display:flex;
  align-items: center;
  cursor: pointer
`

export const IconWrapper = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.accent};
  width: 25px;
  height: 25px;
  margin-right: 7px;
  box-shadow: inset 0px 0px 5px 2px rgba(0,0,0,0.35);
`

export const TitleWrapper = styled.div`
font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`

export const StyledSideMenu = styled(Layout.Sider)`
resize: none;
background: ${({ theme, background }) => background || theme.colors.primary};
overflow-y: auto;
font-family: ${({ theme }) => theme.fonts.lato};
height: 100%;
border-right: 1px solid ${({ theme }) => theme.colors.gray};
padding-top: 50px;

.ant-menu-inline .ant-menu-item{
  width: 100%;
  margin:0;
  padding: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 900;
}

.ant-menu-item:hover{
  color: ${({ theme }) => theme.colors.darkGray};
  background: ${({ theme }) => theme.colors.gray};

}

.ant-menu-item::after{
  border:none;
}

.ant-layout-sider-zero-width-trigger {
  background-color: ${({ theme }) => theme.colors.gray};
  top: 50px;
  border-radius: 0 5px 5px 0;
  right: -28px;
  width: 28px;
  height: 38px;
}

${mediaQueries.mobile`
position: absolute;
height: 100%;
z-index: 1000;
.ant-layout-sider {
  width: 190px;
}
`}
`

const SideMenuItem = styled(Menu.Item)`
  padding-left: 25px !important;
  font-size: 13px;
  margin: 0 !important;
  color: ${({ theme }) => theme.colors.darkGray};
`

const SideMenuIcon = styled.div`
  position: absolute;
  left: ${({ mouseEnter }) => mouseEnter ? '170px' : '30px'};
  top: 70px;
  z-index: 100;
  transition: all 0.5s ease 1s;
  ${mediaQueries.mobile`
    width: 170px;
`}
`

export const Side = ({ children, onBack, logo }) => {
  return (
    <SideBar breakpoint="md" collapsedWidth="0" width="190px">
      <LogoWrapper src={logo} width={180} marginBottom={40} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Back onClick={onBack}>
          <Icon name={IconNames['Back']} size={35} />
          <IconWrapper>
            <Icon name={IconNames['User']} size={28} color="white" />
          </IconWrapper>
        </Back>
        <TitleWrapper style={{ cursor: 'default' }}>
          Cuenta
        </TitleWrapper>
      </div>
      {children}
    </SideBar>
  )
}

export const UserSide = ({ children, logo, selectedkey, ...props }) => {
  return (
    <UserSideBar background={theme.colors.white} selectedkey={selectedkey} {...props}>
      {children}
    </UserSideBar>
  )
}

export const SideMenu = ({ items, title, setItemKey, menuKey, mouseEnter, ...props }) => {
 

  const [collapse, setCollapse] = useState(false)

  return (<div>
    <SideMenuIcon mouseEnter={mouseEnter}>
      <IconButton onClick={() => setCollapse(!collapse)} icon={'Back'} size={25} background={theme.colors.gray} iconColor={theme.colors.darkLilac} flipIcon={collapse} />
    </SideMenuIcon>
    <StyledSideMenu breakpoint="md"
      collapsedWidth="0"
      width={"260px"}
      background={theme.colors.white}
      collapsed={collapse}
    >
      <p style={{ marginLeft: 25, marginBottom: 35, fontWeight: 900, color: handleFillColor(menuKey), fontSize: 16 }}>{title}</p>
      <Menu {...props}>
        {items.length !== 0 && items.map((e) => <SideMenuItem key={`${e.id}`} >{e.name}</SideMenuItem>)}
      </Menu>
    </StyledSideMenu>
  </div>
  )
}