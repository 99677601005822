import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const {
    getUpstreamTicketIdStart,
    getUpstreamTicketIdSuccess,
    getUpstreamTicketIdError,
    getUpstreamTicketFileStart,
    getUpstreamTicketFileSuccess,
    getUpstreamTicketFileError,
} = createActions({
    GET_UPSTREAM_TICKET_ID_START: () => { },
    GET_UPSTREAM_TICKET_ID_SUCCESS: () => { },
    GET_UPSTREAM_TICKET_ID_ERROR: error => ({ error }),
    GET_UPSTREAM_TICKET_FILE_START: () => { },
    GET_UPSTREAM_TICKET_FILE_SUCCESS: values => ({ values }),
    GET_UPSTREAM_TICKET_FILE_ERROR: error => ({ error }),
})

const getUpstreamTicketId = () => {
    return async dispatch => {
        dispatch(getUpstreamTicketIdStart())
        const api = getApi(API_ROUTES)

        return new Promise((resolve, reject) => {
            api.post(`${API_ROUTES.UPSTREAM_TICKET_ID}`)
                .then((response) => {
                    dispatch(getUpstreamTicketIdSuccess())
                    resolve(response.data)
                }).catch((error) => {
                    dispatch(getUpstreamTicketIdError(error.response !== undefined ? error.response.data.business_errors : error.message))
                    reject()
                })
        }
        )
    }
}

const getUpstreamTicketFile = (fileDto) => {
    return async dispatch => {
        dispatch(getUpstreamTicketFileStart())
        const api = getApi(API_ROUTES)

        return new Promise((resolve, reject) => {
            api.post(`${API_ROUTES.UPSTREAM_TICKET_FILE}`, fileDto)
                .then((response) => {
                    dispatch(getUpstreamTicketFileSuccess(response))
                    resolve(response.data)
                }).catch((error) => {
                    dispatch(getUpstreamTicketFileError(error.response !== undefined ? error.response.data.business_errors : error.message))
                    reject()
                })
        }
        )
    }
}


const getUpstreamTicketFileNew = (fileDto) => {
    return async dispatch => {
        dispatch(getUpstreamTicketFileStart())
        const api = getApi(API_ROUTES)

        return new Promise((resolve, reject) => {
            api.post(`${API_ROUTES.UPSTREAM_TICKET_FILE}/form-data`, fileDto)
                .then((response) => {
                    dispatch(getUpstreamTicketFileSuccess(response))
                    resolve(response.data)
                }).catch((error) => {
                    dispatch(getUpstreamTicketFileError(error.response !== undefined ? error.response.data.business_errors : error.message))
                    reject()
                })
        }
        )
    }
}

export {
    getUpstreamTicketId,
    getUpstreamTicketIdStart,
    getUpstreamTicketIdSuccess,
    getUpstreamTicketIdError,
    getUpstreamTicketFile,
    getUpstreamTicketFileNew,
    getUpstreamTicketFileStart,
    getUpstreamTicketFileSuccess,
    getUpstreamTicketFileError,
}
