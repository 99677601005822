import React from 'react'
import styled from 'styled-components'
import { isMobile, isTablet, theme } from '@tops/global_config'
import { IconNames, StyledCheckbox, IconButton } from '@tops/components'
import Icon from './../../Icons'
import { mediaQueries } from '@tops/global_config'
import { Tooltip } from 'antd'
import { i18n } from '@tops/services'

const Table = styled.table`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 14px;
  margin: 20px 0px 0px 0px;
  table-layout: ${({ audits }) => audits ? 'auto' : 'fixed'};

  ${mediaQueries.tablet`
    font-size: smaller;
    table-layout: ${({ audits }) => audits ? 'auto' : 'fixed'};
   `}
`

const TitleHead = styled.thead`
  font-weight: 500;
  border-bottom: ${({ withOutLine, theme, details, withOutShadow }) => withOutLine && details.length ? `1px solid ${theme.colors.gray}` : withOutShadow ? "none" : 'none'};
 `

const Title = styled.th`
  padding: 8px 10px;
  text-align: left;
  max-width: 100px;
  vertical-align: bottom;
  //  &: first-child {
  //   padding:  ${({ withOutLine }) => withOutLine ? '10px 10px 10px 0px' : "10px 10px 10px 15px"};
  //  }
 `

const TableRow = styled.tr`
  width: 100%;
  border-bottom: ${({ withOutLine, theme, withOutShadow }) => withOutLine ? "none" : withOutShadow ? "none" : `1px solid ${theme.colors.gray}`};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  z-index: -1;
  &: last-child {
    border: none;
  }
  ${mediaQueries.mobile`
  padding: 15px 0;`}
`

const TableBody = styled.tbody`
  box-shadow: ${({ withOutLine, theme, withOutShadow }) => withOutLine ? 'none' : withOutShadow ? 'none' : `0px 0px 0px 1px ${theme.colors.gray}, -1px 3px 7px 1px ${theme.colors.gray}`};
  border-radius: 5px;
  border-bottom: ${({ withOutShadow }) => withOutShadow ? "none" : `1px solid ${theme.colors.gray}`};
  transition: all 1s ease-in-out;
  ${({ hasRanking }) => hasRanking && `
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #24a480;
  `}
`
const TableDetail = styled.td`
  padding: 8px 10px;
  font-weight: normal;
  ${({ usersPaginated }) => usersPaginated && "width: 300px;"}
  max-width: ${({ audits }) => audits ? '300px' : '150px'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ hasRanking }) => hasRanking && `
  margin: 1px;
  &: last-child {
   padding: 8px 20px 8px 0px;
   display: flex;
  }
  `}

  &: last-child {
    padding: 8px 10px 8px 10px;
    display: flex;
    ${({ hasRanking }) => !hasRanking && "max-width: 100%; justify-content: flex-end;"}
   }
   
   &: first-child {
    padding: ${({ withOutLine }) => withOutLine ? '8px 10px 8px 0px' : "8px 10px 8px 15px"};
    width: ${({ position }) => position && '15%'};
   }

   ${mediaQueries.tablet`
   &: first-child {
    min-width: 200px;
   }

   &: last-child {
    display: revert;
    vertical-align: middle;
    height: 60px;
    }

   white-space: normal;
    height: 60px;
   `}
 `

const Tag = styled.div`
  background-color: ${({ theme }) => theme.colors.user.wise.secondary};
  color: ${({ theme }) => theme.colors.user.wise.primary};
  padding: .5em 1em;
  text-align: center;
  width: fit-content;
  font-size: 13px;
  border-radius: 8px;
`
export const ListDetailsTitleColumns = ({ deleteSelection, multipleSelectionActions, children, first, second, third, details, withOutLine, withOutShadow, hasRanking, audits }) => {
  const { show, disabledMultiple, set, array, data, masive } = multipleSelectionActions ?? {}
  const { deleteOptionSelected, setRequestToDelete, requestToDelete, setShowDeleteRequestPopup } = deleteSelection ?? {}
  const mobile = isMobile()

  const pending = !masive ? data?.filter((dat) => {
    if (dat.state === 0) {
      return dat
    }
  }) : data

  return <div>
    <Table style={{ width: "100%" }} audits={audits}>

      {!mobile && <TitleHead withOutLine={withOutLine} withOutShadow={withOutShadow} details={details}>
        <tr>
          {show && <Title>
            <StyledCheckbox disabled={disabledMultiple} style={{ margin: 0, justifyContent: "center", padding: 0 }} color={theme.colors.user.requests} checked={array.length === pending.length}
              onChange={(e) => e.target.checked ? set(pending.map((pend) => pend.id)) : set([])} /></Title>}
          {details ? details.map((detail, i) => (detail !== undefined && detail !== null && typeof detail !== 'boolean') && <Title key={i} withOutLine={withOutLine} withOutShadow={withOutShadow}>{detail}</Title>) :
            <>
              <Title>{first ? first : ''}</Title>
              <Title>{second ? second : ''}</Title>
              <Title>{third ? third : ''}</Title>
            </>
          }
        </tr>
      </TitleHead>}

      <TableBody withOutLine={withOutLine} withOutShadow={withOutShadow} hasRanking={hasRanking}>
        {children}
      </TableBody>
    </Table>
  </div>
}

export const MobileListItem = ({ children, disabled, action, withOutLine, withOutShadow }) => {
  return <TableRow onClick={(e) => {
    e.stopPropagation()
    action(e)
  }} disabled={disabled} withOutLine={withOutLine} withOutShadow={withOutShadow} style={{ fontSize: 14 }}>
    <TableDetail>{children}</TableDetail>
  </TableRow >
}


export const ListItemWithoutSpace = ({ deleteSelection, multipleSelectionActions, item, action, onClick, disabled, icon, iconColor, dobleIconColor, iconSize, dobleIconSize, details, withOutLine, withOutShadow, wiseName, dobleIcon, hasRanking, audits, position, trainingAssistance, usersPaginated, scheduleRequestList = false, setForwardStorePopup, setForwardId, isAdmin, isSectorAdmin, businessId, idSector, currentSectorAdmin }) => {
  const { show, set, array, disabledMultiple, tooltipText } = multipleSelectionActions ?? {}
  const { deleteOptionSelected, setRequestToDelete, requestToDelete, setShowDeleteRequestPopup } = deleteSelection ?? {}
  const tablet = isTablet()

 
 
  
  if (tablet) {
    return <TableRow onClick={() => action && action(action)} disabled={disabled} withOutLine={withOutLine} withOutShadow={withOutShadow}>
      {show && <Tooltip title={disabledMultiple && tooltipText}>
        <td>
          <StyledCheckbox style={{ margin: 0, justifyContent: "center", padding: 0 }} color={theme.colors.user.requests}
            checked={array.includes(item.id)}
            disabled={disabledMultiple}
            value={item.id}
            onChange={(e) => {
              e.target.checked ? set([...array, item.id]) : set(array.filter((id) => id !== item.id))
            }} />
        </td>
      </Tooltip>}

      {item?.id && !scheduleRequestList && <Tooltip overlayStyle={{ zIndex: 10000 }} overlayInnerStyle={{ overflow: "hidden", textOverflow: "ellipsis" }}
        title={(item?.name !== null && item?.name?.length >= 40) ? item?.name?.length > 40 ? item?.name?.substring(0, 40) + "..." : item?.name : null}>
        <TableDetail withOutLine={withOutLine} hasRanking={hasRanking} style={{ whiteSpace: "nowrap" }}>{item?.id}<br />{item?.name}</TableDetail>
      </Tooltip>}

      {details ? details.map((detail, i) => (detail !== undefined && detail !== null && typeof detail !== 'boolean') &&
        <Tooltip overlayStyle={{ zIndex: 10000 }} overlayInnerStyle={{ overflow: "hidden", textOverflow: "ellipsis" }} key={i}
          title={(detail !== null && detail.length >= 20) ? detail.length > 500 ? detail.substring(0, 500) + "..." : detail : null}>
          <TableDetail usersPaginated={usersPaginated} audits={audits} withOutLine={withOutLine} hasRanking={hasRanking}>{typeof detail === "string" && detail.length > 30 ? detail.substring(0, 30) + "..." : detail}</TableDetail>
        </Tooltip>) :
        <>
          <TableDetail>{item.title || item.name || item.id}</TableDetail>
          <TableDetail>{item.entity_name || (item.is_wise_admin ? (<Tag>{`Admin ${wiseName}`}</Tag>) : '')}</TableDetail>
          <TableDetail>{item.email}</TableDetail>
        </>
      }
      {icon && action &&
        <TableDetail icon={icon} action={action} dobleIcon={dobleIcon} style={{ alignItems: "center" }}>
          {dobleIcon && <Tooltip title={i18n('USER').REQUESTS.VIEW.EXPIRED_SCHEDULING}>
            <Icon name={IconNames[dobleIcon]} size={dobleIconSize || 35} color={dobleIconColor ? dobleIconColor : "#49494e"} />
          </Tooltip>}
          {(item.state === 'Finalizada' || item.state === 'Rechazada') && (isAdmin || (isSectorAdmin && currentSectorAdmin)) && businessId === 1 &&  idSector === 90 && <Tooltip placement='top' title="Reenviar tienda">
                          <Icon name="forward" size={35} color={theme.colors.darkGray} style={{ marginRight: '5px', cursor: "pointer", marginBottom: '5px' }}  onClick={() => {setForwardStorePopup(true); setForwardId(item.id)}}/>
          </Tooltip>}
          {icon === "TrashBin" && <Icon name={IconNames[icon]} size={iconSize || 38} color={iconColor || theme.colors.user.wise.darkGreen} onClick={() => action(action)} style={{ cursor: 'pointer' }} />}
        </TableDetail>
      }
      <>
        {deleteOptionSelected === 1 &&
          <td>
            <StyledCheckbox style={{ margin: 0, justifyContent: "center", padding: 0 }} color={theme.colors.user.requests}
              checked={requestToDelete.includes(item.id)}
              disabled={false}
              value={item.id}
              onChange={(e) => {
                e.target.checked ? setRequestToDelete([...requestToDelete, item.id]) : setRequestToDelete(requestToDelete.filter((id) => id !== item.id))
              }} />
          </td>
        }
        {deleteOptionSelected === 0 &&
          <TableDetail style={{ alignItems: "center" }}>
            <IconButton shadow={false} background="white" icon="TrashBin" iconColor={"#5e7680"} size={26}
              onClick={() => {
                setRequestToDelete([...requestToDelete, item.id])
                setShowDeleteRequestPopup(true)
              }} />
          </TableDetail>
        }
      </>
    </TableRow>
  } else {
    return <TableRow onClick={onClick} disabled={disabled} withOutLine={withOutLine} withOutShadow={withOutShadow}>
      {show && <Tooltip title={disabledMultiple && tooltipText}>
        <td>
          <StyledCheckbox style={{ margin: 0, justifyContent: "center", padding: 0 }} color={theme.colors.user.requests}
            checked={array.includes(item.id)}
            disabled={disabledMultiple}
            value={item.id}
            onChange={(e) => {
              e.target.checked ? set([...array, item.id]) : set(array.filter((id) => id !== item.id))
            }} />
        </td>
      </Tooltip>}
      {details ? details.map((detail, i) => (detail !== undefined && detail !== null && typeof detail !== 'boolean') &&
        <Tooltip overlayStyle={{ zIndex: 10000 }} overlayInnerStyle={{ overflow: "hidden", textOverflow: "ellipsis" }} key={i}
          title={(detail !== null && detail.length >= 20) ? detail.length > 500 ? detail.substring(0, 500) + "..." : detail : null}>
          <TableDetail usersPaginated={usersPaginated} audits={audits} position={position} withOutLine={withOutLine} hasRanking={hasRanking} style={{ maxWidth: typeof detail !== "string" && "100%", justifyContent: trainingAssistance ? 'flex-start' : ' ' }}>{detail}</TableDetail>
        </Tooltip>) :
        <>
          <TableDetail>{item.title || item.name || item.id}</TableDetail>
          <TableDetail>{item.entity_name || (item.is_wise_admin ? (<Tag>{`Admin ${wiseName}`}</Tag>) : '')}</TableDetail>
          <TableDetail>{item.email}</TableDetail>
        </>
      }
      {icon && action &&
        <TableDetail icon={icon} action={action} dobleIcon={dobleIcon} style={{ alignItems: "center" }}>
          {deleteSelection && <>
            {deleteOptionSelected !== null ? <>
              {deleteOptionSelected === 1 &&
                <td>
                  <StyledCheckbox style={{ margin: 0, justifyContent: "center", padding: 0 }} color={theme.colors.user.requests}
                    checked={requestToDelete.includes(item.id)}
                    disabled={false}
                    value={item.id}
                    onChange={(e) => {
                      e.target.checked ? setRequestToDelete([...requestToDelete, item.id]) : setRequestToDelete(requestToDelete.filter((id) => id !== item.id))
                    }} />
                </td>
              }
              {deleteOptionSelected === 0 &&
                <IconButton shadow={false} background="white" icon="TrashBin" iconColor={"#5e7680"} size={26}
                  onClick={() => {
                    setRequestToDelete([...requestToDelete, item.id])
                    setShowDeleteRequestPopup(true)
                  }} />
              }
            </> :
              <>
                {dobleIcon && <Tooltip title={i18n('USER').REQUESTS.VIEW.EXPIRED_SCHEDULING}>
                  <Icon name={IconNames[dobleIcon]} size={dobleIconSize || 35} color={dobleIconColor ? dobleIconColor : "#49494e"} style={{ marginRight: 5 }} />
                </Tooltip>}
                {(item.state === 'Finalizada' || item.state === 'Rechazada') && (isAdmin ||(isSectorAdmin && currentSectorAdmin)) && businessId === 1 &&  idSector === 90 && <Tooltip placement='top' title="Reenviar tienda">
                          <Icon name="forward" size={35} color={theme.colors.darkGray} style={{ marginRight: '5px', cursor: "pointer", marginBottom: '5px' }}  onClick={() => {setForwardStorePopup(true); setForwardId(item.id)}}/>
                </Tooltip>}
                <Icon name={IconNames[icon]} size={iconSize || 38} color={iconColor || theme.colors.user.wise.darkGreen} onClick={() => action(action)} style={{ cursor: 'pointer' }} />
              </>}
          </>}
          {!deleteSelection && <>
            {dobleIcon && <Tooltip title={i18n('USER').REQUESTS.VIEW.EXPIRED_SCHEDULING}>
              <Icon name={IconNames[dobleIcon]} size={dobleIconSize || 35} color={dobleIconColor ? dobleIconColor : "#49494e"} style={{ marginRight: 5 }} />
            </Tooltip>}
            {(item.state === 'Finalizada' || item.state === 'Rechazada') && (isAdmin || (isSectorAdmin && currentSectorAdmin)) && businessId === 1 &&  idSector === 90 && <Tooltip placement='top' title="Reenviar tienda">
                          <Icon name="forward" size={35} color={theme.colors.darkGray} style={{ marginRight: '5px', cursor: "pointer", marginBottom: '5px' }}  onClick={() => {setForwardStorePopup(true); setForwardId(item.id)}}/>
            </Tooltip>}
            <Icon name={IconNames[icon]} size={iconSize || 38} color={iconColor || theme.colors.user.wise.darkGreen} onClick={() => action(action)} style={{ cursor: 'pointer' }} />
          </>}
        </TableDetail>
      }
    </TableRow>
  }

}
