import styled from 'styled-components'
import { Steps } from 'antd'

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
`

export const StyleStep = styled(Steps.Step)`
  .ant-steps-item-container {
    padding-bottom: 20px;
  }

  & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.25);
  }
`

export const StyleSteps = styled(Steps)`
  box-shadow: 0px -1px 0 0 #e8e8e8 inset;
  font-weight: normal;

  .ant-steps-item-active .ant-steps-item-icon {
    background: transparent;
    border: 1.8px solid ${({ theme }) => theme.colors.user.wise.primary} !important;
  }

  .ant-steps-item-icon > span {
    font-family: ${({ theme }) => theme.fonts.lato};
  }


  .ant-steps-item-active .ant-steps-item-icon > span {
    color: ${({ theme }) => theme.colors.user.wise.primary} !important;
  }

  .ant-steps-item-active .ant-steps-item-title {
    color: ${({ theme }) => theme.colors.user.wise.primary} !important;
    font-weight: bold;
  }

  .ant-steps-item-active .ant-steps-item-description {
    color: ${({ theme }) => theme.colors.user.wise.primary} !important;
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({ theme }) => theme.colors.user.wise.primary};
  }
  .ant-steps-item-finish .ant-steps-item-icon svg {
    color: ${({ theme }) => theme.colors.user.wise.primary};
  }

  .ant-steps-item-active::before {
    background: ${({ theme }) => theme.colors.user.wise.primary};
  }

`