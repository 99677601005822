import styled from 'styled-components'

export const TitleHead = styled.th`
  font-weight: bold;
  padding: 10px;
  border: 1px solid #f0f0f0;
  background: #FAFAFA;
 `

 export const TableData = styled.td`
  padding: 10px;
  border: 1px solid #f0f0f0;
  text-aling: center;
 `
