import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import {
  SmallIconButton, ConfirmationButton, CancelButton, PageTitleDashboard, StyledCheckbox, DashboardContainer, DashboardInput, Loader,MessageBanner,
  AddFieldsButton, MessageError, UploadButton, Icon
} from '@tops/components'
import { Formik, FieldArray, getIn } from 'formik'
import { Form } from 'antd'
import { submitBusiness } from '../../../../actions/business'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { useParams } from 'react-router-dom';
import { fetchAllAccountLevels } from '../../../../actions/accountLevels'
import { theme } from '@tops/global_config'
import { getUpstreamTicketFile, getUpstreamTicketId } from '../../../../actions/files'
import { toBase64 } from '@tops/utils'


const CreateBusiness = ({ history, savedInfo }) => {

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [load, setLoad] = useState(false)
  const [validateError, setValidateError] = useState(false)
  const [upstreamTicket, setUpstreamTicket] = useState(null)

  const [mouseEnter, setMouseEnter] = useState(false)

  const { id } = useParams()

  const dispatch = useDispatch()
  const business = useSelector(state => state.business)
  const accountLevels = useSelector(state => state.accountLevels)


  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchAllAccountLevels(savedInfo.id, false))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
  }, [])

  const onSubmit = async (values) => {
    if(values.image_extension == "image/svg+xml"){
      Object.assign(values, {image_extension: "svg"})
    }
    await dispatch(submitBusiness(values)).then(
      () => {
        setSuccess(true)
        setTimeout(() => setLoad(true), 1000)
        setTimeout(() => {
          history.goBack()
        }, 1000)

      },
      () => setError(true)
    )
  }

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(i18n('ERRORS').BUSINESS.NAME_ERROR),
    entity_configuration_ids: yup.array().required(i18n('ERRORS').BUSINESS.NOT_ENOUGH_ITEMS_ERROR),
    post_user_dto: yup.object().shape({
      name: yup.string().trim().required(i18n('ERRORS').BUSINESS.USER_ADMIN.FULLNAME_ERROR),
      email: yup.string().trim()
        .required(i18n('ERRORS').BUSINESS.USER_ADMIN.EMAIL_ERROR)
        .email(i18n('ERRORS').BUSINESS.USER_ADMIN.VALID_EMAIL_ERROR),
    }),
    entity_configuration: yup.object().shape({
      functional_key_name: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
      fields: yup.array().of(yup.object().shape({
        name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
        field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
        enum_values: yup.array().when('field_type', {
          is: 6,
          then: yup.array().of(yup.string().trim()
               .min(1,i18n('ERRORS').PARAM_MIN).max(100, i18n('ERRORS').PARAM_MAX)).required(i18n('ERRORS').NEED_CONFIGURATION),
        })
      }))
    }),
  })

  const nameInput = (handleChange, errors) => (
    <DashboardInput isRequired={true} label={i18n('NAME')} error={errors.name} horizontal="true" border="true" name="name" onChange={handleChange} width="350px" />
  )

  const logoUpload = ( setFieldValue, values) => (<>
  <div style={{ display: "flex", marginTop: 10 }}>
    <div style={{display: "flex", flexDirection: "column", width: 160}}>
      <label style={{ color: theme.colors.darkGray, width: "180px", fontWeight: "bold" }}>Carga de logotipo</label>
      <span style={{width: 100}}>Si no se carga un archivo se verá el nombre del negocio.</span>
    </div>
    {values.image_base64 !== "" && <div style={{ marginRight: 10 }}>
      <div style={{ width: 105, height: 105, borderRadius: "7px", display: "flex", justifyContent: "center", alignItems: "center",
      border: mouseEnter ? `1px solid ${theme.colors.primary}` : `1px solid #d9e2ee`, transition: "0.2s linear 0.1s" }}
      onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
          <img src={`data:${values.image_extension};base64,${values.image_base64}`} style={{ maxHeight: "100%", maxWidth: "100%", padding: 12}} />
          <div style={{visibility: mouseEnter ? 'visible' : 'hidden', height: 105, width: 105, position: "absolute", background: "#00000080",
          display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 7, cursor: "pointer", transition: "0.2s linear 0.1s"}}
          onClick={() => {
            setFieldValue("image_extension", "")
            setFieldValue("image_base64", "")
            setMouseEnter(false)
          }}>
            <Icon name={'TrashBin'} color={theme.colors.white} />
          </div>
      </div>
    </div>}
    <div style={{ display: "flex" }}>
      <UploadButton hidePreview={true} withPreview={true} isImage={true} method="get" color={theme.colors.black} accept={[".svg", ".png"]} beforeUpload={async (file, _) => {
        if (file.type !== 'image/svg+xml' && file.type !== 'image/png') {
          alert(`${file.name} ${i18n('USER').REQUESTS.CREATE_EDIT.ERRORS.FILE_NOT_VALID}`);
        } else {
          let ticketId = upstreamTicket
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => setLoad(true)
          reader.onloadend = async () => {
            if (ticketId === null) {
              await dispatch(getUpstreamTicketId()).then((result) => {
                setFieldValue('upstream_ticket_id', result.id)
                setUpstreamTicket(result.id)
                ticketId = result.id
              })
            }
            let fileName = file.name.split('.')

            await dispatch(getUpstreamTicketFile({
              upstream_ticket_id: ticketId,
              image_base64: await toBase64(file).then((res) => res.split(',')[1]),
              image_extension: fileName[fileName.length - 1]
            })).then((res) => {
              file.url = reader.result
              setFieldValue("image_base64", file.url.split(',')[1])
              setFieldValue("image_extension", file.type)
            })
            setLoad(false)
          }
        }
        return false
      }}
        onRemove={(_) => {
          setFieldValue("image_extension", "")
          setFieldValue("image_base64", "")
        }} onChange={(_) => {
          setFieldValue("image_extension", "")
          setFieldValue("image_base64", "")
        }}
      />
    </div>
  </div>
  </>)

  const accountLevelsCheckboxes = (values, errors) => (
  <>
    <div style={{ fontWeight: 'bold' }}>{i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LABEL}</div>
    <MessageError errorname="entity_configuration_ids" />

    <FieldArray name="entity_configuration_ids">
      {arrayHelpers => (
        <div style={{ display: "flex", flexDirection: "column", maxHeight: "200px", flexWrap: "wrap" }}>
          {accountLevels.all.map((level, i) => <StyledCheckbox key={i} value={level.id} checked={values.entity_configuration_ids.includes(level.id)} name={"entity_configuration_ids"} onChange={e => {
            if (e.target.checked) {
              arrayHelpers.push(level.id)
            }
            else {
              const idx = values.entity_configuration_ids.indexOf(level.id);
              arrayHelpers.remove(idx)
            }
          }}>{level.name}</StyledCheckbox>)}
        </div>
      )}
    </FieldArray>

    <div style={{ marginTop: "15px", marginBottom: "25px", display: "flex" }}>
      <SmallIconButton icon={'Add'} size={21} onClick={() => history.push(`/dashboard/${savedInfo.name}/${i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LINK}/${i18n('SIDEBAR_LINKS').CREATE_LEVEL.LINK}`)} />
      <span style={{ fontWeight: 600, marginLeft: 13 }}>{i18n('CREATE_ACCOUNT_LEVEL')}</span>
    </div>
  </>
  )

  const userAdmin = (setFieldValue, errors) => (
    <>
      <div style={{ marginBottom: "15px", marginTop: "15px" }}>
        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('ADMIN_USER'))}</span>
      </div>
      <div style={{ margin: "10px 0" }}>
        <DashboardInput isRequired={true} label={i18n('FULL_NAME')} name="post_user_dto.name" error={getIn(errors, 'post_user_dto.name')} 
          onChange={(e) => setFieldValue('post_user_dto.name', e.target.value)} horizontal="true" border="true" width="350px" />
      </div>
      <div style={{ margin: "10px 0" }}>
        <DashboardInput isRequired={true} label={i18n('EMAIL')} name="post_user_dto.email" error={getIn(errors, 'post_user_dto.email')}
          onChange={(e) => setFieldValue('post_user_dto.email', e.target.value)} horizontal="true" border="true" width="350px" />
      </div>
    </>
  )

  const fkInput = (handleChange, errors) => (<>
      <div style={{ fontWeight: "600", marginBottom: "15px" }}>{i18n('PAYROLL')}</div>
      <DashboardInput isRequired={true} error={getIn(errors, 'entity_configuration.functional_key_name')} name="entity_configuration.functional_key_name" label={'Functional key'}
        onChange={handleChange} horizontal="true" border="true" width="350px" />
    </>
  )

  const AddFields = (values, errors) => (<AddFieldsButton data={values.entity_configuration.fields} dataStructure="entity_configuration.fields" />)

  if (accountLevels.isFetching) {
    return <Loader />
  }

  const initial = {
    id: id,
    name: "",
    account_id: savedInfo.id,
    image_base64: "",
    image_extension: "",
    entity_configuration_ids: [],
    post_user_dto: {
      email: "",
      name: "",
      sector_ids: null
    },
    entity_configuration:{
      functional_key_name: '',
      fields: [],
    }
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').BUSINESS.LABEL, i18n('SIDEBAR_LINKS').CREATE_BUSINESS.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').CREATE_BUSINESS} />
        <MessageBanner visible={error} status="error" message={business.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {(business.isSubmitting || load) && <Loader />}
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}>

        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="550px">
                {nameInput(handleChange, errors)}
                {logoUpload(setFieldValue, values)}
                {accountLevelsCheckboxes(values, errors)}
                {userAdmin(setFieldValue, errors)}
                {fkInput(handleChange, errors)}
              </DashboardContainer>
              <div style={{ width:"700px", margin: "0 20px" }}>
                {AddFields(values, errors)}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}

      </Formik>
    </>
  )
}

export default CreateBusiness
