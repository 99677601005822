import React from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import { Page, PageBlock, Container, PageTitle, ListTools } from '@tops/components'
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/auth';

const Tools = (props) => {

  const dispatch = useDispatch()

  const items = [
    { 'name': 'Solicitudes', 'description': 'Descripcion de la herramienta' },
    { 'name': 'Auditoria', 'description': 'Descripcion de la herramienta' },
    { 'name': 'Agenda', 'description': 'Descripcion de la herramienta' },
    { 'name': 'Agenda', 'description': 'Descripcion de la herramienta' },
  ]


  return (
    <Page withHeader logout={() => dispatch(logout())}>
      <PageBlock>
        <PageTitle onBack={() => props.history.goBack()} title={capitalizeFirstLetter(i18n('TOOLS'))} titleSize={22} />
        <Container>
          <ListTools items={items} />
        </Container>
      </PageBlock>
    </Page>
  )
}

export default Tools
