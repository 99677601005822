
import styled from 'styled-components'
import { mediaQueries, theme } from '@tops/global_config'

export const Container = styled.div`
  top:0;
  right:0;
  position: ${({ needFix }) => needFix ? 'fixed' : 'absolute'};
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, 0.3);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.mobile`
    padding: 0 20px;
  `}
`

export const MessageWrapper = styled.div`
  min-height: 200px;
  max-height: ${({ scrolleable }) => scrolleable && `600px`};
  width: ${({ qrPopUp }) => qrPopUp ? '450px' : '650px'};
  background: white;
  border-radius: 5px;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.05), 0 2px 4px 0 rgba(0, 0, 0, 0.25);
`

export const Text = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
`

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
`

export const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  border-bottom: ${({ theme, title }) => title ? `1px solid ${theme.colors.gray}` : "none"};
`

export const CloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 8px;
  cursor: pointer;
`
export const ChildrenContainer = styled.div`
  padding: ${({ scrolleable }) => scrolleable ? `0 30px 20px 30px` : `20px`};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ trainingExternalUser }) => trainingExternalUser && `center`};
  font-size: 13px;
  overflow-y: ${({ scrolleable }) => scrolleable && `auto`};
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(0, 0, 0, 0.08);
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ colorScroll }) => colorScroll ? colorScroll : '#9d9d9d'};
    border-radius: 5px;
  }

`

