const theme = {
  colors: {
    white: '#FFFFFF',
    primary: '#49494e',
    secondary: '#49494E',
    oldGray: '#808896',
    accent: '#f1f6f9',
    lilac: '#b9a5fd',
    darkLilac: '#6247c0',
    gray: '#f0f0f0',
    darkGray: "#49494E",
    smothGray: "#bfbfbf",
    black: "#49494e",
    green: "#cbffda",
    darkGreen: "#4ad474",
    red: "#fd6565",
    lightRed: "rgba(253, 101, 101, 0.26)",
    user: {
      wise: {
        primary: "#3aa159",
        secondary: "#cbffda"
      },
      levels: {
        primary: "#704dfb",
        secondary: "#c0caff"
      },
      users: {
        primary: "#377cff",
        secondary: "#c2d7ff"
      },
      trainings: {
        primary: "#c917a2",
        secondary: "#FFD7F6"
      },
      divisions: {
        primary: "#1db6ac",
        secondary: "#c3f3fb"
      },
      requests: {
        primary: "#25a33d",
        secondary: "#cffedd",
        urgencies: {
          high: "#f82658",
          medium: "#f18a2b",
          low: "#4ad474",
        }
      },
      inventory: {
        primary: "#27c499",
        secondary: "#d7f0ea"
      },
      expiration_date: {
        primary: "#1db6ac",
        secondary: "#c3f3fb"
      },
      checklists: {
        primary: "#19c3e3",
        secondary: "#d4f7fe"
      },
      audits: {
        primary: "#24a480",
        secondary: "#c2ffee"
      },
      schedule: {
        primary: "#933dd6",
        secondary: "#f4e7ff"
      },
      dashboard: {
        primary: "#6247C0",
        secondary: "#d6caff"
      }
    }
  },
  fonts: {
    lato: `'Lato', sans-serif`
  }
}

export default theme
