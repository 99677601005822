import { handleActions } from 'redux-actions'
import {
  fetchWiseConfigurationsStart,
  fetchWiseConfigurationsSuccess,
  fetchWiseConfigurationsError,
  fetchWiseConfigurationStart,
  fetchWiseConfigurationSuccess,
  fetchWiseConfigurationError,
  submitWiseConfigurationStart,
  submitWiseConfigurationSuccess,
  submitWiseConfigurationError,
  editWiseConfigurationStart,
  editWiseConfigurationSuccess,
  editWiseConfigurationError
} from '../actions/wiseConfiguration'

export const defaultState = {
  values: [],
  item: null, // {}
  error: null,
  isFetching: false,
  isSubmitting: false,
}

const reducer = handleActions(
  {
    [fetchWiseConfigurationsStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchWiseConfigurationsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null,
    }),
    [fetchWiseConfigurationsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchWiseConfigurationStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [fetchWiseConfigurationSuccess]: (state, { payload: { item } }) => ({
      ...state,
      item,
      isFetching: false,
      error: null,
    }),
    [fetchWiseConfigurationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitWiseConfigurationStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitWiseConfigurationSuccess]: (state, { payload: { item } }) => ({
      ...state,
      item,
      isSubmitting: false,
      error: null,
    }),
    [submitWiseConfigurationError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editWiseConfigurationStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [editWiseConfigurationSuccess]: (state, { payload: { item } }) => ({
      ...state,
      item,
      isSubmitting: false,
      error: null,
    }),
    [editWiseConfigurationError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
  },
  defaultState
)

export default reducer
