import styled from 'styled-components'

export const DataWrapper = styled.div`
  margin: 10px 0;
  `

export const DataContainer = styled.div`
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const InputsContainer = styled.div`
  display: flex;
  width: 100%;
  // justify-content: space-between;
  align-items: flex-start;
`

export const EraseButtonContainer = styled.div`
  margin-left: 5;
  position: absolute;
  right: -20px;
  bottom: 3px;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
`
export const Label = styled.label`
  font-family: 'Lato',sans-serif;
  color: #49494E;
  line-height: 40px;
  margin-right: 140px;
`
