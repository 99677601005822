import styled from 'styled-components'
import { DatePicker } from 'antd'

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 8px;
`

export const StyledRangePicker = styled(DatePicker.RangePicker)`
  height: 34px;
  max-width: 100%;
  width: ${({ width }) => width || '300px'};
  color: ${({ theme }) => theme.colors.darkGray};
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.05);
  font-size: 13px;
  border:${({ theme, color, selected, error }) => selected ? `1px solid ${color || theme.colors.lilac}` : error ? `1px solid ${theme.colors.red}` : `1px solid ${theme.colors.gray}`};
  transition: border 0.1s linear;
  
  input {
    color: ${({ theme }) => theme.colors.darkGray};
  }
  
  &:hover {
    border-color: ${({ theme, color }) => color ? color : theme.colors.lilac};
    border-right-width: 1px !important;
  }

  & > .ant-picker-active-bar {
    background: ${({ color }) => color};
  }
`
