import styled, { keyframes } from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const SignatureContainer = styled.div`
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
  border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
`

export const IconContainer = styled.div`
  display: ${({ isEmpty }) => isEmpty ? 'none' : 'flex'};
  justify-content: center;
  transition: all 0.1s ease-in;
`

export const SignatureButtonStyle = styled.div`
  width: 135px;
  height: 135px;
  text-align: center;
  position: relative;
  display: flex;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const ErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  animation:${({ hasError }) => hasError ? fadeInDown : fadeInUp} 0.3s ease;
  padding-bottom: 20px;
`