import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const {
  fetchUserSectorsStart, fetchUserSectorsSuccess, fetchUserSectorsError,
  fetchPublicUserSectorsStart, fetchPublicUserSectorsSuccess, fetchPublicUserSectorsError,
  fetchRequestPaginationConfigurationStart, fetchRequestPaginationConfigurationSuccess, fetchRequestPaginationConfigurationError,
  fetchCreatorUsersStart, fetchCreatorUsersSuccess, fetchCreatorUsersError,
  fetchFinalizatorUsersStart, fetchFinalizatorUsersSuccess, fetchFinalizatorUsersError,
  fetchSectorsByUserStart, fetchSectorsByUserSuccess, fetchSectorsByUserError
} = createActions({
  FETCH_USER_SECTORS_START: () => { },
  FETCH_USER_SECTORS_SUCCESS: values => ({ values }),
  FETCH_USER_SECTORS_ERROR: error => ({ error }),
  FETCH_PUBLIC_USER_SECTORS_START: () => { },
  FETCH_PUBLIC_USER_SECTORS_SUCCESS: values => ({ values }),
  FETCH_PUBLIC_USER_SECTORS_ERROR: error => ({ error }),
  FETCH_REQUEST_PAGINATION_CONFIGURATION_START: () => { },
  FETCH_REQUEST_PAGINATION_CONFIGURATION_SUCCESS: values => ({ values }),
  FETCH_REQUEST_PAGINATION_CONFIGURATION_ERROR: error => ({ error }),
  FETCH_CREATOR_USERS_START: () => { },
  FETCH_CREATOR_USERS_SUCCESS: values => ({ values }),
  FETCH_CREATOR_USERS_ERROR: error => ({ error }),
  FETCH_FINALIZATOR_USERS_START: () => { },
  FETCH_FINALIZATOR_USERS_SUCCESS: values => ({ values }),
  FETCH_FINALIZATOR_USERS_ERROR: error => ({ error }),
  FETCH_SECTORS_BY_USER_START: () => { },
  FETCH_SECTORS_BY_USER_SUCCESS: data => ({ data }),
  FETCH_SECTORS_BY_USER_ERROR: error => ({ error })
})

const fetchUserSectors = () => {
  return async dispatch => {
    dispatch(fetchUserSectorsStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(API_ROUTES.SECTORS)
        .then((response) => {
          dispatch(fetchUserSectorsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchUserSectorsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchPublicUserSectors = () => {
  return async dispatch => {
    dispatch(fetchPublicUserSectorsStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(API_ROUTES.PUBLIC_SECTORS)
        .then((response) => {
          dispatch(fetchPublicUserSectorsSuccess(response.data))
          resolve(response.data)
        }).catch((error) => {
          dispatch(fetchPublicUserSectorsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchRequestPaginationConfiguration = (sectorId) => {
  return async dispatch => {
    dispatch(fetchRequestPaginationConfigurationStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.SECTOR}/request-pagination-configuration/${sectorId}`)
        .then((response) => {
          dispatch(fetchRequestPaginationConfigurationSuccess(response.data))
          resolve(response.data)
        }).catch((error) => {
          dispatch(fetchRequestPaginationConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchCreatorUsers = (sectorId) => {
  return async dispatch => {
    dispatch(fetchCreatorUsersStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.USERS}/creator-users-by-sector/${sectorId}`)
        .then((response) => {
          dispatch(fetchCreatorUsersSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchCreatorUsersError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchFinalizatorUsers = (sectorId) => {
  return async dispatch => {
    dispatch(fetchFinalizatorUsersStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.USERS}/finalizator-users-by-sector/${sectorId}`)
        .then((response) => {
          dispatch(fetchFinalizatorUsersSuccess(response.data))
          resolve(response.data)
        }).catch((error) => {
          dispatch(fetchFinalizatorUsersError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchSectorsByUser = () => {
  return async dispatch => {
    dispatch(fetchSectorsByUserStart())
    const api = getApi(API_ROUTES)
    await api.get(`${API_ROUTES.SECTORS}`)
      .then(
        (response) => dispatch(fetchSectorsByUserSuccess(response.data)),
        (error) => dispatch(fetchSectorsByUserError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}



export {
  fetchUserSectors, fetchUserSectorsStart, fetchUserSectorsSuccess, fetchUserSectorsError,
  fetchPublicUserSectors, fetchPublicUserSectorsStart, fetchPublicUserSectorsSuccess, fetchPublicUserSectorsError,
  fetchRequestPaginationConfiguration, fetchRequestPaginationConfigurationStart, fetchRequestPaginationConfigurationSuccess, fetchRequestPaginationConfigurationError,
  fetchCreatorUsers, fetchCreatorUsersStart, fetchCreatorUsersSuccess, fetchCreatorUsersError,
  fetchFinalizatorUsers, fetchFinalizatorUsersStart, fetchFinalizatorUsersSuccess, fetchFinalizatorUsersError,
  fetchSectorsByUser, fetchSectorsByUserStart, fetchSectorsByUserSuccess, fetchSectorsByUserError
}
