import React from 'react'
import { i18n } from '@tops/services'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ViewBusinessLevels from './ViewBusinessLevels'
import CreateBusinessLevels from './CreateBusinessLevels'
import EditBusinessLevels from './EditBusinessLevels'


const BusinessLevels = ({ savedInfo }) => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <ViewBusinessLevels {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_LEVEL.LINK}`}
          render={(props) => <CreateBusinessLevels {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_LEVEL.LINK}/:id`}
          render={(props) => <EditBusinessLevels {...props} savedInfo={savedInfo} />}
        />
      </Switch>
    </>
  )
}

export default BusinessLevels
