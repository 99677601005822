import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
`
export const Next = styled.div`
  transform: rotate(0.5turn);
  padding: 10px 5px;
  cursor: ${({ onClick }) => onClick === null ? 'default' : 'pointer'};
`
export const Prev = styled.div`
  padding: 10px 5px;
  cursor: ${({ onClick }) => onClick === null ? 'default' : 'pointer'};
`
export const PageNumber = styled.div`
  width: auto;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-radius: 2px;
  background: ${({ colorTheme, theme, active }) => active ? colorTheme ? colorTheme.secondary : theme.colors.gray : "transparent"};
  color: ${({ colorTheme, theme, active }) => active && colorTheme ? colorTheme.primary : theme.colors.darkGray};
`
