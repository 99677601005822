import styled from 'styled-components'

export const HelpPopUpContainer = styled.div`
  position: relative;

  & help {
    cursor: help;
  }
`

export const HelpPopUp = styled.div`
  font-family: ${({ theme }) => theme.fonts.lato};
  flex-direction: column;
  background: white;
  display: flex;
  align-self: flex-end;
  align-items: center;
  height: 90px;
  width: 200px;
  font-size: 11px;
  margin-bottom: 10px;
  margin-left: 1000px;
  color: ${({ theme }) => theme.colors.lilac};
  border: 1px solid ${({ theme }) => theme.colors.green};
  border-radius: 5px;
  box-shadow: 0 2px 7.5px 0 rgba(0, 0, 0, 0.08);
  opacity: ${({ show }) => (show ? '1' : '0')};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  transition: opacity 0.4s ease-in-out;
  position: fixed;
  z-index: 5;
  right: 1100px;
  bottom: 50px;
  & a {
    color: ${({ theme }) => theme.colors.lilac};
  }
`

export const LinkHelp = styled.a`
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
  width: 100%;
  self-align: center;
  align-items: center;
  text-align: center;
  font-weight: ${({ bold }) => (bold ? 'bold;' : 'normal;')}
  &:hover {
    background: ${({ theme }) => theme.colors.gray};
  }
`