import React from 'react'
import { ErrorMessage } from 'formik'
import { StyledErrorMessage } from './styled'

export const MessageError = (props) => {
  const { errorname } = props

  return (
    <div>
      <ErrorMessage name={errorname} component={StyledErrorMessage} fontWeight={props.fontWeight} />
    </div>
  )
}
