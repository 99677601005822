import { handleActions } from 'redux-actions'
import {
  fetchRequestTypeStart, fetchRequestTypeSuccess, fetchRequestTypeError,
  fetchRequestTypeByIdStart, fetchRequestTypeByIdSuccess, fetchRequestTypeByIdError,
  submitRequestTypeStart, submitRequestTypeSuccess, submitRequestTypeError,
  editRequestTypeStart, editRequestTypeSuccess, editRequestTypeError,
  fetchAllLevelsBySectorStart, fetchAllLevelsBySectorSuccess, fetchAllLevelsBySectorError,
  fetchAssociatedRequestsStart, fetchAssociatedRequestsSuccess, fetchAssociatedRequestsError,
  fetchAllRelatedRequestsBySectorStart, fetchAllRelatedRequestsBySectorSuccess, fetchAllRelatedRequestsBySectorError,
  fetchAllRequestModelsStart, fetchAllRequestModelsSuccess, fetchAllRequestModelsError,
  fetchRequestExcelStart, fetchRequestExcelSuccess, fetchRequestExcelError,
} from '../actions/requestTypes'

export const defaultState = {
  values: [],
  valuesById: [],
  allLvlsBySector: [],
  allRelatedRequestsBySector: [],
  associatedRequests: [],
  error: null,
  isFetching: false,
  allRequestModels: [],
  excel: {}
}

const reducer = handleActions(
  {
    [fetchRequestTypeStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchRequestTypeSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchRequestTypeError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchRequestTypeByIdStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchRequestTypeByIdSuccess]: (state, { payload: { values } }) => ({
      ...state,
      valuesById: values,
      isFetching: false,
      error: null
    }),
    [fetchRequestTypeByIdError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitRequestTypeStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitRequestTypeSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitRequestTypeError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editRequestTypeStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editRequestTypeSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editRequestTypeError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [fetchAllLevelsBySectorStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllLevelsBySectorSuccess]: (state, { payload: { allLvlsBySector } }) => ({
      ...state,
      allLvlsBySector,
      isFetching: false,
      error: null
    }),
    [fetchAllLevelsBySectorError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchAssociatedRequestsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAssociatedRequestsSuccess]: (state, { payload: { associatedRequests } }) => ({
      ...state,
      associatedRequests,
      isFetching: false,
      error: null
    }),
    [fetchAssociatedRequestsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchAllRelatedRequestsBySectorStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllRelatedRequestsBySectorSuccess]: (state, { payload: { allRelatedRequestsBySector } }) => ({
      ...state,
      allRelatedRequestsBySector,
      isFetching: false,
      error: null
    }),
    [fetchAllRelatedRequestsBySectorError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchAllRequestModelsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllRequestModelsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      allRequestModels: values,
      isFetching: false,
      error: null
    }),
    [fetchAllRequestModelsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchRequestExcelStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchRequestExcelSuccess]: (state, { payload: { values } }) => ({
      ...state,
      excel: values,
      isFetching: false,
      error: null
    }),
    [fetchRequestExcelError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
  },
  defaultState
)

export default reducer
