import { handleActions } from 'redux-actions'
import {
  fetchBusinessLevelsStart,
  fetchBusinessLevelsSuccess,
  fetchBusinessLevelsError,
  submitBusinessLevelStart,
  submitBusinessLevelSuccess,
  submitBusinessLevelError,
  editBusinessLevelStart,
  editBusinessLevelSuccess,
  editBusinessLevelError,
  fetchAllBusinessLevelsStart,
  fetchAllBusinessLevelsSuccess,
  fetchAllBusinessLevelsError,
  fetchBusinessLevelsFieldsStart,
  fetchBusinessLevelsFieldsSuccess,
  fetchBusinessLevelsFieldsError,
  fetchBusinessLevelsByParentIdStart,
  fetchBusinessLevelsByParentIdSuccess,
  fetchBusinessLevelsByParentIdError
} from '../actions/businessLevels'

export const defaultState = {
  values: [],
  all: [],
  fields: [],
  byParent: {},
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [fetchBusinessLevelsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchBusinessLevelsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchBusinessLevelsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitBusinessLevelStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitBusinessLevelSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitBusinessLevelError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editBusinessLevelStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editBusinessLevelSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editBusinessLevelError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [fetchAllBusinessLevelsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllBusinessLevelsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      all: values,
      isFetching: false,
      error: null
    }),
    [fetchAllBusinessLevelsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchBusinessLevelsFieldsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchBusinessLevelsFieldsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      fields: values,
      isFetching: false,
      error: null
    }),
    [fetchBusinessLevelsFieldsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchBusinessLevelsByParentIdStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchBusinessLevelsByParentIdSuccess]: (state, { payload: { parentId, values } }) => ({
      ...state,
      byParent: {
        ...state.byParent,
        [parentId]: values
      },
      isFetching: false,
      error: null
    }),
    [fetchBusinessLevelsByParentIdError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
  },
  defaultState
)

export default reducer
