import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { ConfirmationButton, CancelButton, PageTitleDashboard, Select, DashboardContainer, DashboardInput, Loader, MessageBanner } from '@tops/components'
import { Formik } from 'formik'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { fetchSectorsByBusiness, selectSector} from '../../../../actions/sectors'
import { fetchAllBusiness } from '../../../../actions/business'
import { submitRole } from '../../../../actions/roles'

const CreateRoles = ({ history, savedInfo }) => {
  const [error, setError] = useState(false)
  const [active, setActive] = useState(true)
  const [success, setSuccess] = useState(false)
  const [load, setLoad] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [validateError, setValidateError] = useState(false)

  const sectors = useSelector(state => state.sectors)
  const business = useSelector(state => state.business)
  const roles = useSelector(state => state.roles)

  const dispatch = useDispatch()

  useEffect(() => {
    try {
      dispatch(fetchAllBusiness(savedInfo.id))
    } catch (error) {
      setError(true)
    }
  }, [])

  const validationSchema = yup.object({
    name: yup.string().trim().required(i18n('ERRORS').ROLE.NAME_ERROR),
    business_id: yup.string().ensure().required(i18n('ERRORS').ROLE.BUSINESS_ERROR),
    sector_id: yup.string().ensure().required(i18n('ERRORS').ROLE.SECTOR_ERROR)
  })

  const onSubmit = async (values) => {
    await dispatch(submitRole(values, values.sector_id)).then(
      () => {
        setSuccess(true)
        setTimeout(() => setLoad(true), 1000)
        setTimeout(() => {
          history.goBack()
        }, 1000)

      },
      () => setError(true)
    )
  }

  const nameInput = (handleChange, errors) => (
    <div style={{ marginBottom: "15px" }}>
      <DashboardInput label={i18n('NAME')} name="name" isRequired={true} error={errors.name} onChange={handleChange} horizontal="true"
        border="true" bold="true" width="350px" />
    </div>
  )

  const selectBusiness = (handleChange, setFieldValue, errors) => (
    <Select items={business.values} label={i18n('BUSINESS')} isRequired={true} name="business_id" error={errors.business_id}
      horizontal="true" width="350px" border="true" placeholder={i18n('SELECT_BUSINESS')} onChange={(e) => {
          setFieldValue("business_id", e);
          setFieldValue("sector_id", null);
          dispatch(fetchSectorsByBusiness(savedInfo.id, e));
          dispatch(selectSector(e));
        }
      } 
    />
  )

  const selelectSector = (handleChange, setFieldValue, errors) => (
    <Select items={sectors.allSectorsByBusiness} error={errors.sector_id} label={i18n('SECTOR')} horizontal="true" width="350px"
     border="true" name="sector_id" placeholder={i18n('SELECT_SECTOR')} onChange={(e) => setFieldValue("sector_id", e) } />
  )

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').ROLES.LABEL, i18n('SIDEBAR_LINKS').CREATE_ROLE.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').CREATE_ROLE} />
        <MessageBanner visible={error} status="error" message={roles.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {load || roles.isSubmitting && <Loader />}
      <Formik
        initialValues={{name: '',sector_id: ''}}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, errors, values }) => (
        <>
          {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
          <Form onFinish={handleSubmit} autoComplete="off" >
            <DashboardContainer width="500px">
              {nameInput(handleChange, errors)}
              {selectBusiness(handleChange, setFieldValue, errors)}
              {selelectSector(handleChange, setFieldValue, errors)}
            </DashboardContainer>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
              <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
            </div>
          </Form>
        </>
        )}
      </Formik>
    </>
  )
}

export default CreateRoles
