import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchBusinessLevelsStart,
  fetchBusinessLevelsSuccess,
  fetchBusinessLevelsError,
  submitBusinessLevelStart,
  submitBusinessLevelSuccess,
  submitBusinessLevelError,
  editBusinessLevelStart,
  editBusinessLevelSuccess,
  editBusinessLevelError,
  fetchAllBusinessLevelsStart,
  fetchAllBusinessLevelsSuccess,
  fetchAllBusinessLevelsError,
  fetchBusinessLevelsFieldsStart,
  fetchBusinessLevelsFieldsSuccess,
  fetchBusinessLevelsFieldsError,
  fetchBusinessLevelsByParentIdStart,
  fetchBusinessLevelsByParentIdSuccess,
  fetchBusinessLevelsByParentIdError,
} = createActions({
    FETCH_BUSINESS_LEVELS_START: () => { },
    FETCH_BUSINESS_LEVELS_SUCCESS: values => ({ values }),
    FETCH_BUSINESS_LEVELS_ERROR: error => ({ error }),
    SUBMIT_BUSINESS_LEVEL_START: () => { },
    SUBMIT_BUSINESS_LEVEL_SUCCESS: data => ({ data }),
    SUBMIT_BUSINESS_LEVEL_ERROR: error => ({ error }),
    EDIT_BUSINESS_LEVEL_START: () => { },
    EDIT_BUSINESS_LEVEL_SUCCESS: data => ({ data }),
    EDIT_BUSINESS_LEVEL_ERROR: error => ({ error }),
    FETCH_ALL_BUSINESS_LEVELS_START: () => { },
    FETCH_ALL_BUSINESS_LEVELS_SUCCESS: values => ({ values }),
    FETCH_ALL_BUSINESS_LEVELS_ERROR: error => ({ error }),
    FETCH_BUSINESS_LEVELS_FIELDS_START: () => { },
    FETCH_BUSINESS_LEVELS_FIELDS_SUCCESS: values => ({ values }),
    FETCH_BUSINESS_LEVELS_FIELDS_ERROR: error => ({ error }),
    FETCH_BUSINESS_LEVELS_BY_PARENT_ID_START: () => { },
    FETCH_BUSINESS_LEVELS_BY_PARENT_ID_SUCCESS: (parentId, values) => ({ parentId, values }),
    FETCH_BUSINESS_LEVELS_BY_PARENT_ID_ERROR: error => ({ error }),
  })

const fetchBusinessLevels = (page, accountId, businessId, active) => {
  return async dispatch => {

    dispatch(fetchBusinessLevelsStart())

    const api = getApi(API_ROUTES)

    const status = active ? 'paginated' : 'inactive'

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/${status}/${page}/${10}/${accountId}?business-id=${businessId}`)
        .then((response) => {
          dispatch(fetchBusinessLevelsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchBusinessLevelsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchAllBusinessLevels = (businessId, includeInactives, createdByBusiness) => {
  return async dispatch => {

    dispatch(fetchAllBusinessLevelsStart())

    const api = getApi(API_ROUTES)

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/simple/business/${businessId}?include-inactives=${includeInactives}&only-created-by-business=${createdByBusiness}`)
        .then((response) => {
          dispatch(fetchAllBusinessLevelsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAllBusinessLevelsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchBusinessLevelsByParentId = (businessId, parentId) => {
  return async dispatch => {
    dispatch(fetchBusinessLevelsByParentIdStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/simple/business/${businessId}?only-created-by-business=true&parent-id=${parentId}`)
        .then((response) => {
          dispatch(fetchBusinessLevelsByParentIdSuccess(parentId, response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchBusinessLevelsByParentIdError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject(error)
        })
    })
  }
}

const fetchBusinessLevelsFields = (levelId) => {
  return async dispatch => {

    dispatch(fetchBusinessLevelsFieldsStart())

    const api = getApi(API_ROUTES)

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/fields/${levelId}`)
        .then((response) => {
          dispatch(fetchBusinessLevelsFieldsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchBusinessLevelsFieldsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const submitBusinessLevel = accountLevel => {
  return async dispatch => {
    dispatch(submitBusinessLevelStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(API_ROUTES.LEVELS, accountLevel)
        .then((response) => {
          dispatch(submitBusinessLevelSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitBusinessLevelError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editBusinessLevel = (account) => {
  return async dispatch => {
    dispatch(editBusinessLevelStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.LEVELS, account)
        .then(() => {
          dispatch(editBusinessLevelSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editBusinessLevelError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}


export {
  fetchBusinessLevels,
  fetchBusinessLevelsStart,
  fetchBusinessLevelsSuccess,
  fetchBusinessLevelsError,
  fetchAllBusinessLevelsStart,
  fetchAllBusinessLevelsSuccess,
  fetchAllBusinessLevelsError,
  fetchAllBusinessLevels,
  fetchBusinessLevelsFields,
  fetchBusinessLevelsFieldsStart,
  fetchBusinessLevelsFieldsSuccess,
  fetchBusinessLevelsFieldsError,
  submitBusinessLevel,
  submitBusinessLevelStart,
  submitBusinessLevelSuccess,
  submitBusinessLevelError,
  editBusinessLevel,
  editBusinessLevelStart,
  editBusinessLevelSuccess,
  editBusinessLevelError,
  fetchBusinessLevelsByParentId,
  fetchBusinessLevelsByParentIdStart,
  fetchBusinessLevelsByParentIdSuccess,
  fetchBusinessLevelsByParentIdError,
}
