import axios from 'axios'
import { getToken } from '@tops/utils'
import { saveToken, getRefreshToken, clearLocalUserInfo } from '@tops/utils'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory();

const LIMIT_TIME_RENEW = 10000; // DIEZ SEGUNDOS
// const LIMIT_TIME_RENEW = 3600000; // UNA HORA

export default (API_ROUTES = apiRoutes, baseUrl) => {
    const accessToken = getToken()

    let headerConf = {
        Authorization: `Bearer ${accessToken}`,
        'Access-Control-Allow-Origin': location.origin,
        Locale: `Spanish`
    }

    let api = axios.create({
        baseURL: baseUrl !== undefined ? baseUrl : API_ROUTES.BASE_URL,
        headers: {
            common: headerConf
        },
        timeout: 300000,
    })


    // api.interceptors.request.use(async (config) => {
    //     await validateTokenAndRenew(API_ROUTES);
    //     return config;
    // }, function (error) {
    //     return Promise.reject(error);
    // });

    return api
}

const validateTokenAndRenew = async (API_ROUTES) => {
    try {
        let timestamp = null;
        timestamp = localStorage.getItem('TOKEN_TIMESTAMP');
        if (timestamp !== null && (parseInt(timestamp) + LIMIT_TIME_RENEW) < Date.now()) {
            localStorage.setItem('TOKEN_TIMESTAMP', Date.now());
            await getNewToken(API_ROUTES);
        }
    } catch { }

}

export const getNewToken = async (API_ROUTES) => {
    const params = {}
    const refreshToken = getRefreshToken()
    const api = axios.create({
        baseURL: API_ROUTES.BASE_URL,
        headers: {
            common: {
                'x-refresh-token': refreshToken
            }
        },
        timeout: 120000,
    })

    return await api.post(API_ROUTES.REFRESH_TOKEN, params)
        .then((response) => {
            saveToken(response.data.token);
            return response.data.token;
        })
        .catch((error) => {
            clearLocalUserInfo()
            localStorage.setItem('TOKEN_EXPIRED', true)
            window.location.href = '/login';
        }
        )

}
