import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { theme } from '@tops/global_config'
import { 
  ListWithDetails, Button, Container, Select, ListItem, PageTitleDashboard, Loader, Pagination, MessageBanner, IconButton
} from '@tops/components'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllBusiness } from '../../../../actions/business'
import { fetchSectors } from '../../../../actions/sectors'
import { fetchExpirationRequestType } from '../../../../actions/expirationRequestTypes'
import { fetchSectorsByAccount } from '../../../../actions/sectors'

const ViewExpirationRequestTypes = ({ history, savedInfo }) => {
  const { url } = useRouteMatch()

  const sectors = useSelector(state => state.sectors)
  const business = useSelector(state => state.business)
  const expirationRequestTypes = useSelector(state => state.expirationRequestTypes)

  const [currentPage, setCurrentPage] = useState(1)
  const [active, setActive] = useState(true)
  const [isExpirationModel, setIsExpirationModel] = useState(true)
  const [error, setError] = useState(false)
  const [businessSelected, setBusinessSelected] = useState(null)
  const [sectorSelected, setSectorSelected] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchSectorsByAccount(savedInfo.id))
        dispatch(fetchAllBusiness(savedInfo.id))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
    return () => {
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchExpirationRequestType(currentPage, savedInfo.id, active, isExpirationModel))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
    return () => {}
  }, [currentPage, active])

  const handleActive = () => {
    setActive(!active)
  }

  const handleBusinessChange = (value) => {
    setSectorSelected(null)
    setBusinessSelected(value)
    dispatch(fetchSectors(currentPage, value, active))
  }

  const handleSectorChange = (value) => {
    setSectorSelected(value)
  }

  const submitSearch = () => {
    dispatch(fetchExpirationRequestType(currentPage, savedInfo.id, active, isExpirationModel, businessSelected, sectorSelected))
    !active && setActive(true)
  }

  const clearSearch = () => {
    setBusinessSelected(null)
    setSectorSelected(null)
    dispatch(fetchExpirationRequestType(currentPage, savedInfo.id, active, isExpirationModel))
    !active && setActive(true)
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').EXPIRATION_REQUEST_TYPE.LABEL]}>
        <IconButton size={35} background={theme.colors.primary} icon={'Add'} text={i18n('SIDEBAR_LINKS').CREATE_EXPIRATION_REQUEST_TYPE.LABEL} bold="true" textSize={14} onClick={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').CREATE_EXPIRATION_REQUEST_TYPE.LINK}`)} />
        <MessageBanner visible={error} status="error" message={expirationRequestTypes.error} onAccept={() => setError(false)} />
      </PageTitleDashboard>
      <Container flexDirection="row" justifyContent="flex-start">
        <Select items={business.values} placeholder={i18n('SELECT_BUSINESS')} onChange={handleBusinessChange} width="250px"
          value={businessSelected} border="true" name="businessSelected" />
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Select items={businessSelected ? sectors.values : sectors.allSectorsByAccount} placeholder={i18n('SELECT_SECTOR')} onChange={handleSectorChange} width="250px"
            value={sectorSelected} border="true" name="sectorSelected" />
        </div>
        <Button style={{ marginTop: "-12px" }} small="true" onClick={() => submitSearch()} >Filtrar</Button>
        <Button style={{ marginTop: "-12px" }} small="true" onClick={() => clearSearch()} >Borrar filtros</Button>
      </Container>

      <div style={{ textAlign: "end", marginTop: 15 }} >
        <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
        <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
      </div>

      {sectors.isFetching || business.isFetching || expirationRequestTypes.isFetching ?
        <Loader /> : expirationRequestTypes.values.length === 0 ?
          <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div> :
          <ListWithDetails>
            {
              expirationRequestTypes.values.map((item) => 
                <ListItem isRequest key={item.id} disabled={!active} item={item}
                  details={[{ name: item.sector_id, sector: item.sector_id }]}
                  action={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').EDIT_EXPIRATION_REQUEST_TYPE.LINK}/${item.id}`, { isActive: active, business: businessSelected })}
              />)
            }
            <Pagination currentPage={currentPage} itemsLength={expirationRequestTypes.values.length}
              onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} />
          </ListWithDetails>
      }

      
    </>
  )
}

export default ViewExpirationRequestTypes
