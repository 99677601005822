import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { PageTitleDashboard, Loader, CancelButton } from '@tops/components';
import { theme } from '@tops/global_config';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Table, TitleHead, TitleTable, TableBody, TableRow, TableDetail } from '../styled';
import { fetchUserComplete } from '../../../../actions/user';

const UserDetail = ({ history }) => {
  const { id } = useParams()

  const [error, setError] = useState(false)
  const [load, setLoad] = useState(true)

  const dispatch = useDispatch()

  const users = useSelector(state => state.users)

  useEffect(() => {

    const fetchData = async () => {
      try {
        dispatch(fetchUserComplete(id))
        setLoad(false)
      } catch (error) {
        setError(true)
      }
    }

    fetchData()

  }, [])

  if (load || users.isFetching) {
    return <Loader />
  }

  return (
    <>
      <PageTitleDashboard subtitle={[i18n('USERS_TITLE'), "Detalle de Usuario"]} />
      <div style={{ width: "90%", border: `1px solid ${theme.colors.gray}`, borderRadius: 5, marginBottom: 30 }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TitleHead>
            <tr>
              <TitleTable colspan="4">{`${users.userComplete.user_business_data_and_hierarchy_dto.name} (${users.userComplete.functional_key})`}</TitleTable>
            </tr>
          </TitleHead>
          <TableBody>
            <TableRow>
              <TableDetail style={{ fontWeight: "bold" }}>
                Rol de Negocio
              </TableDetail>
              <TableDetail style={{ fontWeight: "bold" }}>
                Division/es
              </TableDetail>
              <TableDetail style={{ fontWeight: "bold" }}>
                Sector/es
              </TableDetail>
              <TableDetail style={{ fontWeight: "bold" }}>
                Equipo/s WISE
              </TableDetail>
            </TableRow>
            <TableRow border={false}> 
              <TableDetail>
                <div style={{display: 'flex', flexDirection: 'column'}}>{users.userComplete.user_business_data_and_hierarchy_dto.is_web_admin ? <p>Admin de negocio</p> :
                  users.userComplete.user_business_data_and_hierarchy_dto.is_wise_admin ? <p>Admin WISE</p> :
                    users.userComplete.user_business_data_and_hierarchy_dto.is_admin_sector ? <p>Admin de Sector</p> : <p>Usuario regular</p> && !users.userComplete.user_business_data_and_hierarchy_dto.is_training_admin }
                    {users.userComplete.user_business_data_and_hierarchy_dto.is_training_admin && <p>Admin de Capacitaciones</p>}
                    </div>
              </TableDetail>
              <TableDetail>
                {users.userComplete.divisions.length ? users.userComplete.divisions.map((division, i) => <div key={i} style={{ marginBottom: 10 }}>{division.name}</div>
                ) : "-"}
              </TableDetail>
              <TableDetail>
                {users.userComplete.sectors.length ? users.userComplete.sectors.map((sector, i) => <div key={i} style={{ marginBottom: 10 }}>{sector.name}</div>
                ) : "-"}
              </TableDetail>
              <TableDetail>
                {users.userComplete.teams.length ? users.userComplete.teams.map((team, i) => <div key={i} style={{ marginBottom: 10 }}>{team.name}</div>
                ) : "-"}
              </TableDetail>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('GO_BACK')}</CancelButton>
      </div>
    </>
  )

}

export default UserDetail