import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Container, ContentWrapper, LeftSection, RightSection, LogOutButton, StyledMenu, StyledMenuItem, Hamburger, LogoContainer, Logo, MenuMobile, OverlayBackground
} from './styled'
import { getUserInfo } from '@tops/utils'
import { i18n } from '@tops/services'
import { IconButton } from '../IconButton';
import { theme, isMobile, isTablet, checkWidth } from '@tops/global_config';
import { Dropdown } from 'antd';
import { useRouteMatch } from 'react-router-dom'
import { Button } from '../Button'
import Icon, { IconNames } from '../Icons'
import { useSelector, useDispatch } from 'react-redux';
import { fetchPublicUserSectors } from '../../../../user/src/actions/sectors'
import { Popup } from '@tops/components';

const Header = ({ history, logout, withMenu, toggleMenu, setToggleMenu, ...otherProps }) => {
  const tablet = isTablet()
  const mobile = isMobile()
  const width = checkWidth()
  // const [date, setDate] = useState(getFormattedDate(new Date(), locale.DEFAULT_LOCALE))
  const { url } = useRouteMatch()
  const [showPopUp, setShowPopUp] = useState(false)
  const [showRightMenu, setShowRightMenu] = useState(false)
  const [showLeftMenu, setShowLeftMenu] = useState(false)

  const dispatch = useDispatch()

  const sectors = useSelector(state => state.sectors)

  const selectedSector = localStorage.getItem('sector')
  const selectedSectorName = localStorage.getItem('sector_name')
  const businessData = JSON.parse(localStorage.getItem('business_data'))

  useEffect(() => {
    const foundSelectSector = sectors?.publicSectors?.find((sector) => sector.id === parseInt(selectedSector))

    if (foundSelectSector !== undefined) {
      localStorage.setItem("tools", JSON.stringify({ tools: foundSelectSector.tools, is_stock_consumer: foundSelectSector.is_stock_consumer, is_stock_creator: foundSelectSector.is_stock_creator }))
    }

  }, [])

  const menu = (
    <StyledMenu>
      <StyledMenuItem key="1" style={{ borderBottom: width < 600 && "1px solid #f0f0f0" }}
        onClick={() => {
          history.push(`${url}/${i18n('SIDEBAR_LINKS').VIEW_MY_INFO_USER.LINK}`)
          setShowRightMenu(false)
        }}>
        <div>{i18n('VIEW_PROFILE')}</div>
      </StyledMenuItem>
      {width < 600 && <StyledMenuItem key="2" onClick={() => logout().then(() => history.push('/login'))}>
        <div>{i18n('LOGIN').LOGOUT}</div>
      </StyledMenuItem>}
    </StyledMenu>
  );

  const changeSectorPopup = () => {
    return <Popup onClose={() => setShowPopUp(false)} scrolleable colorScroll={'#6fdb8e'}
      colorTheme={theme.colors.user.requests} title={i18n('CHANGE_SECTOR')}>
      <div style={{ margin: "20px auto", display: "flex", flexDirection: "column" }}>
        {sectors.publicSectors?.map((sector) => <Button small type="button" background={sector.id === parseInt(selectedSector) ? theme.colors.green : "transparent"}
          selected={sector.id === parseInt(selectedSector)}
          style={{ minWidth: 350, padding: "10px 0", marginBottom: 10, borderRadius: '5px', color: sector.id === parseInt(selectedSector) ? theme.colors.darkGreen : theme.colors.darkGray, border: `1px solid ${theme.colors.gray}`, fontSize: 14, fontFamily: 'inherit' }}
          onClick={() => {
            localStorage.setItem("sector", sector.id)
            localStorage.setItem('sector_name', sector.name)
            localStorage.setItem("tools", JSON.stringify({ tools: sector.tools, is_stock_consumer: sector.is_stock_consumer, is_stock_creator: sector.is_stock_creator }))
            // window.location.reload(false);
            history.go(0)
            setShowPopUp(false)
          }}>
          {sector.name}
        </Button>)}
      </div>
    </Popup>
  }

  const businessLogo = () => {
    return <LogoContainer>
      {businessData?.logo !== "" ?
        <Logo src={businessData?.logo} alt={businessData?.name} />
        :
        <div style={{ textAlign: "center", fontSize: 16, fontStyle: "italic" }}>
          {businessData?.name}
        </div>}
    </LogoContainer>
  }

  const sector = () => {
    return <div style={{ margin: "0px 15px", cursor: "pointer", minWidth: 42 }} onClick={() => setShowPopUp(true)}>
      {!mobile && <span style={{ fontSize: "10px" }}>{i18n('SECTOR')}</span>}
      <span style={{ display: "flex", alignItems: "center", fontWeight: "bold", fontSize: "13px", whiteSpace: !mobile && "nowrap" }}>
        <div style={{ display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", overflow: "hidden", minWidth: 68 }}>{selectedSectorName}</div>
        <Icon name={IconNames['Change']} size={24} color={theme.colors.darkGreen} style={{ marginLeft: 5, marginRight: 5 }} />
      </span>
    </div>
  }

  /*   <Button small type="button" color={theme.colors.darkGreen} background="transparent"
                style={{ margin: '0px', borderRadius: '2.5px', boxShadow: 'none', border: `1px solid ${theme.colors.darkGreen}` }} onClick={() => setShowPopUp(true)}>
                {sectors.publicSectors?.find((sector) => sector.id === parseInt(selectedSector))?.name}
                <Icon name={IconNames['Change']} size={24} color={theme.colors.darkGreen} style={{ marginLeft: 5 }} />
              </Button> : <div></div> */

  return (
    <>
      {(window.location.href.includes('uat.terrand.app') || process.env.REACT_APP_ENVIROMENT === 'uat') &&
        <div style={{ backgroundColor: theme.colors.user.schedule.primary, color: theme.colors.white, width: '100%', textAlign: 'center', fontSize: 13 }}>
          {i18n('TEST_ENVIROMENT')}
        </div>}
      <Container>
        <ContentWrapper {...otherProps}>
          {/* <span>{date}</span> */}

          <OverlayBackground show={showRightMenu || toggleMenu} onClick={() => {
            setShowRightMenu(false)
            setToggleMenu(false)
          }} />

          {showPopUp && changeSectorPopup()}

          <LeftSection>
            {withMenu && <Hamburger onClick={() => setToggleMenu(true)}>
              <Icon name="Hamburger" />
            </Hamburger>}
            {businessLogo()}
            {selectedSector !== null && <>
              <div style={{ borderRight: "1px solid #f0f0f0", height: 52 }} />
              {sector()}
              <div style={{ borderRight: "1px solid #f0f0f0", height: 52, marginRight: "15px" }} />
            </>
            }
          </LeftSection>

          <RightSection>
            <div style={{ borderRight: "1px solid #f0f0f0", height: 52, marginRight: "15px" }} />

            <div style={{ marginRight: "15px", minWidth: 120, textAlign: "center" }}>
              {withMenu ?
                <Dropdown overlay={menu} trigger={['click']}>
                  <span style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                    <div style={{ display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical", overflow: "hidden" }}>{`¡${i18n('HOME').HELLO} ${getUserInfo().name}!`}</div> <Icon color={theme.colors.darkGreen} name="ArrowDown" size={15} style={{ marginLeft: 5 }} />
                  </span>
                </Dropdown> :
                <span>
                  {`¡${i18n('HOME').HELLO} ${getUserInfo()?.name}!`}
                </span>}
            </div>

            <div style={{ borderRight: "1px solid #f0f0f0", height: 52, marginRight: "15px" }} />

            <div style={{ display: "flex", alignItems: "center", cursor: "pointer", minWidth: 120 }} onClick={() => { logout().then(() => history.push('/login')) }}>
              <span>{i18n('LOGIN').LOGOUT}</span>
              <LogOutButton onClick={() => { logout().then(() => history.push('/login')) }} >
                <IconButton icon="LogOut" background={theme.colors.white} shadow={false} />
              </LogOutButton>
            </div>

          </RightSection>

          <MenuMobile>
            <Icon color={theme.colors.darkGreen} name="ArrowDown" onClick={() => setShowRightMenu(!showRightMenu)} />
            {showRightMenu && menu}
          </MenuMobile>

        </ContentWrapper>
      </Container>
    </>
  )
}

export default withRouter(Header)


