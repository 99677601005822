import React, { useState } from 'react'
import { i18n } from '@tops/services';
import styled, { keyframes } from 'styled-components'
import { mediaQueries } from '@tops/global_config'
import { TreeSelect } from 'antd';
import { theme } from '@tops/global_config'

import 'antd/dist/antd.css';
import './index.css';

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: ${({ horizontal }) => horizontal ? 'space-between' : 'flex-start'};
  font-weight: 500;
  font-size: 14px;
  align-items: ${({ horizontal }) => horizontal && "align-items: flex-end;"};
  ${({ horizontal, width }) => !horizontal && `width: ${width};`}
  ${mediaQueries.mobile`
    flex-direction: column;
    width: ${({ width }) => width};
    max-width:100%;
  `}
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: ${({ bold }) => bold ? '600' : '500'};
  line-height: 40px;
  // min-width: 250px;
  ${({ horizontal }) => horizontal && "margin-right: 20px"}
`

export const SelectContainer = styled.div`
  width: 100%;
  max-width: ${({ width }) => width || '100%'};
`

export const StyledTreeSelect = styled(TreeSelect)`
  max-width: 100%;
  width: ${({ width }) => width || '300px'};
  border:${({ theme, color, selected, error }) => selected ? `1px solid ${color || theme.colors.lilac}` : error ? `1px solid ${theme.colors.red}` : `1px solid ${theme.colors.gray}`};
  font-family: ${({ theme }) => theme.fonts.lato};;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.darkGray};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease-out;
  margin-bottom: 8px;
  border-radius: 6px;

  .ant-select-arrow {
    color: ${({ theme, color }) => color || theme.colors.lilac};
    font-size: 15px;
  }

  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    border-color: ${({ theme, color }) => color || theme.colors.lilac};
  }
`

export const TreeSelectCheckbox = props => {
  const [selected, setSelected] = useState(false)
  return <Container>
    {props.label &&
      <Label horizontal={props.horizontal} bold={props.bold}>
        {props.label}
        {props.isRequired && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
      </Label>}
    <SelectContainer width={props.width}>
      <StyledTreeSelect {...props} bordered={false} placeholder={props.placeholder || 'Elegir'} trigger="click" 
        selected={selected} onDropdownVisibleChange={() => setSelected(!selected)} filterTreeNode
        dropdownStyle={{ background: theme.colors.white, borderRadius: '5px', border: `1px solid ${props.color || theme.colors.lilac}`, boxShadow: '0px 3px 4px 1px rgba(0,0,0,0.25)', position: "fixed" }}  fieldNames={{ label: 'title', value: 'key'}}/>
    </SelectContainer>
  </Container>


  /*     <div style={{ marginRight: 20, marginTop: 10 }}>
      <TreeSelect className="treeSelect" disabled={(editMember.seePopup && editMember.valuePopup === i) || item.id === null ? false : true} label="Puesto" horizontal="true" width="300px" name={`members_of_team[${i}].chart_node_id`} color={theme.colors.user.wise.primary} value={item.chart_node_id}
      onChange={(val) => setFieldValue(`members_of_team[${i}].chart_node_id`, val)} error={false} isRequired={false} showSearch
      optionFilterProp="children"
      filterTreeNode={(search, item) => {
        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      }} dropdownStyle={{ maxHeight: 400, overflow: 'auto' }} allowClear>
        <TreeNode value={chart_nodes.id} title={chart_nodes.name}>
          { chart_nodes.children !== undefined ? chart_nodes.children.map(renderItemFree) : '' }
        </TreeNode>
      </TreeSelect> 
    </div>*/
}