import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'
import { saveLocalUserInfo, clearLocalUserInfo, getToken } from '@tops/utils'

const { authStart, authSuccess, authError,
  authSessionStart, authSessionSuccess, authSessionError,
  authChallengeStart, authChallengeSuccess, authChallengeError  } = createActions({
  AUTH_START: () => { },
  AUTH_SUCCESS: isUserLogged => ({ isUserLogged }),
  AUTH_ERROR: error => ({ error }),
  AUTH_SESSION_START: () => { },
  AUTH_SESSION_SUCCESS: values => ({ values }),
  AUTH_SESSION_ERROR: error => ({ error }),
  AUTH_CHALLENGE_START: () => { },
  AUTH_CHALLENGE_SUCCESS: values => ({ values }),
  AUTH_CHALLENGE_ERROR: error => ({ error })
})

const login = (name, password) => {
  return async dispatch => {
    dispatch(authStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(`${API_ROUTES.LOGIN}/login`, { name, password })
        .then((response) => {
          dispatch(authSuccess(true))
          saveLocalUserInfo({ name: "Administrador" }, response.data.token, response.data.refresh_token)
          resolve(response)
        }).catch((error) => {
          if(error.response.data.user_id)
          {
            dispatch(authSessionSuccess(error.response.data))
            reject(error.response.status)
          }
          else{
            dispatch(authError(error.response !== undefined ? error.response.data.business_errors : error.message))
            reject(error)
          }
        })
    })
  }
}

const challenge = (data,dataLogin) =>{
  return async dispatch => {
    dispatch(authChallengeStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(`${API_ROUTES.LOGIN}/password-challenge`, data)
        .then((response) => {
          dispatch(authChallengeSuccess(true))
          resolve()
        }).catch((error) => {
          if(error.response.status === 404)
          {
            dispatch(login(dataLogin.username, dataLogin.password))
            .then(() => console.log("Good"), (error) => {if(error === 409) {dispatch(challenge(data, dataLogin))}})      
          }
          else
          {
            dispatch(authChallengeError(error.response !== undefined ? error.response.data.business_errors : error.message))
            reject(error)
          }
        })
    })
  }
}

const logout = () => {
  return async () => {
    const api = getApi(API_ROUTES)
    await api.post(`${API_ROUTES.LOGIN}/logout`, { "Locale": getToken() }).then(() => clearLocalUserInfo())
  }

}

const reset = () => {
  return async dispatch => {
    dispatch(authError(null))
  }
}

export { login, authStart, authSuccess, authError, logout, reset,authSessionStart, authSessionSuccess, authSessionError, challenge,
  authChallengeStart, authChallengeSuccess, authChallengeError  }
