import { i18n } from '@tops/services'
import AccountLevels from '../pages/Dashboard/AccountLevels'
import Business from '../pages/Dashboard/Business'
import BusinessLevels from '../pages/Dashboard/BusinessLevels'
import Sectors from '../pages/Dashboard/Sectors'
import ExpirationRequestTypes from '../pages/Dashboard/ExpirationRequestTypes'
import RequestTypes from '../pages/Dashboard/RequestTypes'
import Roles from './../pages/Dashboard/Roles/index';

const SIDEBAR_LINKS = [
  { label: i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LABEL, pathName: i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LINK, component: AccountLevels },
  { label: i18n('SIDEBAR_LINKS').BUSINESS.LABEL, pathName: i18n('SIDEBAR_LINKS').BUSINESS.LINK, component: Business },
  { label: i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LABEL, pathName: i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LINK, component: BusinessLevels },
  { label: i18n('SIDEBAR_LINKS').SECTORS.LABEL, pathName: i18n('SIDEBAR_LINKS').SECTORS.LINK, component: Sectors },
  { label: i18n('SIDEBAR_LINKS').ROLES.LABEL, pathName: i18n('SIDEBAR_LINKS').ROLES.LINK, component: Roles },
  { label: i18n('SIDEBAR_LINKS').REQUEST_TYPE.LABEL, pathName: i18n('SIDEBAR_LINKS').REQUEST_TYPE.LINK, component: RequestTypes },
  { label: i18n('SIDEBAR_LINKS').EXPIRATION_REQUEST_TYPE.LABEL, pathName: i18n('SIDEBAR_LINKS').EXPIRATION_REQUEST_TYPE.LINK, component: ExpirationRequestTypes },
]

export default SIDEBAR_LINKS
