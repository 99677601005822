import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { theme } from '@tops/global_config'
import { List, IconButton, ListItem, PageTitleDashboard, Button, MessageBanner, Loader, Pagination } from '@tops/components'

import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBusiness } from '../../../../actions/business'

const ViewBusiness = ({ history, savedInfo }) => {
  const { url } = useRouteMatch()

  const [currentPage, setCurrentPage] = useState(1)
  const [active, setActive] = useState(true)
  const [error, setError] = useState(false)

  const dispatch = useDispatch()

  const business = useSelector(state => state.business)

  useEffect(() => {

    const fetchData = async () => {

      try {
        await dispatch(fetchBusiness(currentPage, savedInfo.id, active))
      } catch (error) {
        setError(true)
      }
    }

    fetchData()

  }, [currentPage, active])

  const handleActive = () => {
    setActive(!active)
  }


  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').BUSINESS.LABEL]}>
        <IconButton size={35} background={theme.colors.primary} icon={'Add'} text={i18n('SIDEBAR_LINKS').CREATE_BUSINESS.LABEL} bold="true" textSize={14} onClick={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').CREATE_BUSINESS.LINK}`)} />
        <MessageBanner visible={error} status="error" message={business.error} onAccept={() => {setError(false); history.push('/cuentas')}} />
      </PageTitleDashboard>
      <span>
        <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
        <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
      </span>
      <List>
        {
          business.isFetching ?
            <Loader /> :
            business.values.length === 0 ?
              <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div> :
              business.values.map((item) => <ListItem
                key={item.id}
                disabled={!active}
                item={item}
                action={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').EDIT_BUSINESS.LINK}/${item.id}`, { isActive: item.is_active })}
              />)
        }
      </List>
      <Pagination currentPage={currentPage} itemsLength={business.values.length}
        onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} />
    </>
  )
}

export default ViewBusiness
