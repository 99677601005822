import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'


export const PageTitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px 0;
  font-size: ${({ titleSize }) => titleSize ? `${titleSize}px` : '18px'};
  ${mediaQueries.mobile`
  padding: 15px 25px;
`}
  `

export const PageTitleDashboardContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  position:relative;
  font-size: ${({ titleSize }) => titleSize ? `${titleSize}px` : '18px'};
   ${mediaQueries.mobile`
  padding: 0px 15px;
`}
  `
export const PageTitleUserContainer = styled(PageTitleDashboardContainer)`
  border-bottom: 1px solid  ${({ theme }) => theme.colors.accent};
  width: 100%;
  `

export const Title = styled.div`
  flex: 1;
  text-align: ${({ dashboard }) => dashboard ? 'left' : 'center'};
  ${mediaQueries.mobile`
  text-align: right; 
`}
`
export const TitleDashboard = styled.div`
  padding: 8px 0;
  border-bottom: 1px solid  ${({ theme }) => theme.colors.accent};
`

export const ChildrenContainer = styled.div`
 
`

export const PageTitleFixed = styled.div`
  position: sticky;
  top: 0;
  background: white;
  z-index: 300;
  width:100%;
`