import { handleActions } from 'redux-actions'
import {
    submitAccidentAndIncidentConfigurationStart, submitAccidentAndIncidentConfigurationSuccess, submitAccidentAndIncidentConfigurationError,
    fetchAccidentAndIncidentConfigurationStart, fetchAccidentAndIncidentConfigurationSuccess,  fetchAccidentAndIncidentConfigurationError,
} from '../actions/accidentAndIncident'

export const defaultState = {
    values: [],
  }

  const reducer = handleActions(
    {
      [submitAccidentAndIncidentConfigurationStart]: state => ({
        ...state,
        isSubmitting: true,
        error: null
      }),
      [submitAccidentAndIncidentConfigurationSuccess]: (state, { payload: { data } }) => ({
        ...state,
        isSubmitting: false,
        error: null,
      }),
      [submitAccidentAndIncidentConfigurationError]: (state, { payload: { error } }) => ({
        ...state,
        isSubmitting: false,
        error
      }),
      [fetchAccidentAndIncidentConfigurationStart]: state => ({
        ...state,
        isFetching: true,
      }),
      [fetchAccidentAndIncidentConfigurationSuccess]: (state, { payload: { values } }) => ({
        ...state,
        values,
        isFetching: false,
        error: null
      }),
      [fetchAccidentAndIncidentConfigurationError]: (state, { payload: { error } }) => ({
        ...state,
        isFetching: false,
        error
      }),
    },
    defaultState
  )

  export default reducer