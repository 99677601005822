import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Panel = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  & + & {
    margin-top: 12px;
  }
`

export const Header = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const Title = styled.div`
  font-weight:  ${({bold}) => bold ? 'bold' : 'normal'};
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`

export const Rotator = styled.div`
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0)'};
  width: auto;
  height: auto;
  transform-origin: center center;
  transition: transform 0.3s ease-out;
`

export const Content = styled.div`
  margin-top: 8px;
  max-height: ${({ open }) => open ? '100%' : '0'};
  transition: max-height 0.5s ease-out;
  overflow: hidden;
`

export const Body = styled.div`
  margin-top: ${({ open }) => open ? '0px' : '-100%'};
  transition: margin-top 0.5s ease-out;
`