import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const Button = styled.div`
  display: flex;
  justify-content: ${({ small }) => small ? "start" : "flex-end"};
  visibility: ${({ disabled }) => disabled ? 'hidden' : 'show'};
`

export const IconContainer = styled.button`
  background: ${({ theme, background }) => background || theme.colors.lilac};
  border-radius: ${({ square }) => square ? "15%" : "50%"};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:  4px;
  border: none;
  box-shadow: ${({shadow}) => shadow ? '0 3px 2px 0 rgba(0, 0, 0, 0.05)' : 'none'}; // Fix nodo organigrama
  cursor: pointer;
  ${({ small, marginOff }) => marginOff ? "margin-left: 0px" : (small ? "margin-right: 10px;" : "margin-left: 6px;")}
  // ${mediaQueries.mobile`
  //   padding-bottom: 2px;
  // `}
`

export const Text = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: ${({theme}) => theme.fonts.lato};
  font-size: ${({ textSize }) => textSize}px;
  font-weight: ${({ bold }) => bold ? '600' : '500'};
  ${mediaQueries.mobile`
    display: none;
  `}
`