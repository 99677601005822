import styled, { keyframes } from 'styled-components'

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const StyledErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  animation: ${({ hasError }) => (hasError ? fadeInDown : fadeInUp)} 0.3s ease;
  font-weight: ${({ fontWeight }) => (fontWeight ? 500 : 600)};
`
