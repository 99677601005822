
import styled, { keyframes, css } from 'styled-components'
import { mediaQueries } from '@tops/global_config'
import { Timeline } from 'antd';

export const StyledTimeline = styled(Timeline)`
  color: inherit;
`

export const StyledTimelineItem = styled(Timeline.Item)`
  font-size: 14px;
  font-weight: 500;
  .ant-timeline-item-head {
    border-width: 3px;
  }
`