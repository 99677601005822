import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const PageBlockWrapper = styled.div`
  height: auto;
  width: 100%;
  max-width: ${({ narrow }) => (narrow ? 1200 : 1500)}px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.39);
  background: ${({ theme }) => theme.colors.white};
`
export const PageBlockHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.accent};
  padding: 15px 0;
  font-weight: 600;
`

export const PageBlockContainer = styled.div`
  padding: ${({ title }) => title ? '0 30px' : '15px 30px'};
  width: 100%;
  height:100%;
  min-height: 70vh;
  margin-bottom: 10px;
  ${mediaQueries.mobile`
    padding:15px;
  `}
`
