import styled from 'styled-components'

export const NoDataWrapper = styled.div`
  color: #b4b7bd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
`

export const CircleContainer = styled.div`
  background: ${({ background }) => background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: ${({illustrationRise}) => illustrationRise ? '10px' : '30px'};
  margin-bottom: ${({illustrationRise}) => illustrationRise ? '0px' : '15px'};
`