import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchExpirationRequestTypeStart, fetchExpirationRequestTypeSuccess, fetchExpirationRequestTypeError,
  fetchExpirationRequestTypeByIdStart, fetchExpirationRequestTypeByIdSuccess,fetchExpirationRequestTypeByIdError,
  submitExpirationRequestTypeStart, submitExpirationRequestTypeSuccess, submitExpirationRequestTypeError,
  editExpirationRequestTypeStart, editExpirationRequestTypeSuccess, editExpirationRequestTypeError,
  fetchAllLevelsBySectorStart, fetchAllLevelsBySectorSuccess, fetchAllLevelsBySectorError,
} = createActions({
    FETCH_EXPIRATION_REQUEST_TYPE_START: () => { },
    FETCH_EXPIRATION_REQUEST_TYPE_SUCCESS: values => ({ values }),
    FETCH_EXPIRATION_REQUEST_TYPE_ERROR: error => ({ error }),
    FETCH_EXPIRATION_REQUEST_TYPE_BY_ID_START: () => { },
    FETCH_EXPIRATION_REQUEST_TYPE_BY_ID_SUCCESS: values => ({ values }),
    FETCH_EXPIRATION_REQUEST_TYPE_BY_ID_ERROR: error => ({ error }),
    SUBMIT_EXPIRATION_REQUEST_TYPE_START: () => { },
    SUBMIT_EXPIRATION_REQUEST_TYPE_SUCCESS: data => ({ data }),
    SUBMIT_EXPIRATION_REQUEST_TYPE_ERROR: error => ({ error }),
    EDIT_EXPIRATION_REQUEST_TYPE_START: () => { },
    EDIT_EXPIRATION_REQUEST_TYPE_SUCCESS: data => ({ data }),
    EDIT_EXPIRATION_REQUEST_TYPE_ERROR: error => ({ error }),
    FETCH_ALL_LEVELS_BY_SECTOR_START: () => { },
    FETCH_ALL_LEVELS_BY_SECTOR_SUCCESS: allLvlsBySector => ({ allLvlsBySector }),
    FETCH_ALL_LEVELS_BY_SECTOR_ERROR: error => ({ error })
  })

const fetchExpirationRequestType = (page, accountId, active, isExpirationModel, businessId, sectorId ) => {
  return async dispatch => {
    dispatch(fetchExpirationRequestTypeStart())
    const api = getApi(API_ROUTES)
    const status = active ? 'paginated' : 'inactive'
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/${status}/${page}/${10}/${accountId}/${isExpirationModel}${businessId ? `?business-id=${businessId}` : ''}${businessId && sectorId ? `&sectorId=${sectorId}` : (!businessId && sectorId ? `?sectorId=${sectorId}` : '')}`)
        .then((response) => {
          dispatch(fetchExpirationRequestTypeSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchExpirationRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchExpirationRequestTypeById = (expirationRequestTypeId, isExpirationModel) => {
  return async dispatch => {
    dispatch(fetchExpirationRequestTypeByIdStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/complete/${expirationRequestTypeId}/${isExpirationModel}`)
        .then((response) => {
          dispatch(fetchExpirationRequestTypeByIdSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchExpirationRequestTypeByIdError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    })
  }
}


const submitExpirationRequestType = (values, accountId, sectorId) => {
  return async dispatch => {
    dispatch(submitExpirationRequestTypeStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(`${API_ROUTES.EXPIRATION_REQUEST_TYPE}/${accountId}/${sectorId}`, values )
        .then((response) => {
          dispatch(submitExpirationRequestTypeSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitExpirationRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editExpirationRequestType = (expirationRequestType) => {
  return async dispatch => {
    dispatch(editExpirationRequestTypeStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(`${API_ROUTES.EXPIRATION_REQUEST_TYPE}-update`, expirationRequestType)
        .then(() => {
          dispatch(editExpirationRequestTypeSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editExpirationRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchAllLevelsBySector = (accountId) => {
  return async dispatch => {
    dispatch(fetchAllLevelsBySectorStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/simple/sector/${accountId}?include-inactives=false`)
        .then((response) => {
          dispatch(fetchAllLevelsBySectorSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAllLevelsBySectorError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

export {
  fetchExpirationRequestType, fetchExpirationRequestTypeStart, fetchExpirationRequestTypeSuccess,fetchExpirationRequestTypeError,
  fetchExpirationRequestTypeById, fetchExpirationRequestTypeByIdStart, fetchExpirationRequestTypeByIdSuccess, fetchExpirationRequestTypeByIdError,
  submitExpirationRequestType, submitExpirationRequestTypeStart, submitExpirationRequestTypeSuccess, submitExpirationRequestTypeError,
  editExpirationRequestType, editExpirationRequestTypeStart, editExpirationRequestTypeSuccess, editExpirationRequestTypeError,
  fetchAllLevelsBySector, fetchAllLevelsBySectorStart, fetchAllLevelsBySectorSuccess, fetchAllLevelsBySectorError
}
