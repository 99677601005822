import React from 'react'
import { SwitchWrapper, Switch, SwitchLabel, Label, Container } from './styled'


export const StyledSwitch = ({ isOn, onChange, checked, label, id, disabled, ...props }) => {
  
  return (
    <Container {...props}>
      <Label bold={props.bold} fontSize={props.fontSize} horizontal={props.horizontal}>
        {label}
        {props.isRequired && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
      </Label>
      {!props.isEditButton && <SwitchWrapper>
        <Switch id={id ? id : label.toLowerCase()} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        <SwitchLabel htmlFor={id ? id : label.toLowerCase()} />
      </SwitchWrapper>
      }
    </Container>
  )
}

