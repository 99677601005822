import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { capitalizeFirstLetter } from '@tops/utils'

export const getFormattedDate = (date, loc) => {
  dayjs.locale(loc.slice(0, 2))

  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)
  return capitalizeFirstLetter(`${dayjs(date).format('LLLL')}`)
}

export const toISOString = date => new Date(date).toISOString()

export const formatDate = (date) => {
  return `${dayjs(date).format('DD/MM/YYYY')}`
}

export const formatDateAndTime = (date) => {
  return `${dayjs(date).format('DD/MM/YYYY HH:mm A')}`
}

export const formatDateToMinAndMax = (date) => {
  return `${dayjs(date).format('YYYY-MM-DD')}`
}

export const toLocaleDate = (date, loc) => {
  dayjs.locale(loc.slice(0, 2))

  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)
  return `${dayjs(new Date(`${date}Z`)).format('L')}`
}

export const formatTime = (date) => {
  return `${dayjs(date).format('HH:mm A')}`
}

export const formatTimeWithoutFormat = (date) => {
  return `${dayjs(date).format('HH:mm')}`
}

export const toLocaleTime = (date, loc) => {
  dayjs.locale(loc.slice(0, 2))

  return `${dayjs(new Date(`${date}Z`)).format('HH:mm A')}`
}

export const formatCompleteDateTime = (date) => {
  return `${dayjs(date).format('L HH:mm A')}`
}

export const toLocaleCompleteDateTime = (date, loc) => {
  dayjs.locale(loc.slice(0, 2))
  var localizedFormat = require('dayjs/plugin/localizedFormat')
  dayjs.extend(localizedFormat)
  return `${dayjs(new Date(`${date}Z`)).format('L HH:mm A')}`
}
