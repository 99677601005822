import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import {
  SmallIconButton, ConfirmationButton, CancelButton, PageTitleDashboard, StyledCheckbox, DashboardContainer, DashboardInput, StyledSwitch,
  Loader, MessageBanner, Collapse
} from '@tops/components'
import { Form } from 'antd'
import { Formik, FieldArray } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { submitSector } from '../../../../actions/sectors'
import * as yup from 'yup'
import { fetchAllBusinessLevels } from '../../../../actions/businessLevels'
import { useParams } from 'react-router-dom';

const CreateSector = ({ history, location, savedInfo }) => {
  const { path } = useParams()
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [validateError, setValidateError] = useState(false)

  const sectors = useSelector(state => state.sectors)
  const business = useSelector(state => state.business)
  const businessLevels = useSelector(state => state.businessLevels)

  const dispatch = useDispatch()

  const tools = [{ 'name': i18n('SECTOR_TOOL_0'), 'id': 0, 'check': false },
  { 'name': i18n('SECTOR_TOOL_1'), 'id': 1, 'check': false },
  { 'name': i18n('SECTOR_TOOL_2'), 'id': 2, 'check': false },
  { 'name': i18n('SECTOR_TOOL_3'), 'id': 3, 'check': false },
  { 'name': i18n('SECTOR_TOOL_4'), 'id': 4, 'check': false }
  ]

  useEffect(() => {
    try {
      dispatch(fetchAllBusinessLevels(business.businessId, false, false))
    } catch (error) {
      setError(true)
    }
  }, [])

  const onSubmit = async (values) => {
    await dispatch(submitSector(values)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)

      },
      () => (
        setError(true)
        /**Fixear que al haber error, se buguea el collapse de conf de listado */
      )
    )
  }

  const nameInput = (handleChange, errors) => (
    <DashboardInput isRequired={true} label={i18n('NAME')} error={errors.name} horizontal="true" border="true" name="name"
      onChange={handleChange} width="350px" />
  )

  const businessLevelsCheckboxes = (values) => (<>
    <div style={{ marginTop: "15px", marginBottom: "15px", display: "flex" }}>
      <SmallIconButton icon={'Add'} size={21} onClick={() => history.push(`/dashboard/${savedInfo.name}/${i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LINK}/${i18n('SIDEBAR_LINKS').CREATE_LEVEL.LINK}`)} />
      <span style={{ fontWeight: 600, marginLeft: 13 }}>{i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LABEL}</span>
    </div>
    <FieldArray name="entity_configuration_ids" >
      {arrayHelpers => (

        <div style={{ display: "flex", flexDirection: "column", maxHeight: "100px", flexWrap: "wrap" }}>
          {businessLevels.all.map((level, i) => <StyledCheckbox key={i} value={level.id} checked={values.entity_configuration_ids.includes(level.id)} name={"entity_configuration_ids"} onChange={e => {
            if (e.target.checked) {
              arrayHelpers.push(level.id)
            }
            else {
              const idx = values.entity_configuration_ids.indexOf(level.id);
              arrayHelpers.remove(idx)
            }
          }}>{level.name}</StyledCheckbox>)}
        </div>

      )}
    </FieldArray>
  </>
  )

  const toolsSelection = (setFieldValue, values, errors) => (<>
    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
      <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('TOOLS'))}</span>
    </div>

    <FieldArray name="tools">
      {arrayHelpers => (
        <>
          {tools.map((tool, i) => (<>
            <StyledSwitch id={"tool_" + i} key={i} label={tool.name} checked={tool.check}
              onChange={(e) => {
                tool.check = e.target.checked
                const index = values.tools.findIndex(t => t.id === tool.id)
                tool.check ? arrayHelpers.push(i, tool.id) : arrayHelpers.remove(index)
                tool.id === 4 && setFieldValue(`haveToolStock`, tool.check)
                tool.id === 1 && !tool.check ? setFieldValue('request_pagination_configuration_concrete', null) : setFieldValue('request_pagination_configuration_concrete', {
                  by_request_model: false,
                  by_request_number: false,
                  by_creation_date: false,
                  by_finalization_date: false,
                  by_creation_user: false,
                  by_finalization_user: false,
                  by_description: false,
                  by_survey_model: false,
                  by_survey_item_result: false,
                  by_state: false,
                  by_phase: false,
                  by_urgency: false
                })
              }}
              horizontal="true" />
            {tool.id === 1 && tool.check &&
              <Collapse defaultOpenedPanels={[0]} panels={[{ title: "Configuración Listado de Solicitudes", content: requestListConfiguration(setFieldValue, values, errors) }]} />
            }
          </>)
          )}
          {(tools[4].check || values.haveToolStock) && <div style={{ marginLeft: 25 }}>
            <StyledSwitch id="is_stock_consumer" label={i18n('SECTOR_TOOL_4_CONSUMER')} horizontal="true" name="is_stock_consumer"
              checked={values.is_stock_consumer} onChange={(e) => setFieldValue(`is_stock_consumer`, e.target.checked)}
              isRequired={(tools[4].check || values.haveToolStock) && values.is_stock_creator === false && values.is_stock_consumer === false} />
            <StyledSwitch id="is_stock_creator" label={i18n('SECTOR_TOOL_4_CREATOR')} horizontal="true" name="is_stock_creator"
              checked={values.is_stock_creator} onChange={(e) => setFieldValue(`is_stock_creator`, e.target.checked)}
              isRequired={(tools[4].check || values.haveToolStock) && values.is_stock_creator === false && values.is_stock_consumer === false} />
          </div>
          }
        </>
      )}
    </FieldArray></>
  )

  const requestListConfiguration = (setFieldValue, values, errors) => (
    <DashboardContainer width="550px">
      <div>
        <b>
          Filtros
        </b>
        <div>
          Activa los filtros que desees, recuerda que deben ser mínimo 6 y máximo 10.
        </div>

        <div style={{ color: "#97a4b0", display: "flex", justifyContent: "space-between", width: "100%", marginTop: 15 }}>
          <div>
            Id
          </div>
          <div style={{ fontStyle: "italic" }}>
            Fijado
          </div>
        </div>
        <div style={{ color: "#97a4b0", display: "flex", justifyContent: "space-between", width: "100%", marginTop: 15, marginBottom: 10 }}>
          <div>
            Nivel
          </div>
          <div style={{ fontStyle: "italic" }}>
            Fijado
          </div>
        </div>
        <div>
          <StyledSwitch label={"Tipo de Solicitud"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_request_model}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_request_model", !values?.pagination_configuration_concrete?.by_request_model)} horizontal="true" bold={true} />
        </div>
        <div>
          <StyledSwitch label={"Fecha de Creación"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_creation_date}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_creation_date", !values?.pagination_configuration_concrete?.by_creation_date)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Fecha de Finalización"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_finalization_date}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_finalization_date", !values?.pagination_configuration_concrete?.by_finalization_date)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Usuario Creador"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_creation_user}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_creation_user", !values?.pagination_configuration_concrete?.by_creation_user)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Usuario Finalizador"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_finalization_user}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_finalization_user", !values?.pagination_configuration_concrete?.by_finalization_user)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Descripción"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_description}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_description", !values?.pagination_configuration_concrete?.by_description)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Herramienta Relacionada"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_survey_model}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_survey_model", !values?.pagination_configuration_concrete?.by_survey_model)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Respuesta del Item"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_survey_item_result}
            onChange={() => setFieldValue("pagination_configuration_concrete.by_survey_item_result", !values?.pagination_configuration_concrete?.by_survey_item_result)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Estado"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_state}
            onChange={() => {
              setFieldValue("pagination_configuration_concrete.by_state", !values?.pagination_configuration_concrete?.by_state)
              !values?.pagination_configuration_concrete?.by_state && setFieldValue("pagination_configuration_concrete.by_phase", false)
            }} horizontal="true" />
        </div>
        {values?.pagination_configuration_concrete?.by_state &&
          <div style={{ marginLeft: 50 }}>
            <StyledSwitch label={"Etapa (Estado)"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_phase}
              onChange={() => setFieldValue("pagination_configuration_concrete.by_phase", !values?.pagination_configuration_concrete?.by_phase)} horizontal="true" />
          </div>
        }
        <div>
          <StyledSwitch label={"Número de solicitud (Consecutivo)"} style={{ width: "100%", fontWeight: "bold" }}
          checked={values?.pagination_configuration_concrete?.by_request_number}
          onChange={() => setFieldValue("pagination_configuration_concrete.by_request_number", !values.pagination_configuration_concrete?.by_request_number)}
          horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Urgencia"} style={{ width: "100%", fontWeight: "bold" }} checked={values?.pagination_configuration_concrete?.by_urgency}
            onChange={() => setFieldValue("by_urgency", !values?.pagination_configuration_concrete?.by_urgency)} horizontal="true" />
        </div>
      </div>
    </DashboardContainer>
  )

  const validationSchema = yup.object({
    name: yup.string().trim().required(i18n('ERRORS').SECTOR.NAME_ERROR),
  })

  if (businessLevels.isFetching) {
    return <Loader />
  }

  const initial = {
    name: "",
    business_id: location.state.businessId,
    account_id: savedInfo.id,
    entity_configuration_ids: [],
    tools: [],
    is_stock_consumer: false,
    is_stock_creator: false,
    haveToolStock: false,
    pagination_configuration_concrete: null
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').SECTORS.LABEL, i18n('SIDEBAR_LINKS').CREATE_SECTOR.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').CREATE_SECTOR} />
        <MessageBanner visible={error} status="error" message={sectors.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {sectors.isSubmitting && <Loader />}
      <Formik initialValues={initial} validationSchema={validationSchema} validateOnChange={false} onSubmit={(values) => onSubmit(values)}>
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit}>
              <DashboardContainer width="550px">
                {nameInput(handleChange, errors)}
                {businessLevelsCheckboxes(values)}
                {toolsSelection(setFieldValue, values, errors)}
              </DashboardContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')} </CancelButton>
                <ConfirmationButton type="submit" small
                  disabled={(tools[4].check || values.haveToolStock) && values.is_stock_consumer === false && values.is_stock_creator === false}>
                  {i18n('SAVE')}
                </ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default CreateSector
