import React from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter, getUserInfo } from '@tops/utils'
import { Page, Container, Item } from '@tops/components'
import { theme } from '@tops/global_config'
import { PageTitle, ItemsContainer } from './styled'
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/auth'

const Home = (props) => {
  const dispatch = useDispatch()

  return (
    <Page withHeader background="white" logout={() => dispatch(logout())}>
      <Container justifyContent="center">
        <PageTitle>
          {`¡${i18n('HOME').HELLO} ${getUserInfo().name}!`}
        </PageTitle>
        <ItemsContainer>
          <Item title={capitalizeFirstLetter(i18n('ACCOUNTS'))} color={theme.colors.accent} background={theme.colors.primary} onClick={() => props.history.push(`/${i18n('ACCOUNTS')}`)}>
            {/* TODO: reemplazar el span cuando esten las ilustraciones */}
            <span style={{ 'width': 300, 'height': 250 }}></span>
          </Item>
          <Item title={capitalizeFirstLetter(i18n('TOOLS'))} color={theme.colors.primary} background={theme.colors.accent} onClick={() => props.history.push(`/${i18n('TOOLS')}`)}>
            {/* TODO: reemplazar el span cuando esten las ilustraciones */}
            <span style={{ 'width': 300, 'height': 250 }}></span>
          </Item>
        </ItemsContainer>
      </Container>
    </Page>
  )

}
export default Home
