import React from 'react'
// import Icon from '@ant-design/icons';

import { Button, Text, IconContainer } from './styled'
import Icon, { IconNames } from '../Icons';
import { Tooltip } from 'antd';


export const IconButton = ({square, background, iconColor, size, icon, text, textSize, onClick, bold, disabled, shadow = true /* (shadow) Fix nodo organigrama */, flipIcon, ...props }) => (
  <Button disabled={disabled} >
    {text && <Text onClick={onClick} textSize={textSize} bold={bold}>
      {text}
    </Text>}
    <IconContainer size={size} background={background} onClick={onClick} shadow={shadow} {...props} square={square} >
    { props.reprogramming ? <Tooltip title={"Reprogramación"} placement="top">
      <Icon size={size} name={IconNames[icon]} color={iconColor} flipIcon={flipIcon} />
      </Tooltip> : <Icon size={size} name={IconNames[icon]} color={iconColor} flipIcon={flipIcon} /> }
    </IconContainer>
  </Button>
)

export const SmallIconButton = ({ background, size, icon, iconColor, text, textSize, onClick, bold, borderColor, marginOff, paddingRight, shadowButton, border, shadowIcon, shadow, ...props }) => (
  <Button small style={{ border: `1px solid ${borderColor ? borderColor : 'transparent'}`, paddingRight: paddingRight && "10px", boxShadow: shadowButton && "0 3px 2px 0 rgba(0, 0, 0, 0.05)", borderRadius: border && "5px" }} onClick={(e) => {
    e.preventDefault()
    onClick()
  }} {...props}>
    <IconContainer {...props} size={size} background={background} small marginOff={marginOff} shadow={shadow}>
      <Icon size={size} name={IconNames[icon]} color={iconColor} />
    </IconContainer>
    <Text textSize={textSize} bold={bold}>
      {text}
    </Text>
  </Button>
)