import { combineReducers } from 'redux'

import auth from './auth'
import accounts from './accounts'
import accountLevels from './accountLevels'
import business from './business'
import sectors from './sectors'
import businessLevels from './businessLevels'
import expirationRequestTypes from './expirationRequestTypes'
import requestTypes from './requestTypes'
import roles from './roles'
import wiseConfiguration from './wiseConfiguration'
import users from './user'
import searchCache from './searchCache'
import vctAdmin from './vctAdmin'
import files from './files'
import accidentAndIncident from './accidentAndIncident'

const appReducer = combineReducers({
  auth,
  accounts,
  accountLevels,
  business,
  sectors,
  businessLevels,
  expirationRequestTypes,
  requestTypes,
  roles,
  wiseConfiguration,
  users,
  searchCache,
  vctAdmin,
  files,
  accidentAndIncident,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
  }

  return appReducer(state, action)
}


export default rootReducer
