import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import { useSelector, useDispatch } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import { Form } from 'antd';
import {
  SmallIconButton, ConfirmationButton, CancelButton, PageTitleDashboard, StyledCheckbox, DashboardContainer, DashboardInput, StyledSwitch,
  Loader, MessageBanner, Collapse
} from '@tops/components'
import { editSector } from '../../../../actions/sectors';
import { useParams } from 'react-router-dom';
import * as yup from 'yup'
import { fetchAllBusinessLevels } from '../../../../actions/businessLevels';
import './index.css';

const EditSector = ({ history, savedInfo, location }) => {
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [sectorInfo, setSectorInfo] = useState('')
  const [validateError, setValidateError] = useState(false)
  const [load, setLoad] = useState(true)
  const [entityConfigurations, setEntityConfigurations] = useState([])

  const { id } = useParams()

  const sectors = useSelector(state => state.sectors)
  const businessLevels = useSelector(state => state.businessLevels)
  const business = useSelector(state => state.business)

  const dispatch = useDispatch()

  const addIds = []
  const tools = [{ 'name': i18n('SECTOR_TOOL_0'), 'id': 0, 'check': false },
  { 'name': i18n('SECTOR_TOOL_1'), 'id': 1, 'check': false },
  { 'name': i18n('SECTOR_TOOL_2'), 'id': 2, 'check': false },
  { 'name': i18n('SECTOR_TOOL_3'), 'id': 3, 'check': false },
  { 'name': i18n('SECTOR_TOOL_4'), 'id': 4, 'check': false }
  ]

  useEffect(() => {
    const fetchData = async () => {
      if (sectors.values.length > 0) {
        sectors.values.forEach((item) => {
          if (item.id === parseFloat(id)) {
            setSectorInfo(item)
            item.entity_configurations.forEach((entity) => {
              entityConfigurations.push(entity.id);
            })
            setLoad(false);
          }
        })
        try {
          dispatch(fetchAllBusinessLevels(business.businessId, false, false))
        } catch (error) {
          setError(true)
        }
      } else {
        history.push(`/dashboard/${savedInfo.name.toLowerCase()}/sectores`)
      }
    }
    fetchData()
    return () => { }
  }, [])

  const onSubmit = async (values) => {
    // ordeno todos los array para comparar
    sectorInfo.tools.sort();
    values.remove_tools.sort();
    values.add_tools.sort();

    // caso: agregar y eliminar son arrays iguales: por lo que no tengo que enviar nada a back
    if (values.add_tools.length === values.remove_tools.length && values.add_tools.every((value, index) => value === values.remove_tools[index])) {
      values.add_tools = []
      values.remove_tools = []
    }
    else {
      let oadd = values.add_tools
      let oremove = values.remove_tools
      // ahora filtro los comunes que se quieren eliminar y agregar
      values.add_tools = oadd.filter(item => !oremove.includes(item))
      values.remove_tools = oremove.filter(item => !oadd.includes(item))
      // no puedo agregar los que ya existen originalmente
      values.add_tools = values.add_tools.filter(item => !sectorInfo?.tools.includes(item))
      // no puedo remover las que nunca estuvieron agregadas originalmente     
      values.remove_tools = values.remove_tools.filter(item => sectorInfo?.tools.includes(item))
    }

    await dispatch(editSector(values)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  const validationSchema = yup.object({
    name: yup.string().trim().required(i18n('ERRORS').SECTOR.NAME_ERROR)
  })

  const nameInput = (handleChange, values, errors) => (
    <DashboardInput isRequired={true} label={i18n('NAME')} error={errors.name} horizontal="true" border="true" name="name"
      value={values.name} onChange={handleChange} width="350px" />
  )

  const activeSwitch = (setFieldValue, values) => (
    <div style={{ marginBottom: "30px" }}>
      <StyledSwitch id="editSector_isActive" onChange={() => setFieldValue('is_active', !values.is_active)} checked={values.is_active}
        name="is_active" label={i18n('ACTIVE')} horizontal="true" />
    </div>
  )

  const businessLevelsCheckboxes = (setFieldValue, values) => (<>
      <div style={{ marginTop: "15px", marginBottom: "15px", display: "flex" }}>
        <SmallIconButton icon={'Add'} size={21} onClick={() => history.push(`/dashboard/${savedInfo.name}/${i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LINK}/${i18n('SIDEBAR_LINKS').CREATE_LEVEL.LINK}`)} />
        <span style={{ fontWeight: 600, marginLeft: 13 }}>{i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LABEL}</span>
      </div>

      <FieldArray
        name="entity_configuration_ids"
      >
        {arrayHelpers => (
          <div style={{ display: "flex", flexDirection: "column", maxHeight: "150px", flexWrap: "wrap" }}>
            {businessLevels.all.map((level, i) => <StyledCheckbox key={i} value={level.id} class="inputs" style={{ paddingRight: 5 }}
              checked={values.entity_configuration_ids.includes(level.id)} name={"entity_configuration_ids"} disabled={entityConfigurations.includes(level.id) ? true : false}
              onChange={e => {
                if (e.target.checked) {
                  arrayHelpers.push(level.id)
                  addIds.push(level.id)
                }
                else {
                  const idx = values.entity_configuration_ids.indexOf(level.id);
                  arrayHelpers.remove(idx)
                }
                setFieldValue('add_entity_config_ids', addIds)
              }}>{level.name}</StyledCheckbox>)}
          </div>
        )}
      </FieldArray>
    </>
    )

  const toolsSelection = (setFieldValue, values, errors) => (<>
    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
      <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('TOOLS'))}</span>
    </div>

    <FieldArray name="tools">
      {arrayHelpers => (
        <>
          {tools.map((tool, i) => (<>
            <StyledSwitch id={"tool_" + i} key={i} label={tool.name}
              checked={values?.tools?.findIndex(t => t === tool.id) !== -1 ? true : false}
              onChange={() => {
                tool.check = !tool.check
                const index = values.tools.findIndex(t => t === tool.id)
                tool.check ? arrayHelpers.push(i, tool.id) : arrayHelpers.remove(index)
                tool.check ? values.add_tools.findIndex(t => t === tool.id) === -1 && setFieldValue('add_tools', [...values.add_tools, tool.id])
                  : values.remove_tools.findIndex(t => t === tool.id) === -1 && setFieldValue('remove_tools', [...values.remove_tools, tool.id])
                tool.id === 4 && setFieldValue(`haveToolStock`, tool.check)
                tool.id === 4 && tool.check === false && setFieldValue(`is_stock_creator`, false) && setFieldValue(`is_stock_consumer`, false)
                tool.id === 1 && !tool.check ? setFieldValue('request_pagination_configuration_concrete', null) : setFieldValue('request_pagination_configuration_concrete', {})
              }}
              horizontal="true" />
            {tool.id === 1 && tool.check && values.request_pagination_configuration_concrete !== null &&
              <Collapse defaultOpenedPanels={[0]} panels={[{ title: "Configuración Listado de Solicitudes", content: requestListConfiguration(setFieldValue, values, errors) }]} />
            }
          </>)
          )}
          {values.haveToolStock && <div style={{ marginLeft: 25 }}>
            <StyledSwitch id="is_stock_consumer" label={i18n('SECTOR_TOOL_4_CONSUMER')} horizontal="true" name="is_stock_consumer"
              checked={values.is_stock_consumer} onChange={(e) => setFieldValue(`is_stock_consumer`, e.target.checked)}
              isRequired={(tools[4].check || values.haveToolStock) && values.is_stock_creator === false && values.is_stock_consumer === false} />
            <StyledSwitch id="is_stock_creator" label={i18n('SECTOR_TOOL_4_CREATOR')} horizontal="true" name="is_stock_creator"
              checked={values.is_stock_creator} onChange={(e) => setFieldValue(`is_stock_creator`, e.target.checked)}
              isRequired={(tools[4].check || values.haveToolStock) && values.is_stock_creator === false && values.is_stock_consumer === false} />
          </div>
          }
        </>
      )}
    </FieldArray></>
  )

  const requestListConfiguration = (setFieldValue, values, errors) => (
    <DashboardContainer width="550px">
      <div>
        <b>
          Filtros
        </b>
        <div>
          Activa los filtros que desees, recuerda que deben ser mínimo 6 y máximo 10.
        </div>

        <div style={{ color: "#97a4b0", display: "flex", justifyContent: "space-between", width: "100%", marginTop: 15 }}>
          <div>
            Id
          </div>
          <div style={{ fontStyle: "italic" }}>
            Fijado
          </div>
        </div>
        <div style={{ color: "#97a4b0", display: "flex", justifyContent: "space-between", width: "100%", marginTop: 15, marginBottom: 10 }}>
          <div>
            Nivel
          </div>
          <div style={{ fontStyle: "italic" }}>
            Fijado
          </div>
        </div>
        <div>
          <StyledSwitch label={"Tipo de Solicitud"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_request_model}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_request_model", !values.request_pagination_configuration_concrete.by_request_model)} horizontal="true" bold={true} />
        </div>
        <div>
          <StyledSwitch label={"Fecha de Creación"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_creation_date}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_creation_date", !values.request_pagination_configuration_concrete.by_creation_date)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Fecha de Finalización"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_finalization_date}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_finalization_date", !values.request_pagination_configuration_concrete.by_finalization_date)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Usuario Creador"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_creation_user}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_creation_user", !values.request_pagination_configuration_concrete.by_creation_user)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Usuario Finalizador"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_finalization_user}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_finalization_user", !values.request_pagination_configuration_concrete.by_finalization_user)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Descripción"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_description}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_description", !values.request_pagination_configuration_concrete.by_description)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Herramienta Relacionada"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_survey_model}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_survey_model", !values.request_pagination_configuration_concrete.by_survey_model)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Respuesta del Item"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_survey_item_result}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_survey_item_result", !values.request_pagination_configuration_concrete.by_survey_item_result)} horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Estado"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_state}
            onChange={(e) => {
              setFieldValue("request_pagination_configuration_concrete.by_state", !values.request_pagination_configuration_concrete.by_state)
              !e.target.checked && setFieldValue("request_pagination_configuration_concrete.by_phase", false)
            }} horizontal="true" />
        </div>
        {values.request_pagination_configuration_concrete.by_state &&
          <div style={{ marginLeft: 50 }}>
            <StyledSwitch label={"Etapa (Estado)"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_phase}
              onChange={() => setFieldValue("request_pagination_configuration_concrete.by_phase", !values.request_pagination_configuration_concrete.by_phase)} horizontal="true" />
          </div>
        }
        <div>
          <StyledSwitch label={"Número de solicitud (Consecutivo)"} style={{ width: "100%", fontWeight: "bold" }}
          checked={values.request_pagination_configuration_concrete.by_request_number}
          onChange={() => setFieldValue("request_pagination_configuration_concrete.by_request_number", !values.request_pagination_configuration_concrete.by_request_number)}
          horizontal="true" />
        </div>
        <div>
          <StyledSwitch label={"Urgencia"} style={{ width: "100%", fontWeight: "bold" }} checked={values.request_pagination_configuration_concrete.by_urgency}
            onChange={() => setFieldValue("request_pagination_configuration_concrete.by_urgency", !values.request_pagination_configuration_concrete.by_urgency)} horizontal="true" />
        </div>
      </div>
    </DashboardContainer>
  )

  if (businessLevels.isFetching) {
    return <Loader />
  }

  tools.forEach(t => {
    sectorInfo?.tools?.includes(t.id) ? t.check = true : t.check = false
  })

  const initial = {
    id: id,
    name: sectorInfo.name,
    account_id: savedInfo.id,
    tools: sectorInfo?.tools?.sort(),
    entity_configuration_ids: entityConfigurations,
    add_entity_config_ids: [],
    is_active: location.state.isActive,
    haveToolStock: sectorInfo?.tools?.includes(4) ? true : false,
    is_stock_consumer: sectorInfo.is_stock_consumer !== undefined ? sectorInfo.is_stock_consumer : false,
    is_stock_creator: sectorInfo.is_stock_creator !== undefined ? sectorInfo.is_stock_creator : false,
    add_tools: [],
    remove_tools: [],
    request_pagination_configuration_concrete: sectorInfo?.tools?.includes(1) ? {
      sector_id: id,
      by_request_model: sectorInfo?.request_pagination_configuration?.by_request_model || false,
      by_request_number: sectorInfo?.request_pagination_configuration?.by_request_number || false,
      by_creation_date: sectorInfo?.request_pagination_configuration?.by_creation_date || false,
      by_finalization_date: sectorInfo?.request_pagination_configuration?.by_finalization_date || false,
      by_creation_user: sectorInfo?.request_pagination_configuration?.by_creation_user || false,
      by_finalization_user: sectorInfo?.request_pagination_configuration?.by_finalization_user || false,
      by_description: sectorInfo?.request_pagination_configuration?.by_description || false,
      by_survey_model: sectorInfo?.request_pagination_configuration?.by_survey_model || false,
      by_survey_item_result: sectorInfo?.request_pagination_configuration?.by_survey_item_result || false,
      by_state: sectorInfo?.request_pagination_configuration?.by_state || false,
      by_phase: sectorInfo?.request_pagination_configuration?.by_phase || false,
      by_urgency: sectorInfo?.request_pagination_configuration?.by_urgency || false
    } : null,
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').SECTORS.LABEL, i18n('SIDEBAR_LINKS').EDIT_SECTOR.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').EDIT_SECTOR} />
        <MessageBanner visible={error} status="error" message={sectors.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {sectors.isSubmitting && <Loader />}
      <Formik enableReinitialize={true} initialValues={initial} validateOnChange={false}
        validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)}>
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="550px">
                {nameInput(handleChange, values, errors)}
                {activeSwitch(setFieldValue, values)}
                {businessLevelsCheckboxes(setFieldValue, values)}
                {toolsSelection(setFieldValue, values, errors)}
              </DashboardContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')} </CancelButton>
                <ConfirmationButton type="submit" small
                  disabled={(tools[4].check || values.haveToolStock) && values.is_stock_consumer === false && values.is_stock_creator === false}>
                  {i18n('SAVE')}
                </ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default EditSector
