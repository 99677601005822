import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchRolesStart,
  fetchRolesSuccess,
  fetchRolesError,
  submitRoleStart,
  submitRoleSuccess,
  submitRoleError,
  editRoleStart,
  editRoleSuccess,
  editRoleError,
  fetchRolesSimpleStart,
  fetchRolesSimpleSuccess,
  fetchRolesSimpleError, } = createActions({
    FETCH_ROLES_START: () => { },
    FETCH_ROLES_SUCCESS: values => ({ values }),
    FETCH_ROLES_ERROR: error => ({ error }),
    SUBMIT_ROLE_START: () => { },
    SUBMIT_ROLE_SUCCESS: data => ({ data }),
    SUBMIT_ROLE_ERROR: error => ({ error }),
    EDIT_ROLE_START: () => { },
    EDIT_ROLE_SUCCESS: data => ({ data }),
    EDIT_ROLE_ERROR: error => ({ error }),
    FETCH_ROLES_SIMPLE_START: () => { },
    FETCH_ROLES_SIMPLE_SUCCESS: values => ({ values }),
    FETCH_ROLES_SIMPLE_ERROR: error => ({ error }),
  })

const fetchRoles = (page, sectorId, active) => {
  return async dispatch => {
    const status = active ? 'paginated' : 'inactive'
    dispatch(fetchRolesStart())
    const api = getApi(API_ROUTES)
    await api.get(`${API_ROUTES.ROLES}/${status}/${page}/${10}/${sectorId}`)
      .then(
        (response) => dispatch(fetchRolesSuccess(response.data)),
        (error) => dispatch(fetchRolesError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const fetchRolesSimple = (sectorId) => {
  return async dispatch => {
    dispatch(fetchRolesSimpleStart())
    const api = getApi(API_ROUTES)
    await api.get(`${API_ROUTES.ROLES}/simple/${sectorId}`)
      .then(
        (response) => dispatch(fetchRolesSimpleSuccess(response.data)),
        (error) => dispatch(fetchRolesSimpleError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const submitRole = (role, sectorId) => {
  return async dispatch => {
    dispatch(submitRoleStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(`${API_ROUTES.ROLES}/sector/${sectorId}`, role)
        .then((response) => {
          dispatch(submitRoleSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitRoleError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editRole = (role) => {
  return async dispatch => {
    dispatch(editRoleStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.ROLES, role)
        .then(() => {
          dispatch(editRoleSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editRoleError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}


export {
  fetchRoles,
  fetchRolesStart,
  fetchRolesSuccess,
  fetchRolesError,
  submitRole,
  submitRoleStart,
  submitRoleSuccess,
  submitRoleError,
  editRole,
  editRoleStart,
  editRoleSuccess,
  editRoleError,
  fetchRolesSimple,
  fetchRolesSimpleStart,
  fetchRolesSimpleSuccess,
  fetchRolesSimpleError,
}
