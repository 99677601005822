import React, { useState } from 'react'
import { i18n } from '@tops/services'
import { Form } from 'antd';
import "antd/dist/antd.css";
import LoginIlustration from '../../../assets/images/illustration_login.svg'
import TerrandLogo from '../../../assets/images/terrand_logo.svg'
import { Container, SideImagesWrapper, Logo, Image, LoginWrapper } from '../styles'
import { challenge } from '../../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { LoginInput, ConfirmationButton, AlertMessage, Loader } from '@tops/components';
import { Formik } from 'formik';
import * as yup from 'yup'
import { theme } from '@tops/global_config'

const Challenge = (props) => {
  const [error, setError] = useState(false)
  const dispatch = useDispatch()
  const authState = useSelector(state => state.auth)

  const onSubmit = (values) => {
    let data = {
        "user_id": authState.hasSession.user_id,
        "session":  authState.hasSession.session, 
        "new_password": values.new_password
    }
    let dataLogin = {
      username:props.location.state.username,
      password: props.location.state.password 
    }
    dispatch(challenge(data, dataLogin)).then(
      () => props.history.push('/login'),
      () => setError(true)
    )
  }

  const validationSchema = yup.object({
    new_password: yup.string().trim().required(i18n('USER').CHALLENGE.ERROR.REQUIRED_NEW_PASSWORD).matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, i18n('USER').CHALLENGE.ERROR.PASSWORD_NOT_VALID),
    confirm_password: yup.string().trim().required(i18n('USER').CHALLENGE.ERROR.REQUIRED_CONFIRM_PASSWORD).matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, i18n('USER').CHALLENGE.ERROR.PASSWORD_NOT_VALID)
      .test('match', i18n('USER').CHALLENGE.ERROR.NOT_MATCH, 
        function(confirm_password) { 
          return confirm_password === this.parent.new_password; 
      }),
  })

  if(authState.isSubmitting)
  {
    return <Loader/>
  }

  return (
    <Container>
       <SideImagesWrapper>
        <Logo src={TerrandLogo} />
        <Image src={LoginIlustration} />
      </SideImagesWrapper>
      <LoginWrapper>
        <Formik initialValues={{ username: '', password: '' }}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}>
        {({ handleSubmit, handleChange, values, errors }) => (
           <Form onFinish={handleSubmit}>
                <div style={{ fontWeight: 900, fontSize: 18, color: theme.colors.darkGray, paddingBottom:"20px", paddingTop:"20px" }}>
                  {i18n('USER').CHALLENGE.TITLE}
                </div>
                <LoginInput name="new_password" ispassword="true" onChange={handleChange} error={errors.new_password} border="true"
                    label={i18n('USER').CHALLENGE.NEW_PASSWORD} />
                <LoginInput name="confirm_password" ispassword="true" onChange={handleChange} error={errors.confirm_password} border="true"
                    label={i18n('USER').CHALLENGE.CONFIRM_PASSWORD} />
                <div style={{ paddingBottom:"20px", paddingTop:"20px" }}>
                  <ConfirmationButton type="submit" > {i18n('USER').CHALLENGE.SAVE.toUpperCase()} </ConfirmationButton>
                </div>
            </Form>
          )}
        </Formik>
      </LoginWrapper>
    </Container>
  )
}

export default Challenge
