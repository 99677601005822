import React from 'react'
import styled from 'styled-components'
import { ListToolsItem } from './ListItem'
import { mediaQueries } from '@tops/global_config'

const StyledList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${mediaQueries.mobile`
  padding: 15px 0;
`}
`
const ListWithDetailsWrapper = styled.div`
  display:flex;
  justify-content:flex-start;
  font-size: 13px;
  padding: 0 30px;
  width: 100%;
`

const ItemDetail = styled.div`
width: 200px;
max-width: 100%;
font-weight: 900;
font-size: 14px;
margin-bottom:3px;
`

export const List = ({ children }) => (
  <StyledList>
    {children}
  </StyledList>
)

export const ListWithDetails = ({ children, title, details }) => (
  <>
    <ListWithDetailsWrapper>
      {details ? details.map((e, i) => <ItemDetail key={i}>{e.name}</ItemDetail>) :
        <>
        <ItemDetail>
          Nombre
      </ItemDetail>
          <ItemDetail>
            Negocio
      </ItemDetail>
          <ItemDetail>
            Sector
      </ItemDetail></>}
      <ItemDetail>
        {title}
      </ItemDetail>
    </ListWithDetailsWrapper>
    <StyledList>
      {children}
    </StyledList>
  </>
)

export const ListTools = (props) => (
  <StyledList>
    {props.items.map(({ name, description }, i) => {
      return <ListToolsItem key={i} name={name.toUpperCase()} description={description} />
    })}
  </StyledList>
)