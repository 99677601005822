import React from 'react'
import { Icon, IconNames } from '@tops/components';
import { theme } from '@tops/global_config'
import { Container, DatePickerContainer, StyledDatePicker, Label, ErrorMessage } from './styled';

import { ConfigProvider } from "antd";
import moment from 'moment';
import 'moment/locale/es';
import locale from "antd/es/locale/es_ES";

moment.updateLocale('es-ar', {
  week: {
    dow: 1,
  },
});

export const DatePicker = (props) => {

  return <Container {...props}>
    {props.label &&
      <Label {...props}>
        {props.label}
        {props.isRequired && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
      </Label>
    }
    <ConfigProvider locale={locale}>
      <DatePickerContainer>
        <StyledDatePicker
          suffixIcon={!props.disabled && <Icon name={IconNames['Schedule']} color={props.color || theme.colors.darkGray} />}
          allowClear={false}
          // bordered={!props.disabled}
          {...props}
        />
        {props.error && <ErrorMessage hasError={props.error} >{props.error}</ErrorMessage>}
      </DatePickerContainer>
    </ConfigProvider>
  </Container>
}
