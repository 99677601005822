import React from 'react'
import styled from 'styled-components'
import { theme } from '@tops/global_config'
import { IconNames } from '@tops/components';
import Icon from './../../Icons';
import { i18n } from '@tops/services';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ align }) => align || 'center'};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  opacity:${({ disabled }) => disabled ? 0.7 : 1};
  cursor:  ${({ cursor }) => cursor || 'pointer'};
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.lato};
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 30px;
  margin: 8px 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05);
`

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`

const Item = styled.div`
  width: 200px;
  max-width: 100%;
  font-weight: normal;
`

const Tag = styled.div`
  background-color: ${({ theme }) => theme.colors.accent};
  width: 115px;
  margin-right: 2.5em;
  padding: .1em 1em;
  float: right;
  text-align: center;
`

export const ListItem = ({ name, item, action, actionQr, onClick, disabled, details, isUser, isRequest, isQrActive, icon }) => {
  
  const renderDetails = () => {
    if (isUser) {
      return <>{details && details.map((detail, i) =>
        <Item key={i}>{`${detail.name} (${detail.functional_key_value})`}</Item>)}
        <Item>
          {name || item.name}
        </Item>
      </>
    } else if (isRequest){
      return <>
        <Item> {item.name}</Item>
        <Item> {item.business_name} </Item>
        <Item> {item.sector_name} </Item>
      </>
    } else {
      return <>
        <Item>
          {name || item.name}
        </Item>
        {details && details.map((detail, i) =>
          <Item key={i}>{detail.name || detail.functional_key_value}</Item>)}
      </>
    }
  }
  return <Wrapper disabled={disabled} cursor={onClick && !disabled ? 'pointer' : 'default'}>
    <Title onClick={onClick}>
      {renderDetails()}
    </Title>
    {item.is_wise_level ? (<Tag>{i18n('LEVEL_WISE')}</Tag>) : ''}
    <div style={{ cursor: 'pointer', display: 'flex' }}>
      {isQrActive && <Icon onClick={actionQr} name={IconNames['QR']} size={35} color={theme.colors.darkGreen} style={{ marginRight: 15 }} />}
      <Icon onClick={action} name={IconNames[icon ? icon : 'Edit']} size={35} color={theme.colors.darkGreen} />
    </div>
  </Wrapper>
}

export const ListToolsItem = ({ name, description }) => (
  <Wrapper flexDirection="column" align="start" cursor="default">
    <div>
      {name}
    </div>
    <div>
      {description}
    </div>
  </Wrapper>
)