import { handleActions } from 'redux-actions'
import {
    fetchStandardConfigurationStart, fetchStandardConfigurationSuccess, fetchStandardConfigurationError,
    submitStandardConfigurationStart, submitStandardConfigurationSuccess, submitStandardConfigurationError,
    editStandardConfigurationStart, editStandardConfigurationSuccess, editStandardConfigurationError,
} from '../actions/vctAdmin'

export const defaultState = {
  values: [],
}

const reducer = handleActions(
  {
    [fetchStandardConfigurationStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchStandardConfigurationSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchStandardConfigurationError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitStandardConfigurationStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitStandardConfigurationSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitStandardConfigurationError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editStandardConfigurationStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editStandardConfigurationSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editStandardConfigurationError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
  },
  defaultState
)

export default reducer
