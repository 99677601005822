import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'


export const Container = styled.div`
  ${({ width }) => width ? `width: ${width}; max-width: 100%;` : 'max-width: 1500px;'}
  margin: 0 auto;
  padding: 20px 0px;
 ${({ height }) => `height: ${height}`};
  display:flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${ ({ justifyContent }) => justifyContent || "stretch"};
  font-weight: bold;
  border-bottom: ${({ borderBottom, theme }) => borderBottom && `1px solid ${theme.colors.accent}`};
  ${({ background }) => background && `background: ${background};`}
  &::-webkit-scrollbar-thumb {
    background-color: #704dfb;
  }
${mediaQueries.mobile`
    width:100%;
    padding: 20px 30px;
    align-items:center;
    flex-direction: column;
`}
`

export const ContainerAccount = styled.div`
  width: ${({ width }) => width || "90%"};
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  height: ${({ height }) => height || "100%"};
  display:flex;
  flex-direction: ${({ flexDirection }) => flexDirection || "column"};
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  align-items: ${({ justifyContent }) => justifyContent || "stretch"};
  font-weight: bold;

  ${mediaQueries.mobile`
    width:100%;
    padding: 20px 30px;
    align-items:center;
    flex-direction: column;
`}
`

export const DashboardContainer = styled.div`
  width: ${({ width }) => width || "90%"};
  max-width: 100%;
  height: ${({ height }) => height || "100%"};
  display:flex;
  flex-direction:column;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 13px;
  padding: 20px 20px;
  ${mediaQueries.mobile`
    padding: 0 20px;
    flex-direction: column;
`}
`

