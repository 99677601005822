import { createActions } from 'redux-actions'

const { saveSearchCache
} = createActions({
  SAVE_SEARCH_CACHE: values => ({ values }),
})

const saveSearch = (page, filters, currentPage, businessId) => {
  return async dispatch => {
    dispatch(saveSearchCache({ page: page, filters: filters, currentPage: currentPage, businessId: businessId }))
  }
}

export {
  saveSearch,
  saveSearchCache
}