import styled, { keyframes } from 'styled-components'
import { Select } from 'antd'
import { mediaQueries } from '@tops/global_config'

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: ${({ horizontal }) => horizontal ? 'space-between' : 'flex-start'};
  font-weight: 500;
  font-size: 14px;
  align-items: ${({ horizontal }) => horizontal && "align-items: flex-end;"};
  ${({ horizontal, width }) => !horizontal && `width: ${width};`}
  ${mediaQueries.mobile`
    flex-direction: column;
    width: ${({ width }) => width};
    max-width:100%;
  `}
`

export const SelectContainer = styled.div`
  width: 100%;
  max-width: ${({ width }) => width || '100%'};
  color: ${({ theme }) => theme.colors.darkGray};
`

export const StyledSelect = styled(Select)`
  max-width: 100%;
  ${({ icon }) => icon && `padding-left: 15px;`}
  width: ${({ width }) => width || '300px'};
  border:${({ theme, color, selected, error, border }) => selected ? `1px solid ${color || theme.colors.lilac}` : error ? `1px solid ${theme.colors.red}` : border ? '1px solid transparent' : `1px solid ${theme.colors.gray}`};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 13px;
  color: ${({ theme }) => theme.colors.darkGray};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  box-shadow: ${({ shadow }) => shadow ? '0 0 0 0 white' : '0 3px 2px 0 rgba(0, 0, 0, 0.05)'};
  transition: box-shadow 0.2s ease-out;
  border-radius: 6px;
  margin-bottom: ${({ marginBottom }) => marginBottom ? '0px' : '8px'};
  .ant-select-arrow {
    color: ${({ theme, color }) => color || theme.colors.lilac};
    font-size: 15px;
    display: ${({ disabled }) => disabled && 'none'};
  }
  .ant-select-selection-item{
    color: ${({ disabled }) => disabled && 'black'};
  }
  .ant-select-selection-overflow{
    max-height: ${({ scrolleable }) => scrolleable && '56px'};
    overflow-y: ${({ scrolleable }) => scrolleable && 'auto'};
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #9d9d9d;
      border-radius: 5px;
    }
  }
  background: ${({ theme, disabled }) => disabled ? "#f5f5f5" : theme.colors.white};
  border-color: ${({ theme, disabled, error }) => error ? theme.colors.red : disabled ? "#f5f5f5" : theme.colors.gray};

  .ant-select-selection-item{
    color: ${({ theme }) => theme.colors.darkGray};
  }
  
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: ${({ bold }) => bold ? '600' : '500'};
  line-height: ${({ fHeight }) => fHeight ? '18px' : '40px'};
  font-size: ${({ fontSize }) => fontSize ? '13px !important' : '14px !important'};
  // min-width: 250px;
  ${({ horizontal }) => horizontal && "margin-right: 20px"}
  margin-right: ${({ marginRight }) => marginRight && "0px !important"}
`

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const ErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  animation:${({ hasError }) => hasError ? fadeInDown : fadeInUp} 0.3s ease;
`