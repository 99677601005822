import React from 'react'
import styled from 'styled-components'
import { Radio } from 'antd'


const RadioStyled = styled(Radio)`
    .ant-radio-inner::after {
        background-color: ${({ theme }) => theme.colors.user.wise.primary};
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.user.wise.primary};
    }
    .ant-radio:hover .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.user.wise.primary};
      }
  `

export const RadioSelect = ({options, value, onChange, onClick, children, ...props}) => {

    return(
        <RadioStyled.Group {...props} onChange={onChange} value={value} style={{display: "flex", flexDirection: "column"}} >
            {options.map((option) => (
                <RadioStyled value={option.value} style={{marginBottom: 10}}>{option.label}</RadioStyled>
            ))}
        </RadioStyled.Group>
    )
}