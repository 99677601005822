import React from 'react'
import { PageTitleContainer, PageTitleFixed, PageTitleUserContainer, PageTitleDashboardContainer, TitleDashboard, ChildrenContainer, Title } from './styled'
import { IconButton } from '../IconButton'
import { Breadcrumbs } from './../Breadcrumbs';
import { theme } from '@tops/global_config'

export const PageTitle = ({ children, title, onBack, ...props }) => {
  return (
    <PageTitleContainer {...props}>
      {
        onBack && <IconButton size={35} background={theme.colors.primary} icon={'Back'} onClick={onBack} />
      }
      <Title > {title} </Title>
      {children}
    </PageTitleContainer>

  )
}

export const PageTitleDashboard = ({ children, title, subtitle, ...props }) => {
  return (
    <PageTitleFixed>
      <PageTitleDashboardContainer  {...props}>
        <TitleDashboard dashboard> {title} </TitleDashboard>
        {subtitle && <Breadcrumbs items={subtitle} separatorColor={'#704dfb'} />}
        {children && <ChildrenContainer>
          {children}
        </ChildrenContainer>}
      </PageTitleDashboardContainer>
    </PageTitleFixed>
  )
}

export const PageTitleUser = ({ children, title, subtitle, color, logo, location, ...props }) => {
  return (
    <PageTitleFixed>
      <PageTitleUserContainer  {...props}>
        {title && <TitleDashboard dashboard> {title} </TitleDashboard>}
        {subtitle &&
          <div style={{ display: "flex", alignItems: "center" }} >
            {logo && <div style={{ marginRight: 20, borderRadius: "50%", background: theme.colors.user.wise.secondary, width: 35, height: 35, display: "flex", justifyContent: "center", alignItems: "center" }}><img style={{ width: subtitle[0].toLowerCase() === "wise" ? 35 : 20 }} src={logo} /></div>}
            <Breadcrumbs location={location} items={subtitle} separatorColor={color || '#704dfb'} />
          </div>}
        {children && <ChildrenContainer>
          {children}
        </ChildrenContainer>}
      </PageTitleUserContainer >
    </PageTitleFixed>
  )
}
