import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isUserLoggedIn } from '@tops/utils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory()

  const isTokenExpired = () => {
    if(!token) {
      return true
    }

    const tokenParts = token?.split(".");
    if(tokenParts.length !== 3) {
      return true
    }

    const ecodedPayload = tokenParts[1]
    try {
      const decodedPayload = Buffer.from(ecodedPayload, "base64").toString("utf-8")
      const payload = JSON.parse(decodedPayload)

      if(!payload.exp){
        return true
      }

      const expirationTimestamp = payload.exp 
      const currentTimestamp  = Math.floor(Date.now() / 1000)

      return expirationTimestamp < currentTimestamp
    } catch (error) {

      return true
      
    }

  }

  const token = isUserLoggedIn()

  if (!isTokenExpired(token)) {
   return <Route {...rest} render={props => <Component {...props}/>} />
  } else {
    return <Route {...rest} render={props => <Redirect to={{pathname: `/login`, state:{from: props.location} }}/>} />
  }
  }

export default PrivateRoute
