import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { ConfirmationButton, CancelButton, PageTitleDashboard, Select, DashboardContainer, DashboardInput, StyledSwitch, AddFieldsButton, Loader, MessageBanner } from '@tops/components'
import { Formik } from 'formik'
import Form from 'antd/lib/form/Form'
import { useDispatch, useSelector } from 'react-redux'
import { submitAccountLevel, fetchAllAccountLevels } from '../../../../actions/accountLevels'
import * as yup from 'yup'

const CreateAccountLevels = ({ history, savedInfo }) => {
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [load, setLoad] = useState(false)
  const [validateError, setValidateError] = useState(false)

  const dispatch = useDispatch()
  const accountLevels = useSelector(state => state.accountLevels)

  useEffect(() => {
    try {
      dispatch(fetchAllAccountLevels(savedInfo.id, false))
    } catch (error) {
      setError(true)
    }
  }, [])

  const handleSwitch = () => setCheck(!check)

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(i18n('ERRORS').BUSINESS.NAME_ERROR),
    functional_key_name: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    description: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    fields: yup.array().of(yup.object().shape({
      name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
      field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
      enum_values: yup.array().when('field_type', {
        is: 6,
        then: yup.array().of(yup.string().trim()
          .min(1, i18n('ERRORS').PARAM_MIN).max(100, i18n('ERRORS').PARAM_MAX)).required(i18n('ERRORS').NEED_CONFIGURATION),
      })
    }))
  })

  const onSubmit = async (values) => {
    await dispatch(submitAccountLevel(values)).then(
      () => {
        setSuccess(true)
        setTimeout(() => setLoad(true), 1000)
        setTimeout(() => {
          history.goBack()
        }, 1000)

      },
      () => setError(true)
    )
  }

  const initial = {
    name: '',
    fields: [],
    functional_key_name: '',
    description: '',
    account_id: savedInfo.id,
    business_id: null,
    has_location: true,
    has_image: true
  }


  if (accountLevels.isFetching) {
    return <Loader />
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LABEL, i18n('SIDEBAR_LINKS').CREATE_LEVEL.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').CREATE_LEVEL} />
        <MessageBanner visible={error} status="error" message={accountLevels.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {load || accountLevels.isSubmitting && <Loader />}
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(validationSchema.cast(values))}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit}>
              <DashboardContainer width="600px">
                <div style={{ marginBottom: "15px" }}>
                  <DashboardInput label={i18n('NAME')} name="name" isRequired={true} error={errors.name} onChange={handleChange}
                    horizontal="true" border="true" bold="true" width="350px" />
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <StyledSwitch id="createAccountLevels_hasParentLevel" checked={check} label={i18n('HAS_PARENT_LEVEL')} horizontal="true"
                    name="active" onChange={handleSwitch} />
                </div>
                {check && <div style={{ marginBottom: "15px" }}><Select items={accountLevels.all} label={i18n('WHICH')}
                  onChange={(e) => setFieldValue("parent_id", e)} name="parent_id" horizontal="true" width="350px" border="true" /></div>}
                <div style={{ fontWeight: "600", marginBottom: 15 }}>{i18n('FIELDS')}</div>
                <div style={{ marginBottom: "15px" }}>
                  <DashboardInput width="330px" isRequired={true} error={errors.functional_key_name} name="functional_key_name"
                    label={i18n('FUNCTIONAL_KEY')} onChange={handleChange} horizontal="true" />
                </div>
                <DashboardInput width="330px" isRequired={true} error={errors.description} name="description"
                  label={i18n('FUNCTIONAL_KEY_IDENTIFIER')} onChange={handleChange} horizontal="true" />
              </DashboardContainer>

              <div style={{ width: 730, padding: '0 15px' }}>
                <AddFieldsButton data={values.fields} dataStructure="fields" />
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default CreateAccountLevels
