import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { theme } from '@tops/global_config'
import {
  List, IconButton, DashboardContainer, Select, ListItem, PageTitleDashboard, Button, MessageBanner, Loader, Pagination
} from '@tops/components'

import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllBusiness, selectBusiness } from '../../../../actions/business'
import { fetchSectors } from '../../../../actions/sectors'

const ViewSectors = ({ history, savedInfo }) => {
  const { url } = useRouteMatch()

  const sectors = useSelector(state => state.sectors)
  const business = useSelector(state => state.business)

  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState(false)
  const [active, setActive] = useState(true)

  const [businessSelected, setBusinessSelected] = useState(business.businessId)


  const dispatch = useDispatch()

  const handleBusinessChange = (value) => {
    setBusinessSelected(value)
    dispatch(selectBusiness(value))
    dispatch(fetchSectors(currentPage, value, active))
  }

  useEffect(() => {
    const fetchData = async () => {

      try {
        dispatch(fetchAllBusiness(savedInfo.id))
        if (typeof businessSelected !== 'undefined') {
          handleBusinessChange(businessSelected)
        }
      } catch (error) {
        setError(true)
      }
    }

    fetchData()

    return () => {
    }
  }, [currentPage, active, businessSelected])

  const handleActive = () => {
    setActive(!active)
  }


  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').SECTORS.LABEL]}>
        <IconButton disabled={typeof businessSelected === 'undefined'} size={35} background={theme.colors.primary} icon={'Add'} text={i18n('SIDEBAR_LINKS').CREATE_SECTOR.LABEL} bold="true" textSize={14} onClick={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').CREATE_SECTOR.LINK}`, { businessId: businessSelected })} />
        <MessageBanner visible={error} status="error" message={sectors.error} onAccept={() => {setError(false); history.push('/cuentas')}} />
      </PageTitleDashboard>
      <DashboardContainer width="550px">
        <Select items={business.values} label={i18n('SELECT_BUSINESS')} onChange={handleBusinessChange} horizontal="true" width="350px" value={businessSelected} border="true" name="businessSelected" />
      </DashboardContainer>
      <span>
        {typeof businessSelected !== 'undefined' && <>
          <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
          <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
        </>}

      </span>
      {
        sectors.isFetching || business.isFetching ?
          <Loader /> :
          sectors.values.length === 0 && typeof businessSelected !== 'undefined' ?
            <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div> :
            <List>
              {sectors.values.map((item, i) => <ListItem
                key={i}
                disabled={!active}
                item={item}
                action={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').EDIT_SECTOR.LINK}/${item.id}`, { isActive: active })}
              />)}
            </List>
      }
      {typeof businessSelected !== 'undefined' && <Pagination currentPage={currentPage} itemsLength={sectors.values.length}
        onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} />}

    </>
  )
}

export default ViewSectors
