import React from 'react'
import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'
import { i18n } from '@tops/services'
import { ConfirmationButton } from '../Button'


const Container = styled.div`
  top:0;
  right:0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0, 0.6);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.mobile`
    padding: 0 20px;
  `}
`

const MessageWrapper = styled.div`
  min-height: 200px;
  width: 400px;
  background: white;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
`
const TextLink = styled.a`
  font-weight: bold;
  text-decoration: underline;
  color: #6247C0;
  font-size: 15px;
  margin-bottom: 15px;
`

export const AlertMessage = ({ message, onOk }) => {
  return (
    <Container>
      <MessageWrapper>
        <Text>{message} </Text>
        <ConfirmationButton onClick={onOk}>{i18n('GO_BACK')}</ConfirmationButton>
      </MessageWrapper>
    </Container>
  )
}

export const AlertMessageWithTitle = ({ title, message, textLink, subText, onClickAction, onOk }) => {
  return (
    <Container>
      <MessageWrapper style={{}}>
        <b style={{fontSize: 18}}>{title}</b>
        <div style={{textAlign: "center", marginBottom: 15}}>{message}</div>
        <TextLink onClick={onClickAction}>{textLink}</TextLink>
        <div style={{width: 215}}>
          <ConfirmationButton onClick={onOk}>{i18n('GO_BACK')}</ConfirmationButton>
        </div>
        <span style={{width: 300, textAlign: "center", marginTop: 14}}>{subText}</span>
      </MessageWrapper>
    </Container>
  )
}