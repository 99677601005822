import styled, { keyframes, css } from 'styled-components'
import { mediaQueries, theme } from '@tops/global_config'

export const Container = styled.div`
  display:flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
`

export const RadioGroup = styled.div`
  display:block;
  align-items: flex-start;
  width: 200px;
  ${({ bold }) => bold ? 'font-weight: 600;' : 'font-weight: 500;'}
`

export const RadioWrapper = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: left;
  width: 200px;
  height: 70px;
  background: ${({ isCurrentValue, disabled, theme }) => isCurrentValue ? disabled ? theme.colors.gray : theme.colors.user.wise.secondary : "white"};
  border: 1px solid ${({ theme, disabled, isCurrentValue }) => isCurrentValue ? disabled ? theme.colors.darkGray : theme.colors.user.wise.secondary : theme.colors.gray};
  color: ${({ theme, disabled, isCurrentValue }) => isCurrentValue ? disabled ? theme.colors.darkGray : theme.colors.user.wise.primary : theme.colors.secondary};
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
  ${({ disabled }) => {
    if (!disabled) {
      return `&:hover {
        border: 1px solid ${({ theme }) => theme.colors.user.wise.secondary};
        color: ${({ theme }) => theme.colors.user.wise.primary};
      }`
    }
  }}
  
  transition: all 0.2s ease-in;
`;

export const SwitchLabel = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 14px;
  font-weight: bold;
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
`;

export const Radio = styled.input`
  display: none;
`
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.darkGray};
  line-height: 40px;
  font-weight: ${({ bold }) => bold ? '600' : '500'};
  ${({ horizontal }) => horizontal && "margin-right: 20px;"}
`
const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const ErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  animation:${({ hasError }) => hasError ? fadeInDown : fadeInUp} 0.3s ease;
`