import { css } from 'styled-components'
import { useState, useLayoutEffect, useEffect } from 'react'

export const breakpoints = {
  mobile: 360,
  tablet: 1024,
  laptop: 1366
}

export const isMobile = () => {
  const width = checkWidth()

  return width >= breakpoints.mobile && width < breakpoints.tablet
}

export const isTablet = () => {
  const width = checkWidth()
  const mobile = isMobile()

  return width >= breakpoints.tablet && width < breakpoints.laptop
}

export const checkWidth = () => {
  const isSSR = typeof window !== "undefined";
  const [width, setWidth] = useState(window.innerWidth);

  function changeWindowSize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize);

    return () => {
      window.removeEventListener("resize", changeWindowSize);
    };
  }, []);

  return width

}

// Iterate through the sizes and create a media template
const mediaQueries = Object.keys(breakpoints).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export default mediaQueries
