import React from 'react'
import { i18n } from '@tops/services'
import { NoDataWrapper, CircleContainer } from './styled';
import NoDataIllustration from './sad_face.svg'


const NoData = ({ message, illustration = NoDataIllustration, illustrationRise,masiveRiseUpload,masiveRiseUploadSuccess, background = "#f1f2f3" }) => {

  return (
    <>
      <NoDataWrapper>
        <CircleContainer style={masiveRiseUpload ? masiveRiseUploadSuccess ? {padding: '0px', margin:  '40px  0px 10px 0px'} : {padding: '0px', margin:  '0px'} : {}} illustrationRise={illustrationRise} background={background}>
          <img style={ masiveRiseUpload ? masiveRiseUploadSuccess ? {width:'115%'} : {width:'40%'} : {}} src={illustration} />
        </CircleContainer>
        <div style={masiveRiseUpload ?  masiveRiseUploadSuccess ? { marginBottom: '10px', fontWeight: 'bold', color: 'black', fontSize: '18px'} : {position:'relative', top: '-25px',marginBottom: '10px', fontWeight: 'bold', color: 'black', fontSize: '18px'}  : {}}>{message || i18n('NO_DATA_AVAILABLE')}</div>
      </NoDataWrapper>
    </>
  )

}
export default NoData
