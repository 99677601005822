import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.label`
  margin: 5px 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.secondary};
  line-height: 1.5715;
  list-style: none;
  display: flex;
  align-items: baseline;
  line-height: unset;
  font-weight: 500;
`
const Checkbox = styled.span`
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5715;
  list-style: none;
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  padding-right: ${({ noSpace }) => noSpace ? "0px" : "25px"};
  padding-left:  ${({ textLeft }) => textLeft ? "10px;" : 0}
`
const CheckboxInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
  opacity: 0;
`
const Icon = styled.svg`
  fill: none;
  stroke: ${({ color, theme, disabled }) => disabled ? theme.colors.darkGray : color ? color.primary : 'white'};
  stroke-width: 3px;
`

const CheckboxInner = styled.span`
position: relative;
top: 0;
left: 0;
display: block;
width: 17px;
height: 17px;
background-color: ${({ theme, checked, color, disabled }) => disabled ? theme.colors.gray : (checked ? color ? color.secondary : theme.colors.secondary : color ? theme.colors.white : theme.colors.accent)};
border: 1px solid ${({ theme, disabled, color, checked }) => disabled ? theme.colors.gray : checked ? color ? color.secondary : theme.colors.secondary : "#c4c4c4"};
border-radius: 2px;
border-collapse: separate;
-webkit-transition: all 0.3s;
transition: all 0.3s;
box-shadow: 0px 3px 4px -1px rgba(0,0,0,0.15);

&::after{
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: '';
}
${Icon} {
  visibility: ${props => (props.checked ? 'visible' : 'hidden')}
}
`



export const StyledCheckbox = ({ textLeft, checked, children, onChange, disabled, color, noSpace, ...props }) => (
  <Wrapper {...props}>
    {textLeft &&
      children
    }
    <Checkbox textLeft={textLeft} noSpace={noSpace} {...props}>
      <CheckboxInput {...props} checked={checked} onChange={onChange} onClick={(e) => e.stopPropagation()} disabled={disabled}></CheckboxInput>
      <CheckboxInner checked={checked} color={color} disabled={disabled}>
        <Icon color={color} disabled={disabled} viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </CheckboxInner>
    </Checkbox>
    {!textLeft &&
      children
    }
  </Wrapper>
)