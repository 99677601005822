import React from 'react'
import 'moment/locale/es';
import { Icon, IconNames } from '@tops/components';
import { theme } from '@tops/global_config'
import { Container, StyledRangePicker } from './styled';

import { ConfigProvider } from "antd";
import moment from 'moment';
import locale from "antd/es/locale/es_ES";

moment.locale('es', {
  week: {
    dow: 1,
  },
});

const dateFormat = 'dd/MM/aaaa';

export const RangePicker = (props) => {
  return <Container>
    <ConfigProvider locale={locale} format={dateFormat}>
      <StyledRangePicker
        suffixIcon={<Icon name={IconNames['Schedule']} color={props.color || theme.colors.darkGray} />}
        allowClear={false}
        {...props}
      />
    </ConfigProvider>
  </Container>
}
