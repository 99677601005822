import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { i18n } from '@tops/services'
import { Page, PageBlock } from '@tops/components'
import ViewAccounts from '../Accounts/ViewAccounts'
import CreateAccount from '../Accounts/CreateAccount'
import EditAccount from '../Accounts/EditAccount'
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/auth'

const Accounts = (props) => {

  const { path } = useRouteMatch()
  const dispatch = useDispatch()

  return (
    <Page withHeader narrow logout={() => dispatch(logout())}>
      <PageBlock narrow>
        <Switch>
          <Route
            exact
            path={`${path}`}
            component={ViewAccounts}
          />
          <Route
            exact
            component={CreateAccount}
            path={`${path}/${i18n('CREATE_ACCOUNT').LINK}`}
          />
          <Route
            exact
            component={EditAccount}
            path={`${path}/${i18n('EDIT_ACCOUNT').LINK}/:id`}
          />
        </Switch>
      </PageBlock>
    </Page>
  )

}

export default Accounts