import React, { useEffect, useState } from 'react'
import { i18n } from '@tops/services'
import { Title, Subtitle, Wrapper } from './styled'
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import UserDetail from './UserDetail/index';
import { theme } from '@tops/global_config';
import { Button, Container, IconButton, Input, ListDetailsTitleColumns, ListItemWithoutSpace, Loader, MessageBanner, Pagination, Popup, Select } from '@tops/components';
import { Tag } from 'antd';
import { saveSearch } from '../../../actions/searchCache';
import { fetchAllBusiness } from '../../../actions/business';
import { fetchUsers, fetchAllDivisions, fetchAllTeams, reset, fetchBlacklisted, unblacklist, submitUnblacklist } from '../../../actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSectorsByAccount } from '../../../actions/sectors';

const Main = ({ savedInfo, history }) => {
  const { url, path } = useRouteMatch()
  const searchCache = useSelector(state => state.searchCache)
  const [usersList, setUsersList] = useState(true)
  const [blacklist, setBlacklist] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [success, setSuccess] = useState(false)
  const [currentPageUsers, setCurrentPageUsers] = useState(searchCache.search.users !== undefined ? searchCache.search.users.currentPage : 1)
  const [currentPageBlacklist, setCurrentPageBlacklist] = useState(1)
  const [load, setLoad] = useState(true)
  const [error, setError] = useState(false)
  const [filters, setFilters] = useState(searchCache.search.users !== undefined ? searchCache.search.users.filters : {})
  const [filtersBlackList, setFilterBlacklist] = useState({})
  const [hasSearched, setHasSearched] = useState(false)
  const [showFilters, setShowFilters] = useState(searchCache.search.users !== undefined)
  const [showFiltersBlacklist, setShowFiltersBlacklist] = useState(false)
  const [businessId, setBusinessId] = useState(searchCache.search.users !== undefined ? searchCache.search.users.businessId : null)

  const dispatch = useDispatch()

  const business = useSelector(state => state.business)
  const sectors = useSelector(state => state.sectors)
  const users = useSelector(state => state.users)

  const roles = [{ id: 0, name: "Admin de negocio" }, { id: 1, name: "Admin WISE" }, { id: 2, name: "Admin de sector" }]

  useEffect(() => {

    const fetchData = async () => {
      try {
        Promise.all([
          dispatch(fetchAllBusiness(savedInfo.id)),
          dispatch(fetchAllTeams(savedInfo.id))
        ])
        setLoad(false)
      } catch (error) {
        setError(true)
      }
    }

    fetchData()

  }, [])

  useEffect(() => {

    const fetchData = async () => {
      try {
        businessId !== null && dispatch(fetchUsers(currentPageUsers, filters, businessId))
      } catch (error) {
        setError(true)
      }
      setLoad(false)
    }

    fetchData()

  }, [currentPageUsers, hasSearched])

  useEffect(() => {

    const fetchData = async () => {
      try {
        dispatch(fetchBlacklisted(currentPageBlacklist, filtersBlackList))
      } catch (error) {
        setError(true)
      }
      setLoad(false)
    }

    fetchData()

  }, [currentPageBlacklist, hasSearched])

  useEffect(() => {
    dispatch(saveSearch("users", filters, currentPageUsers, businessId))
  }, [currentPageUsers, businessId])

  const submitSearch = async () => {
    setLoad(true)

    currentPageUsers > 1 && setCurrentPageUsers(1)
    setHasSearched(!hasSearched)
    setShowFilters(true)
    dispatch(saveSearch("users", filters, currentPageUsers, businessId))
  }

  const submitSearchBlacklist = async () => {
    setLoad(true)

    currentPageBlacklist > 1 && setCurrentPageBlacklist(1)
    setHasSearched(!hasSearched)
    setShowFiltersBlacklist(true)
  }

  const formattedTags = (key, filt) => {
    switch (key) {
      case "business-id":
        return business?.values?.find((business) => business.id === businessId).name
      case "sector-id":
        return sectors?.allSectorsByAccount?.find((sector) => sector.id === filters[key]).name
      case "division-id":
        return users?.divisions?.find((division) => division.id === filters[key]).name
      case "role-id":
        return roles.find((role) => role.id === filters[key]).name
      case "team-id":
        return users?.teams?.find((team) => team.id === filters[key]).name
      default:
        return filt[key]
    }
  }

  const renderFilterTags = () => {

    return <Container flexDirection="row" width="100%">
      <div style={{ fontSize: 13, fontWeight: 'normal', marginRight: 10 }}>Filtros activos:</div>

      {businessId !== null && <Tag key={"businessId"} style={{ borderRadius: 4, color: 'inherit' }}>{formattedTags("business-id")}</Tag>}

      {Object.keys(filters).map((key, i) => <Tag closable key={key} onClose={() => {
        let newData = filters

        delete newData[key]

        setFilters(newData)
        setHasSearched(!hasSearched)

        if (i === Object.keys(newData).length && businessId === null) {
          setCurrentPageUsers(1)
          setShowFilters(false)
        }

      }} style={{ borderRadius: 4, color: 'inherit' }}>{formattedTags(key, filters)}</Tag>)}
      <Tag style={{ borderRadius: 4, color: 'inherit', cursor: 'pointer' }} onClick={() => {
        setFilters({})
        // setLevel(null)
        // setValues({})
        setCurrentPageUsers(1)
        setHasSearched(false)
        setShowFilters(false)
        setBusinessId(null)
        dispatch(reset())

      }}>Limpiar todos los filtros</Tag>
    </Container>
  }

  const renderFilterTagsBlacklist = () => {

    return <Container flexDirection="row" width="100%">
      <div style={{ fontSize: 13, fontWeight: 'normal', marginRight: 10 }}>Filtros activos:</div>

      {Object.keys(filtersBlackList).map((key, i) => <Tag closable key={key} onClose={() => {
        let newData = filtersBlackList

        delete newData[key]

        setFilterBlacklist(newData)
        setHasSearched(!hasSearched)

        if (i === Object.keys(newData).length) {
          setCurrentPageUsers(1)
          setShowFiltersBlacklist(false)
        }

      }} style={{ borderRadius: 4, color: 'inherit' }}>{formattedTags(key, filtersBlackList)}</Tag>)}
      <Tag style={{ borderRadius: 4, color: 'inherit', cursor: 'pointer' }} onClick={() => {
        setFilterBlacklist({})
        setCurrentPageBlacklist(1)
        setHasSearched(false)
        setShowFiltersBlacklist(false)
        dispatch(reset())

      }}>Limpiar todos los filtros</Tag>
    </Container>
  }

  const blacklistPopup = () => {
    return <Popup onClose={() => setShowPopup(false)} title={"Habilitar envio de mail"} style={{ width: 450 }}>
      <div style={{ margin: "10px auto", fontSize: 16, width: 250, textAlign: "center" }}>
        ¿Desea <span style={{ fontWeight: "bold" }}>habilitar el envío de mails</span> para el mail seleccionado?
        Esta acción es irreversible.

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
          <Button small="true" type="button" color={"#704dfb"} background="white" style={{ border: "1px solid #704dfb" }} onClick={() => {
            setShowPopup(false)
          }}>{i18n("CANCEL")}</Button>
          <Button small="true" type="button" background={"#704dfb"}
            color={"white"} onClick={() => {
              dispatch(submitUnblacklist(showPopup)).then(() => {
                setSuccess(true)
                dispatch(fetchBlacklisted(currentPageBlacklist, filters))
              })
              setShowPopup(false)
            }}>Habilitar</Button>
        </div>
      </div>
    </Popup>
  }

  if (load || users.isFetching) {
    return <Loader />
  }

  return (
    <Wrapper>
      <Title>
        {`¡${i18n('WELCOME_TO').toUpperCase()} ${savedInfo.name.toUpperCase()}!`}
      </Title>
      <Subtitle>
        {i18n('SELECT_AN_OPTION')}
      </Subtitle>
      {showPopup && blacklistPopup()}

      <div style={{ width: '100%', display: 'flex', marginBottom: 15 }}>
        <Button type="button" onClick={() => {
          setUsersList(true)
          setBlacklist(false)
        }} style={{ minWidth: '100px', padding: '10px 5px', boxShadow: 'none', borderRadius: 0, borderBottom: usersList ? `1px solid #704dfb` : 'none', fontSize: '17px', transition: 'all .2s', color: "#704dfb" }} background={'transparent'}>
          Usuarios
        </Button>
        <Button type="button" onClick={() => {
          setUsersList(false)
          setBlacklist(true)
        }} style={{ minWidth: '100px', padding: '10px 5px', boxShadow: 'none', borderRadius: 0, borderBottom: blacklist ? `1px solid #704dfb` : 'none', fontSize: '17px', transition: 'all .2s', color: "#704dfb" }} background={'transparent'}>
          Mails deshabilitados
        </Button>
      </div>

      <Switch>
        <Route
          path={`${path}/user-detail/:id`}
          render={(props) => <UserDetail {...props} savedInfo={savedInfo} />}
        />
      </Switch>

      {usersList && <>
        <div style={{ display: "flex" }}>
          <Select items={business.values} placeholder={i18n('SELECT_BUSINESS')} onChange={(val) => {
            setBusinessId(val)
            dispatch(fetchSectorsByAccount(savedInfo.id, val))
            setFilters({})
          }} width="250px"
            value={businessId} border="true" style={{ marginRight: 5 }} />
          <Input placeholder={i18n('USER_NAME')} onChange={(e) => setFilters({ ...filters, "name": e.target.value })} width="245px" value={filters["name"]}
            border="true" />
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Select items={sectors.allSectorsByAccount} placeholder={i18n('SELECT_SECTOR')} onChange={(val) => {
            setFilters({ ...filters, "sector-id": val })
            dispatch(fetchAllDivisions(savedInfo.id, { "sector-id": val, "business-id": businessId }))
          }} width="250px"
            value={filters["sector-id"]} border="true" style={{ marginRight: 5 }} />
          <Select items={users.divisions} placeholder={i18n('SELECT_DIVISION')} onChange={(val) => setFilters({ ...filters, "division-id": val })} width="250px"
            value={filters["division-id"]} border="true" style={{ marginRight: 5 }} />
          <Select items={roles} placeholder={i18n('SELECT_ROLE')} onChange={(val) => setFilters({ ...filters, "role-id": val })} width="250px"
            value={filters["role-id"]} border="true" style={{ marginRight: 5 }} />
          <Select items={users.teams} placeholder={i18n('SELECT_WISE_TEAM')} onChange={(val) => setFilters({ ...filters, "team-id": val })} width="250px"
            value={filters["team-id"]} border="true" style={{ marginRight: 5 }} />
          <Button type="button" small="true" disabled={businessId === null} style={{ marginBottom: "10px" }} onClick={() => submitSearch()}>{i18n('SEARCH')}</Button>
        </div>

        {(showFilters && Object.keys(filters).length !== 0 || showFilters && businessId !== null) && renderFilterTags()}

        <div style={{ width: "90%" }}>
          {
            users.users.length !== 0 ?
              <ListDetailsTitleColumns details={[]}>
                {
                  users.users.map((item, i) => <ListItemWithoutSpace
                    icon="EyeShow"
                    key={`${item.id}_${i}`}
                    item={item}
                    iconColor={theme.colors.lilac}
                    action={() => history.push(`${url}/user-detail/${item.id}`)}
                  />)
                }
              </ListDetailsTitleColumns>
              :
              ((businessId === null || Object.keys(filters).length === 0) && !hasSearched) ?
                <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('CHOOSE_BUSINESS')}</div> :
                <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div>
          }
          <Pagination currentPage={currentPageUsers} itemsLength={users.users.length}
            onNext={() => setCurrentPageUsers(currentPageUsers + 1)} onPrev={() => setCurrentPageUsers(currentPageUsers - 1)} />
        </div>
      </>}

      {blacklist && <>
        <MessageBanner status={"success"} visible={success} message="Mail habilitado exitosamente" onAccept={() => setSuccess(false)} />
        <div style={{ display: "flex", marginTop: 15 }}>
          <Input placeholder={i18n('USER_NAME')} onChange={(e) => setFilterBlacklist({ ...filtersBlackList, "optional-name": e.target.value })} width="245px" value={filtersBlackList["optional-name"]}
            border="true" />
          <Button type="button" small="true" style={{ marginBottom: "10px" }} onClick={() => submitSearchBlacklist()}>{i18n('SEARCH')}</Button>
        </div>

        {(showFiltersBlacklist && Object.keys(filtersBlackList).length !== 0 || showFiltersBlacklist) && renderFilterTagsBlacklist()}

        <div style={{ width: "90%" }}>
          {
            users.blacklisted.length !== 0 ?
              <ListDetailsTitleColumns details={[]}>
                {
                  users.blacklisted.map((item, i) => <ListItemWithoutSpace
                    key={`${item.id}_${i}`}
                    details={[item.blacklisted_email, <div style={{ display: "flex", width: '100%', justifyContent: "flex-end", alignItems: "center" }}>
                      <IconButton icon="EnableMail" iconColor={"#704dfb"} background="transparent" shadow={false} onClick={() => setShowPopup(item.id)} />
                    </div>]}
                    iconColor={theme.colors.lilac}
                  />)
                }
              </ListDetailsTitleColumns>
              :
              <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div>
          }
          <Pagination currentPage={currentPageBlacklist} itemsLength={users.blacklisted.length}
            onNext={() => setCurrentPageBlacklist(currentPageBlacklist + 1)} onPrev={() => setCurrentPageBlacklist(currentPageBlacklist - 1)} />
        </div>
      </>}

    </Wrapper>
  )

}

export default Main