import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { theme } from '@tops/global_config'
import { SearchContainer } from './styled.js'
import { List, IconButton, Search, Container, Button, StyledLink, PageTitle, ListItem, AlertMessage, Loader, Icon, IconNames, Pagination } from '@tops/components'
import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccounts, searchAccounts } from './../../../actions/accounts'
import { resetStateAction } from '../../../actions/reset.js'

const ViewAccounts = (props) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  const [currentPage, setCurrentPage] = useState(1)
  const [active, setActive] = useState(true)
  const [search, setSearch] = useState('')

  const accounts = useSelector(state => state.accounts)

  useEffect(() => {

    const resetState = async () => {
      dispatch(resetStateAction())
    }

    resetState()

  }, [])

  useEffect(() => {

    const fetchData = async () => {
      dispatch(fetchAccounts(currentPage, active))
    }

    fetchData()

  }, [active, currentPage])


  const clear = () => {
    setSearch('')
    setCurrentPage(1)
    dispatch(fetchAccounts(currentPage, active))
  }

  const submitSearch = async (e) => {
    e.preventDefault()
    setCurrentPage(1)

    if (search !== '') {
      await dispatch(searchAccounts(search, active))
    }

    if (search === '') {
      clear()
    }
  }

  const handleActive = () => {
    setActive(!active)
    setSearch('')
    setCurrentPage(1)
  }

  const renderAccountsList = (list) => {
    if (list.values.length == 0) {
      return <div style={{ fontSize: 15 }}>{i18n('NO_DATA_AVAILABLE')}</div>
    }

    return list.values.map((item) => <ListItem
      key={item.id}
      disabled={!active}
      name={item.name.toUpperCase()}
      item={item}
      onClick={() => !active ? null : props.history.push(`/dashboard/${item.name.split(' ').join('_').toLowerCase()}`, localStorage.setItem('account', JSON.stringify({ name: item.name, id: item.id, wiseActive: item.has_wise_access, wiseName: item.wise_custom_name })))}
      action={() => props.history.push(`${url}/${i18n('EDIT_ACCOUNT').LINK}/${item.id}`, { isActive: active, hasWiseAccess: item.has_wise_access })} />)
  }


  if (accounts.error) {
    return <AlertMessage message={accounts.error} onOk={() => {
      props.history.push('/')
      accounts.error = null
    }} />
  }

  return (
    <>
      <PageTitle title={i18n('ACCOUNT_LIST')} onBack={() => props.history.push('/')}>
        <StyledLink to={`${url}/${i18n('CREATE_ACCOUNT').LINK}`}>
          <IconButton size={35} background={theme.colors.primary} icon={'Add'} text={i18n('CREATE_ACCOUNT').LABEL} bold="true" textSize={14} />
        </StyledLink>
      </PageTitle>
      <Container>
        <SearchContainer>
          <form onSubmit={submitSearch}>
            <Search value={search} onChange={(e) => setSearch(e.target.value)} clear={clear} width="350px" />
          </form>
          <span>
            <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
            <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
          </span>
        </SearchContainer>

        {accounts.isFetching ? <Loader /> : <><List>
          {
            renderAccountsList(accounts)
          }

        </List>
        </>}
        <Pagination currentPage={currentPage} itemsLength={accounts.values.length}
          onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} />
      </Container>
    </>
  )
}

export default ViewAccounts
