import React, { useState } from 'react'
import { Icon, IconNames } from '@tops/components';
import { theme } from '@tops/global_config'
import { Container, StyledCalendar } from './styled';
import es from "./es"
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');
moment.updateLocale('es-ar', {
  week: {
    dow: 1,
  },
});


export const Calendar = (props) => {
  return <Container {...props}>
    <StyledCalendar
      weekPicker
      weekStartDayIndex={1}
      locale={es}
      showOtherDays
      highlightToday={false}
      onChange={(val) => {
        val[0].day += 1
        val[1].day += 1
        return props.onChangeFunc([moment(val[0].format("YYYY/MM/DD")).format("YYYY/MM/DD"), moment(val[1].format("YYYY/MM/DD")).format("YYYY/MM/DD")])
      }}
      {...props}
    />
  </Container>
}
