import { handleActions } from 'redux-actions'
import {
  fetchExpirationRequestTypeStart, fetchExpirationRequestTypeSuccess, fetchExpirationRequestTypeError,
  fetchExpirationRequestTypeByIdStart, fetchExpirationRequestTypeByIdSuccess, fetchExpirationRequestTypeByIdError,
  submitExpirationRequestTypeStart, submitExpirationRequestTypeSuccess, submitExpirationRequestTypeError,
  editExpirationRequestTypeStart, editExpirationRequestTypeSuccess, editExpirationRequestTypeError,
  fetchAllLevelsBySectorStart, fetchAllLevelsBySectorSuccess, fetchAllLevelsBySectorError
} from '../actions/expirationRequestTypes'

export const defaultState = {
  values: [],
  valuesById: [],
  allLvlsBySector: [],
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [fetchExpirationRequestTypeStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchExpirationRequestTypeSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchExpirationRequestTypeError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchExpirationRequestTypeByIdStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchExpirationRequestTypeByIdSuccess]: (state, { payload: { values } }) => ({
      ...state,
      valuesById: values,
      isFetching: false,
      error: null
    }),
    [fetchExpirationRequestTypeByIdError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitExpirationRequestTypeStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitExpirationRequestTypeSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitExpirationRequestTypeError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editExpirationRequestTypeStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editExpirationRequestTypeSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editExpirationRequestTypeError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [fetchAllLevelsBySectorStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllLevelsBySectorSuccess]: (state, { payload: { allLvlsBySector } }) => ({
      ...state,
      allLvlsBySector,
      isFetching: false,
      error: null
    }),
    [fetchAllLevelsBySectorError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    })
  },
  defaultState
)

export default reducer
