import React from 'react'
import { i18n } from '@tops/services'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ViewExpirationRequestTypes from './ViewExpirationRequestTypes'
import CreateExpirationRequestType from './CreateExpirationRequestType'
import EditExpirationRequestType from './EditExpirationRequestType'


const ExpirationRequestTypes = ({ savedInfo }) => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <ViewExpirationRequestTypes {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_EXPIRATION_REQUEST_TYPE.LINK}`}
          render={(props) => <CreateExpirationRequestType {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_EXPIRATION_REQUEST_TYPE.LINK}/:id`}
          render={(props) => <EditExpirationRequestType {...props} savedInfo={savedInfo} />}
        />
      </Switch>
    </>
  )
}

export default ExpirationRequestTypes
