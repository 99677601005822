import { handleActions } from 'redux-actions'
import {
  fetchAccountLevelsStart,
  fetchAccountLevelsSuccess,
  fetchAccountLevelsError,
  submitAccountLevelStart,
  submitAccountLevelSuccess,
  submitAccountLevelError,
  editAccountLevelStart,
  editAccountLevelSuccess,
  editAccountLevelError,
  fetchAllAccountLevelsStart,
  fetchAllAccountLevelsSuccess,
  fetchAllAccountLevelsError,
  fetchAccountLevelsFieldsStart,
  fetchAccountLevelsFieldsSuccess,
  fetchAccountLevelsFieldsError,
} from '../actions/accountLevels'

export const defaultState = {
  values: [],
  all: [],
  fields: [],
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [fetchAccountLevelsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAccountLevelsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchAccountLevelsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitAccountLevelStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitAccountLevelSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitAccountLevelError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editAccountLevelStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editAccountLevelSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editAccountLevelError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [fetchAllAccountLevelsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllAccountLevelsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      all: values,
      isFetching: false,
      error: null
    }),
    [fetchAllAccountLevelsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchAccountLevelsFieldsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAccountLevelsFieldsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      fields: values,
      isFetching: false,
      error: null
    }),
    [fetchAccountLevelsFieldsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
  },
  defaultState
)

export default reducer
