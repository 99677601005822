import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const {
    fetchStandardConfigurationStart,
    fetchStandardConfigurationSuccess,
    fetchStandardConfigurationError,
    submitStandardConfigurationStart,
    submitStandardConfigurationSuccess,
    submitStandardConfigurationError,
    editStandardConfigurationStart,
    editStandardConfigurationSuccess,
    editStandardConfigurationError,
} = createActions({
    FETCH_STANDARD_CONFIGURATION_START: () => { },
    FETCH_STANDARD_CONFIGURATION_SUCCESS: values => ({ values }),
    FETCH_STANDARD_CONFIGURATION_ERROR: error => ({ error }),
    SUBMIT_STANDARD_CONFIGURATION_START: () => { },
    SUBMIT_STANDARD_CONFIGURATION_SUCCESS: data => ({ data }),
    SUBMIT_STANDARD_CONFIGURATION_ERROR: error => ({ error }),
    EDIT_STANDARD_CONFIGURATION_START: () => { },
    EDIT_STANDARD_CONFIGURATION_SUCCESS: data => ({ data }),
    EDIT_STANDARD_CONFIGURATION_ERROR: error => ({ error }),
})

const fetchStandardConfiguration = (businessId) => {
    return async dispatch => {
      dispatch(fetchStandardConfigurationStart())
      const api = getApi(API_ROUTES)
      await api.get(`${API_ROUTES.VCT_ADMIN}/standard-configuration-parameter-listed/${businessId}`)
        .then(
          (response) => dispatch(fetchStandardConfigurationSuccess(response.data)),
          (error) => dispatch(fetchStandardConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
        )
    }
}

const submitStandardConfiguration = (values, wiseConfigurationId) => {
    return async dispatch => {
      dispatch(submitStandardConfigurationStart())
      const api = getApi(API_ROUTES)
      return new Promise((resolve, reject) => {
        api.post(`${API_ROUTES.VCT_ADMIN}/vct-standard-configuration/${wiseConfigurationId}`, values)
          .then((response) => {
            dispatch(submitStandardConfigurationSuccess(response.data))
            resolve()
          }).catch((error) => {
            dispatch(submitStandardConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
            reject()
          })
      }
      )
    }
}

const editStandardConfiguration = (value, wiseConfigurationId) => {
  return async dispatch => {
    dispatch(editStandardConfigurationStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(`${API_ROUTES.VCT_ADMIN}/vct-standard-configuration-post-put-delete/${wiseConfigurationId}`, value)
        .then(() => {
          dispatch(editStandardConfigurationSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editStandardConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}



export {
    fetchStandardConfigurationStart,
    fetchStandardConfigurationSuccess,
    fetchStandardConfigurationError,
    fetchStandardConfiguration,
    submitStandardConfigurationStart,
    submitStandardConfigurationSuccess,
    submitStandardConfigurationError,
    submitStandardConfiguration,
    editStandardConfigurationStart,
    editStandardConfigurationSuccess,
    editStandardConfigurationError,
    editStandardConfiguration,
}
