import _ from "lodash";

export const isInstanceOfDate = d => d instanceof Date;
export const isObjectEmpty = o => Object.keys(o).length === 0;
export const isObject = o => o != null && typeof o === 'object';
export const properObject = o => isObject(o) && !o.hasOwnProperty ? { ...o } : o;

export const updatedDiff = (base, object) => {

  function changes(object, base) {
    let arrayIndexCounter = 0;
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        let resultKey = _.isArray(base) ? arrayIndexCounter++ : key;

        result[resultKey] = (_.isObject(value) && _.isObject(base[key])) ? value : value;
      }
    });
  }
  return changes(object, base);
};

export const arrayDiff = (base, array) => {
  return _.isEqual(base, array)
};