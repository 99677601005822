import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import { ConfirmationButton, CancelButton, PageTitleDashboard, StyledCheckbox, DashboardContainer, DashboardInput, StyledSwitch, Loader,
  MessageBanner, EditFieldsButton, UploadButton, Icon
} from '@tops/components'
import { useDispatch, useSelector } from 'react-redux'
import { editBusiness } from '../../../../actions/business'
import { Form } from 'antd'
import { Formik, FieldArray, getIn } from 'formik'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { fetchAllAccountLevels } from '../../../../actions/accountLevels'
import { theme } from '@tops/global_config'
import { getUpstreamTicketFile, getUpstreamTicketId } from '../../../../actions/files'
import { toBase64 } from '@tops/utils'

const EditBusiness = ({ history, location, savedInfo }) => {
  const [savedInfoBusiness, setInfoBusiness] = useState([])
  const [accountEntityConfigurations, setAccountEntityConfigurations] = useState([])
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [load, setLoad] = useState(true)
  const [validateError, setValidateError] = useState(false)
  const [upstreamTicket, setUpstreamTicket] = useState(null)

  const [mouseEnter, setMouseEnter] = useState(false)

  const { id } = useParams()

  const dispatch = useDispatch()
  const business = useSelector(state => state.business)
  const accountLevels = useSelector(state => state.accountLevels)

  useEffect(() => {
    if (business.values.length > 0) {
      business.values.forEach((item) => {
        if (item.id === parseFloat(id)) {
          setInfoBusiness(item);
          item.account_entity_configurations.forEach((entity) => {
            accountEntityConfigurations.push(entity.id);
          })
          setLoad(false);
        }
      })
    } else {
      history.push(`/dashboard/${savedInfo.name.toLowerCase()}/negocio`)
    }

    try {
      dispatch(fetchAllAccountLevels(savedInfo.id, false))
    } catch (error) {
      setError(true)
    }

    return () => {

    }
  }, [])

  const onSubmit = async (values) => {

    const filtered = Object.fromEntries(Object.entries(values).filter(([key, value]) => {
      if (key === 'id') {
        return values[key]
      }
      return values[key] !== initial[key]
    }))
    /**Agregar image_extension a lo filtrado */
    Object.assign(filtered, {image_extension: values.image_extension})
    if(values.image_extension == "image/svg+xml"){
      Object.assign(filtered, {image_extension: "svg"})
    }
    await dispatch(editBusiness(filtered)).then(
      () => {
        setSuccess(true)
        setTimeout(() => setLoad(true), 1000)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(i18n('ERRORS').NAME_ERROR),
    person_configuration: yup.object().shape({
      functional_key_name: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
      add_custom_fields: yup.array().of(yup.object().shape({
        name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
        field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
        enum_values: yup.array().when('field_type', {
          is: 6,
          then: yup.array().of(yup.string().trim()
               .min(1,i18n('ERRORS').PARAM_MIN).max(100, i18n('ERRORS').PARAM_MAX)).required(i18n('ERRORS').NEED_CONFIGURATION),
        })
      }))
    }),
  })

  const nameInput = (handleChange, errors, values) => (
    <DashboardInput isRequired={true} label={i18n('NAME')} value={values.name} error={errors.name} horizontal="true" border="true"
      name="name" onChange={handleChange} width="350px" />
  )

  const logoUpload = ( setFieldValue, values) => (<>
    <div style={{ display: "flex", marginTop: 10 }}>
      <div style={{display: "flex", flexDirection: "column", width: 160}}>
        <label style={{ color: theme.colors.darkGray, width: "180px", fontWeight: "bold" }}>Carga de logotipo</label>
        <span style={{width: 100}}>Si no se carga un archivo se verá el nombre del negocio.</span>
      </div>
      {(values.image_base64 !== "" || (values.image_base64 === "" && values.logo_url !== ""))  && <div style={{ marginRight: 10 }}>
        <div style={{ width: 105, height: 105, borderRadius: "7px", display: "flex", justifyContent: "center", alignItems: "center",
        border: mouseEnter ? `1px solid ${theme.colors.primary}` : `1px solid #d9e2ee`, transition: "0.2s linear 0.1s", position: "relative" }}
        onMouseEnter={() => setMouseEnter(true)} onMouseLeave={() => setMouseEnter(false)}>
            <img src={values.logo_url || `data:${values.image_extension};base64,${values.image_base64}`} style={{ maxHeight: "100%", maxWidth: "100%", padding: 12 }} />
            <div style={{visibility: mouseEnter ? 'visible' : 'hidden', height: 105, width: 105, position: "absolute", background: "#00000080",
            display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 7, cursor: "pointer", transition: "0.2s linear 0.1s"}}
            onClick={() => {
              setFieldValue("image_extension", "")
              setFieldValue("image_base64", "")
              setFieldValue("logo_url", "")
              setMouseEnter(false)
              setFieldValue("should_delete_logo", true)
            }}>
              <Icon name={'TrashBin'} color={theme.colors.white} />
            </div>
        </div>
      </div>}
      <div style={{ display: "flex" }}>
        <UploadButton hidePreview={true} withPreview={true} isImage={true} method="get" color={theme.colors.black} accept={[".svg", ".png"]} beforeUpload={async (file, _) => {
          if (file.type !== 'image/svg+xml' && file.type !== 'image/png') {
            alert(`${file.name} ${i18n('USER').REQUESTS.CREATE_EDIT.ERRORS.FILE_NOT_VALID}`);
          } else {
            let ticketId = upstreamTicket
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
              if (ticketId === null) {
                await dispatch(getUpstreamTicketId()).then((result) => {
                  setFieldValue('upstream_ticket_id', result.id)
                  setUpstreamTicket(result.id)
                  ticketId = result.id
                })
              }
              let fileName = file.name.split('.')
              await dispatch(getUpstreamTicketFile({
                upstream_ticket_id: ticketId,
                image_base64: await toBase64(file).then((res) => res.split(',')[1]),
                image_extension: fileName[fileName.length - 1]
              })).then((res) => {
                file.url = reader.result
                setFieldValue("image_base64", file.url.split(',')[1])
                setFieldValue("image_extension", file.type)
                setFieldValue("should_delete_logo", false)
              })
              setLoad(false)
            }
          }
          return false
        }}
        onChange={(_) => {
          setFieldValue("image_base64", "")
          setFieldValue("image_extension", "")
          setFieldValue("logo_url", "")
        }}
        />
      </div>
    </div>
    </>)

  const accountLevelsCheckboxes = (values) => (
  <>
    <div style={{ fontWeight: 'bold' }}>{i18n('SIDEBAR_LINKS').ACCOUNT_LEVELS.LABEL}</div>
    <FieldArray name="entity_configuration_ids">
      {arrayHelpers => (
        <div style={{ display: "flex", flexDirection: "column", maxHeight: "200px", flexWrap: "wrap" }}>
          {
            accountLevels.all.map((level, i) =>
              <StyledCheckbox key={i} value={level.id} checked={values.entity_configuration_ids.includes(level.id)}
                name={"entity_configuration_ids"} disabled={true}>
                {level.name}
              </StyledCheckbox>)
          }
        </div>
      )}
    </FieldArray>
  </>
  )

  const userAdmin = () => (
    <>
      <div style={{ marginBottom: "15px", marginTop: "15px" }}>
        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('ADMIN_USER'))}</span>
      </div>
      <div style={{ margin: "10px 0" }}>
        <DashboardInput label={i18n('FULL_NAME')} name="admins.name" value={savedInfoBusiness.admins.reverse()[0].name}  horizontal="true" border="true" width="350px" disabled={true}/>
      </div>
      <div style={{ margin: "10px 0" }}>
        <DashboardInput label={i18n('EMAIL')} name="admins.email" value={savedInfoBusiness.admins.reverse()[0].email} horizontal="true" border="true" width="350px" disabled={true}/>
      </div>
    </>
  )

  const fkInput = (handleChange, errors, values) => (<>
    <div style={{ fontWeight: "600", marginBottom: "15px" }}>{i18n('PAYROLL')}</div>
    <DashboardInput isRequired={true} error={getIn(errors, 'person_configuration.functional_key_name')}
      name="person_configuration.functional_key_name" label={'Functional key'} horizontal="true" border="true" width="350px"
      onChange={handleChange} value={values.person_configuration.functional_key_name} />
  </>
  )

  if (accountLevels.isFetching || load) {
    return <Loader />
  }

  const initial = {
      id: id,
      name: savedInfoBusiness.name,
      is_active: savedInfoBusiness.is_active,
      entity_configuration_ids: accountEntityConfigurations,
      person_configuration: {
        functional_key_name: savedInfoBusiness.person_configuration.functional_key_name,
        is_active: true,
        remove_custom_field_ids:[],
        update_custom_fields: [],
        add_custom_fields: []
      },
      logo_url: savedInfoBusiness.logo_url,
      image_base64: savedInfo.logo_url || "",
      should_delete_logo: false,
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').BUSINESS.LABEL, i18n('SIDEBAR_LINKS').EDIT_BUSINESS.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').EDIT_BUSINESS} />
        <MessageBanner visible={error} status="error" message={business.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {(business.isSubmitting || load) && <Loader />}
      <Formik
        enableReinitialize={false}
        validateOnChange={false}
        initialValues={initial}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}>

        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="550px">
                <div style={{ marginBottom: "30px" }}>
                  <StyledSwitch id="editBusiness_isActive" onChange={() => setFieldValue('is_active', !values.is_active)} checked={values.is_active} name="is_active" label={i18n('ACTIVE')} horizontal="true" />
                </div>
                {nameInput(handleChange, errors, values)}
                {logoUpload(setFieldValue, values)}
                {accountLevelsCheckboxes(values)}
                {userAdmin()}
                {fkInput(handleChange, errors, values)}
              </DashboardContainer>
              <div style={{ width:"700px", margin: "0 20px" }}>
                <EditFieldsButton data={savedInfoBusiness.person_configuration.fields} addStructure={'add_custom_fields'} updateStructure={'update_custom_fields'} removeStructure={'remove_custom_field_ids'}  isBusiness={true}/>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
            </>
        )}
      </Formik>
    </>
  )
}

export default EditBusiness
