import React, { useState } from 'react'
import { StyledSelect, Label, Container, SelectContainer, ErrorMessage } from './styled'
import { Select as AntdSelect } from 'antd'
import { theme } from '@tops/global_config'
import { i18n } from '@tops/services'
import { Icon, IconNames } from '@tops/components';

export const Select = props => {
  const [selected, setSelected] = useState(false)

 

  return (
    <Container {...props}>
      {props.label &&
        <Label horizontal={props.horizontal} bold={props.bold} fontSize={props.fontSize} marginRight={props.marginRight} fHeight={props.fHeight}>
          {props.label}
          {props.isRequired && <span style={{ color: 'red', marginLeft: 5, marginRight: 5 }}>*</span>}
        </Label>}
      <SelectContainer width={props.width} style={{display: props.trainings ? 'flex' : undefined, justifyContent: props.trainings ? 'flex-end' : undefined}}>
        <div style={{ display: 'flex', alignItems: 'center', position: "relative" }}>
          {props.icon && <Icon size={20} name={props.icon} color="#b4b7bd" style={{ position: "absolute", top: 11, left: 10, zIndex: 1 }} />}
          <StyledSelect open={props.visible} notFoundContent={props.notFoundContent} value={props.value} placeholder={props.placeholder || i18n("CHOOSE")} selected={selected} onDropdownVisibleChange={() => setSelected(!selected)} {...props}
            trigger="click" bordered={false} getPopupContainer={trigger => trigger.parentNode} marginBottom={props.marginBottom} showSearch showArrow
            filterOption={(input, option) => option.children.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(input.normalize("NFD").replace(/[\u0300-\u036f]/g, '').toLowerCase()) > -1} optionFilterProp="children" scrolleable={props.scrolleable}
            onChange={(val) => {
              if (props.mode === "multiple") {
                if (Array.isArray(val)) {
                  return val.includes("showAll") ? props.handleShowAll(props.items) : props.onChange(val)
                }
              } else if (!isNaN(val)) {
                return props.onChange(val)
              } else {
                return props.onChange(val)
              }
            }}
            dropdownStyle={{ zIndex: 10, background: theme.colors.white, borderRadius: '5px', border: `1px solid ${props.color || theme.colors.lilac}`, boxShadow: '0px 3px 4px 1px rgba(0,0,0,0.25)', position: "fixed" }}>
            {props.showAllOption && <AntdSelect.Option key={"showAll"} style={{ 'background': props.color, 'color': theme.colors.white, fontFamily: theme.fonts.lato, fontSize: 13 }}>
              Todos
            </AntdSelect.Option>}
            {
              props.items === undefined ? [] : props.items.map((item, i) =>
                <AntdSelect.Option key={item.constructor === Object ? item.id : i} value={item.constructor === Object ? (props.isuser ? item.person_id : item.id) : item} disabled={item.constructor === Object ? props.disabledValue === item.id : props.disabledValue === item}
                  style={{ 'background': theme.colors.white, 'color': theme.colors.darkGray, fontFamily: theme.fonts.lato, fontSize: 13 }}>
                  {item.constructor === Object ?
                    (props.isfunctionalkey ? (item.functional_key !== undefined || item.functional_key_name !== undefined ? `${item.name}  (${item.functional_key !== undefined ? item.functional_key : item.functional_key_name})` :
                      (item.person !== null ? `${item.name}  (${item.person.functional_key})` : item.name)) : item.name) : item}
                </AntdSelect.Option>)
            }
          </StyledSelect>
          {props.filter && props.possibilityFiltering &&
            <div onClick={props.filter} style={{ marginLeft: '15px', cursor: 'pointer', visibility: props.auditorIds ? 'hidden' : 'visible' }}>
              <Icon name={IconNames['Filter']} color={props.color} />
            </div>}
        </div>
        {props.error && <ErrorMessage hasError={props.error}>{props.error}</ErrorMessage>}
      </SelectContainer>
    </Container>
  )
}