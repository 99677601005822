import React, { Fragment, useState } from 'react'
import { i18n } from '@tops/services';
import { Select } from '../Select'
import { DashboardInput, Input } from '../Input'
import { SmallIconButton } from '../IconButton'
import { Divider, Tooltip } from 'antd'
import { useFormikContext, FieldArray, getIn } from 'formik'
import Icon, { IconNames } from './../Icons/index';
import { DataWrapper, EraseButtonContainer, InputsContainer, DataContainer, Label } from './styled';
import { useEffect } from 'react';
import { StyledSwitch } from '../Switch';
import { theme } from '@tops/global_config';
import { Popup } from '../Popup/index';
import { ButtonWithIcon } from '../Button';
import { ConfirmationButton, CancelButton, StyledCheckbox } from '@tops/components';

const selectItems = [
  { id: 0, name: "Alfanumerico" },
  { id: 1, name: "Numerico" },
  { id: 2, name: "Si/No" },
  { id: 4, name: "Fecha" },
  { id: 5, name: "Hora" },
  { id: 6, name: "Parametrizable" },
  { id: 8, name: "Texto largo" },
]

const alarmMoreOneDropDown = [
  { id: 0, name: i18n('ALARM_HOUR') },
  { id: 1, name: i18n('ALARM_DAYS') },
  { id: 2, name: i18n('ALARM_WEEK') },
  { id: 3, name: i18n('ALARM_MONTH') }
]

const alarmOneDropDown = [
  { id: 0, name: i18n('ALARM_HOUR_ONE') },
  { id: 1, name: i18n('ALARM_DAYS_ONE') },
  { id: 2, name: i18n('ALARM_WEEK_ONE') },
  { id: 3, name: i18n('ALARM_MONTH_ONE') }
]

const nextPhase = [
  { id: null, name: 'Siguiente Etapa' }
]


const paramsPopUpExpirationsLevels = (setShowPopupExpirationsLevels, setFieldValue, structure, params, setNewFieldDto, valuesParams, isEdit) => {
  if (valuesParams?.length !== 0 && isEdit) {
    var found = valuesParams?.find(item => typeof item === "object")
    valuesParams = found === undefined || found.length === 0 ? valuesParams : valuesParams.map(item => item.name)
  }
  const checkEmpty = valuesParams?.length !== 0 && valuesParams?.[0] !== undefined ? valuesParams.findIndex((el) => el.trim().length > 0) : -2;

  return <Popup title="Configuración de campos parametrizables" style={{ maxHeight: 600 }} onClose={() => {
    setShowPopupExpirationsLevels(false)
  }}>
    <div style={{ overflowY: 'auto', maxHeight: 430 }}>
      <p style={{ fontSize: 15, fontWeight: "bold" }}>Opciones <span style={{ color: 'red' }} >*</span></p>
      <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', marginBottom: 8 }}>
        {valuesParams && valuesParams.map((valuesParam, i) => {
          return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: 10 }}>
            <DashboardInput width="500px" value={valuesParam} onChange={(e) => {
              let arr = [...valuesParams]
              let index = arr.indexOf(valuesParam)
              if (index === -1) {
                arr[i] = e.target.value
              }
              else {
                arr[i] = e.target.value
              }
              setNewFieldDto(arr)
            }} />
            <div style={{ display: 'flex' }}>
              {!isEdit &&
                <ButtonWithIcon small="true" type="button" icon="TrashBin" background={theme.colors.white} color={theme.colors.oldGray} iconSize={35}
                  style={{ marginLeft: '5px', marginBottom: '10px', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.00)' }} onClick={() => {
                    let arr = [...valuesParams]
                    let index = arr.indexOf(valuesParam)
                    arr.splice(index, 1)
                    setNewFieldDto(arr)
                  }} />
              }
            </div>
          </div>
        })}

      </div>
      <SmallIconButton type="button" icon={'Add'} bold="true" size={21} text={i18n('ADD_FIELDS_TO_SECTION')}
        onClick={() => { setNewFieldDto([...valuesParams, '']) }} />
    </div>

    <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', marginBottom: 15, marginTop: 15 }}>
      <ConfirmationButton small="true" type="button" disabled={checkEmpty === -1} onClick={() => {
        setShowPopupExpirationsLevels(false)
        setFieldValue(structure, [...valuesParams])
      }}>{i18n('ACCEPT')}</ConfirmationButton>
      {structure !== null && <CancelButton small="true" type="button" onClick={() => {
        setShowPopupExpirationsLevels(false)
      }}>{i18n('CANCEL')}</CancelButton>}

    </div>
  </Popup>
}

const paramsPopUp = (setShowPopup, setFieldValue, structure, params, setNewFieldDto, valuesParams, isEdit, itemPopUp, structureParameters, setParametersPhase, parametersPhase) => {
  if (valuesParams?.length !== 0 && isEdit) {
    var found = valuesParams?.find(item => typeof item === "object")
    valuesParams = found === undefined || found.length === 0 ? valuesParams : valuesParams.map(item => item.name)
  }


  const checkEmpty = valuesParams?.length !== 0 && valuesParams?.[0] !== undefined ? valuesParams.findIndex((el) => el.trim().length > 0) : -2;

  return <Popup title="Configuración de campos parametrizables" style={{ maxHeight: 600 }} onClose={() => {
    setShowPopup(false)
  }}>
    <div style={{ overflowY: 'auto', maxHeight: 430 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ fontSize: 15, fontWeight: "bold", marginLeft: '14px' }}>Opciones <span style={{ color: 'red' }} >*</span></p>
        <p style={{ fontSize: 15, fontWeight: "bold", marginRight: '135px' }}>Saltar etapa por respuesta</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start', marginBottom: 8 }}>
        {valuesParams && valuesParams.map((valuesParam, i) => {
          return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
            <DashboardInput width="250px" value={valuesParam} disabled={structure === null} onChange={(e) => {
              let arr = [...valuesParams]
              let index = arr.indexOf(valuesParam)
              if (index === -1) {
                arr[i] = e.target.value
              }
              else {
                arr[i] = e.target.value
              }
              setNewFieldDto(arr)
            }} />
            <div style={{ display: 'flex' }}>
              <div>
                <Select width={'250px'} style={{ marginLeft: '10px' }} disabled={structureParameters === null || parametersPhase.should_finalize_request[i]} items={itemPopUp} value={parametersPhase.next_phase_modifier[i]}
                  onChange={(e) => {
                    if (!isNaN(e)) {
                      let arrParameters = [...parametersPhase.next_phase_modifier]

                      arrParameters[i] = e

                      setParametersPhase({ ...parametersPhase, next_phase_modifier: arrParameters })
                    }
                  }} />
                <StyledCheckbox style={{ paddingRight: 8 }}
                  checked={parametersPhase.should_finalize_request[i]}
                  onChange={(e) => {
                    let arrParameters = [...parametersPhase.should_finalize_request]
                    let arrParametersNextPhase = [...parametersPhase.next_phase_modifier]

                    arrParameters[i] = e.target.checked
                    arrParametersNextPhase[i] = null

                    setParametersPhase({ ...parametersPhase, should_finalize_request: arrParameters, next_phase_modifier: arrParametersNextPhase })
                  }}>Cierre de flujo</StyledCheckbox>
              </div>
              {structure !== null &&
                <ButtonWithIcon small="true" type="button" icon="TrashBin" background={theme.colors.white} color={theme.colors.oldGray} iconSize={30}
                  style={{ marginLeft: '5px', marginBottom: '10px', boxShadow: '0px 0px 0px 0px rgba(0,0,0,0.00)' }} onClick={() => {
                    let arr = [...valuesParams]
                    let index = arr.indexOf(valuesParam)
                    arr.splice(index, 1)
                    setNewFieldDto(arr)

                    let arrParametersNextPhase = [...parametersPhase.next_phase_modifier]
                    arrParametersNextPhase.splice(index, 1)

                    let arrParametersShouldFinalize = [...parametersPhase.should_finalize_request]
                    arrParametersShouldFinalize.splice(index, 1)

                    setParametersPhase({ next_phase_modifier: arrParametersNextPhase, should_finalize_request: arrParametersShouldFinalize })


                  }} />
              }
            </div>
          </div>
        })}

      </div>
      {structure !== null && <SmallIconButton type="button" icon={'Add'} bold="true" size={21} text={i18n('ADD_FIELDS_TO_SECTION')}
        onClick={() => { setNewFieldDto([...valuesParams, '']); setParametersPhase({ next_phase_modifier: [...parametersPhase.next_phase_modifier, null], should_finalize_request: [...parametersPhase.should_finalize_request, false] }) }} />
      }
    </div>

    <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', marginBottom: 15, marginTop: 15 }}>
      <ConfirmationButton small="true" type="button" disabled={checkEmpty === -1} onClick={() => {
        setShowPopup(false)
        structure !== null && setFieldValue(structure, [...valuesParams]) && setFieldValue(`${structureParameters}["next_phase_modifier"]`, [...parametersPhase.next_phase_modifier]) && setFieldValue(`${structureParameters}["should_finalize_request"]`, [...parametersPhase.should_finalize_request])
      }}>{i18n('ACCEPT')}</ConfirmationButton>
      {structure !== null && <CancelButton small="true" type="button" onClick={() => {
        setShowPopup(false)
      }}>{i18n('CANCEL')}</CancelButton>}

    </div>
  </Popup>
}

const yesNoPopUp = (setShowYesNoPopUp, setFieldValue, structure, setYesNo, yesNo, itemPopUp) => {
  return <Popup title="Configuración Campo SI/NO" style={{ maxHeight: 600, maxWidth: 500 }} onClose={() => {
    setShowYesNoPopUp(false)
  }}>

    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ flexDirection: 'column', marginLeft: 35 }}>
        <p style={{ fontSize: 15, fontWeight: "bold" }}>Opciones</p>
        <p style={{ textAlign: "left", marginRight: 15, marginTop: 21 }}>SI</p>
        <p style={{ textAlign: "left", marginRight: 15, marginTop: 45 }}>NO</p>
      </div>
      <div style={{ flexDirection: 'column', justifyContent: 'flex-end', marginRight: 14 }}>
        <p style={{ fontSize: 15, fontWeight: "bold" }}>Saltar etapa por respuesta</p>
        <Select width={'250px'} disabled={yesNo.should_finalize_request[1]} items={itemPopUp} value={yesNo.next_phase_modifier[1]} onChange={(e) => {
          if (!isNaN(e)) {
            let yes = [...yesNo.next_phase_modifier]
            yes.splice(1, 1, e)
            setYesNo({ ...yesNo, next_phase_modifier: yes })
          }
        }
        }
        />
        <StyledCheckbox style={{ paddingRight: 8 }} checked={yesNo.should_finalize_request[1]} onChange={(e) => {
          setYesNo({ ...yesNo, should_finalize_request: [false, e.target.checked], next_phase_modifier: [yesNo.next_phase_modifier[0], null] })
        }}>Cierre de flujo</StyledCheckbox>

        <Select width={'250px'} disabled={yesNo.should_finalize_request[0]} items={itemPopUp} value={yesNo.next_phase_modifier[0]} onChange={(e) => {
          if (!isNaN(e)) {
            let no = [...yesNo.next_phase_modifier]
            no.splice(0, 1, e)
            setYesNo({ ...yesNo, next_phase_modifier: no })
          }
        }
        }
        />
        <StyledCheckbox style={{ paddingRight: 8 }} checked={yesNo.should_finalize_request[0]} onChange={(e) => {
          setYesNo({ ...yesNo, should_finalize_request: [e.target.checked, false], next_phase_modifier: [null, yesNo.next_phase_modifier[1]] })
        }}>Cierre de flujo</StyledCheckbox>

      </div>
    </div>

    <div style={{ width: "100%", display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
      <ConfirmationButton small="true" type="button" onClick={() => {
        console.log(yesNo)
        yesNo.next_phase_modifier.length && setFieldValue(`${structure}["next_phase_modifier"]`, [...yesNo.next_phase_modifier])
        yesNo.should_finalize_request.length && setFieldValue(`${structure}["should_finalize_request"]`, [...yesNo.should_finalize_request])
        setShowYesNoPopUp(false)
      }}>{i18n('ACCEPT')}</ConfirmationButton>
      <CancelButton small="true" type="button" onClick={() => {
        setShowYesNoPopUp(false)
      }}>{i18n('CANCEL')}</CancelButton>
    </div>
  </Popup>
}

export const AddFieldsButton = ({ data, dataStructure }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const [showPopup, setShowPopup] = useState(false)
  const [structure, setStructure] = useState('')
  const [newFieldDto, setNewFieldDto] = useState([])
  const [params, setParams] = useState([])


  return (
    <DataWrapper>
      <FieldArray name={dataStructure}>
        {arrayHelpers => (
          <>
            {showPopup && paramsPopUpExpirationsLevels(setShowPopup, setFieldValue, structure, params, setNewFieldDto, newFieldDto)}
            {data.map((item, i) => <> <DataContainer key={i}>
              <InputsContainer>
                <DashboardInput label={i === 0 && i18n('NAME')} isrequired="true" error={getIn(errors, `${dataStructure}[${i}].name`)} border="true" width="260px" name="name"
                  value={item.name} onChange={(e) => setFieldValue(`${dataStructure}[${i}].name`, e.target.value)} bold="true" style={{ marginRight: 10 }} />
                <Select label={i === 0 && i18n('TYPE')} items={selectItems} isrequired="true" bold="true"
                  error={item.field_type === 6 ? getIn(errors, `${dataStructure}[${i}].enum_values`) : getIn(errors, `${dataStructure}[${i}].field_type`)} border="true"
                  width="260px" name="field_type" selected={item.field_type} value={item.field_type}
                  onChange={(e) => {
                    setFieldValue(`${dataStructure}[${i}].field_type`, e)

                    if (e === 6) {
                      setFieldValue(`${dataStructure}[${i}].is_nullable`, false)
                    } else {
                      setFieldValue(`${dataStructure}[${i}].is_nullable`, true)
                    }
                  }} style={{ marginRight: 5 }} />
                <StyledSwitch id={`${i}_filtro_atr`} bold label={i === 0 && "Es Filtro de Atributo"} checked={item.is_filter}
                  onChange={(e) => setFieldValue(`${dataStructure}[${i}].is_filter`, e.target.checked)} />
              </InputsContainer>
              <EraseButtonContainer>
                <div style={{ alignSelf: 'center', visibility: `${item.field_type === 6 ? 'visible' : 'hidden'}` }}>
                  <SmallIconButton icon="Configuration" onClick={() => {
                    setShowPopup(true)
                    setStructure(`${dataStructure}[${i}].enum_values`)
                    setNewFieldDto(item.enum_values)
                  }} small="true" size={28} iconColor={item.enum_values.length > 0 && theme.colors.darkLilac} background={item.enum_values.length === 0 && theme.colors.accent} />
                </div>
                <Icon name={IconNames['Close']} size={18} onClick={() => arrayHelpers.remove(i)} />
              </EraseButtonContainer>
            </DataContainer>
              {item.field_type !== null && <div style={{ width: 150, marginBottom: 20 }}>
                <StyledSwitch id={i} horizontal="true" label={i18n('IS_REQUIRED')} checked={!item.is_nullable}
                  onChange={(e) => setFieldValue(`${dataStructure}[${i}].is_nullable`, !e.target.checked)} disabled={item.field_type === 6} />
              </div>}
            </>
            )}
            <SmallIconButton icon={'Add'} size={21} text={i18n('ADD_FIELDS')}
              onClick={() => arrayHelpers.push({
                name: "",
                field_type: null,
                is_nullable: true, // opcional
                is_unique: false,
                enum_values: [],
                is_filter: true // prendido para creacion
              })} />
          </>
        )}
      </FieldArray>
    </DataWrapper>
  )
}

export const EditFieldsButton = ({ data, addStructure, updateStructure, removeStructure, isBusiness }) => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [status, setStatus] = useState('')
  const [structure, setStructure] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [paramStructure, setParamStructure] = useState('')
  const [newFieldDto, setNewFieldDto] = useState([])
  const [params, setParams] = useState([])


  const valuesStructure = isBusiness ? values.person_configuration : values
  useEffect(() => {
    if (status === 'add') {
      isBusiness ? setStructure(`person_configuration.${addStructure}`) : setStructure(addStructure)
    } else if (status === 'remove') {
      isBusiness ? setStructure(`person_configuration.${removeStructure}`) : setStructure(removeStructure)
    }
  }, [status, structure])

  return (
    <DataWrapper>
      <FieldArray name={structure}>
        {arrayHelpers => (
          <>
            {showPopup && paramsPopUpExpirationsLevels(setShowPopup, setFieldValue, paramStructure, params, setNewFieldDto, newFieldDto === null ? [] : newFieldDto, isEdit)}

            {data.map((item, i) => {
              const foundValue = valuesStructure[updateStructure].findIndex((val) => val.id === item.id)
              return <DataContainer key={i}>
                <InputsContainer style={{ overflowY: 'auto' }}>
                  <DashboardInput label={i === 0 && i18n('NAME')} isrequired="true" border="true" bold="true" width="260px" name="name" style={{ marginRight: 10 }}
                    value={valuesStructure[updateStructure][foundValue] !== undefined ? valuesStructure[updateStructure][foundValue].name : item.name} onChange={(e) => {
                      if (foundValue === -1) {
                        valuesStructure[updateStructure].push({ id: item.id, name: e.target.value })
                        setFieldValue(isBusiness ? `person_configuration.${updateStructure}[${foundValue}].name` : `${updateStructure}[${foundValue}].name`, e.target.value)
                      }
                      setFieldValue(isBusiness ? `person_configuration.${updateStructure}[${foundValue}].name` : `${updateStructure}[${foundValue}].name`, e.target.value)
                    }} />

                  <Select label={i === 0 && i18n('TYPE')} disabled={true} items={selectItems} bold="true" isrequired="true" value={item.field_type} border="true" width="260px"
                    name="field_type" style={{ marginRight: 5 }} />
                  <StyledSwitch id={`${i}_filtro_atr`} bold label={i === 0 && "Es Filtro de Atributo"} checked={valuesStructure[updateStructure][foundValue] !== undefined ? valuesStructure[updateStructure][foundValue].is_filter : item.is_filter}
                    onChange={(e) => {
                      if (foundValue === -1) {
                        valuesStructure[updateStructure].push({ id: item.id, is_filter: !item.is_filter })
                        setFieldValue(`${updateStructure}[${foundValue}].is_filter`, e.target.checked)
                      }
                      setFieldValue(`${updateStructure}[${foundValue}].is_filter`, e.target.checked)

                    }} />
                </InputsContainer>
                <EraseButtonContainer>
                  <div style={{ marginBottom: 10, alignSelf: 'center', visibility: `${item.field_type === 6 ? 'visible' : 'hidden'}` }}>
                    <SmallIconButton icon="Configuration" onClick={() => {
                      setShowPopup(true)
                      if (foundValue === -1) {
                        valuesStructure[updateStructure].push({ id: item.id, enum_values_to_add: item.values })
                        setParamStructure(`${updateStructure}[${valuesStructure[updateStructure].length - 1}].enum_values_to_add`)
                        setNewFieldDto(item.values)
                      } else {
                        setParamStructure(`${updateStructure}[${foundValue}].enum_values_to_add`)
                        setNewFieldDto(valuesStructure[updateStructure][foundValue].enum_values_to_add)
                      }
                      setIsEdit(true)
                    }} small="true" size={28} iconColor={item.values === undefined && theme.colors.darkLilac} background={item.values === undefined && theme.colors.accent} />
                  </div>

                  <Icon name={IconNames['Close']} size={18} onMouseEnter={() => setStatus('remove')} onMouseLeave={() => setStatus('')}
                    onClick={() => {
                      if (status === "remove") {
                        arrayHelpers.push(item.id)
                        data.splice(i, 1)
                        valuesStructure[updateStructure].splice(foundValue, 1)
                      }
                    }} />
                </EraseButtonContainer>
              </DataContainer>
            }
            )}

            {valuesStructure[addStructure]?.map((item, i) => <><DataContainer key={i}>
              <InputsContainer>
                <DashboardInput isrequired="true" style={{ marginRight: 10 }}
                  error={isBusiness ?
                    getIn(errors, `person_configuration.${addStructure}[${i}].name`) :
                    getIn(errors, `${addStructure}[${i}].name`)
                  }
                  bold="true" border="true" width="260px" name="name" value={item.name}
                  onChange={(e) => setFieldValue(isBusiness ? `person_configuration.${addStructure}[${i}].name` : `${addStructure}[${i}].name`, e.target.value)} />

                <Select items={selectItems} isrequired="true" value={item.field_type}
                  error={isBusiness ?
                    (item.field_type === 6 ? getIn(errors, `person_configuration.${addStructure}[${i}].enum_values`) :
                      getIn(errors, `person_configuration.${addStructure}[${i}].field_type`))
                    :
                    (item.field_type === 6 ? getIn(errors, `${addStructure}[${i}].enum_values`) :
                      getIn(errors, `${addStructure}[${i}].field_type`))
                  }
                  bold="true" border="true" width="260px" name="field_type"
                  onChange={(e) => {
                    setFieldValue(isBusiness ? `person_configuration.${addStructure}[${i}].field_type` : `${addStructure}[${i}].field_type`, e)
                    e === 6 ? setFieldValue(isBusiness ? `person_configuration.${addStructure}[${i}].is_nullable` : `${addStructure}[${i}].is_nullable`, false) :
                      setFieldValue(isBusiness ? `person_configuration.${addStructure}[${i}].is_nullable` : `${addStructure}[${i}].is_nullable`, true)
                  }
                  } style={{ marginRight: 5 }} />

                <StyledSwitch id={`${i}_filtro_atr_add`} bold checked={item.is_filter}
                  onChange={(e) => {
                    setFieldValue(`${addStructure}[${i}].is_filter`, e.target.checked)
                  }} />

              </InputsContainer>

              <EraseButtonContainer>
                <div style={{ marginBottom: 10, alignSelf: 'center', visibility: `${item.field_type === 6 ? 'visible' : 'hidden'}` }}>
                  <SmallIconButton icon="Configuration" onClick={() => {
                    setShowPopup(true)
                    setParamStructure(isBusiness ? `person_configuration.${addStructure}[${i}].enum_values` : `${addStructure}[${i}].enum_values`)
                    setNewFieldDto(item.enum_values)
                    setIsEdit(false)
                  }} small="true" size={28} iconColor={item.enum_values.length > 0 && theme.colors.darkLilac} background={item.enum_values.length === 0 && theme.colors.accent} />
                </div>
                <Icon name={IconNames['Close']} size={18} onClick={() => {
                  arrayHelpers.remove(i)
                }} />
              </EraseButtonContainer>
            </DataContainer>
              {item.field_type !== null && <div style={{ width: 150, marginBottom: 20 }}>
                <StyledSwitch id={i} horizontal="true" label={i18n('IS_REQUIRED')} checked={!item.is_nullable}
                  onChange={(e) => setFieldValue(isBusiness ? `person_configuration.${addStructure}[${i}].is_nullable` : `${addStructure}[${i}].is_nullable`, !e.target.checked)} />
              </div>}
            </>
            )}

            <SmallIconButton icon={'Add'} size={21} text={i18n('ADD_FIELDS')} onMouseEnter={() => setStatus('add')} onMouseLeave={() => setStatus('')}
              onClick={() => {
                arrayHelpers.push({
                  name: "",
                  field_type: null,
                  is_nullable: true,
                  is_unique: false,
                  enum_values: [],
                  is_filter: false, // apagado para edicion
                })
              }} style={{ width: "fit-content" }} />
          </>
        )}
      </FieldArray>
    </DataWrapper >
  )
}

export const AddFieldsOnRequestTypeButton = ({ data, dataStructure, levelOptions, isEdit, hasMultipleValues, stages, indexStage, phaseActual, expirationsTrue }) => {
  const { values, setFieldValue, errors } = useFormikContext();
  const [showPopup, setShowPopup] = useState(false)
  const [showPopupExpirationsLevels, setShowPopupExpirationsLevels] = useState(false)
  const [showYesNoPopUp, setShowYesNoPopUp] = useState(false)
  const [structure, setStructure] = useState('')
  const [currentPhase, setcurrentPhase] = useState('')
  const [parametersPhase, setParametersPhase] = useState({ next_phase_modifier: [], should_finalize_request: [] })
  const [newFieldDto, setNewFieldDto] = useState([])
  const [params, setParams] = useState([])
  const [yesNo, setYesNo] = useState({ next_phase_modifier: [], should_finalize_request: [] })
  const [structureParameters, setStructureParameters] = useState([])

  const phases = values.version_dto.phases_dto.map((phases, index) => (
    {
      id: index,
      name: `Etapa ${index + 1} (${phases.name})`
    }
  )
  )

  const presentPhase = phases.filter((e) => e.id - 1 > currentPhase)
  const itemPopUp = nextPhase.concat(presentPhase)

  return (
    <DataWrapper>
      {/* {data.length > 0 && <div style={{ fontWeight: "600" }}>{i18n('FIELDS')}</div>} */}
      <FieldArray name={dataStructure}>
        {arrayHelpers => (
          <>
            {showPopup && paramsPopUp(setShowPopup, setFieldValue, structure, params, setNewFieldDto, newFieldDto === null ? [] : newFieldDto, isEdit, itemPopUp, structureParameters, setParametersPhase, parametersPhase === null ? [] : parametersPhase)}
            {showPopupExpirationsLevels && paramsPopUpExpirationsLevels(setShowPopupExpirationsLevels, setFieldValue, structure, params, setNewFieldDto, newFieldDto === null ? [] : newFieldDto, isEdit, itemPopUp)}
            {showYesNoPopUp && yesNoPopUp(setShowYesNoPopUp, setFieldValue, structure, setYesNo, yesNo, itemPopUp)}
            {data.map((item, i) => <Fragment key={i}>
              <DataContainer>
                <InputsContainer style={{ justifyContent: 'space-between' }}>
                  <DashboardInput label={i18n('NAME')} horizontal="true" error={getIn(errors, `${dataStructure}[${i}].new_field_dto.name`)} border="true" width="260px"
                    value={item.new_field_dto.name} onChange={(e) => setFieldValue(`${dataStructure}[${i}].new_field_dto.name`, e.target.value)} />
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Select label={i18n('TYPE')} items={[...selectItems, /*{ id: 3, name: "Si/No/Nm" } ,*/ { id: 7, name: "Nivel" }]}
                      error={item.new_field_dto.field_type === 6 ? getIn(errors, `${dataStructure}[${i}].new_field_dto.parameters`) :
                        getIn(errors, `${dataStructure}[${i}].new_field_dto.field_type`)}
                      name="field_type" value={item.new_field_dto.field_type} selected={item.new_field_dto.field_type} horizontal="true" border="true" width="260px"
                      onChange={(e) => {
                        setFieldValue(`${dataStructure}[${i}].new_field_dto.field_type`, e)
                        if (e === 6) {
                          setFieldValue(`${dataStructure}[${i}].is_required`, true)
                        } else {
                          setFieldValue(`${dataStructure}[${i}].new_field_dto.parameters`, null)
                          setFieldValue(`${dataStructure}[${i}].is_required`, false)
                        }
                      }} />
                    {item.new_field_dto.field_type === 7 && <div>
                      <Select items={levelOptions} error={getIn(errors, `${dataStructure}[${i}].new_field_dto.entity_configuration_id`)}
                        onChange={(val) => setFieldValue(`${dataStructure}[${i}].new_field_dto.entity_configuration_id`, val)} width="260px"
                        value={item.new_field_dto.entity_configuration_id} />
                      <StyledSwitch id={`${dataStructure}_${i}_levelFilter`} horizontal="true" label={"Filtrar contenido por Nivel General"} checked={item.entity_field_should_filter_by_request_entity}
                        onChange={(e) => setFieldValue(`${dataStructure}[${i}].entity_field_should_filter_by_request_entity`, e.target.checked)}
                        disabled={item.new_field_dto.field_type === 6} />
                    </div>}
                  </div>
                  <div style={{ marginBottom: 10, alignSelf: 'center', visibility: `${item.new_field_dto.field_type === 6 || item.new_field_dto.field_type === 2 && !expirationsTrue ? 'visible' : 'hidden'}` }}>
                    <SmallIconButton icon="Configuration" onClick={() => {
                      if (item.new_field_dto.field_type === 6) {
                        !expirationsTrue && setShowPopup(true)
                        expirationsTrue && setShowPopupExpirationsLevels(true)
                        setStructure(`${dataStructure}[${i}].new_field_dto.parameters`)
                        !expirationsTrue && setStructureParameters(`${dataStructure}[${i}]`)
                        setNewFieldDto(item.new_field_dto.parameters)
                        !expirationsTrue && setParametersPhase({ next_phase_modifier: item.next_phase_modifier, should_finalize_request: item.should_finalize_request })
                        !expirationsTrue && setcurrentPhase(phaseActual)
                        return
                      }
                      !expirationsTrue && setShowYesNoPopUp(true)
                      !expirationsTrue && setStructure(`${dataStructure}[${i}]`)
                      !expirationsTrue && setcurrentPhase(phaseActual)
                      !expirationsTrue && setYesNo({
                        next_phase_modifier: item.next_phase_modifier.length === 0 ? item.next_phase_modifier = [null, null] : item.next_phase_modifier,
                        should_finalize_request: item.should_finalize_request.length ? item.should_finalize_request : []
                      })
                    }}
                      small="true" size={28}
                      iconColor={item.new_field_dto?.parameters?.length > 0 || item.next_phase_modifier?.length > 0 ? theme.colors.darkLilac : "false"} background={item.new_field_dto?.parameters?.length === 0 || item.next_phase_modifier?.length > 0 ? theme.colors.accent : "false"} />
                  </div>
                </InputsContainer>

                <EraseButtonContainer onClick={() => arrayHelpers.remove(i)}>
                  <Icon name={IconNames['Close']} size={18} style={{ marginBottom: 10 }} />
                </EraseButtonContainer>
              </DataContainer>

              {/* Limitar Caractéres */}
              <DataContainer>
                {item.new_field_dto.field_type !== null &&
                  <InputsContainer>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <p style={{ fontSize: 12, fontWeight: 600, marginBottom: 0, marginRight: 15 }}>Configuración:</p>
                      <Input width="110px" label="Limite de caracteres" type="number"
                        disabled={false}
                        value={item.text_length_limit}
                        onChange={(e) => setFieldValue(`${dataStructure}[${i}].text_length_limit`, e.target.value)}
                        horizontal="true"
                        style={{ marginBottom: 0 }}
                      />
                    </div>
                    <div style={{ width: 130, marginBottom: 10, marginLeft: 22, display: "inline-block" }}>
                      <StyledSwitch id={`${dataStructure}_${i}`} horizontal="true" label={i18n('IS_REQUIRED')} checked={item.is_required}
                        onChange={(e) => setFieldValue(`${dataStructure}[${i}].is_required`, e.target.checked)} bold={true}
                        disabled={item.new_field_dto.field_type === 6} />
                    </div>
                  </InputsContainer>
                }
              </DataContainer>

              <Divider style={{ marginTop: 0 }} />

              {/* {
                (hasMultipleValues && stages.length !== 0) && <div style={{ width: 260, marginBottom: 20, marginLeft: 240, display: "inline-block" }}>
                  <Select items={stages} label={i18n('FUTURE_LOAD_PHASE')} horizontal="true" width="100px" border="true"
                    name="future_load_phase_index" value={item.future_load_phase_index} selected={item.future_load_phase_index}
                    onChange={(e) => setFieldValue(`${dataStructure}[${i}].future_load_phase_index`, e)} />
                </div>
              } */}
            </Fragment>
            )}
            <Tooltip placement="topLeft" title={(data.length === 6 && hasMultipleValues) && "Límite de campos permitidos 6 para Vista de tabla"}>
              <div style={{ marginLeft: 20, marginBottom: 20 }}>
                <SmallIconButton icon={'Add'} bold="true" size={21} text={i18n('ADD_FIELDS_TO_SECTION')}
                  background={(data.length === 6 && hasMultipleValues) && theme.colors.darkGray}
                  onClick={() =>
                    (data.length >= 6 && hasMultipleValues) ? {} :
                      arrayHelpers.push(
                        {
                          is_required: false,
                          existing_field_id: null,
                          future_load_phase_index: null,
                          entity_field_should_filter_by_request_entity: false,
                          new_field_dto: {
                            field_type: null,
                            name: "",
                            ternary_option_name: "", // Joaco 10/6/20: Si vas a usar el tipo de campo si/no/nm aca iria el nombre de la tercera opcion, esto viene de un problema mayor: el tipo de campo si/no/nm no se deberia llamar asi en el pasaje de tiendacheck a tops se definio que en vez de ser "si / no / necesita mejorar" la opcion "necesita mejorar" deberia tener su propio nombre, de ahi que en el back se llama ternario, si va a usar ese tipo de campo tenes que enviar un valor sino podes dejarlo en nulo o vacio.
                            entity_configuration_id: null,
                            parameters: [],
                          },
                          next_phase_modifier: [],
                          should_finalize_request: []
                        })
                  } />
              </div>
            </Tooltip>
          </>
        )}
      </FieldArray>
    </DataWrapper>
  )
}

export const AddFieldsOnExpirationRequestTypeButton = ({ data, dataStructure }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  return (
    <DataWrapper>
      <FieldArray name={dataStructure}>
        {arrayHelpers => (
          <>
            {data?.map((item, i) => <>
              <DataContainer key={i}>
                <InputsContainer style={{ width: "97%" }}>
                  <Label>{i18n('ALARM_TYPE')}</Label>
                  <DashboardInput type="number" min="1" value={item.quantity} error={getIn(errors, `${dataStructure}[${i}].quantity`)}
                    onChange={(e) => setFieldValue(`${dataStructure}[${i}].quantity`, parseInt(e.target.value))} horizontal="true" width="100px" style={{ /*marginLeft: "57px" */ }} />
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Select items={item.quantity === 1 ? alarmOneDropDown : alarmMoreOneDropDown} horizontal="true" width="200px" border="true"
                      error={getIn(errors, `${dataStructure}[${i}].model_phase_alarm_units`)}
                      name="model_phase_alarm_units" value={item.model_phase_alarm_units} selected={item.model_phase_alarm_units}
                      onChange={(e) => setFieldValue(`${dataStructure}[${i}].model_phase_alarm_units`, e)} />
                  </div>
                </InputsContainer>
                <EraseButtonContainer onClick={() => arrayHelpers.remove(i)}>
                  <Icon name={IconNames['Close']} size={18} />
                </EraseButtonContainer>
              </DataContainer>
            </>
            )}
            <div style={{ marginLeft: 20, marginBottom: 20 }}>
              <SmallIconButton icon={'Add'} bold="true" size={21} text={i18n('ALARM_ADD')}
                onClick={() => arrayHelpers.push(
                  {
                    quantity: null,
                    model_phase_alarm_units: null
                  }
                )} />
            </div>
          </>
        )}
      </FieldArray>
    </DataWrapper>
  )
}