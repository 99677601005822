import React, { useState } from 'react'
import { theme } from '@tops/global_config'
import { InputContainer, StyledInput, Label, IconWrapper, Container, ErrorMessage, SearchContainer, StyledTextArea } from "./styled"
import Icon, { IconNames } from './../Icons';
import { Button } from '../Button';
import { i18n } from '@tops/services';

export const Input = props => {

  

  return (
    <Container style={props.style} horizontal={props.horizontal} width={props.width}>
      {props.label &&
        <Label {...props} sizeLabel={props.sizeLabel}>
          {props.label}
          {props.isRequired && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Label>
      }
      <InputContainer width={props.width}>
        <StyledInput {...props} hasError={props.error} placeholder={props.placeholder} isPercentage={props.isPercentage} value={props.value} isFinder={props.isFinder} iconName={props.iconName} marginBottom={props.marginBottom} maxLength={props.maxLength}/>
          {props.isFinder === true && <div style={{ position: 'absolute', top: 8, right: 10 }}><Icon size={28} name={props.iconName} color="#24a480" /></div>}
          {props.isPercentage === true && <div style={{ position: "absolute", right: "26px", top: "7px" }}>%</div>}
          {(props.error && !props.value && !props.massiveRiseError && !props.displayError) && <ErrorMessage hasError={props.error} >{props.error}</ErrorMessage>}
          {(props.displayError && props.error && !props.massiveRiseError) && <ErrorMessage hasError={props.error} >{props.error}</ErrorMessage>}
      </InputContainer >
    </Container>
  )
}

export const LoginInput = props => {
  const [show, setShow] = useState(false)

  const icon = <Icon size={25} name={show ? IconNames['NewEyeShow'] : IconNames['NewEyeHide']} color={props.secondStep ?  "#6247c0" : "#415d69"} />

  return (
    <Container horizontal={props.horizontal } width={props.width} >
      {props.label &&
        <Label>
          {props.label}
        </Label>
      }
      <InputContainer width={props.width}>
        {/* <IconWrapper position="left" style={{ top: '9px', left: '13px' }} >
          <Icon size={30} color={theme.colors.user.requests.primary} name={props.ispassword ? IconNames['LoginLock'] : IconNames['LoginUser']} />
        </IconWrapper> */}
        <StyledInput login width="100%" {...props} hasError={props.error} textColor={theme.colors.black} type={props.ispassword && !show ? 'password' : 'text'} placeholder={props.placeholder} />

        {
          props.ispassword &&
          <div>
            <IconWrapper onClick={() => setShow(!show)} position="right" pointer="true" isLogin>
              {icon}
            </IconWrapper>
          </div>
          }

        {props.error && <ErrorMessage style={{marginBottom: props.isUserLogin ? 25 : 0}} hasError={props.error} >{props.error}</ErrorMessage>}
      </InputContainer >
    </Container>
  )
}

export const Search = props => {

  return (
    <>
      <InputContainer width={props.width}>
        <IconWrapper position="left"  >
          <Icon size={30} name={IconNames['Search']} />
        </IconWrapper>
        <StyledInput {...props} placeholder="Buscar..." hasIcon />
        {
          (props.value.length > 0) && <IconWrapper position="right" pointer="true" onClick={props.clear} >
            <Icon size={30} name={IconNames['Close']} />
          </IconWrapper>
        }
      </InputContainer >
    </>
  )

}

export const UserSearch = ({ children, placeholder, background, color, parent, ...props }) => {
  return (
    <SearchContainer>
      <InputContainer>
        <Input width="300px" {...props} color={color} placeholder={placeholder ? placeholder : i18n('USER').WISE.MY_TEAMS.TYPE_HERE} />
        {
          (props.value.length > 0) && <IconWrapper style={{marginRight: 10}} position="right" pointer="true" onClick={props.clear} >
            <Icon size={30} name={IconNames['Close']} />
          </IconWrapper>
        }
      </InputContainer>
      {React.Children.toArray(children).length > 0 ?
        <div style={{ display: 'flex', margin: 0 }}>
          {children}
        </div>
        : null}
      <Button type="submit" small="true" background={background} color={color} style={{ marginLeft: "10px", padding: '5px 20px'}}>
        {i18n('SEARCH')}
      </Button>
    </SearchContainer>
  )
}

export const DashboardInput = ({ ...props }) => {
  return <Input {...props} />
}

export const TextAreaInput = (props) => {
  return <Container horizontal={props.horizontal} width={props.width} style={props.style}>
    {props.label &&
      <Label {...props} bold={props.bold} sizeLabel={props.sizeLabel}>
        {props.label}
        {props.isRequired && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
      </Label>
    }
    <InputContainer width={props.width}>
      <StyledTextArea {...props} hasError={props.error} placeholder={props.placeholder} />
      {props.error && <ErrorMessage hasError={props.error} >{props.error}</ErrorMessage>}
      {props.maxLength && !props.reasonForAbsence &&
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12, fontWeight: 'normal' }}>
          <div>{i18n('MAX_CHARACTERS')} {props.maxLength}</div>
          <div>{props.value?.length}</div>
        </div>
      }
    </InputContainer >
  </Container>
}
