import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'
import { Container } from '../Container'

export const Wrapper = styled(Container)`
  max-width: 500px;
  
  @media (max-width: 768px){
    max-width: 100%;
  }
  
`