import React, { useState, useEffect, Fragment } from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter, updatedDiff, arrayDiff } from '@tops/utils'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, StyledCheckbox, DashboardContainer, DashboardInput, StyledSwitch, Select, Button,
  MessageBanner, SmallIconButton, Loader, MessageError, AddFieldsOnRequestTypeButton, TextAreaInput, Icon, Popup
} from '@tops/components'
import { theme } from '@tops/global_config'
import { fetchAllLevelsBySector, fetchRequestTypeById, fetchAllRelatedRequestsBySector, editRequestType } from '../../../../actions/requestTypes'
import { fetchSectorsByBusiness } from '../../../../actions/sectors'
import { fetchAllBusiness } from '../../../../actions/business'
import { fetchRolesSimple } from '../../../../actions/roles'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Divider } from 'antd'
import { Formik, FieldArray, getIn } from 'formik'
import * as yup from 'yup'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './styles.css'

const EditRequestType = ({ history, savedInfo }) => {

  const [error, setError] = useState(false)
  const [validateError, setValidateError] = useState(false)
  const [publicCheck, setPublicCheck] = useState(false)
  const [plannedCheck, setPlannedCheck] = useState(false)
  const [success, setSuccess] = useState(false)
  const [stages, setStages] = useState([])
  const [stagesFiltered, setStagesFiltered] = useState([])
  const [isExpirationModel, setIsExpirationModel] = useState(false)
  const [showReorderSectionsPopup, setShowReorderSectionsPopup] = useState({ status: false, index: null })
  const [showReorderItemsPopup, setShowReorderItemsPopup] = useState({ status: false, phaseIndex: null, index: null })

  const { id } = useParams()
  const dispatch = useDispatch()

  const business = useSelector(state => state.business)
  const sectors = useSelector(state => state.sectors)
  const levels = useSelector(state => state.requestTypes)
  const requestTypes = useSelector(state => state.requestTypes)
  const roles = useSelector(state => state.roles)

  const pdf = {
    values: [{
      id: null,
      name: 'PDF',
    },
    {
      id: 0,
      name: 'PDF con firma',
    }]
  }

  const urgencyNomenclature = [
    { id: 0, name: '1, 2, 3' },
    { id: 1, name: 'Alta, Media, Baja' }
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchAllBusiness(savedInfo.id))
        dispatch(fetchRequestTypeById(id, isExpirationModel))
          .then((response) => {
            if (response.status === 200) {
              dispatch(fetchAllLevelsBySector(response.data.sector_id))
              dispatch(fetchAllRelatedRequestsBySector(response.data.sector_id));
              dispatch(fetchRolesSimple(response.data.sector_id))
              dispatch(fetchSectorsByBusiness(savedInfo.id, response.data.business_id))
            }
          })
      } catch (error) {
        setError(true)
      }
    }
    fetchData()

  }, [])

  const handleSwitch = (val, fun) => fun(!val)

  const aprroversDropDown = [{ id: 0, name: i18n('APPROVAL_TYPE_NPlus1') }, { id: 1, name: i18n('APPROVAL_TYPE_ROLES') }]

  const division = [{ id: 0, name: i18n('DERIVATION_TYPE_0') }, { id: 1, name: i18n('DERIVATION_TYPE_1') }, /* { id: 2, name: i18n('DERIVATION_TYPE_2') } */]


  const handlePublicPrivate = () => { setPublicCheck(!publicCheck) }

  const handlePlannedSelfResolvable = () => { setPlannedCheck(!plannedCheck) }

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(i18n('ERRORS').REQUEST_TYPE.NAME_ERROR),
    related_request_model_id: yup.mixed().when('hasRelatedRequest', { is: true, then: yup.mixed().required(i18n('ERRORS').REQUEST_TYPE.RELATED_REQUEST_ERROR), }),
    version_dto: yup.object().shape({
      entity_configuration_ids: yup.array().required(i18n('ERRORS').REQUEST_TYPE.LEVELS_ERROR),
      phases_dto: yup.array().of(yup.object().shape({
        name: yup.string().trim().required(i18n('ERRORS').REQUEST_TYPE.STAGE_NAME_ERROR),
        can_be_derived: yup.boolean(),
        derivation_option: yup.mixed().when('can_be_derived', {
          is: true,
          then: yup.mixed().required(i18n('ERRORS').REQUEST_TYPE.DERIVATION_TYPE_ERROR),
        }),
        has_approval: yup.boolean(),
        approval_type: yup.mixed().when('has_approval', {
          is: true,
          then: yup.mixed().required(i18n('ERRORS').REQUEST_TYPE.APPROVAL_TYPE_ERROR),
        }),
        roles: yup.array().nullable().when('approval_type', {
          is: 1,
          then: yup.array().required(i18n('ERRORS').REQUEST_TYPE.ROLE_ERROR),
        }),
        has_solver: yup.boolean(),
        resolution_role_id: yup.mixed().when('has_solver', {
          is: true,
          then: yup.mixed().required(i18n('ERRORS').REQUEST_TYPE.RESOLUTOR_ERROR),
        }),
        roles_to_edit: yup.mixed().when('can_be_edited', {
          is: true,
          then: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
        }),
        phase_sections_dto: yup.array().of(yup.object().shape({
          /*name: yup.string().trim().required(i18n('ERRORS').REQUEST_TYPE.SECTION_NAME_ERROR),*/
          field_section_dto: yup.array().of(yup.object().shape({
            new_field_dto: yup.object().shape({
              name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
              field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
              // ternary_option_name: yup.string().trim().required(),
              entity_configuration_id: yup.mixed().when('field_type', {
                is: 7,
                then: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
              }),
              parameters: yup.mixed().when('field_type', {
                is: 6,
                then: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
              })
            })
          }))
        }))
      }))

    })
  })

  const onSubmit = async (values) => {
    const objectSubmitted = updatedDiff(initialValues, values)
    objectSubmitted.id = id
    objectSubmitted.description = values.description
    objectSubmitted.description_url = values.description_url
    objectSubmitted.diferential_pdf_type = values.diferential_pdf_type

    if (!arrayDiff(initialValues.version_dto, values.version_dto)) {
      objectSubmitted.version_dto = values.version_dto

      objectSubmitted.version_dto.phases_dto.forEach(phase => {
        phase.phase_sections_dto.forEach(phaseSection =>
          phaseSection.field_section_dto.forEach(fieldSection => {
            fieldSection.existing_field_id = null
            if (fieldSection.new_field_dto.field_type === 6) {
              let found = fieldSection.new_field_dto.parameters.find(item => typeof item === "object")
              fieldSection.new_field_dto.parameters = found === undefined || found.length === 0 ? fieldSection.new_field_dto.parameters : fieldSection.new_field_dto.parameters.map(item => item.name)
            }
          })
        )
      });
    }

    if (initialValues.related_request_model_id !== values.related_request_model_id) {
      objectSubmitted.should_update_related_request_model_id = true
    }

    await dispatch(editRequestType(objectSubmitted)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  const nameInput = (values, handleChange, errors) => (
    <div style={{ marginBottom: "15px" }}>
      <DashboardInput label={i18n('NAME')} error={errors.name} horizontal="true" border="true" width="450px" name="name" onChange={handleChange}
        value={values.name} />
    </div>
  )

  const businessSelect = (values, setFieldValue, errors) => (
    <div style={{ marginBottom: "15px" }}>
      <Select items={business.values} label={i18n('BUSINESS')} horizontal="true" width="450px" border="true"
        name="businessSelected" selected={values.businessSelected} value={values.businessSelected} disabled={true}
      />
    </div>
  )

  const sectorOwnerSelect = (values, setFieldValue, errors) => (
    <Select items={sectors.allSectorsByBusiness} label={i18n('OWNER_SECTOR')} horizontal="true" width="450px"
      name="sectorOwnerSelected" selected={values.sectorOwnerSelected} value={values.sectorOwnerSelected} disabled={true} border="true" />
  )

  const publicPrivateButtons = (setFieldValue, values) => (
    <div style={{ marginTop: "15px", marginBottom: "15px", display: "flex" }}>
      <Button type="button" square small background={theme.colors.accent} selected={values.is_public}
        onClick={(e) => { setFieldValue("is_public", true); setFieldValue("allowed_sectors_ids", []); handlePublicPrivate() }}>{i18n('PUBLIC')}</Button>
      <Button type="button" square small background={theme.colors.accent} selected={!values.is_public}
        onClick={(e) => { setFieldValue("is_public", false); handlePublicPrivate() }}>{i18n('PRIVATE')}</Button>
    </div>
  )

  const allowedSectorsIdsCheckboxes = (setFieldValue, values) => (
    <FieldArray name="allowed_sectors_ids">
      {
        arrayHelpers => (
          <div style={{ display: "flex", flexDirection: "column", maxHeight: "200px", flexWrap: "wrap" }}>
            {
              sectors.allSectorsByBusiness.map((sector, i) => {
                if (sector.id !== values.sectorOwnerSelected) {
                  return <StyledCheckbox key={i} value={sector.id} checked={values.is_public ? true : values.allowed_sectors_ids.includes(sector.id)}
                    name={"allowed_sectors_ids"} disabled={values.is_public && true} onChange={e => {
                      if (e.target.checked) {
                        arrayHelpers.push(sector.id);
                      }
                      else {
                        const idx = values.allowed_sectors_ids.indexOf(sector.id);
                        arrayHelpers.remove(idx)
                      }
                    }
                    }>{sector.name}</StyledCheckbox>
                }
              })
            }
          </div>
        )}
    </FieldArray>
  )

  const entityConfigurationIdsCheckboxes = (values, errors) => (<>
    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
      <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('LEVELS'))}</span>
    </div>
    <MessageError errorname="version_dto.entity_configuration_ids" />
    <FieldArray name={`version_dto.entity_configuration_ids`}>
      {
        arrayHelpers => (
          <div style={{ display: "flex", flexDirection: "column", maxHeight: "100px", flexWrap: "wrap" }}>
            {
              levels.allLvlsBySector.length == 0 ? i18n('NOT_LEVELS_TO_OWNER_SECTOR') :
                levels.allLvlsBySector.map((level, i) =>
                  <StyledCheckbox key={i} value={level.id} checked={values.version_dto.entity_configuration_ids.includes(level.id)}
                    name={`version_dto.entity_configuration_ids`} onChange={e => {
                      if (e.target.checked) {
                        arrayHelpers.push(level.id);
                      }
                      else {
                        const idx = values.version_dto.entity_configuration_ids.indexOf(level.id);
                        arrayHelpers.remove(idx)
                      }
                    }
                    }>
                    {level.name}</StyledCheckbox>
                )
            }
          </div>
        )}
    </FieldArray>
  </>
  )

  const descriptionInput = (values, handleChange) => (
    <div style={{ marginTop: "25px", marginBottom: "25px" }}>
      <TextAreaInput label={i18n('DESCRIPTION')} horizontal="true" border="true" width="450px"
        name="description" value={values.description} onChange={handleChange} rows={5} style={{ alignSelf: 'flex-start' }} />
    </div>
  )

  const linkInput = (values, handleChange) => (
    <div style={{ marginBottom: "15px" }}>
      <DashboardInput label={i18n('LINK')} horizontal="true" border="true" width="450px" value={values.description_url} name="description_url" onChange={handleChange} placeholder="Escribir aquí" />
    </div>
  )

  const plannedSelfButtons = (setFieldValue, values) => (
    <div style={{ /* marginTop: "15px", marginBottom: "15px",*/ display: "flex" }}>
      <Button type="button" square small background={theme.colors.accent} selected={values.can_be_planned}
        onClick={() => { setFieldValue("can_be_planned", true); handlePlannedSelfResolvable() }}>{i18n('PLANNED')}</Button>
      <Button type="button" square small background={theme.colors.accent} selected={!values.can_be_planned}
        onClick={() => { setFieldValue("can_be_planned", false); handlePlannedSelfResolvable() }}>{i18n('SELFRESOLVABLE')}</Button>
    </div>
  )

  const relatedRequest = (setFieldValue, values, errors) => (<>
    <StyledSwitch id="createReq_should_be_accesible_from_request_creation_drop_list" checked={values.should_be_accesible_from_request_creation_drop_list}
      label={i18n('VISIBLE_FROM_REQUESTS_TOOL')} horizontal="true" name="should_be_accesible_from_request_creation_drop_list" style={{ fontWeight: 600 }}
      onChange={(e) => {
        setFieldValue("should_be_accesible_from_request_creation_drop_list", e.target.checked)
      }} />
    <StyledSwitch id="createReq_hasRelatedRequest" checked={values.hasRelatedRequest} style={{ fontWeight: 600 }}
      label={i18n('HAS_RELATED_REQUEST')} horizontal="true" name="hasRelatedRequest"
      onChange={(e) => {
        setFieldValue(`hasRelatedRequest`, !values.hasRelatedRequest);
        setFieldValue(`related_request_model_id`, null);
      }} />
    {values.hasRelatedRequest &&
      <div style={{ margin: "25px 0" }}>
        <Select items={requestTypes.allRelatedRequestsBySector} label={i18n('RELATED_REQUESTS')} horizontal="true" width="300px" border="true"
          onChange={(e) => setFieldValue(`related_request_model_id`, e)} error={errors.related_request_model_id}
          disabled={requestTypes.allRelatedRequestsBySector.length == 0 ? true : false}
          value={values.related_request_model_id} selected={values.related_request_model_id} />
      </div>
    }
  </>
  )

  const expirationDate = (setFieldValue, values) => (
    <StyledSwitch id="createReq_hasExpirationDate" checked={values.has_expiration_date} label={i18n('EXPIRATION_DATE')} horizontal="true" name="expirationDate"
      onChange={(e) => setFieldValue("has_expiration_date", e.target.checked)} />
  )

  const pdfType = (setFieldValue, errors, values) => (
    // <StyledSwitch id="pdfType" checked={values.pdfType} label={i18n('PDF_TYPE')} horizontal="true" name="pdfType"
    //   onChange={(e) => setFieldValue("pdfType", e.target.checked)} />
    <Select items={pdf.values} label={i18n('PDF_TYPE')} value={values.diferential_pdf_type} horizontal="true" width="300px" border="true" style={{ marginRight: '-12px', marginTop: 5 }}
      name="diferential_pdf_type" onChange={(e) => {
        setFieldValue(`diferential_pdf_type`, e);
      }} />
  )


  const urgencyNomenclatures = (setFieldValue, errors, values) => (
    <Select items={urgencyNomenclature} label={'Nomenclatura de Urgencias'} value={values.version_dto.urgency_type} horizontal="true" width="300px" border="true" style={{ marginRight: '-12px', marginTop: 5 }}
      name="urgency_type" onChange={(e) => {
        setFieldValue(`version_dto.urgency_type`, e);
      }} />
  )

  const tableView = (setFieldValue, errors, values) => (
    <>
      <div style={{ marginTop: 15, marginBottom: 30, position: 'relative' }}>
        <p style={{ marginBottom: 0, lineHeight: 1.8, fontWeight: 600 }}>Configurar vista de tabla<br></br></p>
        <StyledSwitch id={"tablesHaveTotalColumnTrue"}
          label={<div>
            <p>Incluir suma de campos numéricos</p>
          </div>}
          horizontal="true"
          checked={values?.version_dto?.tables_have_total_column}
          name={`version_dto.tables_have_total_column`}
          onChange={(e) => setFieldValue(`version_dto.tables_have_total_column`, e.target.checked)} />
      </div>
    </>

  )

  const stageSection = (handleChange, setFieldValue, values, errors) => (<>
    {/* STAGE */}
    <FieldArray name={`version_dto.phases_dto`}>
      {
        arrayHelpers => (<div>
          {
            values.version_dto.phases_dto.map((phase, i) =>
              <div key={i}>
                <div style={{ marginBottom: "15px" }}>
                  <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('STAGE')) + " " + (i + 1)}</span>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <DashboardInput id={"createReq_stage_" + i + "_name"} error={getIn(errors, `version_dto.phases_dto[${i}].name`)}
                    name={`version_dto.phases_dto[${i}].name`} label={i18n('NAME')} horizontal="true" border="true" width="450px"
                    value={phase.name} onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].name`, e.target.value)} />
                </div>

                {/* SECTION */}
                <FieldArray name={`version_dto.phases_dto[${i}].phase_sections_dto`}>
                  {arrayHelpers => (
                    <div style={{ marginBottom: 20 }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 15, width: "130%" }}>
                        <div style={{ marginBottom: "15px" }}>
                          <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('SECTIONS'))}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer", width: "fit-content", border: "1px solid #704dfb", borderRadius: "8px", padding: "4px 5px" }}
                          onClick={() => setShowReorderSectionsPopup({ status: true, index: i })}>
                          <Icon name="Reorder" color={"#704dfb"} size={25} />
                          <b style={{ marginLeft: 5, color: "#704dfb" }}>{i18n('USER').CHECKLISTS.CREATE_EDIT.REORDER_SECTIONS}</b>
                        </div>
                      </div>

                      {phase.phase_sections_dto.map((section, j) => <Fragment key={j}>
                        {/* Section has multiple values */}
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px", width: "130%", flexWrap: "wrap" }}>
                          <DashboardInput id={"createPhaseSection" + i + "_" + j + "_name"} error={false} // getIn(errors, `version_dto.phases_dto[${i}].phase_sections_dto[${j}].name`)
                            name={`version_dto.phases_dto[${i}].phase_sections_dto[${j}].name`} label={i18n('SECTION_NAME')} horizontal="true" border="true" width="450px"
                            onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].phase_sections_dto[${j}].name`, e.target.value)}
                            value={section.name} />
                          <div style={{ display: "flex", alignItems: "center", cursor: "pointer", width: "fit-content", border: "1px solid #704dfb", borderRadius: "8px", padding: "4px 5px" }}
                            onClick={() => setShowReorderItemsPopup({ status: true, phaseIndex: i, index: j })}>
                            <Icon name="Reorder" color={"#704dfb"} size={25} />
                            <b style={{ marginLeft: 5, color: "#704dfb" }}>Reordenar campos</b>
                          </div>
                        </div>
                        <div style={{ marginBottom: 30 }}>
                          <StyledSwitch id={"createPhaseSection" + i + "_" + j + "_hasMultipleValues"}
                            label={
                              " Vista de tabla, permite al usuario repetir campos de esta sección"
                            }
                            bold={true}
                            horizontal="true"
                            checked={values.version_dto.phases_dto[i].phase_sections_dto[j].has_multiple_values}
                            name={`version_dto.phases_dto[${i}].phase_sections_dto[${j}].has_multiple_values`}
                            onChange={(e) => section.field_section_dto.length <= 6 && setFieldValue(`version_dto.phases_dto[${i}].phase_sections_dto[${j}].has_multiple_values`, e.target.checked)} />
                        </div>
                        {/* Checkbox para convertir números a letras (SE VA A AGREGAR EN OTRO SPRINT) */}
                        {/* {values.version_dto.phases_dto[i].phase_sections_dto[j].has_multiple_values &&
                        <div style={{ marginBottom: 20 }}>
                          <StyledCheckbox key={j} value={false} checked={false} name={"allowed_sectors_ids"} disabled={false}>
                            Mostrar campo "Total" en letras.
                          </StyledCheckbox>
                        </div>} */}
                        <div style={{ width: 700 }}>
                          <AddFieldsOnRequestTypeButton data={section.field_section_dto} levelOptions={levels.allLvlsBySector}
                            dataStructure={`version_dto.phases_dto[${i}].phase_sections_dto[${j}].field_section_dto`} isEdit
                            dataStructureSiNo={`version_dto.phases_dto[${i}]`}
                            hasMultipleValues={values.version_dto.phases_dto[i].phase_sections_dto[j].has_multiple_values}
                            stages={stages} indexStage={i} phaseActual={i} />
                        </div>
                        {(j > 0 || (j > 0 && i === 0) || (j === 0 && i > 0)) &&
                          <>
                            <div style={{ float: "right", padding: "20px 0 20px 40px", marginTop: "-60px" }} >
                              <SmallIconButton icon={"TrashBin"} size={21} text={i18n('DELETE_SECTION')} bold="true" iconColor="white" background="#b9a5fd"
                                onClick={() => arrayHelpers.remove(j)} />
                            </div>
                            <Divider style={{ width: 700 }} />
                          </>
                        }
                      </Fragment>
                      )}
                      <SmallIconButton icon={'Add'} bold="true" size={21} text={i18n('ADD_SECTION')}
                        onClick={() => arrayHelpers.push(
                          {
                            name: '',
                            has_multiple_values: false,
                            field_section_dto: [
                              {
                                is_required: false,
                                existing_field_id: null,
                                future_load_phase_index: null,
                                entity_field_should_filter_by_request_entity: false,
                                text_length_limit: null,
                                new_field_dto: {
                                  field_type: null,
                                  name: "",
                                  ternary_option_name: "", // Joaco 10/6/20: Si vas a usar el tipo de campo si/no/nm aca iria el nombre de la tercera opcion, esto viene de un problema mayor: el tipo de campo si/no/nm no se deberia llamar asi en el pasaje de tiendacheck a tops se definio que en vez de ser "si / no / necesita mejorar" la opcion "necesita mejorar" deberia tener su propio nombre, de ahi que en el back se llama ternario, si va a usar ese tipo de campo tenes que enviar un valor sino podes dejarlo en nulo o vacio.
                                  entity_configuration_id: null,
                                  parameters: []
                                },
                                next_phase_modifier: []
                              }
                            ],

                          }
                        )} />
                    </div>
                  )}
                </FieldArray>

                <div style={{ marginBottom: "25px" }}>

                  {/* Etapa Editable */}
                  <StyledSwitch id={"createReq_stage_" + i + "_can_be_edited"} name={`version_dto.phases_dto[${i}].can_be_edited`}
                    label={i18n('EDITABLE')} horizontal="true" checked={values.version_dto.phases_dto[i].can_be_edited}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].can_be_edited`, e.target.checked);
                      if (e.target.checked === false)
                        setFieldValue(`version_dto.phases_dto[${i}].editable_option`, null);
                    }} />
                  {phase.can_be_edited &&
                    <div style={{ margin: "25px 0" }}>
                      <Select mode="multiple" items={roles.simple_values} error={getIn(errors, `version_dto.phases_dto[${i}].roles_to_edit`)} label={i18n('SOLVER_ROLES')}
                        horizontal="true" width="350px" border="true"
                        value={values.version_dto.phases_dto[i].roles_to_edit} selected={values.version_dto.phases_dto[i].roles_to_edit}
                        onChange={(e) => { setFieldValue(`version_dto.phases_dto[${i}].roles_to_edit`, e) }
                        } />
                    </div>}

                  {/* Derivación */}
                  <StyledSwitch id={"createReq_stage_" + i + "_can_be_derived"} name={`version_dto.phases_dto[${i}].can_be_derived`}
                    label={i18n('DERIVATION')} horizontal="true" checked={values.version_dto.phases_dto[i].can_be_derived}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].can_be_derived`, e.target.checked);
                      if (e.target.checked === false)
                        setFieldValue(`version_dto.phases_dto[${i}].derivation_option`, null);
                    }} />
                  {phase.can_be_derived && <div style={{ margin: "25px 0" }}>
                    <Select items={division} label={i18n('TYPE')} error={getIn(errors, `version_dto.phases_dto[${i}].derivation_option`)}
                      horizontal="true" width="300px" border="true" name={`version_dto.phases_dto[${i}].derivation_option`}
                      value={values.version_dto.phases_dto[i].derivation_option} selected={values.version_dto.phases_dto[i].derivation_option}
                      onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].derivation_option`, e)} />
                  </div>}

                  {/* Revertir */}
                  <StyledSwitch id={"createReq_stage_" + i + "_can_be_reversed"} name={`version_dto.phases_dto[${i}].can_be_reversed`}
                    label={i18n('REVERSE')} horizontal="true" disabled={i === 0 || i === 1 ? true : false}
                    checked={values.version_dto.phases_dto[i].can_be_reversed}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].can_be_reversed`, e.target.checked);
                      if (e.target.checked === false)
                        setFieldValue(`version_dto.phases_dto[${i}].reversion_target_model_phase_index`, null);
                    }} />
                  {phase.can_be_reversed && <div style={{ margin: "25px 0" }}>
                    <Select items={stagesFiltered.filter(item => item.id !== i)} label={i18n('STAGE')} error={getIn(errors, `version_dto.phases_dto[${i}].reversion_target_model_phase_index`)}
                      horizontal="true" width="300px" border="true" name={`version_dto.phases_dto[${i}].reversion_target_model_phase_index`}
                      value={values.version_dto.phases_dto[i].reversion_target_model_phase_index} selected={values.version_dto.phases_dto[i].reversion_target_model_phase_index}
                      onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].reversion_target_model_phase_index`, e)}
                    />
                  </div>}

                  {/* Aprobador */}
                  {values.version_dto.phases_dto[i].approval_type !== null ? values.version_dto.phases_dto[i].has_approval = true : ""}
                  <StyledSwitch id={"createReq_stage_" + i + "_has_approval"}
                    checked={values.version_dto.phases_dto[i].has_approval}
                    label={i18n('APPROVAL')} horizontal="true" name={`version_dto.phases_dto[${i}].has_approval`}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].has_approval`, e.target.checked);
                      if (e.target.checked === true) {
                        setFieldValue(`version_dto.phases_dto[${i}].has_solver`, false)
                        setFieldValue(`version_dto.phases_dto[${i}].resolution_role_id`, null)
                        setFieldValue(`version_dto.phases_dto[${i}].cannot_force_reject`, false)
                      }
                    }} />
                  {phase.has_approval &&
                    <div style={{ margin: "25px 0" }}>
                      <Select items={aprroversDropDown} error={getIn(errors, `version_dto.phases_dto[${i}].approval_type`)} label={i18n('APPROVAL_TYPE')}
                        horizontal="true" width="350px" border="true"
                        selected={values.version_dto.phases_dto[i].approval_type} value={values.version_dto.phases_dto[i].approval_type}
                        onChange={(e) => {
                          setFieldValue(`version_dto.phases_dto[${i}].approval_type`, e)
                          if (e === 0) setFieldValue(`version_dto.phases_dto[${i}].roles`, null)
                        }
                        } />

                      {values.version_dto.phases_dto[i].approval_type === 0 ? values.version_dto.phases_dto[i].roles = null : ''}
                      {
                        phase.approval_type === 1 ?
                          <Select mode="multiple" items={roles.simple_values} label={i18n('SOLVER_ROLES')} horizontal="true"
                            value={values.version_dto.phases_dto[i].roles} selected={values.version_dto.phases_dto[i].roles}
                            width="350px" border="true" placeholder={i18n('SOLVER_ROLES_PLACEHOLDER')} error={getIn(errors, `version_dto.phases_dto[${i}].roles`)}
                            onChange={(e) => { setFieldValue(`version_dto.phases_dto[${i}].roles`, e) }
                            } />
                          : ''}
                    </div>}

                  {/* Resolutor */}
                  {values.version_dto.phases_dto[i].resolution_role_id !== null ? values.version_dto.phases_dto[i].has_solver = true : ""}
                  <StyledSwitch id={"createReq_stage_" + i + "_has__solver"}
                    checked={values.version_dto.phases_dto[i].has_solver}
                    label={i18n('SOLVER')} horizontal="true" name={`version_dto.phases_dto[${i}].has_solver`}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].has_solver`, e.target.checked)
                      if (e.target.checked === true) {
                        setFieldValue(`version_dto.phases_dto[${i}].approval_type`, null)
                        setFieldValue(`version_dto.phases_dto[${i}].has_approval`, false)
                        setFieldValue(`version_dto.phases_dto[${i}].roles`, null)
                      }
                      else {
                        setFieldValue(`version_dto.phases_dto[${i}].resolution_role_id`, null)
                        setFieldValue(`version_dto.phases_dto[${i}].cannot_force_reject`, false)
                      }
                    }} />
                  {
                    phase.has_solver &&
                    <>
                      <div style={{ margin: "25px 0" }}>
                        <Select items={roles.simple_values} label={i18n('SOLVER_ROLES')} horizontal="true"
                          width="350px" border="true" placeholder={i18n('SOLVER_ROLES_ONE_PLACEHOLDER')}
                          error={getIn(errors, `version_dto.phases_dto[${i}].resolution_role_id`)}
                          value={values.version_dto.phases_dto[i].resolution_role_id} selected={values.version_dto.phases_dto[i].resolution_role_id}
                          onChange={(e) => {
                            setFieldValue(`version_dto.phases_dto[${i}].approval_type`, null)
                            setFieldValue(`version_dto.phases_dto[${i}].resolution_role_id`, e)
                          }} />
                      </div>
                      <div style={{ margin: "25px 0" }}>
                        <StyledSwitch id={"createReq_stage_" + i + "_cannot_force_reject"} checked={values.version_dto.phases_dto[i].cannot_force_reject}
                          label={i18n('SOLVER_CANNOT_FORCE_REJECT')} horizontal="true" name={`version_dto.phases_dto[${i}].cannot_force_reject`}
                          onChange={(e) => {
                            setFieldValue(`version_dto.phases_dto[${i}].cannot_force_reject`, e.target.checked);
                          }} />
                      </div>
                    </>
                  }

                  {/* Etapa Finalización Inmediata */}
                  <StyledSwitch id={"createReq_stage_" + i + "finalizer_roles"} name={`version_dto.phases_dto[${i}].finalizer_roles`}
                    label={i18n('IMMEDIATE_FINALIZATION')} horizontal="true"
                    checked={values.version_dto.phases_dto[i].can_be_finalized_early}
                    disabled={i === 0 || i === values.version_dto.phases_dto.length - 1 ? true : false}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].can_be_finalized_early`, e.target.checked)
                      if (e.target.checked === false) {
                        setFieldValue(`version_dto.phases_dto[${i}].can_be_finalized_early`, false)
                      }
                    }}
                  />

                  {phase.can_be_finalized_early &&
                    <div style={{ margin: "25px 0" }}>
                      <Select mode="multiple" items={values.finalizer_roles ? values.finalizer : roles.simple_values} error={getIn(errors, `version_dto.phases_dto[${i}].finalizer_roles`)} label={i18n('SOLVER_ROLES')}
                        horizontal="true" width="350px" border="true"
                        value={values.version_dto.phases_dto[i].finalizer_roles}
                        onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].finalizer_roles`, e)
                        } />
                    </div>
                  }

                  <StyledSwitch id={"createReq_stage_" + i + "_has_alarm"} name={`version_dto.phases_dto[${i}].has_alarm`}
                    label={i18n('ALARM')} horizontal="true" checked={values.version_dto.phases_dto[i].has_alarm}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_alarm`, e.target.checked)} />
                  {
                    values.version_dto.phases_dto[i].alarms_dto = []
                  }

                  <StyledSwitch id={"createReq_stage_" + i + "_has_files"} name={`version_dto.phases_dto[${i}].has_files`}
                    label={i18n('FILE')} horizontal="true" checked={values.version_dto.phases_dto[i].has_files}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_files`, e.target.checked)} />
                  {
                    values.version_dto.phases_dto[i].has_files &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative' }}>
                      <StyledCheckbox key={"createReq_stage_" + i + "_obligatory_file"} value={values.version_dto.phases_dto[i].obligatory_file} textLeft
                        style={{ position: 'relative', right: '-10px', top: '-5px', alignItems: 'center', fontWeight: 'bold' }}
                        checked={values.version_dto.phases_dto[i].obligatory_file}
                        name={"obligatory_file"}
                        onChange={() => {
                          setFieldValue(`version_dto.phases_dto[${i}].obligatory_file`, !values.version_dto.phases_dto[i].obligatory_file)
                        }}
                      >Obligatorio</StyledCheckbox>
                    </div>
                  }

                  <StyledSwitch id={"createReq_stage_" + i + "_has_signature"} name={`version_dto.phases_dto[${i}].has_signature`}
                    label={i18n('SIGNATURE')} horizontal="true" checked={values.version_dto.phases_dto[i].has_signature}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_signature`, e.target.checked)} />

                  {/* Programación - No se encuentra habilitado en la primer etapa*/}
                  <StyledSwitch id={"createReq_stage_" + i + "_can_be_planned"} name={`version_dto.phases_dto[${i}].can_be_planned`}
                    label={i18n('PLANNED_REQUEST')} horizontal="true" disabled={i === 0 ? true : false}
                    checked={values.version_dto.phases_dto[i].can_be_planned}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].can_be_planned`, e.target.checked)} />

                  <StyledSwitch id={"createReq_stage_" + i + "_has_location"} name={`version_dto.phases_dto[${i}].has_location`}
                    label={i18n('LOCATION')} horizontal="true" checked={values.version_dto.phases_dto[i].has_location}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_location`, e.target.checked)} />

                  <StyledSwitch id={"createReq_stage_" + i + "_has_stock"} name={`version_dto.phases_dto[${i}].has_stock`}
                    label={i18n('INVENTORY')} horizontal="true" checked={values.version_dto.phases_dto[i].has_stock}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_stock`, e.target.checked)} />

                  {values.diferential_pdf_type === 0 && <DashboardInput id={"createPhaseSection" + i + "_custom_signature"}
                    name={`version_dto.phases_dto[${i}].custom_signature`} label={i18n('SIGNATURE_NOMENCLATURE')} horizontal="true" border="true" width="350px" onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].custom_signature`, e.target.value)} value={values.version_dto.phases_dto[i].custom_signature} placeholder={"Escribir aquí"} />}

                </div>
                {i > 0 &&
                  <>
                    <div style={{ float: "right", padding: "20px 0 20px 40px" }} >
                      <SmallIconButton icon={"TrashBin"} size={21} text={i18n('DELETE_STAGE')} bold="true" iconColor="white" background="#b9a5fd"
                        onClick={() => {
                          arrayHelpers.remove(i)
                          stagesFiltered.pop()
                        }} />
                    </div>
                    <Divider />
                  </>
                }
              </div>)

          }
          <SmallIconButton bold="true" icon={'Add'} size={21} text={i18n('ADD_STAGE')}
            onClick={() => {
              arrayHelpers.push(
                {
                  name: '',
                  cannot_force_reject: false,
                  can_be_derived: false,
                  derivation_option: null,
                  can_be_reversed: false,
                  reversion_target_model_phase_index: null,
                  checklist_id: null, // Joaco (5/6/20): Por ahora no mandes este campo o mandalo en null, va a ser para cuando se pueda conectar con checklists, todavia no hace nada.
                  has_approval: false,
                  has_alarm: false,
                  has_files: false,
                  has_signature: false,
                  has_location: false,
                  has_stock: false,
                  obligatory_file: false,
                  custom_signature: "",
                  resolution_role_id: null,
                  approval_type: null,
                  can_be_edited: false,
                  roles_to_edit: [],
                  phase_sections_dto: [],
                  roles: null,
                  finalizer_roles: [],
                  can_be_finalized_early: false,
                  alarms_dto: [],
                  can_be_planned: false
                }
              )
              setStages(prev => {
                return [...prev, {
                  id: arrayHelpers.form.values.version_dto.phases_dto.length,
                  name: i18n('STAGE') + " " + (arrayHelpers.form.values.version_dto.phases_dto.length + 1)
                }]
              }
              )
              setStagesFiltered(stages.filter(item => item.id !== arrayHelpers.form.values.version_dto.phases_dto.length))
            }}
          />
        </div>
        )}
    </FieldArray>
  </>)

  if (requestTypes.isFetching === false && typeof requestTypes.valuesById.version_dto !== "undefined" && stages.length === 0) {
    requestTypes.valuesById.version_dto.phases_dto.map((stage, i) => {
      stages.push({ id: i, name: i18n('STAGE') + " " + (i + 1) })
    })

    let filtered = stages.filter(item => item.id !== (requestTypes.valuesById.version_dto.phases_dto.length - 1))
    if (filtered.length !== stagesFiltered.length && filtered.every((value, index) => value !== stagesFiltered[index])) {
      setStagesFiltered(filtered)
    }
  }

  const initialValues = {
    id: id,
    is_active: requestTypes.valuesById.is_active,
    name: requestTypes.valuesById.name,
    businessSelected: requestTypes.valuesById.business_id,
    sectorOwnerSelected: requestTypes.valuesById.sector_id,
    is_public: requestTypes.valuesById.is_public,
    allowed_sectors_ids: requestTypes.valuesById.allowed_sectors_ids === null || requestTypes.valuesById.length === 0 ? [] : requestTypes.valuesById.allowed_sectors_ids,
    description: requestTypes.valuesById.description,
    description_url: requestTypes.valuesById.description_url,
    notifies_state_changes: false, // true cuando quiero notificar
    is_periodic: requestTypes.valuesById.is_periodic, // Joaco (5/6/20): Esto es por el tema de periodicidad, por ahora lo desplazamos asi que mandalo siempre en false.
    can_be_planned: requestTypes.valuesById.can_be_planned,
    hasRelatedRequest: requestTypes.valuesById.related_request_model_id === null ? false : true,
    related_request_model_id: requestTypes.valuesById.related_request_model_id,
    has_expiration_date: false,
    can_be_edited: requestTypes.valuesById.can_be_edited,
    roles_to_edit: requestTypes.valuesById.roles_to_edited,
    diferential_pdf_type: requestTypes.valuesById.diferential_pdf_type,
    should_update_related_request_model_id: true,
    is_expiration_model: false,
    should_be_accesible_from_request_creation_drop_list: requestTypes.valuesById.should_be_accesible_from_request_creation_drop_list,
    version_dto: {
      phases_dto: typeof requestTypes.valuesById.version_dto !== "undefined" ? requestTypes.valuesById.version_dto?.phases_dto : [],
      entity_configuration_ids: typeof requestTypes.valuesById.version_dto !== "undefined" ? requestTypes.valuesById.version_dto?.entity_configuration_ids : [],
      urgency_type: requestTypes.valuesById.version_dto?.urgency_type,
      tables_have_total_column: requestTypes?.valuesById?.version_dto?.tables_have_total_column
    }
  }


  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    return result
  }

  const reorderSectionsPopup = (data, setFieldValue) => {
    let currentData = [].concat(data)

    return <Popup colorTheme={theme.colors.user.checklists} title={i18n('USER').CHECKLISTS.CREATE_EDIT.REORDER_SECTIONS} scrolleable>
      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-around', alignItems: "center" }}>
        <div className="popup-sections" style={{ border: '1px solid #b8c1ca', overflowY: 'auto', width: '100%', maxHeight: 400, marginBottom: "40px" }}>
          <div style={{ fontSize: 18, textAlign: "center" }}>
            <DragDropContext onDragEnd={result => {
              const { source, destination } = result

              if (!destination) {
                return;
              }
              if ((source.index === destination.index) && (source.droppableId === destination.droppableId)) {
                return;
              }

              currentData = reorder(currentData, source.index, destination.index)

            }}>
              <Droppable droppableId="sections">
                {(provided) => (
                  <ul className="sections" {...provided.droppableProps} ref={provided.innerRef}>
                    {currentData.map((item, index) => (
                      <Draggable key={item.name} draggableId={item.name} index={index}>
                        {(provided) => (
                          <div className="section" ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}>
                            <div style={{ display: 'flex' }}>
                              <p>{item.name}</p>
                            </div>

                            <Icon color="#704dfb" name="Arrows" style={{ padding: 10 }} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CancelButton small="true" type="button" color={"#1b5c8f"} background="white" style={{ border: "1px solid #1b5c8f" }} onClick={() => setShowReorderSectionsPopup({ status: false, index: null })}>
            {i18n("CANCEL")}
          </CancelButton>
          <ConfirmationButton small="true" type="button" background={"#1b5c8f"} color={"white"}
            onClick={() => {
              setFieldValue(`version_dto.phases_dto[${showReorderSectionsPopup.index}].phase_sections_dto`, currentData)
              data = currentData
              setShowReorderSectionsPopup({ status: false, index: null })
            }}>{i18n("SAVE_CHANGES")}</ConfirmationButton>
        </div>
      </div>
    </Popup>
  }

  const reorderItemsPopup = (data, setFieldValue) => {

    let currentData = [].concat(data)

    return <Popup colorTheme={theme.colors.user.checklists} title={"Reordenar campos"}>
      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'space-around', alignItems: "center" }}>
        <div className="popup-items" style={{ border: '1px solid #b8c1ca', overflowY: 'auto', width: '100%', maxHeight: 400, marginBottom: "40px" }}>
          <div style={{ fontSize: 18, textAlign: "center" }}>
            <DragDropContext onDragEnd={result => {
              const { source, destination } = result

              if (!destination) {
                return;
              }
              if ((source.index === destination.index) && (source.droppableId === destination.droppableId)) {
                return;
              }

              currentData = reorder(currentData, source.index, destination.index)

            }}>
              <Droppable droppableId="items">
                {(provided) => (
                  <div className="items" {...provided.droppableProps} ref={provided.innerRef}>
                    {currentData.map((item, index) => (
                      <Draggable key={item.new_field_dto.name} draggableId={item.new_field_dto.name} index={index}>
                        {(provided) => (
                          <div className='item' ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}>
                            <div style={{ display: 'flex' }}>
                              <p>{item.new_field_dto.name}</p>
                            </div>

                            <Icon name="Arrows" style={{ padding: 10 }} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <CancelButton small="true" type="button" color={"#1b5c8f"} background="white" style={{ border: "1px solid #1b5c8f" }} onClick={() => setShowReorderItemsPopup({ status: false, phaseIndex: null, index: null })}>
            {i18n("CANCEL")}
          </CancelButton>
          <ConfirmationButton small="true" type="button" background={"#1b5c8f"} color={"white"}
            onClick={() => {
              setFieldValue(`version_dto.phases_dto[${showReorderItemsPopup.phaseIndex}].phase_sections_dto[${showReorderItemsPopup.index}].field_section_dto`, currentData)
              data = currentData
              setShowReorderItemsPopup({ status: false, phaseIndex: null, index: null })
            }}>{i18n("SAVE_CHANGES")}</ConfirmationButton>
        </div>
      </div>
    </Popup>
  }


  if (requestTypes.isFetching || business.isFetching || sectors.isFetching) {
    return <Loader />
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').REQUEST_TYPE.LABEL, i18n('SIDEBAR_LINKS').EDIT_REQUEST_TYPE.LABEL]} >
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').EDIT_REQUEST_TYPE} />
        <MessageBanner visible={error} status="error" message={requestTypes.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {requestTypes.isSubmitting && <Loader />}
      <Formik initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="650px">
                <div style={{ marginBottom: "30px" }}>
                  <StyledSwitch id="editRequestType_isActive" onChange={() => setFieldValue('is_active', !values.is_active)}
                    checked={values.is_active} name="is_active" label={i18n('ACTIVE')} horizontal="true" />
                </div>
                {descriptionInput(values, handleChange)}
                {linkInput(values, handleChange)}
                {nameInput(values, handleChange, errors)}
                {businessSelect(values, setFieldValue, errors)}
                {sectorOwnerSelect(values, setFieldValue, errors)}
                {publicPrivateButtons(setFieldValue, values)}
                {allowedSectorsIdsCheckboxes(setFieldValue, values)}
                {entityConfigurationIdsCheckboxes(values, errors)}
                {plannedSelfButtons(setFieldValue, values)}


                <div style={{ marginBottom: "25px" }}>
                  {relatedRequest(setFieldValue, values, errors)}
                  { // arreglamos con orne dejarlo para mas adelante
                /* <StyledSwitch id="createReq_hasSpreadSheet" checked={values.hasSpreadSheet} label={i18n('SPREADSHEET')} horizontal="true" name="hasSpreadSheet" value="hasSpreadSheet"
                  onChange={(e) => setFieldValue("hasSpreadSheet", e.target.checked)} />
                <StyledSwitch id="createReq_hasResources" checked={values.hasResources} label={i18n('RESOURCES')} horizontal="true" name="resources"
                  onChange={(e) => setFieldValue("hasResources", e.target.checked)} />
                {values.hasResources && <div style={{ margin: "25px 0" }}>
                  <Select items={[]} label={i18n('RELATED_RESOURCES')} horizontal="true" width="300px" border="true" />
                </div>}
              */}
                  {expirationDate(setFieldValue, values)}
                  {pdfType(setFieldValue, errors, values)}
                  {urgencyNomenclatures(setFieldValue, errors, values)}
                  {tableView(setFieldValue, errors, values)}
                </div>
                {stageSection(handleChange, setFieldValue, values, errors)}
              </DashboardContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
              {showReorderSectionsPopup.status && reorderSectionsPopup(values.version_dto.phases_dto[showReorderSectionsPopup.index].phase_sections_dto, setFieldValue)}
              {showReorderItemsPopup.status && reorderItemsPopup(values.version_dto.phases_dto[showReorderItemsPopup.phaseIndex].phase_sections_dto[showReorderItemsPopup.index].field_section_dto, setFieldValue)}
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default EditRequestType