import React, { useState } from 'react'
import { PaginationContainer, Prev, Next, PageNumber } from './styled'
import Icon from '../Icons'
import IconNames from './../Icons/iconNames';
import { theme } from '@tops/global_config';
import { Input } from '../Input';
import { ConfirmationButton } from '../Button';

export const Pagination = ({ currentPage, colorTheme, onPrev, onNext, itemsLength, take = 10 }) => {
  return <PaginationContainer>
    <Prev onClick={currentPage === 1 ? null : onPrev}>
      <Icon name={IconNames['Back']} size={30} color={currentPage === 1 ? theme.colors.gray : colorTheme ? colorTheme.primary : theme.colors.darkGray} />
    </Prev>
    <PageNumber active={true} colorTheme={colorTheme}>{currentPage}</PageNumber>
    <Next onClick={itemsLength >= take ? onNext : null} disabled={itemsLength < take} take={take}>
      <Icon name={IconNames['Back']} size={30} color={itemsLength < take ? theme.colors.gray : colorTheme ? colorTheme.primary : theme.colors.darkGray} />
    </Next>
  </PaginationContainer>
}

export const PaginationWithCount = ({ currentPage, setCurrentPage, colorTheme, onPrev, onNext, itemsLength, take = 10, count, setExecuteUseEffect }) => {
  const [countToSetPage, setCountToSetPage] = useState("")
  const [errorCount, setErrorCount] = useState(false)
  

  return <PaginationContainer>
    <Prev onClick={currentPage === 1 ? null : onPrev}>
      <Icon name={IconNames['Back']} size={33} color={currentPage === 1 ? theme.colors.gray : colorTheme ? colorTheme.primary : theme.colors.darkGray} />
    </Prev>

    {(parseInt(currentPage) - 1 !== 0 && parseInt(currentPage) - 1 !== parseInt(currentPage)) && <>
      <PageNumber>1</PageNumber>
      <div style={{ margin: "0 10px" }}>...</div>
    </>}
    {(parseInt(currentPage) - 1 !== 0 && parseInt(currentPage) - 1 !== parseInt(currentPage)) && <PageNumber>{parseInt(currentPage) - 1}</PageNumber>}

    <PageNumber active={true} colorTheme={colorTheme}>{parseInt(currentPage)}</PageNumber>

    {(parseInt(currentPage) !== count) && <>
      <PageNumber>{parseInt(currentPage) + 1}</PageNumber>
      <div style={{ margin: "0 10px" }}>...</div>
      <PageNumber>{count}</PageNumber>
    </>}

    <Next onClick={(itemsLength >= take && parseInt(currentPage) !== count) ? onNext : null} disabled={parseInt(currentPage) === count} take={take}>
      <Icon name={IconNames['Back']} size={33} color={(itemsLength < take || parseInt(currentPage) === count) ? theme.colors.gray : colorTheme ? colorTheme.primary : theme.colors.darkGray} />
    </Next>

    {(count > 0 && count > 1) && <>
      <Input width="55px" color={colorTheme.primary} value={countToSetPage} style={{ borderColor: errorCount && theme.colors.red, marginLeft: 5 }}
        onChange={(e) => setCountToSetPage(e.target.value)} />
      <ConfirmationButton type="button" style={{ minWidth: 70, marginLeft: 20, marginBottom: 10 }} disabled={countToSetPage === ""} small={true} color={colorTheme.primary} background={colorTheme.secondary}
        onClick={() => {
          if (countToSetPage !== 0 && countToSetPage <= count) {
            setCurrentPage(parseInt(countToSetPage))
            setErrorCount(false)
            setExecuteUseEffect !== undefined && setExecuteUseEffect(false)
          } else {
            setErrorCount(true)
            setExecuteUseEffect !== undefined && setExecuteUseEffect(false)
          }
        }}>Ir</ConfirmationButton>
    </>}

  </PaginationContainer>
}