import React from 'react'
import { i18n } from '@tops/services'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ViewRequestTypes from './ViewRequestTypes'
import CreateRequestType from './CreateRequestType'
import EditRequestType from './EditRequestType'


const RequestTypes = ({ savedInfo }) => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <ViewRequestTypes {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_REQUEST_TYPE.LINK}`}
          render={(props) => <CreateRequestType {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_REQUEST_TYPE.LINK}/:id`}
          render={(props) => <EditRequestType {...props} savedInfo={savedInfo} />}
        />
      </Switch>
    </>
  )
}

export default RequestTypes
