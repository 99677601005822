import React from 'react'
import styled from 'styled-components'
import Icon from '../Icons'
import { i18n } from '@tops/services';

const Banner = styled.div`
  background: ${({ theme, status }) => status === 'success' ? theme.colors.darkGreen : status === 'error' ? theme.colors.red : theme.colors.lilac};
  width: ${({ width }) => width ? width : '100%'};
  min-height: ${({ visible }) => visible ? '40px' : '0'};
  max-height: ${({ visible }) => visible ? '100%' : '0'};
  opacity: ${({ visible }) => visible ? '1' : '0'};
  left: 0;
  padding: 0 15px;
  border-radius: ${({ multipleErrors, tokenExpired }) => multipleErrors ? tokenExpired ? '5px' : '0px' : '0 0 5px 5px;'};
  color: white;
  font-size: 14px;
  display: flex;
  margin-top: ${({ multipleErrors, tokenExpired }) => multipleErrors ? tokenExpired ? '0px' : '3px' : '0px'};
  justify-content: center;
  align-items: center;
  transition: min-height 0.3s ease-in-out;
  overflow:hidden;
  margin-bottom: ${({visible}) => visible ? '30px' : '0px'}
`

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: ${({sharedDevice}) =>  sharedDevice ?    'center'  : 'space-between'} 
`

const LinkContainer = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const TextContainer = styled.div`
  display:flex;
`



export const MessageBanner = ({ message, visible, onAccept, status, width, multipleErrors, sharedDevice, tokenExpired, ...props  }) => (
  <Banner visible={visible} status={status} width={width} multipleErrors={multipleErrors} tokenExpired={tokenExpired} {...props}>
    <ContentContainer sharedDevice={sharedDevice}>
      <TextContainer>
        <Icon name={status === 'success' ? 'Success' : status === 'error' ? 'Error' : 'Info'} />
        <div style={{ marginLeft: 15, marginRight: 10 }}>
          {message}
        </div>
      </TextContainer>

      {onAccept && <LinkContainer onClick={onAccept}>
        {i18n('ACCEPT')}
      </LinkContainer>}

    </ContentContainer>
  </Banner>
)