import React from 'react'

import Header from '../Header'
import PageBlock from '../PageBlock'

import { PageContainer, PageWrapper } from './styled'

export const Page = ({ children, logout, ...otherProps }) => (
  <PageContainer {...otherProps}>
    {otherProps.withHeader && <Header {...otherProps} logout={logout} />}
    <PageWrapper {...otherProps}>
      {children}
    </PageWrapper>
  </PageContainer>
)