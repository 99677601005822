import React from 'react'
import styled from 'styled-components'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  height: 100%;
`

const Wrapper = styled.div`
  position: absolute;
  top:0;
  left: 0;
  background: white;
  height: 100vh;
  width: 100vw;
  opacity: 0.7;
  z-index: 11000;
`

export const Loader = () => {
  return (
    <Wrapper>
      <LoaderWrapper>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50, color: 'black' }} spin />} size='large' />
      </LoaderWrapper>
    </Wrapper>
  )
}
