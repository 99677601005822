import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { i18n } from '@tops/services'
import ViewRoles from './ViewRoles/index';
import CreateRoles from './CreateRoles/index';
import EditRoles from './EditRoles/index';

const Roles = ({ savedInfo }) => {

  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <ViewRoles {...props} savedInfo={savedInfo} />}
      />
      <Route
        exact
        path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_ROLE.LINK}`}
        render={(props) => <CreateRoles {...props} savedInfo={savedInfo} />}
      />
      <Route
        exact
        path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_ROLE.LINK}/:id`}
        render={(props) => <EditRoles {...props} savedInfo={savedInfo} />}
      />
    </Switch>
  )

}

export default Roles