import React from 'react'
import { i18n } from '@tops/services'
import { ConfirmationButton } from '../Button'
import { Container, MessageWrapper, Title, CloseButton, TitleWrapper, ChildrenContainer } from './styled'
import Icon from '../Icons'
import IconNames from '../Icons/iconNames';

export const Popup = ({ title, children, message, onClose, scrolleable, needFix, error, viewIcon, noPadding, colorScroll, ...props }) => {
  return (
    <Container needFix={needFix}>
      <MessageWrapper {...props} scrolleable={scrolleable}>
        <TitleWrapper title={title}>
          <Title>
            {title}
          </Title>
          {onClose &&
          <CloseButton onClick={onClose} style={{ display: viewIcon === "" ? 'block' : viewIcon }}>
            <Icon name={IconNames['Close']} />
          </CloseButton>
          }
        </TitleWrapper>
        {error}

        <ChildrenContainer trainingExternalUser={props.trainingExternalUser} scrolleable={scrolleable} noPadding={noPadding} colorScroll={colorScroll}>
          {children}
        </ChildrenContainer>


      </MessageWrapper>
    </Container>
  )
}