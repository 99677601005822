import React from 'react'
import styled from 'styled-components'
import Icon from './../Icons/';
import IconNames from './../Icons/iconNames';
import { Link } from 'react-router-dom';
import { i18n } from '@tops/services';

const BreadcrumbContainer = styled.ol`
  display: flex;
  align-items: center;
  list-style: none;
  padding-top: 15px;
  font-weight: bold;
`

const BreadcrumbItem = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme, separatorColor, lastIndex }) => lastIndex ? separatorColor : theme.colors.darkGray}
  }
`

const Separator = styled.div`
  transform: rotate(0.5turn);
  margin: 0 5px;
`

const LinkItem = styled(Link)`
  color: ${({ theme }) => theme.colors.darkGray};

  &:hover {
    color: inherit;
  }

  &::after {
    content: '';
    width: 100%;
    height: 1.5px;
    display: block;
    background: ${({ theme }) => theme.colors.darkGray};
    opacity:0;
    transition: 300ms;
  }
  
  &:hover::after {
    opacity: 1;
  }

`

export const Breadcrumbs = ({ items, separatorColor, location }) => {

  return (
    <BreadcrumbContainer>
      {items.map((e, index) => {
        const lastIndex = index === items.length - 1
        let links = []

        if (location !== undefined) {
          const paths = location.pathname.split('/').filter(i => i !== "" && i !== "dashboard")

          links = location.takeTo !== undefined ? location.takeTo : `/dashboard/${paths.slice(0, index + 1).join('/')}`

          if (paths.some((path) => path === i18n('SIDEBAR_LINKS').SUB_SIDEBAR_LINKS_AUDITS.AUDITS.LINK)) {
            links = `/dashboard/${paths.slice(0, index + 2).join('/')}`
          }
        }

        return <BreadcrumbItem key={index} separatorColor={separatorColor} lastIndex={lastIndex}>
          {!lastIndex && e.toLowerCase() !== "wise" && location !== undefined ? <LinkItem to={links}>{e}</LinkItem> : <li>{e}</li>}
          {!lastIndex && <Separator><Icon name={IconNames['Back']} color={separatorColor} size={40} /></Separator>}
        </BreadcrumbItem>
      })}
    </BreadcrumbContainer>
  )
}