import React from 'react'
import { PageBlockWrapper, PageBlockContainer, PageBlockHeader } from './styled'


export const PageBlock = ({ children, withHeader, title, ...otherProps }) => (
  <PageBlockWrapper {...otherProps}>
    {
      title && <PageBlockHeader>
        {title}
      </PageBlockHeader>
    }

    <PageBlockContainer>
      {children}
    </PageBlockContainer>
  </PageBlockWrapper>
)