import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { i18n } from '@tops/services'
import ViewAccountLevels from '../../../pages/Dashboard/AccountLevels/ViewAccountLevels'
import CreateAccountLevels from '../../../pages/Dashboard/AccountLevels/CreateAccountLevels'
import EditAccountLevels from '../../../pages/Dashboard/AccountLevels/EditAccountLevels'

const AccountLevels = ({ savedInfo }) => {

  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <ViewAccountLevels {...props} savedInfo={savedInfo} />}
      />
      <Route
        exact
        path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_LEVEL.LINK}`}
        render={(props) => <CreateAccountLevels {...props} savedInfo={savedInfo} />}
      />
      <Route
        exact
        path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_LEVEL.LINK}/:id`}
        render={(props) => <EditAccountLevels {...props} savedInfo={savedInfo} />}
      />
    </Switch>
  )

}

export default AccountLevels