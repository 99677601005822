import React, { useState, useEffect } from 'react'
import { i18n, locale } from '@tops/services'
import { theme } from '@tops/global_config'
import {
  ListWithDetails, Button, Container, Select, ListItem, PageTitleDashboard, Loader, Pagination, MessageBanner, IconButton, RangePicker
} from '@tops/components'

import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllBusiness } from '../../../../actions/business'
import { fetchSectors, fetchSectorsByBusiness } from '../../../../actions/sectors'
import { fetchRequestType, fetchAllRequestModels, fetchRequestExcel, fetchRequestTypeById, fetchRequestTypeByBuisness,fetchRequestTypeWithoutResults } from '../../../../actions/requestTypes'
import { fetchSectorsByAccount } from '../../../../actions/sectors'
import { Tag } from 'antd'
import { toLocaleDate } from '@tops/utils'
import moment from 'moment'

const ViewRequestTypes = ({ history, savedInfo }) => {
  const { url } = useRouteMatch()

  const sectors = useSelector(state => state.sectors)
  const business = useSelector(state => state.business)
  const requestTypes = useSelector(state => state.requestTypes)
  


  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState(false)
  const [isExpirationModel, setIsExpirationModel] = useState(false)
  const [active, setActive] = useState(true)
  const [businessSelected, setBusinessSelected] = useState(business.businessId)
  const [sectorId, setSectorId] = useState(sectors.sectorsId)
  const [versions, setVersions] = useState([])
  const [filters, setFilters] = useState({})
  
  
 




  const dispatch = useDispatch()

  const urgencyData = [
    { id: 0, name: i18n('USER').REQUESTS.VIEW.URGENCIES.LOW },
    { id: 1, name: i18n('USER').REQUESTS.VIEW.URGENCIES.MEDIUM },
    { id: 2, name: i18n('USER').REQUESTS.VIEW.URGENCIES.HIGH },
  ]

  const requestState = [
    { id: 0, name: i18n('USER').REQUESTS.VIEW.STATUS.PENDING },
    { id: 1, name: i18n('USER').REQUESTS.VIEW.STATUS.FINALIZED },
    { id: 2, name: i18n('USER').REQUESTS.VIEW.STATUS.REJECTED }
  ]

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchSectorsByAccount(savedInfo.id))
        dispatch(fetchAllBusiness(savedInfo.id))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
    return () => { }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(!sectorId) {
          dispatch(fetchRequestTypeByBuisness(currentPage, savedInfo.id, active, isExpirationModel, businessSelected, sectorId))
          return
        }
        
        dispatch(fetchRequestType(currentPage, savedInfo.id, active, isExpirationModel, sectorId))
        
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
    return () => { }
  }, [currentPage, active])

  const handleBusinessChange = (value) => {
    if(!isNaN(value)){
      setSectorId(null)
      setBusinessSelected(value)
      dispatch(fetchSectorsByBusiness(savedInfo.id, value))
    }
  }

  const handleSectorChange = (value) => {
    if(!isNaN(value))
    {
      dispatch(fetchAllRequestModels(value))
      setSectorId(value)
    }
  }

  const handleActive = () => {
    setActive(!active)
  }

  const submitSearch = () => {
    if(!sectorId && sectors.values.length === 0) {
      setCurrentPage(1)
      dispatch(fetchRequestTypeWithoutResults(currentPage, savedInfo.id, active, isExpirationModel))
      return
    } if (!sectorId){
      dispatch(fetchRequestTypeByBuisness(currentPage, savedInfo.id, active, isExpirationModel, businessSelected, sectorId))
      setCurrentPage(1)
      return
    }
    setCurrentPage(1)
    dispatch(fetchRequestType(currentPage, savedInfo.id, active, isExpirationModel, sectorId))
    
    
  }

  const clearSearch = () => {
    setBusinessSelected(null)
    setSectorId(null)
    setFilters({})
    setCurrentPage(1)
    dispatch(fetchRequestType(currentPage, savedInfo.id, active, isExpirationModel))
    
  }

  const fetchExcel = async () => {
    await dispatch(fetchRequestExcel(sectorId, currentPage, filters)).then((data) => {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ExcelSolicitudes_${toLocaleDate(new Date(), locale.DEFAULT_LOCALE)}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }, () =>  setError(true))
  }

 


  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').REQUEST_TYPE.LABEL]}>
        <IconButton size={35} background={theme.colors.primary} icon={'Add'} text={i18n('SIDEBAR_LINKS').CREATE_REQUEST_TYPE.LABEL} bold="true" textSize={14} onClick={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').CREATE_REQUEST_TYPE.LINK}`)} />
        <MessageBanner visible={error} status="error" message={requestTypes.error} onAccept={() => setError(false)} />
      </PageTitleDashboard>
      <Container flexDirection="row" justifyContent="flex-start">
        <Select items={business.values} placeholder={i18n('SELECT_BUSINESS')} onChange={handleBusinessChange} width="250px"
          value={businessSelected} border="true" name="businessSelected" showSearch filterOption={true} optionFilterProp="children"  filtradoItem/>
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Select items={businessSelected ? sectors.allSectorsByBusiness : undefined} placeholder={i18n('SELECT_SECTOR')} onChange={handleSectorChange} width="250px"
            value={sectorId} border="true" name="sectorSelected" showSearch filterOption={true} optionFilterProp="children" disabled={businessSelected ? false : true} />
        </div>
        <Button style={{ marginTop: "-12px" }} small="true" onClick={() => submitSearch()} >Filtrar</Button>
        <Button style={{ marginTop: "-12px" }} small="true" onClick={() => clearSearch()} >Borrar filtros</Button>
      </Container>
      {/* <form onSubmit={(e) => submitSearch(e, filters)}>
        <Container width="100%" borderBottom={true}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ marginRight: 10}}>
              <Select horizontal="true" width="250px" placeholder="Tipo de solicitud" items={requestTypes.allRequestModels}
                showSearch filterOption={true} optionFilterProp="children" onChange={(val) => {
                  if(!isNaN(val)){
                    dispatch(fetchRequestTypeById(val, false)).then(response =>{
                      setVersions([])
                      if(response?.data?.version_dto?.version !== 1)
                      {
                        let v = []
                        for(let i = response?.data?.version_dto?.version; i > 0 ; i--)
                        { 
                          v.push({id:i, name: i})
                        }
                        setVersions(v)
                      }
                      else{
                        setVersions([{id:1, name: response?.data?.version_dto?.version}])
                      }
                    })
                    setFilters({ ...filters, "optional-request-model-filter": val })
                  }
                }} value={filters['optional-request-model-filter']} />
            </div>
            <div style={{ marginRight: 10 }}>
              <Select horizontal="true" width="150px" placeholder="Versión" items={versions}
                onChange={(val) => setFilters({ ...filters, "version-id": val })} value={filters['version-id']} />
            </div>
            <div style={{ marginRight: 10 }}>
              <RangePicker width="230px"  
                onChange={(_, date) => setFilters({ ...filters, "optional-date-from-filter": date[0], "optional-date-to-filter": date[1] })}
                value={filters['optional-date-from-filter'] === undefined ? [] :
                  [moment(filters['optional-date-from-filter'], "YYYY/MM/DD").isValid() ? moment(filters['optional-date-from-filter'], "YYYY/MM/DD") : "", moment(filters['optional-date-to-filter'], "YYYY/MM/DD").isValid() ? moment(filters['optional-date-to-filter'], "YYYY/MM/DD") : ""]}
              />
            </div>
            <div style={{ marginRight: 10 }}>
              <Select horizontal="true" width="150px" placeholder="Estado" items={requestState}
                onChange={(val) => setFilters({ ...filters, "optional-state-filter": val })} value={filters['optional-state-filter']} />
            </div>
            <div style={{ marginRight: 5 }}>
              <Select horizontal="true" width="150px" placeholder="Urgencia" items={urgencyData}
                onChange={(val) => setFilters({ ...filters, "optional-urgency-filter": val })} value={filters['optional-urgency-filter']} />
            </div>
            <Button type="button" small="true" style={{ marginBottom: "10px" }} onClick={() => fetchExcel()} >Exportar a Excel</Button>
          </div>

        </Container>
      </form> */}

      <div style={{ textAlign: "end", marginTop: 15 }} >
        <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
        <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
      </div>

      {
        sectors.isFetching || business.isFetching || requestTypes.isFetching ?
          <Loader /> :
          requestTypes.values.length === 0  ?
            <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div> :
            <ListWithDetails>
              {requestTypes.values.map((item) =>
                <ListItem isRequest key={item.id} item={item} action={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').EDIT_REQUEST_TYPE.LINK}/${item.id}`)} />)}
            </ListWithDetails>
      }
      <Pagination currentPage={currentPage} itemsLength={requestTypes.values.length}
        onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} />
    </>
  )
}

export default ViewRequestTypes
