import React from 'react'
import { i18n } from '@tops/services'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ViewWiseConfiguration from './ViewWiseConfiguration'
import CreateWiseConfiguration from './CreateWiseConfiguration'
import EditWiseConfiguration from './EditWiseConfiguration'
import StandardConfiguration from './StandardConfiguration'
import AdvancedAccidentSettings from './AdvancedAccidentSettings'


const Wise = ({ savedInfo }) => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <ViewWiseConfiguration {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').WISE_CREATE_CONFIGURATION.LINK}`}
          render={(props) => <CreateWiseConfiguration {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').WISE_EDIT_CONFIGURATION.LINK}/:id`}
          render={(props) => <EditWiseConfiguration {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').WISE_EDIT_CONFIGURATION.LINK}/:id/configuracion-estandar`}
          render={(props) => <StandardConfiguration {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').WISE_EDIT_CONFIGURATION.LINK}/:id/configuración-avanzada-de-accidente`}
          render={(props) => <AdvancedAccidentSettings {...props} savedInfo={savedInfo} />}
        />
      </Switch>
    </>
  )
}

export default Wise
