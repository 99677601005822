import React, { useState } from 'react'
import { theme } from '@tops/global_config'
import Icon, { IconNames } from './../Icons';
import { Button } from '../Button';
import { i18n } from '@tops/services';
import styled, { keyframes } from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const Container = styled.div`
  display:flex;
  width: 170px;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  font-size: 14px;
  ${mediaQueries.mobile`
    flex-direction: column;
    width: ${({ width }) => width};
    max-width:100%;
  `}
`
export const StyledInput = styled.input`
  border: none;
  overflow: hidden;
  text-align: center;
`
export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.darkGray};
  line-height: 40px;
  width: 100%;
`
export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100px;
  position: relative;
  border: ${({ theme, border, borderColor, hasError }) => !hasError ? `1px solid ${theme.colors.gray}` : `1px solid ${theme.colors.red}`} ;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 14px;
  color: ${({ textColor, theme }) => textColor || theme.colors.black};
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'inherit')};
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.2s ease-out;
  margin-bottom: 8px;
  border-radius: 6px;
  overflow: hidden;
`
export const BackgroundColor = styled.div`
  background: ${({ theme, color }) => color ? color : theme.colors.black};
  height: 37px;
  border-radius: 0 6px 6px 0;
  width: 45px;
`
const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const ErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 14px;
  animation:${({ hasError }) => hasError ? fadeInDown : fadeInUp} 0.3s ease;
`


export const TrafficLights = props => {

  return (<>
    <Container horizontal={props.horizontal} {...props}>
      {props.label &&
        <Label>
          {props.label}
          {props.isRequired && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
        </Label>
      }
      <div style={{ flexDirection: "column" }}>
        <InputContainer hasError={props.error}>
          <StyledInput {...props} placeholder={props.placeholder} />
          <div style={{ position: "absolute", right: "48px", top: "8px" }}>%</div>
          <BackgroundColor color={props.color} />
        </InputContainer >
      </div>

    </Container>
    {props.error && <ErrorMessage hasError={props.error} >{props.error}</ErrorMessage>}
  </>
  )
}