import React from 'react'
import styled from 'styled-components'

const ItemContainer = styled.div`
  background-color: ${({ theme, background }) => background || theme.colors.primary};
  cursor: pointer;
  color: ${({ theme, color }) => color || theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.lato};
  border: none;
  max-width: 300px;
  width: 100%;
  height: 250px;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0px 7px 3px -3px rgba(0,0,0,0.30);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
  margin: 15px;
`

const Item = ({ title, children, ...props }) => (
  <ItemContainer {...props}>
    {children}
    {title}
  </ItemContainer>
)

export default Item