import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const PageTitle = styled.div`
  font-size: 32px;
  margin: 45px 0;
  text-align:center;
  ${mediaQueries.mobile`
  margin: 10px 0;
`}
`
export const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.mobile`
    flex-direction: column;
`}
`