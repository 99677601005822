const sessionKey = '@tops'
const userKey = `${sessionKey}/user`
const tokenKey = `${sessionKey}/token`
const refreshTokenKey = `${sessionKey}/refresh-token`

// Read section

export const isUserLoggedIn = () => getToken()

export const isSuperUserLoggedIn = () => isUserLoggedIn() && getUserInfo().is_super_user

export const isWiseAdminLoggedIn = () => isUserLoggedIn() && getUserInfo().is_wise_admin

export const isWebAdminLoggedIn = () => isUserLoggedIn() && getUserInfo().is_web_admin

export const isTrainingAdminLoggedIn = () => isUserLoggedIn() && getUserInfo().is_training_admin

export const isTrainingChiefLoggedIn = () => isUserLoggedIn() && getUserInfo().is_training_chief

export const isTrainingInstructorLoggedIn = () => isUserLoggedIn() && getUserInfo().is_training_instructor

export const isTrainingOrganizerLoggedIn = () => isUserLoggedIn() && getUserInfo().is_training_organizer

export const isRegularUserLoggedIn = () => getUserInfo().is_web_admin === false && getUserInfo().is_wise_admin === false && getUserInfo().is_sector_admin === false

export const isWiseTeamMember = () => isUserLoggedIn() && getUserInfo().is_team_member

export const isSectorAdmin = () => isUserLoggedIn() && getUserInfo().is_sector_admin

export const canAccessProgressiveDiscipline = () => isUserLoggedIn() && getUserInfo().can_access_progressive_discipline

export const getUserInfo = () => getCacheObject(userKey)

export const getToken = () => getCacheString(tokenKey)

export const getRefreshToken = () => getCacheString(refreshTokenKey)

export const isProgressiveDisciplineReadonly = () => isUserLoggedIn() && getUserInfo().is_progressive_discipline_readonly

export const isAuditsReadonly = () => isUserLoggedIn() && getUserInfo().is_audits_readonly

export const cacheReducerReader = () => {
  const cachedReducers = {}

  const cachedUser = getUserInfo()
  if (cachedUser) cachedReducers['user'] = cachedUser

  return cachedReducers
}

// Write section

export const saveLocalUserInfo = (user, token, refreshToken) => {
  saveObject(userKey, user)
  saveToken(token)
  saveRefreshToken(refreshToken)
}

export const clearLocalUserInfo = () => {
  localStorage.removeItem(userKey)
  localStorage.removeItem(tokenKey)
  localStorage.removeItem(refreshTokenKey)
  localStorage.removeItem('sector')
  localStorage.removeItem('tools')
  localStorage.removeItem('layout')
  localStorage.removeItem('myDashSession')
  localStorage.removeItem('wiseConfiguration')
  localStorage.removeItem('locale')
  localStorage.removeItem('TOKEN_TIMESTAMP')
  localStorage.removeItem('sector_name')
  localStorage.removeItem('business_data')
  }

export const saveToken = token => saveString(tokenKey, token)

export const saveRefreshToken = refreshToken => saveString(refreshTokenKey, refreshToken)

// Utils

const saveString = (key, string) => localStorage.setItem(key, string)

const getCacheString = key => localStorage.getItem(key)

const saveObject = (key, object) => localStorage.setItem(key, JSON.stringify(object))

const getCacheObject = key => JSON.parse(localStorage.getItem(key))
