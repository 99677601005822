const getBaseUrl = () => {
  // PROD ENVIROMENT
  if (process.env.REACT_APP_ENVIROMENT === 'prod') {
    return 'https://back.terrand.app/api'
  }
  // UAT ENVIROMENT
  if (process.env.REACT_APP_ENVIROMENT === 'uat') {
    return 'https://back-uat.terrand.app/api'
  }
  // QA ENVIROMENT
  if (process.env.REACT_APP_ENVIROMENT === 'qa') {
    // return 'https://qa-topsbackend.terrand-audits.com/api'
    return 'https://back-qa.terrand.app/api'
  }
  // DEV ENVIROMENT
  if (process.env.REACT_APP_ENVIROMENT === 'dev') {
    return 'https://back-dev.terrand.app/api'
  }
  // Docker ENVIROMENT
  if (process.env.REACT_APP_ENVIROMENT === 'docker') {
    return 'https://back-dev.terrand.app/api'
  }
  // LOCAL/DEFAULT ENVIROMENT
  // return 'https://localhost:5001/api'
  return 'https://back-qa.terrand.app/api'
}
const API_ROUTES = {
  BASE_URL: getBaseUrl(),
  LOGIN: (process.env.REACT_APP_ENVIROMENT !== 'dev' && process.env.REACT_APP_ENVIROMENT !== 'docker') ? '/auth-admin' : '/admin-authentication-mock',
  REFRESH_TOKEN: '/auth-admin/refresh-token',
  ACCOUNTS: '/account-admin',
  LEVELS: '/entity-configuration-admin',
  BUSINESS: '/business-admin',
  SECTORS: '/sector-admin',
  REQUEST_TYPE: '/request-model-admin',
  ROLES: 'role-admin',
  WISE_CONFIGURATION: 'entity-configuration-business-admin',
  EXPIRATION_REQUEST_TYPE: 'request-model-admin/expiration-model',
  REQUEST_TYPE_ADMIN: '/request-admin',
  DIVISIONS: '/division-admin',
  MYTEAM_ADMIN: '/my-team-admin',
  USERS: '/user-admin',
  VCT_ADMIN: '/vct-admin',
  UPSTREAM_TICKET_ID: 'upstreamticket-admin',
  UPSTREAM_TICKET_FILE: 'upstreamticketfile-admin',
  ACCIDENT_VERSION: '/accident-and-incident-version-admin',
}

export default API_ROUTES
