import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersError,
  fetchUserCompleteStart,
  fetchUserCompleteSuccess,
  fetchUserCompleteError,
  fetchAllDivisionsStart,
  fetchAllDivisionsSuccess,
  fetchAllDivisionsError,
  fetchAllTeamsStart,
  fetchAllTeamsSuccess,
  fetchAllTeamsError,
  fetchBlacklistedStart,
  fetchBlacklistedSuccess,
  fetchBlacklistedError,
  submitUnblacklistStart,
  submitUnblacklistSuccess,
  submitUnblacklistError,
  resetUsers
} = createActions({
  FETCH_USERS_START: () => { },
  FETCH_USERS_SUCCESS: values => ({ values }),
  FETCH_USERS_ERROR: error => ({ error }),
  FETCH_USER_COMPLETE_START: () => { },
  FETCH_USER_COMPLETE_SUCCESS: values => ({ values }),
  FETCH_USER_COMPLETE_ERROR: error => ({ error }),
  FETCH_ALL_DIVISIONS_START: () => { },
  FETCH_ALL_DIVISIONS_SUCCESS: values => ({ values }),
  FETCH_ALL_DIVISIONS_ERROR: error => ({ error }),
  FETCH_ALL_TEAMS_START: () => { },
  FETCH_ALL_TEAMS_SUCCESS: values => ({ values }),
  FETCH_ALL_TEAMS_ERROR: error => ({ error }),
  FETCH_BLACKLISTED_START: () => { },
  FETCH_BLACKLISTED_SUCCESS: values => ({ values }),
  FETCH_BLACKLISTED_ERROR: error => ({ error }),
  SUBMIT_UNBLACKLIST_START: () => { },
  SUBMIT_UNBLACKLIST_SUCCESS: values => ({ values }),
  SUBMIT_UNBLACKLIST_ERROR: error => ({ error }),
  RESET_USERS: () => { },
})

const fetchUsers = (page, filters, businessId) => {
  return async dispatch => {

    // const status = active ? 'paginated' : 'inactive'

    const query = []

    Object.entries(filters).forEach((filter) => filter[1] !== null && query.push(`${filter[0]}=${filter[1]}`))

    dispatch(fetchUsersStart())

    const api = getApi(API_ROUTES)

    await api.get(`${API_ROUTES.USERS}/paginated/${page}/${10}/${businessId}${query.length ? `?${query.join('&')}` : ''}`)
      .then(
        (response) => dispatch(fetchUsersSuccess(response.data)),
        (error) => dispatch(fetchUsersError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const fetchUserComplete = (id) => {
  return async dispatch => {
    dispatch(fetchUserCompleteStart())

    const api = getApi(API_ROUTES)

    await api.get(`${API_ROUTES.USERS}/complete/${id}`)
      .then(
        (response) => dispatch(fetchUserCompleteSuccess(response.data)),
        (error) => dispatch(fetchUserCompleteError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const fetchAllDivisions = (accountId, filters) => {
  return async dispatch => {
    dispatch(fetchAllDivisionsStart())
    const api = getApi(API_ROUTES)

    const query = []

    Object.entries(filters).forEach((filter) => filter[1] !== null && query.push(`${filter[0]}=${filter[1]}`))

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.DIVISIONS}/simple/account-divisions/${accountId}${query.length ? `?${query.join('&')}` : ''}`)
        .then((response) => {
          dispatch(fetchAllDivisionsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAllDivisionsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchAllTeams = (accountId) => {
  return async dispatch => {
    dispatch(fetchAllTeamsStart())
    const api = getApi(API_ROUTES)

    const query = []

    // Object.entries(filters).forEach((filter) => filter[1] !== null && query.push(`${filter[0]}=${filter[1]}`))

    await api.get(`${API_ROUTES.MYTEAM_ADMIN}/simple/account-teams/${accountId}${query.length ? `?${query.join('&')}` : ''}`)
      .then(
        (response) => dispatch(fetchAllTeamsSuccess(response.data)),
        (error) => dispatch(fetchAllTeamsError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const fetchBlacklisted = (page, filters) => {
  return async dispatch => {

    // const status = active ? 'paginated' : 'inactive'

    const query = []

    Object.entries(filters).forEach((filter) => filter[1] !== null && query.push(`${filter[0]}=${filter[1]}`))

    dispatch(fetchBlacklistedStart())

    const api = getApi(API_ROUTES)

    await api.get(`blacklisted-email-admin/paginated/${page}/${10}/${query.length ? `?${query.join('&')}` : ''}`)
      .then(
        (response) => dispatch(fetchBlacklistedSuccess(response.data)),
        (error) => dispatch(fetchBlacklistedError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const submitUnblacklist = (id) => {
  return async dispatch => {
    dispatch(submitUnblacklistStart())

    const api = getApi(API_ROUTES)

    await api.put(`blacklisted-email-admin/reinstate-mail/${id}`)
      .then(
        (response) => dispatch(submitUnblacklistSuccess(response.data)),
        (error) => dispatch(submitUnblacklistError(error.response !== undefined ? error.response.data.business_errors : error.message))
      )
  }
}

const reset = () => {
  return async dispatch => {
    dispatch(resetUsers())
  }
}


export {
  fetchUsers,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersError,
  fetchUserComplete,
  fetchUserCompleteStart,
  fetchUserCompleteSuccess,
  fetchUserCompleteError,
  fetchAllDivisions,
  fetchAllDivisionsStart,
  fetchAllDivisionsSuccess,
  fetchAllDivisionsError,
  fetchAllTeams,
  fetchAllTeamsStart,
  fetchAllTeamsSuccess,
  fetchAllTeamsError,
  fetchBlacklisted,
  fetchBlacklistedStart,
  fetchBlacklistedSuccess,
  fetchBlacklistedError,
  submitUnblacklist,
  submitUnblacklistStart,
  submitUnblacklistSuccess,
  submitUnblacklistError,
  reset,
  resetUsers
}
