import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'
import { Upload } from 'antd'

export const Button = styled.div`
  border: ${({ theme, color, background, borderButton }) => `1px solid ${borderButton ? borderButton : background ? background : color ? color : theme.colors.user.requests.primary}`};
  border-radius: 5px;
  padding: ${({ padding }) => padding ? '6px 0px' : '5px 10px'};
  text-align: center;
  min-width: ${({ minWidth, minWidthAbsence }) => minWidth ? '130px' : minWidthAbsence ? '180px' : '300px'};
  color: ${({ theme, color }) => color || theme.colors.user.requests.primary};
  ${({ background }) => `background: ${background}`};
  font-size: 13px;
  font-weight: ${({ fontWeight }) => fontWeight ? 600 : 500};
  cursor: pointer;
  margin-bottom: ${({ marginBottom }) => marginBottom ? '0px' : '10px'};
  width: ${({ widthButton }) => `${widthButton && widthButton}`};
`

export const ButtonPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  font-size: 12px;
  cursor: pointer;
`

export const StyledUpload = styled(Upload)`
  ${({ withPreview }) => withPreview ?
    `.ant-upload-list-item {
    font-size: 12px;
    font-weight: normal;
  }` :
    `.ant-upload-list-item {
    width: 200px;
    font-size: 12px;
    font-weight: normal;
    height: 30px;
    margin-top: 7px;
  }` }

  .ant-upload-list-text-container, ant-upload-list-picture-container {
    height: 25px;
  }

  .ant-upload-list-item-name {
    line-height: 2;
    padding: 0px 0px 2px 5px;
  }

  .ant-upload-list-item-info > span {
    display: flex;
    align-items: center;
  }

  .ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    top: 8px;
  }

  .ant-upload.ant-upload-select-picture-card {
    border-style: solid;
    border-radius: 5px;
    width: ${({ assistance }) => assistance ? '70px' : '104px'};
    height: ${({ assistance }) => assistance ? '70px' : '104px'};
  }

  .ant-upload.ant-upload-select-picture-card:hover {
    border-color: ${({ color }) => color};
  }

  a {
    color: dodgeblue;
    text-decoration: underline;
  }

  .ant-upload-list-picture-card-container {
    width: ${({ assistance }) => assistance ? '70px' : '104px'};
    height: ${({ assistance }) => assistance ? '70px' : '104px'};
  }
`