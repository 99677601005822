import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  font-size: 18px;
  ${mediaQueries.tablet`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`