import { handleActions } from 'redux-actions'
import {
  fetchAccountsStart,
  fetchAccountsSuccess,
  fetchAccountsError,
  submitAccountStart,
  submitAccountSuccess,
  submitAccountError,
  editAccountStart,
  editAccountSuccess,
  editAccountError
} from '../actions/accounts'

export const defaultState = {
  values: [],
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [fetchAccountsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAccountsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchAccountsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitAccountStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitAccountSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitAccountError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editAccountStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editAccountSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editAccountError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    })
  },
  defaultState
)

export default reducer
