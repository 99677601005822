import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { theme } from '@tops/global_config'
import { StyledButton, StyledButtonback } from './styled'
//import { IconButton } from '@tops/components'
import { IconNames } from '@tops/components';
import Icon from '../Icons';

export const Button = ({ onClick, children, loading, background, color, ...otherProps }) => (
  <StyledButton
    onClick={onClick}
    background={background}
    color={color}
    {...otherProps}
  >
    {loading ? <CircularProgress color={color} size={20} /> : children}
  </StyledButton>
)

export const ButtonWithIcon = ({ onClick, text, background, color, icon, iconSize, disabled, marginIcon, ...otherProps }) => (
  <>
    <Button onClick={onClick} background={background} color={color} disabled={disabled} {...otherProps}>
      <Icon name={icon ? IconNames[icon] : IconNames['Add']} size={iconSize || 24} color={disabled ? theme.colors.darkGray : color} style={{ margin: marginIcon ? marginIcon : '0px 10px 0px 0px' }} />
      {text}
    </Button>
  </>
)

export const CancelButton = ({ children, onClick, loading, ...otherProps }) => (
  <Button
    onClick={onClick}
    background={theme.colors.accent}
    color={theme.colors.secondary}
    style={otherProps.style}
    {...otherProps}
  >
    {children}
  </Button>
)

export const ConfirmationButton = ({ children, onClick, loading, ...otherProps }) => (
  <Button
    onClick={onClick}
    background={theme.colors.lilac}
    color={theme.colors.darkLilac}
    {...otherProps}
  >
    {children}
  </Button>
)

export const ButtonWithIconBack = ({ onClick, text, background, color, ...otherProps }) => (
  <>
    <StyledButtonback onClick={onClick} background={background} color={color} {...otherProps}>
      <Icon
        name={IconNames['SmallBack']}
        size={24}
        color={color}
        onClick={onClick}
        style={{ paddingRight: '7px' }}
      />
      {text}
    </StyledButtonback>
  </>
)

