import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const {
  fetchWiseConfigurationsStart,
  fetchWiseConfigurationsSuccess,
  fetchWiseConfigurationsError,
  fetchWiseConfigurationStart,
  fetchWiseConfigurationSuccess,
  fetchWiseConfigurationError,
  submitWiseConfigurationStart,
  submitWiseConfigurationSuccess,
  submitWiseConfigurationError,
  editWiseConfigurationStart,
  editWiseConfigurationSuccess,
  editWiseConfigurationError,
} = createActions({
    FETCH_WISE_CONFIGURATIONS_START: () => { },
    FETCH_WISE_CONFIGURATIONS_SUCCESS: values => ({ values }),
    FETCH_WISE_CONFIGURATIONS_ERROR: error => ({ error }),
    FETCH_WISE_CONFIGURATION_START: () => { },
    FETCH_WISE_CONFIGURATION_SUCCESS: item => ({ item }),
    FETCH_WISE_CONFIGURATION_ERROR: error => ({ error }),
    SUBMIT_WISE_CONFIGURATION_START: () => { },
    SUBMIT_WISE_CONFIGURATION_SUCCESS: item => ({ item }),
    SUBMIT_WISE_CONFIGURATION_ERROR: error => ({ error }),
    EDIT_WISE_CONFIGURATION_START: () => { },
    EDIT_WISE_CONFIGURATION_SUCCESS: item => ({ item }),
    EDIT_WISE_CONFIGURATION_ERROR: error => ({ error }),
  })

const fetchWiseConfigurations = (userId, active) => {
  return async dispatch => {
    dispatch(fetchWiseConfigurationsStart())
    const api = getApi(API_ROUTES)
    const status = active ? 'simple' : 'inactives'
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.WISE_CONFIGURATION}/${status}/configuration/${userId}`)
        .then((response) => {
          dispatch(fetchWiseConfigurationsSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchWiseConfigurationsError(error.response.data.business_errors || error.message))
          reject(error)
        })
    })
  }
}

const fetchWiseConfiguration = (configurationId) => {
  return async dispatch => {
    dispatch(fetchWiseConfigurationStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.WISE_CONFIGURATION}/detailed/configuration/${configurationId}`)
        .then((response) => {
          dispatch(fetchWiseConfigurationSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchWiseConfigurationError(error.response.data.business_errors || error.message))
          reject(error)
        })
    })
  }
}

const submitWiseConfiguration = (wiseConfiguration) => {
  return async dispatch => {
    dispatch(submitWiseConfigurationStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(API_ROUTES.WISE_CONFIGURATION, wiseConfiguration)
        .then((response) => {
          dispatch(submitWiseConfigurationSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(submitWiseConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject(error)
        })
    })
  }
}

const editWiseConfiguration = (wiseConfiguration) => {
  return async dispatch => {
    dispatch(editWiseConfigurationStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.WISE_CONFIGURATION, wiseConfiguration)
        .then((response) => {
          dispatch(editWiseConfigurationSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(editWiseConfigurationError(error.response.data.business_errors || error.message))
          reject(error)
        })
    })
  }
}

export {
  fetchWiseConfigurations,
  fetchWiseConfigurationsStart,
  fetchWiseConfigurationsSuccess,
  fetchWiseConfigurationsError,
  fetchWiseConfiguration,
  fetchWiseConfigurationStart,
  fetchWiseConfigurationSuccess,
  fetchWiseConfigurationError,
  submitWiseConfiguration,
  submitWiseConfigurationStart,
  submitWiseConfigurationSuccess,
  submitWiseConfigurationError,
  editWiseConfiguration,
  editWiseConfigurationStart,
  editWiseConfigurationSuccess,
  editWiseConfigurationError,
}
