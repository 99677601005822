import styled from 'styled-components'

export const StyledIcon = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  transform: ${props => props.flipIcon ? `scale(-1,1);` : `none;`}
  width: ${props => props.width * 0.65 || "100%"}px;
  height: ${props => props.height * 0.65 || "100%"}px;
  
  & svg {
    ${props => props.name === 'RiskNotice' ? `stroke: ${props.color}` : `fill: ${props.color}`};
    width: ${props => props.width * 0.65 || "100%"}px;
  height: ${props => props.height * 0.65 || "100%"}px;
  }

  @media (max-resolution: 124dpi) {
    stroke-width: 1.2px;
  }

  /* Bugfix: prevents icons from being cut off on Firefox by enabling hardware rendering */
  -moz-transform: translateZ(0);
`
