import { createActions } from 'redux-actions'

const { resetState,
} = createActions({
  RESET_STATE: () => { },

})

const resetStateAction = () => {
  return async dispatch => {
    dispatch(resetState())
  }
}



export {
  resetStateAction,
}
