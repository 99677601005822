import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import { Switch, Route, Router, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { createBrowserHistory } from 'history'
import { globalStyle as GlobalStyle } from '@tops/global_config'
import { theme } from '@tops/global_config'
import { i18n } from '@tops/services'
import store from './store'
import Home from './pages/Home'
import Login from './pages/Login'
import { PrivateRoute } from '@tops/components'
import Accounts from './pages/Accounts'
import Tools from './pages/Tools'
import Dashboard from './pages/Dashboard'
import Challenge from './pages/Login/Challenge';

const browserHistory = createBrowserHistory()

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Fragment>
          <Router history={browserHistory}>
            <Switch>
              <PrivateRoute exact path="/" component={Home} />
              <PrivateRoute path={`/${i18n('ACCOUNTS')}`} component={Accounts} />
              <PrivateRoute path={`/${i18n('TOOLS')}`} component={Tools} />
              <PrivateRoute path="/dashboard/:name" component={Dashboard} />
              {/* <Redirect from="/dashboard" to={`/${i18n('ACCOUNTS')}`} /> */}
              <Route exact path="/login" component={Login} />
              <Route path={`/${i18n('LOGIN').CHALLENGE.LINK}`} component={Challenge} />
            </Switch>
          </Router>
          <GlobalStyle />
        </Fragment>
      </ThemeProvider>
    </Provider>
  )
}

export default App
