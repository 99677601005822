import React from 'react'
import { i18n } from '@tops/services'
import { Form } from 'antd';
import "antd/dist/antd.css";
import LoginIlustration from '../../assets/images/illustration_login.svg'
import TerrandLogo from '../../assets/images/terrand_logo.svg'

import { Container, SideImagesWrapper, Logo, Image, LoginWrapper } from './styles'
import { login, reset } from './../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LoginInput, ConfirmationButton, AlertMessage, Loader } from '@tops/components';
import { Formik } from 'formik';
import * as yup from 'yup'

const Login = (props) => {

  const dispatch = useDispatch()
  const authState = useSelector(state => state.auth)

  const onSubmit = (values) => {
    dispatch(login(values.username, values.password))
      .then(() => props.history.push('/'),
        (error) => {
          if (error === 409) {
            props.history.push(`/${i18n('LOGIN').CHALLENGE.LINK}`, { username: values.username, password: values.password })
          }
        })
  }

  const validationSchema = yup.object({
    username: yup.string().required(i18n('ERRORS').USERNAME_ERROR),
    password: yup.string().required(i18n('ERRORS').PASSWORD_ERROR)
  })


  return (
    <Container>
      {authState.isFetching && <Loader />}
      {authState.error && <AlertMessage message={i18n('GENERAL_ERROR')} onOk={() => {
        dispatch(reset())
      }} />}
      <SideImagesWrapper>
        <Logo src={TerrandLogo} />
        <Image src={LoginIlustration} />
      </SideImagesWrapper>

      <LoginWrapper>
        <Formik initialValues={{ username: '', password: '' }}
          validateOnChange={false}
          validationSchema={validationSchema}
          onSubmit={(values) => onSubmit(values)}>
          {props => (
            <Form onFinish={props.handleSubmit}>
              <LoginInput label={i18n('LOGIN').USERNAME} name="username" error={props.errors.username} onChange={props.handleChange} border="true" />
              <LoginInput ispassword="true" label={i18n('LOGIN').PASSWORD} name="password" error={props.errors.password} onChange={props.handleChange} border="true" />
              <ConfirmationButton type="submit">{i18n('LOGIN').ENTER.toUpperCase()}</ConfirmationButton>
            </Form>
          )}
        </Formik>
      </LoginWrapper>
    </Container>
  )
}

export default withRouter(Login)
