import React from 'react'

const sectorId = localStorage.getItem('sector')
export const changeSurveyBySector = (newValue, value) => {
  if(parseInt(sectorId) === 43 || parseInt(sectorId) === 61) {
    return newValue
  } else {
    return value
  }
}

export default {
  LOGIN: {
    ENTER: 'Ingresar',
    LOG_IN: 'Iniciar sesión',
    REQUEST_CODE: 'Solicitar código',
    FREE_DEMO: {
      TITLE: '¿No tienes una cuenta? Solicita una demo gratuita.',
      LINK: 'contacto',
    },
    PRIVACY_POLICY: {
      LINK: 'politica-de-privacidad'
    },
    USERNAME: 'Usuario',
    EMAIL: 'Correo electrónico',
    PASSWORD: 'Contraseña',
    LOGOUT: 'Cerrar sesión',
    FORGOT_PASSWORD: 'Olvidé mi contraseña',
    I_FORGOT_PASSWORD: 'Olvidé mi contraseña',
    NEED_HELP: 'Si tiene problemas para iniciar sesión comuníquese con soporte.',
    ERROR: {
      PASSWORD_ERROR: 'Debe ingresar su contraseña',
      USERNAME_ERROR: 'Debe ingresar su usuario',
    },
    CHALLENGE: {
      LINK: 'cambiar-contraseña',
      TITLE: 'Cambiar contraseña',
      NEW_PASSWORD: 'Nueva contraseña',
      REPEAT_PASSWORD: 'Repetir nueva contraseña',
      CONFIRM_PASSWORD: 'Confirme nueva contraseña',
      REQUIREMENT: <>Requiere <b>12 caracteres, 1 mayúscula y 1 número</b><br />Sin espacios.</>,
      ERROR: {
        PASSWORD_NOT_VALID: 'Requiere 12 caracteres, 1 mayúscula y 1 número',
        NOT_MATCH: 'Las contraseñas no coinciden',
        REQUIRED_NEW_PASSWORD: 'Debe completar la nueva contraseña',
        REQUIRED_CONFIRM_PASSWORD: 'Debe confirmar la nueva contraseña',
      }
    },
  },
  ERRORS: {
    REQUIRED_FIELD: "Este campo es obligatorio",
    NAME_ERROR: 'Debe ingresar un nombre',
    VALUE_NOT_ACCEPTED: 'Ambos valores no pueden ser 0',
    MIN_NUMBER_ONE: 'El N° seleccionado debe ser mayor a 18.',
    MAX_NUMBER_HUNDRED: 'El N° seleccionado debe ser menor a 100.',
    NEED_CONFIGURATION: 'Debe cargar la configuración',
    PARAM_MAX: 'El campo tiene un valor máximo de 100 caracteres.',
    PARAM_MIN: 'Debe cargar los campos, no pueden estar vacíos.',
    VALIDATE_ERROR: 'Por favor controle el formulario ya que contiene errores.',
    DATE_MIN: 'Fecha de alta debe ser posterior a fecha de baja.',
    BUSINESS: {
      NAME_ERROR: 'Debe ingresar el nombre del negocio',
      NOT_ENOUGH_ITEMS_ERROR: 'Debe elegir al menos un item',
      USER_ADMIN: {
        FULLNAME_ERROR: 'Debe ingresar nombre y apellido',
        EMAIL_ERROR: 'Debe ingresar una dirección de e-mail',
        VALID_EMAIL_ERROR: 'Debe ingresar una dirección de e-mail válida',
      }
    },
    SECTOR: {
      NAME_ERROR: 'Debe ingresar el nombre del sector',
      SECTOR_TOOL_4_ERROR: "Debe seleccionar si es creador o consumidor.",
    },
    ROLE: {
      NAME_ERROR: 'Debe ingresar un nombre para el rol',
      BUSINESS_ERROR: 'Por favor seleccione un negocio',
      SECTOR_ERROR: 'Por favor seleccione un sector'
    },
    REQUEST_TYPE: {
      NAME_ERROR: 'Debe ingresar un nombre',
      SECTOR_ERROR: 'Por favor seleccione un sector',
      PUBLICPRIVATE_ERROR: 'Por favor seleccione si es privada o pública',
      LEVELS_ERROR: 'Por favor seleccione al menos un nivel',
      RELATED_REQUEST_ERROR: 'Debe elegir una solicitud relacionada',
      STAGE_ERROR: 'Por favor ingrese una etapa',
      STAGE_NAME_ERROR: 'Debe ingresar un nombre de etapa',
      SECTION_NAME_ERROR: 'Debe ingresar un nombre de sección',
      DERIVATION_TYPE_ERROR: 'Debe elegir un tipo de derivación',
      APPROVAL_TYPE_ERROR: 'Debe elegir un tipo de aprobación',
      ROLE_ERROR: 'Debe elegir al menos un rol',
      RESOLUTOR_ERROR: 'Debe elegir un rol para resolutor',
      SIGNATURE_ERROR: 'Debe elegir un nombre de firma'
    },
    EXPIRATION_REQUEST_TYPE: {
      NAME_ERROR: 'Debe ingresar un nombre',
      SECTOR_ERROR: 'Por favor seleccione un sector',
      PUBLICPRIVATE_ERROR: 'Por favor seleccione si es privada o pública',
      LEVELS_ERROR: 'Por favor seleccione al menos un nivel',
      STAGE_ERROR: 'Por favor ingrese una etapa',
      STAGE_NAME_ERROR: 'Debe ingresar un nombre de etapa',

      SECTION_NAME_ERROR: 'Debe ingresar un nombre de sección',
      APPROVAL_TYPE_ERROR: 'Debe elegir un tipo de aprobación',
      ROLE_ERROR: 'Debe elegir al menos un rol',
      RESOLUTOR_ERROR: 'Debe elegir un rol para resolutor'
    }
  },
  SUCCESS: {
    CREATE_ACCOUNT: '¡Cuenta creada correctamente!',
    EDIT_ACCOUNT: '¡Cuenta editada correctamente!',
    CREATE_LEVEL: '¡Nivel creado correctamente!',
    EDIT_LEVEL: '¡Nivel editado correctamente!',
    CREATE_BUSINESS: '¡Negocio creado correctamente!',
    EDIT_BUSINESS: '¡Negocio editado correctamente!',
    CREATE_SECTOR: '¡Sector creado correctamente!',
    EDIT_SECTOR: '¡Sector editado correctamente!',
    CREATE_ROLE: '¡Nuevo rol creado exitosamente!',
    CREATE_REQUEST_TYPE: '¡Tipo de solicitud creada exitosamente!',
    EDIT_REQUEST_TYPE: '¡Tipo de solicitud editada exitosamente!',
    CREATE_EXPIRATION_REQUEST_TYPE: '¡Nuevo vencimiento creado exitosamente!',
    EDIT_EXPIRATION_REQUEST_TYPE: '¡Tipo de Vencimiento editado exitosamente!',
  },
  HOME: {
    HELLO: 'Hola',
    CHOOSE_SECTOR: 'Seleccione un sector para continuar',
  },
  HELP: 'Ayuda',
  OPTIONS: 'Opciones',
  NOTIFICATIONS: 'Notificaciones',
  SIDEBAR_LINKS: {
    ACCOUNT_LEVELS: {
      LABEL: 'Niveles de cuenta',
      LINK: 'niveles-de-cuenta'
    },
    BUSINESS: {
      LABEL: 'Negocio',
      LINK: 'negocio'
    },
    BUSINESS_LEVELS: {
      LABEL: 'Niveles de negocio',
      LINK: 'niveles-de-negocio'
    },
    SECTORS: {
      LABEL: 'Sectores',
      LINK: 'sectores'
    },
    ROLES: {
      LABEL: 'Roles',
      LINK: 'roles'
    },
    CREATE_ROLE: {
      LABEL: 'Nuevo rol',
      LINK: 'nuevo-rol'
    },
    EDIT_ROLE: {
      LABEL: 'Editar rol',
      LINK: 'editar-rol'
    },
    REQUEST_TYPE: {
      LABEL: 'Tipos de solicitud',
      LINK: 'tipos-de-solicitud'
    },
    CREATE_REQUEST_TYPE: {
      LABEL: 'Crear nuevo tipo de solicitud',
      LINK: 'crear-tipo-de-solicitud'
    },
    EDIT_REQUEST_TYPE: {
      LABEL: 'Editar tipo de solicitud',
      LINK: 'editar-tipo-de-solicitud'
    },
    EXPIRATION_REQUEST_TYPE: {
      LABEL: 'Tipos de vencimientos',
      LINK: 'tipos-de-vencimientos'
    },
    CREATE_EXPIRATION_REQUEST_TYPE: {
      LABEL: 'Nuevo tipo de vencimiento',
      LINK: 'nuevo-tipo-de-vencimiento'
    },
    EDIT_EXPIRATION_REQUEST_TYPE: {
      LABEL: 'Editar tipo de vencimiento',
      LINK: 'editar-tipo-de-vencimiento'
    },
    CREATE_BUSINESS: {
      LABEL: 'Nuevo negocio',
      LINK: 'nuevo-negocio'
    },
    EDIT_BUSINESS: {
      LABEL: 'Editar negocio',
      LINK: 'editar-negocio'
    },
    CREATE_LEVEL: {
      LABEL: 'Nuevo nivel',
      LINK: 'nuevo-nivel'
    },
    EDIT_LEVEL: {
      LABEL: 'Editar nivel',
      LINK: 'editar-nivel',
    },
    LEVEL_HISTORY: {
      LABEL: 'Historial de ediciones',
      LINK: 'historial-de-ediciones',
    },
    CREATE_SECTOR: {
      LABEL: 'Nuevo sector',
      LINK: 'nuevo-sector'
    },
    EDIT_SECTOR: {
      LABEL: 'Editar sector',
      LINK: 'editar-sector',
    },
    WISE: {
      LABEL: 'Seguridad e Higiene',
      LINK: 'seguridad-e-higiene',
    },
    WISE_CREATE_CONFIGURATION: {
      LABEL: 'Nueva configuración',
      LINK: 'nueva-configuracion',
    },
    WISE_EDIT_CONFIGURATION: {
      LABEL: 'Editar configuración',
      LINK: 'editar-configuracion',
    },
    DASHBOARD: {
      LABEL: 'Dashboard',
      LINK: 'dashboard',
    },
    CHECKLISTS: {
      LABEL: 'Planillas',
      LINK: 'planillas',
    },
    CREATE_CHECKLIST: {
      LABEL: 'Nueva planilla',
      LINK: 'nueva-planilla',
    },
    VIEW_CHECKLIST: {
      LABEL: 'Detalle de planilla',
      LINK: 'detalle-de-planilla',
    },
    EDIT_CHECKLIST: {
      LABEL: 'Editar planilla',
      LINK: 'editar-planilla',
    },
    AUDITS: {
      LABEL: changeSurveyBySector('Periodicos', 'Auditorias'),
      LINK:  'auditorias',
    },
    SUB_SIDEBAR_LINKS_AUDITS: {
      AUDITS: {
        LABEL: changeSurveyBySector('Periodicos', 'Auditorias'),
        LINK: 'auditorias',
        CREATE_AUDIT: {
          LABEL: changeSurveyBySector('Ejecutar-periodico', 'Ejecutar auditoria'),
          LINK:'ejecutar-auditoria',
        },
        VIEW_AUDIT_DETAIL: {
          LABEL: changeSurveyBySector('Detalle de periodico', 'Detalle de auditoria'),
          LINK:  'detalle-de-auditoria',
        },
        EDIT_AUDIT: {
          LABEL: changeSurveyBySector('Editar periodico', 'Editar auditoria'),
          LINK: 'editar-auditoria',
        },
      },
      CONFIGURATION: {
        LABEL: 'Configuración',
        LINK: 'configuracion',
        CREATE_CONFIGURATION: {
          LABEL: 'Nueva configuración',
          LINK: 'nueva-configuracion',
        },
        EDIT_CONFIGURATION: {
          LABEL: 'Editar configuración',
          LINK: 'editar-configuracion',
        },
        VIEW_CONFIGURATION_DETAIL: {
          LABEL: 'Detalle de configuración',
          LINK: 'detalle-de-configuracion',
        },
      },
      AUDITS_RANKING: {
        SIMPLE_LABEL: 'Ranking',
        LABEL: changeSurveyBySector('Rankink de Periodicos', 'Ranking de Auditorias'),
        LINK:  'ranking-de-auditorias',
      }
    },
    EXPIRATIONS: {
      LABEL: 'Vencimientos',
      LINK: 'vencimientos',
    },
    CREATE_EXPIRATION: {
      LABEL: 'Nuevo Vencimiento',
      LINK: 'nuevo-vencimiento',
    },
    VIEW_EXPIRATION: {
      LABEL: 'Detalle de Vencimiento',
      LINK: 'detalle-de-vencimiento',
    },
    EDIT_EXPIRATION: {
      LABEL: 'Actualización',
      LINK: 'actualizacion',
    },
    INVENTORY: {
      LABEL: 'Inventario',
      LINK: 'inventario',
    },
    SUB_SIDEBAR_LINKS_INVENTORY: {
      WAREHOUSES: {
        LABEL: 'Almacenes',
        LINK: 'almacenes',
        CREATE_WAREHOUSE: {
          LABEL: 'Nuevo Almacén',
          LINK: 'nuevo-almacen',
        },
        VIEW_WAREHOUSE_DETAIL: {
          LABEL: 'Detalle almacén',
          LINK: 'detalle-de-almacen',
        },
        EDIT_WAREHOUSE: {
          LABEL: 'Editar Almacén',
          LINK: 'editar-almacen',
        },
        EDIT_MATERIALS_IN_WAREHOUSE: {
          LABEL: 'Editar Materiales de Almacén',
          LINK: 'editar-materiales-de-almacen',
        }
      },
      MATERIALS: {
        LABEL: 'Materiales',
        LINK: 'materiales',
        CREATE_MATERIAL: {
          LABEL: 'Nuevo material',
          LINK: 'nuevo-material',
        },
        VIEW_MATERIAL_DETAIL: {
          LABEL: 'Detalle material',
          LINK: 'detalle-material',
        },
        EDIT_MATERIAL: {
          LABEL: 'Editar material',
          LINK: 'editar-material',
        },
      },
    },
    REQUESTS: {
      LABEL: 'Solicitudes',
      LINK: 'solicitudes',
    },
    USERS: {
      LABEL: 'Usuarios',
      LINK: 'usuarios',
    },
    SUB_SIDEBAR_LINKS_USERS: {
      USERS: {
        LABEL: 'Usuarios de sistema',
        LABEL_USERS_ONLY: 'Usuarios de sistema',
        LINK: 'usuarios-de-sistema',
        CREATE_USER: {
          LABEL: 'Nuevo usuario',
          LINK: 'nuevo-usuario'
        },
        EDIT_USER: {
          LABEL: 'Editar usuario',
          LINK: 'editar-usuario',
        },
      },
      PEOPLE: {
        LABEL: 'Personas internas',
        LINK: 'personas',
        VIEW_PEOPLE: {
          NAME_AND_SURNAME: 'Nombre y apellido',
          DNI_OR_FILE: 'DNI/Legajo',
          POSITION: 'Puesto'
        },
        CREATE_PEOPLE: {
          LABEL: 'Nueva',
          LINK: 'nueva-persona',
        },
        EDIT_PEOPLE: {
          LABEL: 'Editar persona',
          LINK: 'editar-persona',
        },
      },
      EXTERNAL_USERS: {
        LABEL: 'Personas externas',
        LINK: 'usuarios-externos',
        EDIT_EXTERNAL_USERS: {
          LABEL: 'Editar externo',
          LINK: 'editar-externo',
        },
      },
    },
    SUB_SIDEBAR_LINKS_TRAININGS: {
      CATEGORIES: {
        LABEL: 'Categorías',
        LINK: 'categorias',
        CREATE_CATEGORY: {
          LABEL: 'Nueva categorías',
          LINK: 'nueva-categoria',
        },
        EDIT_CATEGORY: {
          LABEL: 'Editar categorías',
          LINK: 'editar-categoria',
        },
        SUCCESS: 'Categoría creada con éxito',
        EDIT_SUCCESS: 'Categoría editada con éxito'
      },
      COURSES: {
        LABEL: 'Cursos',
        LINK: 'cursos',
        CREATE_COURSE: {
          LABEL: 'Nuevo curso',
          LINK: 'nuevo-curso',
        },
        EDIT_COURSE: {
          LABEL: 'Detalle',
          LINK: 'editar-curso',
        },
        SUCCESS: 'Curso creado con éxito'
      },
      POSITIONS: {
        LABEL: 'Config. de puestos',
        LINK: 'puestos',
        CREATE_POSITION: {
          LABEL: 'Nuevo puesto',
          LINK: 'nuevo-puesto',
        },
        EDIT_POSITION: {
          LABEL: 'Detalle',
          LINK: 'editar-puesto',
        },
        SUCCESS: 'Puesto creado con éxito',

      },
      DASHBOARD: {
        LABEL: 'Dashboard',
        FILTERS: 'FILTRAR',
        LINK: 'capacitciones-dashboard',
      },
      TRAININGS: {
        LABEL: 'Capacitaciones',
        LINK: 'capacitaciones',
        CREATE_TRAINING: {
          LABEL: 'Nueva',
          LINK: 'nueva-capacitacion',
        },
        EDIT_TRAINING: {
          LABEL: 'Detalle',
          LINK: 'editar-capacitacion',
        },
      },
      LINK_QR: {
        LINK: 'trainings'
      }
    },
    TRAININGS: {
      LABEL: 'Registro de Capacitaciones',
      LINK: 'registro-de-capacitaciones'
    },
    CREATE_REQUEST: {
      LABEL: 'Nueva solicitud',
      LINK: 'nueva-solicitud',
    },
    VIEW_REQUEST: {
      LABEL: 'Detalle de solicitud',
      LINK: 'detalle-solicitud',
    },
    SCHEDULE: {
      LABEL: 'Agenda',
      LINK: 'agenda',
    },
    LEVELS: {
      LABEL: 'Niveles',
      LINK: 'niveles',
    },
    USERS: {
      LABEL: 'Usuarios de sistema',
      LINK: 'usuarios-de-sistema',
    },
    CREATE_USER: {
      LABEL: 'Nuevo usuario',
      LINK: 'nuevo-usuario'
    },
    EDIT_USER: {
      LABEL: 'Editar usuario',
      LINK: 'editar-usuario',
    },
    WISE: {
      LABEL: 'Seguridad e Higiene',
      LINK: 'seguridad-e-higiene',
    },
    WISE_CREATE_CONFIGURATION: {
      LABEL: 'Nueva configuración',
      LINK: 'nueva-configuracion',
    },
    WISE_EDIT_CONFIGURATION: {
      LABEL: 'Editar configuración',
      LINK: 'editar-configuracion',
    },
    VIEW_MY_INFO_USER: {
      LABEL: 'Mis Datos',
      LINK: 'ver-mis-datos',
    },
    DIVISIONS: {
      LABEL: 'Divisiones',
      LINK: 'divisiones',
    },
    CREATE_DIVISION: {
      LABEL: 'Nueva division',
      LINK: 'nueva-division'
    },
    EDIT_DIVISION: {
      LABEL: 'Editar division',
      LINK: 'editar-division',
    },
    VIEW_MYTEAMS: {
      LABEL: 'Ver equipo',
      LINK: 'ver-equipo',
    },
    EDIT_MYTEAMS: {
      LABEL: 'Editar equipo',
      LINK: 'editar-equipo',
    },
    VIEW_MYPERFORMANCE: {
      LABEL: 'Ver performance',
      LINK: 'ver-performance',
    },
    INDIVIDUAL_PERFORMANCE: {
      LABEL: 'Performance Individual',
      LINK: 'performance-individual',
    },
    POSITIVE_AGREEMENT: {
      LABEL: 'Acuerdo positivo',
      LINK: 'acuerdo-positivo',
    }
  },
  SIDEBAR_LINKS_WISE: {
    WISE: {
      LABEL: 'Seguridad e Higiene',
      LINK: 'seguridad-e-higiene',
      CAPITAL_LETTER: 'WISE'
    },
  },
  SUB_SIDEBAR_LINKS_WISE: {
    MY_TEAM: {
      LABEL: 'Mi Equipo',
      LINK: 'mi-equipo'
    },
    NEW_TEAM: {
      LABEL: 'Nuevo equipo',
      LINK: 'crear-nuevo-equipo'
    },
    EDIT_TEAM: {
      LABEL: 'Editar equipo',
      LINK: 'editar-equipo'
    },
    ORGANIZATION_CHART: {
      LABEL: 'Organigrama',
      LINK: 'organigrama',
      CREATE_POSITION: {
        LABEL: 'Nuevo Puesto',
        LINK: 'nuevo-puesto',
      },
      EDIT_POSITION: {
        LABEL: 'Editar Puesto',
        LINK: 'editar-puesto',
      },
    },
    RISK_NOTICE: {
      LABEL: 'Aviso de riesgo',
      LINK: 'aviso-de-riesgo',
      CREATE_RISK_NOTICE: {
        LABEL: 'Nuevo aviso de riesgo',
        LINK: 'nuevo-aviso-de-riesgo',
      },
      EDIT_RISK_NOTICE: {
        LABEL: 'Editar aviso de riesgo',
        LINK: 'editar-aviso-de-riesgo',
      },
      DETAIL_RISK_NOTICE: {
        LABEL: 'Detalle de aviso de riesgo',
        LINK: 'detalle-aviso-de-riesgo',
      },
    },
    ACCIDENT_AND_INCIDENT: {
      LABEL: 'Accidente e incidente',
      LINK: 'accidente-e-incidente',
      CREATE_ACCIDENT_AND_INCIDENT: {
        LABEL: 'Nuevo reporte de accidente e incidente',
        LINK: 'nuevo-accidente-e-incidente',
      },
      EDIT_ACCIDENT_AND_INCIDENT: {
        LABEL: 'Editar accidente e incidente',
        LINK: 'editar-accidente-e-incidente',
      },
      DETAIL_ACCIDENT_AND_INCIDENT: {
        LABEL: 'Detalle de reporte de accidente e incidente',
        LINK: 'detalle-accidente-e-incidente',
      },
      LOAD_DATA_ACCIDENT_AND_INCIDENT: {
        LOADING_EVENT_DATA: {
          LABEL: 'Carga de datos de investigación',
          LINK: 'carga-de-datos-de-investigacion',
        },
        MEDICAL_SERVICE: {
          LABEL: 'Servicio médico',
          LINK: 'servicio-medico',
        },
        RESEARCH_DATA_UPLOAD: {
          LABEL: 'Carga de datos de investigación 2',
          LINK: 'carga-de-datos-de-investigacion-2',
        },
        CLOSURE: {
          LABEL: 'Cierre del informe',
          LINK: 'cierre-de-informe',
        },
      },
    },
    PROGRESSIVE_DISCIPLINE: {
      LABEL: 'Disciplina progresiva',
      LINK: 'disciplina-progresiva',
      CREATE_PROGRESSIVE_DISCIPLINE: {
        LABEL: 'Nueva disciplina progresiva',
        LINK: 'nueva-disciplina-progresiva',
      },
      EDIT_PROGRESSIVE_DISCIPLINE: {
        LABEL: 'Editar disciplina progresiva',
        LINK: 'editar-disciplina-progresiva',
      },
      DETAIL_PROGRESSIVE_DISCIPLINE: {
        LABEL: 'Detalle de disciplina progresiva',
        LINK: 'detalle-disciplina-progresiva',
      },
      RESOLVE_PROGRESSIVE_DISCIPLINE: {
        LABEL: 'Resolución de Disciplina progresiva',
        LINK: 'resolucion-disciplina-progresiva',
      },
    },
    CONFIG_AUDITS_WISE: {
      LABEL: changeSurveyBySector('Configuración Periodicos', 'Configuración Auditorías'),
      LINK: 'configuracion-auditoria',
    },
    AUDITS_WISE: {
      LABEL: changeSurveyBySector('Periodicos', 'Auditorias'),
      LINK: 'auditorias',
      CREATE_AUDITS_WISE: {
        LABEL: changeSurveyBySector('Nuevo periodico', 'Nueva auditoría'),
        LINK: 'nueva-auditoria',
      },
      /*       EDIT_AUDITS_WISE: {
              LABEL: 'Editar auditoría',
              LINK: 'editar-auditoria',
            }, */
      DETAIL_AUDITS_WISE: {
        LABEL: changeSurveyBySector('Detalle de periodico', 'Detalle de auditoría'),
        LINK: 'detalle-auditoría',
      },
    },
    POSITIVE_AGREEMENT: {
      LABEL: 'Acuerdo positivo',
      LINK: 'acuerdo-positivo',
      CREATE_POSITIVE_AGREEMENT: {
        LABEL: 'Nuevo acuerdo positivo',
        LINK: 'nuevo-acuerdo-positivo'
      },
      DETAIL_POSITIVE_AGREEMENT: {
        LABEL: 'Detalle de acuerdo positivo',
        LINK: 'detalle-acuerdo-positivo'
      }
    },
    WISE_DASHBOARDS: {
      LABEL: 'Dashboard Seguridad e Higiene',
      LINK: 'dashboard-seguridad-e-higiene'
    },
    TOPS_DASHBOARDS: {
      LABEL: 'Dashboard TOPS',
      LINK: 'dashboard-requests'
    },
    VCT: {
      LABEL: "VCT",
      LINK: "vct",
      CREATE: {
        LABEL: "Creación",
        LINK: "creacion",
      },
      DETAIL: {
        LABEL: "Detalle",
        LINK: "detalle"
      }
    }
  },
  ACCOUNTS: 'cuentas',
  TOOLS: 'herramientas',
  TEST_ENVIROMENT: 'Ambiente de prueba',
  CREATE_ACCOUNT: {
    LABEL: 'Nueva cuenta',
    LINK: 'nueva-cuenta',
  },
  EDIT_ACCOUNT: {
    LABEL: 'Editar cuenta',
    LINK: 'editar-cuenta',
  },
  ACCOUNT_LIST: 'Listado de cuentas',
  ACTIVES: 'Habilitados',
  INACTIVES: 'Deshabilitados',
  WELCOME_TO: 'Bienvenido a',
  SELECT_AN_OPTION: 'Seleccione una opción del menú para configurar una cuenta.',
  USERS_TITLE: 'Usuarios',
  USER_DETAIL: 'Detalle de Usuario',
  USER_NAME: 'Nombre de usuario',
  SAVE: 'Guardar',
  CANCEL: 'Cancelar',
  TYPE_HERE: "Escribir aquí...",
  CHOOSE: "Elegir",
  ACCOUNT_NAME: 'Nombre de la cuenta',
  LANGUAGE: 'Idioma',
  HOUR_ZONE: 'Zona Horaria',
  ACTIVE: 'Habilitado',
  INACTIVE: 'Deshabilitado',
  WISE_NAME: 'Seguridad & Higiene',
  WISE_NAME_INPUT: 'Nombre de la herramienta',
  FUNCTIONAL_KEY: 'Functional Key',
  FUNCTIONAL_KEY_IDENTIFIER: 'Identificador de functional key',
  SELECT: 'Seleccionar',
  CONTINUE: 'Continuar',
  SELECT_BUSINESS: 'Seleccionar Negocio',
  SELECT_SECTOR: 'Seleccionar Sector',
  SELECT_DIVISION: 'Seleccionar División',
  SELECT_ROLE: 'Seleccionar Rol',
  SELECT_WISE_TEAM: 'Seleccionar Equipo WISE',
  CHOOSE_BUSINESS: 'Seleccione un negocio',
  SECTOR: 'Sector',
  ADMIN_USER_SECTOR: 'Usuario Administrador',
  BUSINESS: 'Negocio',
  NAME: 'Nombre',
  CREATE_REQUEST_TYPE: 'Crear Tipo de Solicitud',
  HAS_PARENT_LEVEL: '¿Tiene nivel padre?',
  WHICH: '¿Cual?',
  LEVEL_WISE: "Nivel WISE",
  ERROR_HAVE_LEVEL_WISE: "Ya posee un nivel WISE seleccionado",
  ACTIVATE_PAYROLL: 'Activar nivel nómina',
  CREATE_ACCOUNT_LEVEL: 'Crear Nivel de Cuenta',
  ADMIN_USER: 'Usuario Administrador General',
  ADMIN_WISE: 'Usuario Administrador WISE',
  FULL_NAME: 'Nombre y Apellido',
  EMAIL: 'E-mail',
  PAYROLL: 'Datos de Nómina',
  FIELDS: 'Campos',
  ADD_FIELDS: 'Añadir grupo de campos',
  DELETE_FIELDS: 'Eliminar',
  ADD_FIELDS_TO_SECTION: 'Añadir campos a la sección',
  TYPE: 'Tipo',
  IS_REQUIRED: 'Es obligatorio',
  FUTURE_LOAD_PHASE: 'A completar en etapa',
  GO_BACK: 'Volver',
  SECTOR_TOOL_0: 'Agenda',
  SECTOR_TOOL_1: 'Solicitudes',
  SECTOR_TOOL_2: 'Planillas',
  SECTOR_TOOL_3: changeSurveyBySector('Periodicos', 'Auditorias'),
  SECTOR_TOOL_4: 'Inventario',
  SECTOR_TOOL_4_CONSUMER: "Consumidor",
  SECTOR_TOOL_4_CREATOR: "Creador",
  OWNER_SECTOR: 'Sector Dueño',
  PUBLIC: 'Pública',
  PRIVATE: 'Privada',
  LEVELS: 'Niveles',
  INSTANCE_LEVELS: 'Nivel',
  LEVELS_PARENTS: 'Nivel superior',
  LEVELS_PARENTS_CODE: 'Código nivel superior',
  CODE: 'Código',
  DESCRIPTION: 'Descripción',
  LINK: 'Link',
  PLANNED: 'Planificable',
  SELFRESOLVABLE: 'Autoresoluble',
  VISIBLE_FROM_REQUESTS_TOOL: "Visible desde herramienta solicitudes",
  HAS_RELATED_REQUEST: '¿Posee solicitudes asociadas?',
  RELATED_REQUESTS: 'Tipo de solicitud asociada',
  SPREADSHEET: 'Planilla',
  RESOURCES: 'Repuestos',
  RELATED_RESOURCES: 'Tipo de respuestos',
  EXPIRATION_DATE: 'Fecha de vencimiento',
  PDF_TYPE: 'Tipo de PDF',
  STAGE: 'Etapa',
  DELETE_STAGE: 'Borrar etapa',
  DELETE_SECTION: 'Borrar sección',
  SECTIONS: 'Secciones',
  REPEAT_SECTION_FIELDS: 'Repetir campos de esta sección',
  SECTION_NAME: 'Nombre de la sección',
  VALUES: 'Valores',
  EDITABLE: 'Editable',
  DERIVATION: 'Derivación',
  DERIVATION_TYPE_0: 'Interna',
  DERIVATION_TYPE_1: 'Intrasector',
  DERIVATION_TYPE_2: 'Intersector',
  REVERSE: 'Revertir',
  APPROVAL: 'Aprobación',
  APPROVAL_TYPE: 'Tipo',
  APPROVAL_TYPE_ROLES: 'Roles',
  APPROVAL_TYPE_NPlus1: 'N+1',
  SOLVER: 'Resolutor',
  SOLVER_ROLES: 'Roles',
  SOLVER_ROLES_PLACEHOLDER: 'Por favor seleccione uno o más roles',
  SOLVER_ROLES_ONE_PLACEHOLDER: 'Selecione el rol',
  SOLVER_CANNOT_FORCE_REJECT: 'Anular rechazo de resolutor',
  ALARM: 'Alarma',
  ALARM_ADD: 'Agregar alarma',
  ALARM_TYPE: 'Tipo de alarma',
  ALARM_HOUR: 'Horas',
  ALARM_DAYS: 'Días',
  ALARM_WEEK: 'Semanas',
  ALARM_MONTH: 'Meses',
  ALARM_HOUR_ONE: 'Hora',
  ALARM_DAYS_ONE: 'Día',
  ALARM_WEEK_ONE: 'Semana',
  ALARM_MONTH_ONE: 'Mes',
  FILE: 'Archivo',
  SIGNATURE: 'Firma',
  IMMEDIATE_FINALIZATION: 'Finalización Inmediata',
  PLANNED_REQUEST: 'Programación',
  LOCATION: 'Ubicación',
  INVENTORY: 'Inventario',
  SIGNATURE_NOMENCLATURE: "Nomenclatura de firma",
  ADD_STAGE: 'Nueva etapa',
  ADD_SECTION: 'Nueva sección',
  GENERAL_ERROR: 'Ocurrió un problema. Intente de nuevo.',
  NO_DATA_AVAILABLE: 'No hay datos disponibles.',
  NO_PENDING_DATA_AVAILABLE: 'No tienes solicitudes asignadas pendientes, prueba filtrar por "Todas".',
  NO_DATA_WITH_PARAMETERS_AVAILABLE: 'No hay solicitudes con los parámetros seleccionados, prueba filtrando nuevamente.',
  CHANGE_SECTOR: 'Cambiar de Sector',
  VIEW_PROFILE: 'Ver mis datos',
  HOME_MESSAGE: 'Recuerde que una vez que ingresó al sistema puede cambiar de sector desde el menú "configuración" o desde las opciones de usuario.',
  ACCEPT: 'Aceptar',
  SAVE_CHANGES: 'Guardar cambios',
  FINALIZE: 'Finalizar',
  DOWNLOAD: 'Descargar',
  COMING_SOON: '¡Próximamente!',
  DASHBOARD_UNDER_CONSTRUCTION: 'Nuestro dashboard está en construcción.',
  SEARCH: 'Buscar',
  CREATE: 'Crear',
  SEND: 'Enviar',
  EDIT: 'Editar',
  MAX_CHARACTERS: "Máximo caracteres",
  ATTACH_FILE: "Adjuntar archivo",
  POLICY: {
    LINK: 'politicas-de-privacidad',
    TITLE_PRIVACY_POLICIES: 'Políticas de Privacidad',
    PRESENT_POLICY: 'La presente Política de Privacidad establece los términos en que ORTUSTEN S.A.C.I.F usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.',
    TITLE_INFORMATION_COLLECTED: 'Información que es recogida',
    INFORMATION_COLLECTED: 'Nuestro sitio web podrá recoger información personal y del cliente por ejemplo: Nombre,información de contacto como su dirección de correo electrónica e información operativa del negocio.',
    TITLE_INFORMATION_USE: 'Uso de la Información recogida',
    INFORMATION_USE: 'ORTUSTEN S.A.C.I.F está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.',
    TITLE_COOKIES: 'Cookies',
    COOKIES: 'Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador, al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, y también facilita las futuras visitas a una web recurrente. Otra función que tienen las cookies es que con ellas las web pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado de su web. Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas y su frecuencia. Esta información es empleada únicamente para análisis estadístico y después la información se elimina de forma permanente. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo las cookies ayudan a proporcionar un mejor servicio de los sitios web, estás no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente, visitas a una web. Usted puede aceptar o negar el uso de cookies, sin embargo la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.',
    TITLE_PERSONAL_INFORMATION: 'Control de su información personal',
    PERSONAL_INFORMATION: 'Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial. ORTUSTEN S.A.C.I.F Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.',
  },
  QUALITY: {
    LINK: 'politica-de-calidad',
    TITLE_QUALITY_POLICIES: 'Política de Calidad',
    FIRST_PARAGRAPH: 'La política de calidad de Terrand, está basada en el cumplimiento de nuestra misión y visión desarrollando y diseñando nuestro producto con calidad, innovación y profesionalismo, adhériendose a los criterios de seguridad, fiabilidad, capacidad de respuesta y eficacia. Está política se aplica a cada una de las actividades, mediante un proceso de mejora continua del sistema de gestión de calidad y servicios de información. La política de calidad de Terrand, se fundamenta en lo siguiente:',
    OUR_VALUES_TITLE: 'Nuestros Valores',
    OUR_VALUES: [
      "Liderazgo.",
      "Responsabilidad y compromiso.",
      "Integridad.",
      "Calidad.",
      "Respeto.",
      "Transparencia."
    ],
    OUR_MISSION_TITLE: 'Nuestra Misión',
    OUR_MISSION: 'Generar mobilidad y eficiencia en los procesos operativos de las operaciones a través del desarrollo de tecnología de excelencia.',
    OUR_VISION_TITLE: 'Nuestra Visión',
    OUR_VISION_1: 'Ser reconocidos como la opción más confiable a la hora de digitalizar procesos mediante una aplicación.',
    OUR_VISION_2: "Con el objetivo principal de “alcanzar la satisfacción y el cumplimiento de las expectativas de nuestros clientes, equipo de trabajo y partes interesadas pertinentes, asegurando el cumplimiento de nuestras actividades dentro del marco de legislación vigente y requisitos aplicables”. Nos comprometemos a:",
    CLIENT_FOCUS_TITLE: 'Foco en el cliente',
    CLIENT_FOCUS: 'Relacionar y mantener relaciones de largo plazo con nuestros clientes teniendo siempre presentes los objetivos del negocio de cada cliente de cada proyecto.',
    KNOWLEDGE_TITLE: 'Conocimiento',
    KNOWLEDGE: 'Mantener actualizado nuestro equipo de trabajo mediante la capacitación, generando un ambiente de trabajo para que nuestros empleados puedan desarrollar el pleno uso de su potencial.',
    IMPROVEMENT_TITLE: 'Mejora continua',
    IMPROVEMENT: 'Propiciar el mejoramiento continuo de la calidad a través de un esquema de gestión en el que todos participen.',
  },
  USER: {
    RECOVER_PASSWORD: {
      LABEL: 'Recuperar contraseña',
      LINK: 'recuperar-contrasenia',
      VIEW: {
        TITLE: "Recuperar contraseña",
        EMAIL_INPUT: "E-mail",
        ALREADY_HAVE_CODE: "Ya tengo código",
        SEND: "Aceptar",
        ENTER_CODE: "Código de 6 dígitos",
        NEW_PASSWORD_TITLE: "Crear nueva contraseña",
        NEW_PASSWORD: "Nueva contraseña",
        SECURITY_CODE: "Código de seguridad",
        ERRORS: {
          EMAIL: 'Debe ingresar su e-mail',
          VALID_EMAIL: 'Debe ingresar un e-mail válido',
          MIN_CONFIRMATION_CODE: 'El código de seguridad contener 6 dígitos',
          CONFIRMATION_CODE: 'Debe ingresar el código',
          NEW_PASSWORD: 'Debe completar este campo',
          PASSWORD_NOT_VALID: 'Requiere 12 caracteres, 1 mayúscula y 1 número',
        }
      },
      SUCCESS: {
        SEND_MAIL: "Se envió el mail exitosamente. Por favor, revise su casilla."
      },
      SECOND_STEP: {
        LABEL: 'Nueva contraseña',
        LINK: 'nueva-contrasenia',
        SUCCESS_LINK: 'confirmacion'
      },
      PASSWORD_CHANGE_SUCCESS: '¡Contraseña cambiada con éxito!'
    },
    WISE: {
      ORGANIZATION_CHART: {
        EMPTY_POSITION_PLACEHOLDER: 'No posee puestos',
        NEW_POSITION: 'Nuevo Puesto',
        EDIT_POSITION: 'Editar Puesto',
        ORGANIZATION_CHART_MESSAGES: {
          EMPTY_CHART: 'Cree un nuevo puesto para luego poder crear un equipo y ver su organigrama',
          EMPTY_POSITION_NAME: 'El nombre del puesto no puede estar vacío. Por favor, utilice un nombre válido',
          ALREADY_EXISTS_POSITION_NAME: 'El nombre del puesto ya existe. Por favor, intente nombrando el puesto de manera diferente',
          POSITIONS_CREATED_SUCCESSFULLY: '¡Sus puestos se crearon exitosamente! Ya puede visualizar su organigrama',
          POSITIONS_EDITED_SUCCESSFULLY: '¡Sus puestos se editaron exitosamente! Ya puede visualizar su organigrama'
        },
        INPUT_PLACEHOLDER: 'Escribir aquí nombre del puesto',
      },
      MY_TEAMS: {
        RISK_NOTICE: "Aviso de riesgo",
        ACCIDENT_AND_INCIDENT: "Accidente e incidente",
        PROGRESSIVE_DISCIPLINE: "Disciplina progresiva",
        AUDITS: changeSurveyBySector('Periodicos', 'Auditorias'),
        POSITIVE_AGREEMENT: "Acuerdo positivo",
        VCT: "VCT",
        SITE: "Sitio",
        SITE_PLACEHOLDER: "Seleccionar Sitio",
        TYPE_HERE: 'Escribir aquí',
        SECTOR: 'Sector',
        NAME_OF_THE_TEAM: 'Nombre del equipo',
        MEMBERS_OF_THE_TEAM: 'Miembros del equipo',
        TOTAL_OBJECTIVES_FOR_TEAM: 'Objetivos totales por equipo',
        TOTAL_OBJECTIVES_FOR_TEAM_SUBTEXT: 'Todos los objetivos representados son anuales.',
        POSITION_OF_THE_TEAM: 'Puesto',
        USER_OF_THE_TEAM: 'Usuario',
        TOOL_OF_THE_TEAM: 'Herramienta',
        OBJETIVE_BY_GENERATION: "Obj. de generación",
        OBJETIVE_BY_RESOLUTION: "Obj. de resolución",
        OBJETIVE_INDIVIDUAL: "Obj. individual",
        INDICATORS: "Indicadores",
        INDICATOR_YEAR: "Indicador anual",
        ROLE: 'Rol',
        ACCUMULATED_PER_MONTH: 'Acumulado al mes',
        ACCUMULATED_PER_YEAR: 'Acumulado al año',
        OBJETIVE_BY_GENERATION_YEAR: 'Objetivo de generación anual',
        OBJETIVE_BY_RESOLUTION_YEAR: 'Objetivo de resolución anual',
        OBJETIVE_BY_GENERATION_MONTH: 'Objetivo de generación mensual',
        OBJETIVE_BY_RESOLUTION_MONTH: 'Objetivo de resolución mensual',
        SUCCESS_EDIT_MY_TEAM: 'Su equipo se ha actualizado correctamente',
        SUCCESS_CREATE_MY_TEAM: '¡Su equipo se creó exitosamente!',
        CANNOT_DELETE_MEMBER: 'No es posible eliminar el primer miembro',
        CANNOT_DELETE_TOOL: 'No es posible dejar sin herramientas al miembro',
        DELETE_MEMBER: 'Eliminar Miembro',
        DELETE_TOOL: 'Eliminar herramienta',
        REMOVE_RESPONSIBLE: 'Remover responsable',
        ASSIGN_RESPONSIBLE: 'Hacer responsable',
        ADD_TOOL: 'Agregar herramienta',
        TRAFFIC_LIGHT_CONFIGURATION: 'Semáforo de Indicadores',
        POSITION: 'Puesto',
        USER: 'Usuario',
        TOOLS: 'Herramientas',
        GENERAL_TEAM_INFORMATION: 'Información general del equipo',
        MEMBER: 'Miembro',
        SEE_PERFORMANCE: 'Ver Performance',
        MY_PERFORMANCE: 'Mi performance',
        TOOL: 'Herramienta',
        INDICATOR: 'Indicador',
        ACCUMULATED: 'Acumulado',
        DISABLED_TEAM: 'Equipo deshabilitado',
        DISABLE_TEAM: 'Deshabilitar equipo',
        ACTIVE_FILTERS: "Filtros Activos:",
        CLEAN_ALL_FILTERS: "Limpiar todos los filtros",
        INFO_MESSAGE_BANNER: "Recuerde que en caso de herramientas: Disciplina Progresiva y Accidente e incidente debe tener asignado al menos un rol laboral y un rol de servicio médico respectivamente.",
        ROLES: {
          MANAGER: "Manager",
          VIEW_ONLY: "Solo vista",
          LABOR: "Laborales",
          MEDICAL_SERVICE: "Servicio Médico"
        },
        PLACEHOLDER_ROLES: {
          ENABLED: "Seleccionar rol",
          DISABLED: "Debe seleccionar una herramienta"
        },
      },
      CREATE_CONFIGURATION: {
        BUSINESS: 'Negocio',
        ADMINISTRATOR: 'Administrador',
        NAME: 'Nombre y apellido',
        EMAIL: 'E-mail',
        MY_TEAM: 'Mi equipo',
        GROUPER: 'Agrupador',
        SCHEMA: 'Esquema',
        LEVELS_SCHEMA: 'Esquema de niveles',
        NEW_LEVELS_SCHEMA: 'Nuevo esquema de niveles',
        RISK_NOTICE: 'Aviso de riesgo',
        ASSOCIATED_REQUESTS: 'Solicitudes asociadas',
        ACCIDENT_AND_INCIDENT: 'Accidente e incidente',
        SECTOR: 'Sector de sector médico',
        SITE: 'Sitio',
        SUBLOCATION: 'Sublocación',
        SUCCESS: {
          CREATE_WISE_CONFIGURATION: '¡Configuración creada correctamente!',
        },
        ERRORS: {
          NAME: 'Debe ingresar un nombre válido',
          EMAIL: 'Debe ingresar un e-mail válido',
          BUSINESS: 'Debe seleccionar un negocio válido',
          GROUPER: 'Debe seleccionar un agrupador válido',
          ASSOCIATED_REQUESTS: 'Debe seleccionar al menos una solicitud asociada válida',
          SECTOR: 'Debe seleccionar un sector válido',
          LEVELS_SCHEMA: 'Debe seleccionar uno o más esquemas válidos',
        }
      },
      RISK_NOTICE: {
        NEW_LIST: {
          NOTICE_NUMBER: "Número",
          SITE: "Sitio",
          SUBLOCATION: "Sublocación",
          RESPONSABLE: "Responsable",
          SOLICITOR: "Solicitante",
          DATE: "Fecha",
          STATE: "Estado",
          TYPE_OF_POTENTIAL_RISK: "Tipo de riesgo potencial",
          RISK_NOTICE_STATE_NOTICE: "El aviso de riesgo cambia de estado a 'Solucionado' 5 minutos luego del cierre de la última acción relacionada"
        },
        NOTICE_NUMBER: "Número de aviso",
        TYPE_OF_SUBLOCATION: "Tipo de sublocación",
        SUBLOCATION: "Sublocación",
        DATE: "Fecha",
        STATE: "Estado",
        STATE_TITLE: "Estado: ",
        RECORD: "Historial",
        CREATED_BY_ME: "Creados por mí",
        ASSIGNED_TO_ME: "Asignados a mí",
        CREATE_RISK_NOTICE: {
          TITLES: {
            GENERAL_INFORMATION: "Información general",
            RISK_NOTICE_INFORMATION: "Información del aviso de riesgo",
            TO_ATTACH: "Adjuntar",
            ATTACH_IMAGE_OF_RISK: 'Adjuntar foto del riesgo',
            SEND_NOTICE: "Enviar aviso",
            SEE_PDF: "Ver PDF",
            POTENTIAL_RISK: "Riesgo potencial",
            RISK_TYPE: "Riesgo tipo",
            RISK_TYPE_TITLES: {
              SECURITY: "Seguridad",
              QUALITY: "Calidad",
              ENVIRONMENT: "Medio ambiente",
            },
            RISK_TYPE_CONTENT: {
              REQUIRES_IMMEDIATE_CORRECTION: "REQUIERE CORRECCIÓN INMEDIATA",
              TEXT_SECURITY_A: "Condición capaz de generar una muerte, accidente grave o con lesión permanente. \n Ej. Rodamiento / engranajes sin protección, escalera a altura rota o inestable, llave de crote de tensión sin funcionar, switch de seguridad sin funcionar.",
              TEXT_QUALITY_A: "Riesgo directo a la inocuidad alimentaria. \n Ej. Presencia de cuerpos extraños, arreglos provisorios en zonas críticas, rotura de vidrios / acrilicos, pérdidas de producto post tratamiento térmico, riesgos químicos, incumplimiento a reglas básicas, presencia de plagas en áreas de elaboración / envasado, incumplimiento de reglas de directiva de alérgenos (ropa, elementos de limpieza, etc.)",
              TEXT_ENVIRONMENT_A: "Condición o práctica capaz de generar un incumplimiento legal. \n Ej. Residuos especiales sin identificación, corrientes de residuos especiales mezclados entre sí, residuos especiales fuera del sitio de acopio definido, derrames de químicos / hidrocarburos, vuelcos de efluentes no controlados a canaleta / drenaje (Mayores a 5000L)",
              TEXT_SECURITY_B: "Condición que puede generar un accidente menor y/o de primeros auxilios. \n Ej. Canaleta de desagüe en malas condiciones, desniveles de piso, manguera en piso, falta de candado en un tablero.",
              TEXT_QUALITY_B: "Riesgo potencial para la inocuidad alimentaria.\n Ej. Presencia de cuerpos extraños sin riesgo directo, vidrios o acrílicos con rajaduras en zonas críticas, pérdidas de producto antes del tratamiento térmico, presencia de plagas en áreas externas o almacenes.",
              TEXT_ENVIRONMENT_B: "Condición o práctica que genera un uso innecesario de recursos. \n Ej. Pérdidas de agua / vapor, pérdidas de energía / aire, consumo evitable de energía / agua.",
              TEXT_SECURITY_C: "Condición con posibilidad de generar un incidente. \n Ej. Falta de señalización de un riesgo, piso sin demarcación, orden y limpieza.",
              TEXT_QUALITY_C: "Riesgo bajo (Sin riesgo para la seguridad alimentaria) \n Ej. Deficiencias de infraestructura sin impacto directo en el producto, arreglos provisorios fuera de zonas críticas, vidrios o acrílicos rajados en zona no críticas.",
              TEXT_ENVIRONMENT_C: "Resto de los desvíos no identificados anteriormente y que no ingresen como categoría A/B \n Ej. Condiciones que puedan provocar un impacto al Medio Ambiente, incumplimiento de estándares de MA: Segregación de Residuos",
            },
            ADD_IMAGE: "Agregar imagen",
          },
          SUBTITLES: {
            DATE_OF_THE_EVENT: "Fecha del suceso",
            TYPE_OF_INFORMANT: "Tipo de informante",
            REPORT_NAME: "Nombre y apellido de Informante",
            SITE: "Sitio",
            SUBLOCATION: "Sublocación",
            SECTOR_MANAGER: "Responsable de sector",
            ADDITIONAL_RESPONSIBLES: "Responsables adicionales",
            RISK: "Riesgo",
            SELECT_POTENTIAL_RISK: "Seleccionar Riesgo potencial",
            IMMEDIATE_ACTION: "Acción de contingencia",
            POTENTIAL_RISK_SELECTED: "Riesgo potencial seleccionado",
          },
          PLACEHOLDERS: {
            USER_SEARCH: "Búsqueda de usuarios",
            FLOOR_LEVEL_SELECTOR: "Selector nivel planta",
            LEVEL_SELECTOR: "Selector de nivel",
            SELECT_TYPE_OF_RISK: "Seleccionar tipo de riesgo",
            SELECT_TEAM: "Seleccionar equipo",
            SELECT_REQUEST: "Seleccione solicitud",
          },
          PREDEFINED_RISKS_TYPE: {
            ENVIRONMENTAL_RISK: "Seguridad - Riesgo Ambiental",
            HEALTH_RISK: "Seguridad - Riesgo de Salud",
            CHEMICAL_HAZARDS: "Seguridad - Riesgos Químicos",
            MECHANICAL_RISKS: "Seguridad - Riesgos Mecánicos",
            ELECTRICAL_RISKS: "Seguridad - Riesgos Eléctricos",
            BUILDING_RISKS: "Seguridad - Riesgos Edilicios",
            POTENTIAL_RIKS: "Seguridad - Riesgos Potenciales",
            RISK_OF_FIRE: "Seguridad - Riesgo de Incendio",
            TRAFFIC_RISK: "Seguridad - Riesgo de Tránsito",
            QUALITY_RISKS: "Seguridad - Riesgos de Calidad ",
            PHYSICAL_RISK: "Seguridad - Riesgo Físico",
            ERGONOMIC_RISKS: "Seguridad - Riesgos Ergonómicos",
            ENTRAPMENT_RISK: "Seguridad - Riesgo de Atrapamiento",
            TRANSPORTATION_RISK: "Seguridad - Riesgo de Transportes",
            DANGEROUS_ENERGY_HAZARD: "Seguridad - Riesgo con Energías Peligrosas",
            RISK_IN_CONFINED_SPACES: "Seguridad - Riesgo en Espacios Confinados",
            EMERGENCY_RISKS: "Seguridad - Riesgos de Emergencias",
            LOTO_UPGRADE_RISKS: "Seguridad - Riesgos de Mejora de LOTO",
            HEIGHT_RISKS: "Seguridad - Riesgos de Altura",
            QUALITY: {
              STRANGE_BODIES: "Calidad - Cuerpos Extraños",
              MICROBIOLOGICAL_RISK: "Calidad - Riesgo Microbiológico",
              CHEMICAL_RISK: "Calidad - Riesgo Químico",
              ALLERGENS: "Calidad - Alergenos",
              PESTS: "Calidad - Plagas",
              MAINTENANCE: "Calidad - Mantenimiento",
              OPERATING_METHODS: "Calidad - Métodos Operativos",
              GMP: "Calidad - GMP",
              OTHERS: "Calidad - Otros",
            },
            ENVIRONMENT: {
              WATER: "Medio Ambiente - Agua",
              AIR: "Medio Ambiente - Aire",
              ENERGY: "Medio Ambiente - Energía",
              SPILLS: "Medio Ambiente - Derrames",
              STEAM: "Medio Ambiente - Vapor",
              CHEMICAL: "Medio Ambiente - Químicos",
              SPECIAL_WASTE: "Medio Ambiente - Residuos especiales",
            },
            HEALTH: {
              BIOLOGICAL_RISK: "Salud - Riesgo Biológico",
              CHEMICAL_HAZARD: "Salud - Riesgo Químico",
              HEALTH_RISK: "Salud - Riesgo Sanitario",
              PSYCHOSOCIAL_RISK: "Salud - Riesgo Psicosocial",
            }
          },
        },
        EDIT_RISK_NOTICE: {
          CREATION_DATE: "Fecha de creación",
          REQUEST_NUMBER: "Número de solicitud",
          APPLICANT: "Solicitante",
          WORKSTATION: "Puesto de trabajo",
          REJECT: "Rechazar",
          LOAD_DEFINITIVE_ACTION: "Crear Acción",
          DERIVATE: "Derivar",
          SUCCESS_DERIVATION: "Derivado con éxito",
          SUCCESS_REJECT: "Rechazado con éxito",
          ERROR_DERIVATION: "Derivado con éxito",
          CHANGES_REASON_TITLE: "¿Qué cambios se han aplicado?",
          CHANGES_REASON: "Motivo de la edición",
          MESSAGE_BANNER_RISK: "¡Aviso de riesgo editado exitosamente!",
        },
        STATUS_STATE: {
          PENDING: "Pendiente de Acción",
          RESOLVED: "Solucionado",
          REJECTED: "Rechazado",
          DERIVED: "Derivado",
          PENDING_TWO: "Pendiente de Solución",
        },
        POPUPS: {
          REJECT_RISK_NOTICE: "Rechazo de aviso de riesgo",
          DERIVATION_RISK_NOTICE: "Derivación de aviso de riesgo",
          DEFINITIVE_ACTION_CHARGE: "Carga de acción",
        },
        DERIVE_TO: "Derivar a",
        SELECT_RESPONSIBLE: "Seleccione responsable",
        REASON_FOR_DERIVATION: "Motivo de derivación",
        DEFINITIVE_ACTION: "Acciones",
        EDIT_FIELDS: "Edición de campos",
        MESSAGE_BANNER_RISK: "¡Aviso de riesgo enviado exitosamente!",
        LOAD_REQUEST: "Cargar acción de aviso de riesgo",
        IMMEDIATE_ACTION_IMAGE: "Imagen de acción de contingencia",
        ATTACHED_FILES: "Archivos adjuntos",
        DESCRIPTION: "Descripción",
        DESCRIPTION_PLACEHOLDER: "Escribir aquí...",
        ATTACH_NOTE: "Se puede cargar un máximo de 2Mb por documento (pdf, docx, doc, xls, xlsx) o imagen (png, jpeg)",
        ATTACH_NOTE_IMAGE: 'Se puede cargar un máximo de 2Mb por imagen (png, jpeg)',
        FILE_NOT_VALID: "No es un archivo válido",
        FILE_SIZE: "El archivo supera el tamaño máximo permitido",
      },
      ACCIDENT_AND_INCIDENT: {
        MESSAGE_BANNER_SUCCESS: "¡Reporte de accidente e incidente enviado exitosamente!",
        NUMBER: "Número",
        SITE: "Sitio",
        SUB_SITE: "Subsitio",
        SUBLOCATION: "Sublocación",
        INJURED_PERSON: "Persona accidentada",
        DATE: "Fecha",
        STATE: "Estado",
        ATTACH_NOTE_IMAGE: 'Se puede cargar un máximo de 2Mb por imagen (png, jpeg)',
        FILE_NOT_VALID: "No es un archivo válido",
        FILE_SIZE: "El archivo supera el tamaño máximo permitido",
        TURN_AND_SCHEDULE: {
          TOMORROW: "Mañana",
          LATE: "Tarde",
          NIGHT: "Noche",
          CENTRAL: "Central",
          NORMAL: "Normal",
          EXTRA: "Extra",
        },
        CREATED_BY_ME: "Creados por mí",
        ASSIGNED_TO_ME: "Asignados a mí",
        CREATE_ACCIDENT_AND_INCIDENT: {
          TITLES: {
            GENERAL_INFORMATION: "Información general",
            ACCIDENT_AND_INCIDENT_INFORMATION: "Información del reporte de accidente e incidente",
            DESCRIPTION_ACCIDENT_AND_INCIDENT: "Datos del accidente e incidente",
            SEND: "Enviar",
            CLOSE: "Cerrar",
            ACCIDENTED_PERSON: "Persona accidentada",
          },
          SUBTITLES: {
            DATE_OF_ISSUE: "Fecha de emisión",
            DATE_OF_THE_EVENT: "Fecha del suceso",
            REPORT_NAME: "Informe a nombre de",
            SITE: "Sitio",
            SUB_SITE: "Subsitio",
            SUBLOCATION: "Sublocación",
            COMPANY: "Tipo",
            TYPE_OF_ACCIDENT: "Tipo de accidente",
            IS_NOT_ON_PAYROLL: "El accidentado NO es parte de la nómina",
            INJURED_PERSON: "Nombre y apellido",
            TYPE_OF_INJURY: "Tipo de lesión",
            LOCATION_OF_INJURY: "Ubicación de la lesión",
            EVENT_DATE: "Fecha del evento",
            EVENT_TIME: "Hora del evento",
            EVENT_DATE_AND_TIME: "Fecha y horario del evento",
            TURN: "Turno",
            SCHEDULE: "Horario",
            DESCRIPTION: "Descripción",
            IMMEDIATE_ACTION: "Acción inmediata",
            SUBPLANT: "Subplanta",
            SEE_DATA: "Ver Datos",
          },
          PLACEHOLDERS: {
            SELECTOR_DEFAULT: "Seleccionar",
            USER_SEARCH: "Búsqueda de usuarios",
            LEVEL_SELECTOR: "Selector de nivel",
            DESCRIPTION_PLACEHOLDER: "Escribir aquí...",
            TYPE_HERE: "Escribir aquí"
          },
          PREDEFINED_COMPANY: {
            INTERNAL: "Interno",
            EXTERNAL: "Externo",
            OTHER_PAYROLL: "Otra (Nivel nómina)"
          },
          PREDEFINED_ACCIDENT_TYPE: {
            MATERIAL_DAMAGE: "Accidente con daños materiales",
            PERSONAL_INJURY: "Accidente con lesión personal",
            IN_ITINERE: "Accidente in itinere",
            OCCUPATIONAL_DISEASE: "Enfermedad ocupacional",
            UNWANTED_ENVIRONMENTAL_EVENTS: "Eventos ambientales indeseados",
            OFF_WORK_EVENTS: "Eventos ocurridos fuera de trabajo",
            INCIDENT: "Incidente relacionado con materiales",
            INCIDENT_TWO: "Incidente relacionado con personas",
          },
          PREDEFINED_INJURY_TYPE: {
            IMPRISONMENT: "Aprisionamiento",
            TRAUMA: "Traumatismo",
            IRRITATION: "Irritación",
            PAIN: "Dolor",
            STRAIN_TORSION: "Distensión/torsión",
            ABRASIONS: "Escoriaciones",
            LACERATION: "Laceración",
            FISSURE_SPRAIN_FRACTURE: "Fisura/esguince/fractura",
            TEAR: "Desgarro",
            CUTTING_STAB_WOUND: "Herida cortante/punzante",
            BURN: "Quemadura",
            POISONING: "Intoxicación",
            LOW_BACK_BACK_CERVICAL_PAIN: "Lumbalgia/dorsalgia/cervicalgia",
            PHYSICAL_EFFORT: "Esfuerzo físico",
            EXPOSURE_TO_HEAT: "Exposición al calor",
            INFLAMMATION: "Inflamación",
          },
          PREDEFINED_LOCATION_INJURY: {
            HEAD: "Cabeza",
            EYES: "Ojos",
            RIGHT_EYE: "Ojo derecho",
            LEFT_EYE: "Ojo izquierdo",
            FACE: "Cara",
            NECK: "Cuello",
            CHEST: "Pecho",
            DORSAL_REGION: "Región dorsal",
            SHOULDER: "Hombro",
            LEFT_SHOULDER: "Hombro izquierdo",
            RIGHT_SHOULDER: "Hombro derecho",
            ARM: "Brazo",
            LEFT_ARM: "Brazo izquierdo",
            RIGHT_ARM: "Brazo derecho",
            ELBOW: "Codo",
            LEFT_ELBOW: "Codo izquierdo",
            RIGHT_ELBOW: "Codo derecho",
            FOREARM: "Antebrazo",
            LEFT_FOREARM: "Antebrazo izquierdo",
            RIGHT_FOREARM: "Antebrazo derecho",
            WRIST: "Muñeca",
            LEFT_WRIST: "Muñeca izquierda",
            RIGHT_WRIST: "Muñeca derecha",
            HAND: "Mano",
            LEFT_HAND: "Mano izquierda",
            RIGHT_HAND: "Mano derecha",
            FINGERS_OF_THE_HAND: "Dedos de la mano",
            FINGERS_OF_THE_RIGHT_HAND: "Dedos Mano Derecha",
            FINGERS_OF_THE_LEFT_HAND: "Dedos Mano Izquierda",
            ABDOMEN: "Abdomen",
            LUMBAR_REGION: "Región lumbar",
            HIP: "Cadera",
            THIGH: "Muslo",
            KNEE: "Rodilla",
            LEFT_KNEE: "Rodilla izquierda",
            RIGHT_KNEE: "Rodilla derecha",
            LEG: "Pierna",
            LEFT_LEG: "Pierna izquierda",
            RIGHT_LEG: "Pierna derecha",
            ANKLE: "Tobillo",
            LEFT_ANKLE: "Tobillo izquierdo",
            RIGHT_ANKLE: "Tobillo derecho",
            FOOT: "Pie",
            LEFT_FOOT: "Pie izquierdo",
            RIGHT_FOOT: "Pie derecho",
            TOES: "Dedos del pie",
            LEFT_TOES: "Dedos Pie Izquierdo",
            RIGHT_TOES: "Dedos Pie Derecho",
            CERVICAL: "Cervical",
          }
        },
        MEDICAL_SERVICE: {
          TITLES: {
            MEDICAL_SERVICE_DATA: "Datos de servicio médico",
            SEND: "Enviar",
            REJECT: "Rechazar",
          },
          SUBTITLES: {
            MEDIC_NAME: "Nombre y apellido del médico",
            MEDICAL_ATTENTION_DATE: "Fecha de atención médica",
            EVENT_TYPE: "Clasificación del evento",
            DIAGNOSTIC: "Diagnóstico",
            RELEASE_DATE: "Fecha de alta",
            ADMISSION_DATE: "Fecha de baja",
            DAYS_LOST: "Días perdidos",
          },
          PLACEHOLDERS: {
            TYPE_HERE: "Escribir aquí...",
            LEVEL_SELECTOR: "Selector de nivel",
          },
        },
        DETAIL_ACCIDENT_AND_INCIDENT: {
          TITLES: {
            GENERAL_INFORMATION: "Información general",
            DESCRIPTION_ACCIDENT_AND_INCIDENT: "Datos del accidente e incidente",
            RECORD: "Historial",
            SEND: "Enviar",
            PERSONAL_DATA: "Datos de la persona",
            LOAD_DATA: "Cargar datos",
            LOAD_DATA_MEDICAL_SERVICES: "Cargar servicio médico",
            NEXT: "Siguiente",
            ERROR_TO_OPEN: "Hubo un error en la aplicación al intentar abrir el detalle seleccionado, por favor intente de vuelta más tarde. Si el error persiste comuniquese con soporte. Será redirigido en... ",
            STEP_1: "Paso 1: Carga de datos de evento",
            EVENT_TRACKING: "Trackeo de evento",
            STEP_2: "Paso 2: Datos de equipo de investigación",
            RISK_MATRIX: "Matriz de riesgo",
            RESEARCH_MODEL: "Modelo de investigación",
            FINAL_PHASE: "Cierre de informe",
            MEDICAL_SERVICE: "Datos de servicio médico",
            RESEARCH_MODEL: "Modelo de investigación",
          },
          SUBTITLES: {
            CREATION_DATE: "Fecha de creación",
            APPLICATION_NUMBER: "Número de solicitud",
            APPLICATION: "Solicitante",
            WORKSTATION: "Puesto de trabajo",
            REPORT_NAME: "Informe a nombre de",
            TYPE_OF_INFORMANT: "Tipo de informante",
            INFORMANT_DATA: "Nombre y apellido de Informante",
            SUBLOCATION: "Sublocación",
            COMPANY: "Tipo",
            TYPE_OF_ACCIDENT: "Tipo de accidente",
            INJURED_PERSON: "Nombre y apellido",
            TYPE_OF_INJURY: "Tipo de lesión",
            LOCATION_OF_INJURY: "Ubicación de la lesión",
            EVENT_DATE_AND_TIME: "Fecha y hora del evento",
            TURN: "Turno",
            SCHEDULE: "Horario",
            SUBLOCATION_SUBPLANT: "Sublocación",
            RESPONSIBLE_BOSS: "Jefe responsable de sector",
            DESCRIPTION: "Descripción",
            IMMEDIATE_ACTION: "Acción inmediata",
            IMAGE: "Imagen cargada",
            IMAGES: "Imagenes cargadas",
          },
          PLACEHOLDERS: {
            SELECTOR_DEFAULT: "Seleccionar",
            USER_SEARCH: "Búsqueda de usuarios",
            LEVEL_SELECTOR: "Selector de nivel",
            DESCRIPTION_PLACEHOLDER: "Escribir aquí...",
            TYPE_HERE: "Escribir aquí"
          },
        },
        DATA_OF_THE_LOADING_EVENT_DATA: {
          TITLES: {
            DATA: "Datos de la persona afectada",
            EVENT_TRACKING: "Trackeo de evento",
            SUCCESS_MESSAGE_BANNER: "¡Paso 1 realizado exitosamente!",
            SUCCESS_PARTIAL_SAVED: "¡Éxito al guardar!",
            INFO_PARTIAL_SAVED: "Usted esta editando un borrador.",
          },
          SUBTITLES: {
            EMPLOYEE_TYPE: "Tipo",
            NAME_AND_SURNAME: "Nombre y apellido",
            EMPLOYEE_ID: "ID de empleado",
            HIRING_DATE: "Fecha de contratación",
            NAME_OF_EMPLOYER: "Nombre del empleador",
            DEPARTMENT: "Departamento",
            BIRTHDATE: "Fecha de nacimiento",
            AGE: "Edad",
            GENDER: "Género",
            DATE_AND_TIME_OF_THE_EVENT: "Fecha y horario del evento",
            DATE_AND_TIME_REPORTED: "Fecha y horario reportado",
            TIME_IN_TURN: "Tiempo en turno",
            TURN: "Turno",
            LOCATION: "Locación",
            SUBLOCATION: "Sublocación",
            SPECIFIC_LOCATION: "Locación específica",
          },
          GENDERS: {
            MALE: "Masculino",
            FEMALE: "Femenino",
            OTHER: "Otro",
          }
        },
        DATA_OF_THE_RESEARCH_DATA_UPLOAD: {
          TITLES: {
            DATA: "Datos de equipo de investigación",
            RISK_MATRIX: "Matriz de riesgo",
            RESULT: "Resultado",
            CALCULATE: "Calcular",
            RISK: "RIESGO",
            SUCCESS_MESSAGE_BANNER: "¡Paso 2 realizado exitosamente!",
          },
          SUBTITLES: {
            DESCRIPTION: "Descripción del evento",
            RELATED_TO_12_BASICS: "Relacionado a 12 básicos",
            BODY_PARTS_AFFECTED: "Partes del cuerpo afectadas",
            TYPE_OF_INJURY: "Tipo de lesión",
            WITNESSES: "Nombre de testigos",
          },
          FIVE_WHYS: {
            LOSS: "Pérdida",
            INCIDENT: "Incidente",
            IMMEDIATE_CAUSE: "Causa Inmediata",
            BASIC_CAUSE: "Causa Básica",
            ROOT_CAUSE: "Causa Raíz",
          },
          SELECTOR_12_BASICS: {
            ONE: "Trabajo en altura",
            TWO: "Productos químicos",
            THREE: "Espacios confinados",
            FOUR: "Polvos explosivos",
            FIVE: "Gases explosivos",
            SIX: "Incendio y evacuación",
            SEVEN: "Seguridad en maquinas y equipos",
            EIGHT: "Autoelevadores y carretillas elevadoras",
            NINE: "Racks y pallets",
            TEN: "Carga y descarga de camiones",
            ELEVEN: "Flota de camiones",
            TWELVE: "Permisos de trabajo",
            THIRTEEN: "No aplica",
            FOURTEEN: "LOTO",
          },
          POPUP: {
            POPUP_ASOCIATED_ACTIONS: "Acciones asociadas",
            DATE: "Fecha",
            STATE: "Estado",
            NAME: "Nombre",
            POPUP_ASOCIATED_RISK_NOTICE: "Asociar aviso de riesgo",
            SELECT_SECTOR: "Sector",
            ASSOCIATED_REQUEST: "Asociar solicitud",
            SELECT_ASOCIATED_REQUEST: "Solicitud asociada a la acción",
            REFERENCE: "Referencia",
            NEGLIGENCE: "Negligencia",
            LOW: "Bajo",
            MEDIUM: "Medio",
            HIGH: "Alto",
            VERY_HIGH: "Muy alto",
            DELETE: {
              TEXT: "Recuerde que si elimina un tipo de contacto, también se eliminarán las causas inmediatas y causas básicas vinculadas a este. De la misma forma que si elimina una causa inmediata, las causas básicas vinculadas serán eliminadas.",
              TEXT_2: "¿Desea realizar de igual manera esta acción?",
            },
            OPTIONAL_INVESTIGATION: {
              TITLE: "Modelo de Investigación opcional",
              TEXT_ONE: "Matriz de riesgo da menor a ",
              TEXT_TWO: "Investigación a criterio de la unidad de negocio.",
              POINTS: " puntos.",
              BUTTON: "Modelo de Investigación",
            }
          },
          RISK_MATRIX: {
            GRAVITY: {
              TITLE: "Gravedad (G)",
              LABEL_1: "Sin lesiones",
              LABEL_2: "Menor",
              LABEL_3: "Importante",
              LABEL_4: "Severo",
              LABEL_5: "Fatal",
              LABEL_6: "Catastrófico",
              DESCRIPTION_1: "",
              DESCRIPTION_2: "Primeros auxilios necesarios",
              DESCRIPTION_3: "(Evento con tiempo perdido/Evento sin tiempo perdido)",
              DESCRIPTION_4: "Lesiones serias que requieren tratamiento a largo plazo o discapacidad permanente",
              DESCRIPTION_5: "Una fatalidad",
              DESCRIPTION_6: "Varios fallecimientos",
            },
            PROBABILITY: {
              TITLE: "Probabilidad (P)",
              LABEL_1: "Imposible en tiempo real",
              LABEL_2: "Casi imposible",
              LABEL_3: "Es posible, pero improbable",
              LABEL_4: "Sólo posible a largo plazo",
              LABEL_5: "Inusual pero posible",
              LABEL_6: "Posible",
              LABEL_7: "Previsible",
              DESCRIPTION_1: "",
              DESCRIPTION_2: ">20 años, una vez en la vida",
              DESCRIPTION_3: "1/20 años",
              DESCRIPTION_4: "1/10 años",
              DESCRIPTION_5: "1/3 años",
              DESCRIPTION_6: "1/6 meses",
              DESCRIPTION_7: "1 semana",
            },
            EXPOSITION: {
              TITLE: "Exposición (E)",
              LABEL_1: "Muy excepcional",
              LABEL_2: "Pocas veces al año",
              LABEL_3: "Mensualmente",
              LABEL_4: "Semanalmente u ocasionalmente",
              LABEL_5: "Diario",
              LABEL_6: "Permanente",
              DESCRIPTION_1: "<1/año",
              DESCRIPTION_2: "Pocas veces/año",
              DESCRIPTION_3: "1-2/mes",
              DESCRIPTION_4: "1/semana",
              DESCRIPTION_5: ">1/día",
              DESCRIPTION_6: ">2/día",
            }
          },
          RESEARCH_MODEL: {
            TITLES: {
              RESEARCH_MODEL: "Modelo de investigación",
              ANALYSIS_TECHNIQUE: "Técnica de Análisis Sistemático de Causa",
              ADD_NEW_LINE: "Añadir nueva línea",
              FIVE_WHY: "Cinco ¿Por qué?",
              ADD_FIVE_WHY: "Agregar cinco ¿por qué?",
            },
            SUBTITLES: {
              LOSS_DESCRIPTION: "Pérdida/Descripción del accidente",
              TYPE_OF_CONTACT: "Tipos de contacto/Incidente",
              IMMEDIATE_CAUSES: "Causas Inmediatas",
              BASIC_CAUSES: "Causas Básicas",
              ROOT_CAUSES: "Causas Raíz",
            },
            MORE_OPTIONS: {
              TYPE_OF_CONTACTS: {
                DELETE: "Eliminar tipo de contacto",
                ASSOCIATED_RISK_WARNING: "Asociar creación de aviso de riesgo",
                ASSOCIATED_REQUESTS: "Asociar creación de solicitud",
                SEE_REQUEST_DETAIL: "Ver acciones asociadas",
              },
              IMMEDIATE_CAUSES: {
                DELETE: "Eliminar causa inmediata",
                ADD: "Agregar causa inmediata",
                ASSOCIATED_RISK_WARNING: "Asociar creación de aviso de riesgo",
                ASSOCIATED_REQUESTS: "Asociar creación de solicitud",
                UNLINK_REQUEST: "Desvincular solicitud",
                SEE_REQUEST_DETAIL: "Ver acciones asociadas",
              },
              BASIC_CAUSES: {
                DELETE: "Eliminar causa básica",
                ADD: "Agregar causa básica",
                ASSOCIATED_RISK_WARNING: "Asociar creación de aviso de riesgo",
                ASSOCIATED_REQUESTS: "Asociar creación de solicitud",
                UNLINK_REQUEST: "Desvincular solicitud",
                SEE_REQUEST_DETAIL: "Ver acciones asociadas",
              },
            },
            ACTIONS_CREATED: {
              ACTIONS_CREATED_LIST: "Acciones a tomar creadas",
              LIST_TITLES: {
                CREATION_DATE: "Fecha de Creación",
                CAUSE_TO_ACTION: "Causa a accionar",
                ACTION_TYPE: "Tipo de Acción",
                DESCRIPTION: "Descripción",
                STATE: "Estado"
              },
              ADD: "Agregar causa básica",
              ASSOCIATED_RISK_WARNING: "Asociar creación de aviso de riesgo",
              ASSOCIATED_REQUESTS: "Asociar creación de solicitud",
              UNLINK_REQUEST: "Desvincular solicitud",
              SEE_REQUEST_DETAIL: "Ver acciones asociadas",
            },
          },
        },
        DATA_OF_CLOSURE: {
          TITLES: {
            CLOSING_THE_REPORT: "Cierre del informe",
            CLOSE_REPORT: "Cerrar informe",
            SUCCESS_MESSAGE_BANNER: "¡Paso 3 realizado exitosamente!",
          },
          SUBTITLES: {
            DESCRIPTION: "Descripción",
            ATTACH_LESSON_LEARNED: "Adjuntar lección aprendida",
          },
        },
        STEPS: {
          TITLES: {
            STEP_ONE: "Paso 1",
            STEP_TWO: "Paso 2",
            STEP_THREE: "Paso 3",
          },
          DESCRIPTIONS: {
            EVENT: "Evento",
            INVESTIGATION: "Investigación",
            CLOSURE: "Cierre",
          },
        },
        STATUS_STATE: {
          PENDING: "Pendiente de investigación",
          ON_INVESTIGATON: "En investigación",
          PENDING_TO_CLOSE: "Pendiente de cierre",
          CLOSED: "Finalizado"
        },
      },
      PROGRESSIVE_DISCIPLINE: {
        LIST: {
          ACTIVE_FILTERS: "Filtros Activos:",
          CLEAN_ALL_FILTERS: "Limpiar todos los filtros",
          NUMBER: "Número",
          NAME_OF_AUDITOR: "Nombre del auditor",
          SECTOR_OF_HAPPENING: "Sector donde ocurrió",
          SECTOR: "Sublocación",
          WORKER_TYPE: "Tipo de personal",
          FULLNAME_OF_AUDITED: "Nombre y apellido del auditado",
          NAME_OF_AUDITED: "Nombre del auditado",
          STATUS: "Estado",
          DATE: "Fecha",
          EXPORT_TO_EXCEL: "Exportar a Excel",
          DIVERSION_TYPE: "Tipo de desvío",
        },
        CREATE: {
          WORKER_TYPES: {
            INTERNAL: "Interno",
            EXTERNAL: "Externo",
            AGENCY: "Agencia",
          },
          DEVIATION_RECOGNITION_TYPES: {
            TYPE_0: "ADA- Regla General o de Oro Sitio",
            TYPE_1: "ADA- Regla Cardinal",
            TYPE_2: "ADA- Estandar de Calidad",
            TYPE_3: "ADA- Estándar Operativo de Área (No Seguridad)",
            TYPE_4: "ADA- Estándar Operativo de Área (Seguridad)",
            TYPE_5: "ADA- Regla General o de Oro Autoelevador",
            TYPE_6: "ADA- Regla General o de Oro Transportista",
            TYPE_7: "ADA- Estandar Critico",
            TYPE_8: "ADA- Procedimiento Interno",
            TYPE_9: "LCH- Desvíos Terminos de Seguridad",
            TYPE_10: "LCH- Desvíos Terminos de Calidad",
            TYPE_11: "LCH- Desvíos Terminos de Jornada de trabajo",
            TYPE_12: "LCH- Desvíos Terminos de Bienestar",
            TYPE_13: "LCH- Desvíos Terminos Operativos",
            TYPE_14: "LCH- Reconocimiento Danoner Icare",
            TYPE_15: "LCH- Reconocimiento Danoner wise",
            TYPE_16: "LCH- Reconocimiento Valores en Acción",
            TYPE_17: "LCH- Reconocimiento Innovación",
            TYPE_18: "LLSSA - Desvíos Terminos de Seguridad",
            TYPE_19: "LLSSA - Desvíos Terminos de Calidad",
            TYPE_20: "LLSSA - Desvíos Termino de Jornada de trabajo",
            TYPE_21: "LLSSA - Desvíos Terminos de Bienestar",
            TYPE_22: "LLSSA - Desvíos Temrinos Operativos",
            TYPE_23: "LLSSA - Reconocimiento Danoner Icare",
            TYPE_24: "LLSSA - Reconocimeinto Danoner Wise",
            TYPE_25: "LLSSA - Reconocimiento Valores en Acción",
            TYPE_26: "LLSSA - Reconocimiento Innovación",
            TYPE_27: "KAS - Estándar de Calidad",
            TYPE_28: "KAS - Estándar Operativo de Área",
            TYPE_29: "KAS - Estándar crítico",
            TYPE_30: "KAS - Regla de Oro",
            TYPE_31: "KAS - Procedimiento interno",
            TYPE_32: "KAS - Desvíos en terminos de Seguridad",
            TYPE_33: "KAS - Desvíos en terminos de Calidad",
            TYPE_34: "KAS - Desvíos en terminos de Jornada laboral",
            TYPE_35: "KAS - Desvíos en terminos de Bienestar",
            TYPE_36: "KAS - Reconocimiento Danone ICare",
            TYPE_37: "KAS - Reconocimiento Danone WISE",
            TYPE_38: "KAS - Reconocimiento Innovación",
            TYPE_39: "KAS - Reconocimiento de valores",
          },
          GENERAL_INFORMATION: "Información general",
          CREATION_DATE: "Fecha de emisión",
          WHERE_IT_HAPPENED: "¿Dónde ocurrió?",
          SUB_TEXT: "¿A qué sector pertenece el auditado?",
          AUDITED_INFORMATION: "Información del auditado",
          SECTOR: "Sector",
          SECTOR_PLACEHOLDER: "Nivel de sector de trabajo del auditado",
          DEVIATION_RECOGNITION_TYPE: "Tipo de desvio/reconocimiento",
          WORKER_TYPE: "Tipo de personal",
          WORKER_TYPE_PLACEHOLDER: "Seleecionar tipo",
          NAME_OF_AUDITED: "Nombre del auditado",
          NAME_OF_AUDITED_PLACEHOLDER: "Seleccionar auditado",
          FULLNAME_OF_AUDITED: "Nombre y apellido del auditado",
          FULLNAME_OF_AUDITED_PLACEHOLDER: "Escribir aquí",
          HAS_CONTACT: "¿Tiene contacto con el auditado?",
          YES: "Sí",
          NO: "No",
          CREATED_SUCCESSFULLY: "¡Disciplina progresiva creada con éxito!",
          SUBTITLES_SITE: "Sitio",
          PLACEHOLDER_LEVEL_SELECTOR: "Selector de nivel",
          SUBLOCATION: "Sublocación",
        },
        DETAIL: {
          RECORD: "Historial",
          GENERAL_INFORMATION: "Información general",
          CREATION_DATE: "Fecha de creación",
          AUDITOR_NAME: "Nombre del auditor",
          WHERE_IT_HAPPENED: "¿Dónde ocurrió?",
          LOCATION: "Sitio",
          SUBLOCATION: "Sublocación",
          LOCATION_RESPONSIBLE: "Responsable de sector",
          AUDITED_INFORMATION: "Información del auditado",
          HR_RESPONSIBLE: "Responsable de RRHH",
          DEVIATION_RECOGNITION_TYPE: "Tipo de desvio/reconocimiento",
          WORKER_TYPE: "Tipo de personal",
          FULLNAME_OF_AUDITED: "Nombre y apellido del auditado",
          OBSERVATIONS: "Observaciones",
          RELEASE: "Descargo",
          HAS_CONTACT: "¿Tiene contacto con el auditado?",
          RESOLVE: "Resolver",
          RESOLUTION_TITLE: "Resolución de disciplina progresiva",
          RESOLUTION_DATE: "Fecha",
          RESOLUTION_USER: "Nombre del Responsable",
          RESOLUTION_MEASURE_TYPE: "Tipo de medida",
          RESOLUTION_OBSERVATION_TITLE: "Observaciones"
        },
        RESOLVE: {
          MEASURE_TYPES: {
            POSITIVE_AGREEMENT: "Acuerdo positivo",
            WRITTEN_WARNING: "Apercibimiento por escrito",
            VERBAL_AWARENESS: "Apercibimiento verbal",
            DISMISSAL: "Despido",
            SEND_REPORT_CONSULT_STAFF: "Enviar informe - Consultar a personal",
            SEND_REPORT_TO_CONTRACTOR: "Enviar informe al contratista",
            RECOGNITION: "Reconocimiento",
            PENALTY_TO_BE_REVIEWED: "Sanción a revisar por contratista",
            SUSPENSION: "Suspensión",
          },
          GENERAL_INFORMATION: "Información general",
          MEASURE_TYPE: "Tipo de medida",
          MEASURE_TYPE_PLACEHOLDER: "Seleccione una opción",
          OBSERVATIONS: "Observaciones",
          RELEASE: "Descargo",
          OBSERVATIONS_PLACEHOLDER: "Escribir aquí...",
        }
      },
      POSITIVE_AGREEMENT: {
        LIST: {
          NUMBER: "Número",
          SITE: "Sitio",
          SUBLOCATION: "Sublocación",
          RESPONSABLE: "Responsable",
          APPLICANT: "Solicitante",
          DATE: "Fecha",
          TYPE_OF_AGREEMENT: "Tipo de acuerdo",
          EXCEL: "Exportar a Excel",
          CREATED_BY_ME: "Creados por mí",
          ASSIGNED_TO_ME: "Asignados a mí"
        },
        CREATION: {
          TITLES: {
            FIRST: "Información general",
            SECOND: "¿Dónde se detectó el acuerdo positivo?"
          },
          SUBTITLES: {
            DATE: "Fecha",
            APPLICANT: "Solicitante",
            APPLICANTS_SECTOR: "Sector del solicitante",
            INFORMED_ON_BEHALF_OF: "Informó a nombre de",
            LOCATION: "Sitio",
            SUBLOCATION: "Sublocación",
            TYPE_OF_OPPORTUNITY: "Tipo de acuerdo",
            TYPES_OF_OPPORTUNITIES: {
              FIRST: "Mejora",
              SECOND: "Reconocimiento"
            },
            AGREEMENT_MADE: "Acuerdo realizado",
            RECOGNITION: "Reconocimiento"
          },
          SUBMIT_BUTTON: "Guardar y enviar",
          MESSAGE_BANNER: "¡Acuerdo positivo creado exitosamente!",
          STATE_TITLE: "Estado: Finalizado",
        }
      }
    },
    USERS: {
      VIEW: {
        WISE_ADMIN: 'Admin Wise',
        NAME_COLUMN: "Nombre y Apellido",
        EMAIL_COLUMN: "E-mail",
        POSITIONS: "Puesto",
      },
      CREATE_EDIT: {
        NAME: "Nombre y Apellido ",
        EMAIL: "E-mail ",
        ADMIN_BUSINESS: "Administrador de negocio",
        ADMIN_WISE: "Administrador WISE",
        ADMIN_SECTOR: "Administrador de sector",
        ADMIN_TRAININGS: "Administrador de Capacitaciones",
        USER_SSO: "Usuario Microsoft",
        SECTOR: 'Sector',
        SELECT_SECTOR_PLACEHOLDER: 'Seleccione un sector',
        PAYROLL: "Datos de la nómina",
        NPlus1: 'N+1',
        NPlus1_PLACEHOLDER: 'Seleccionar usuario',
        NPlus1_NO_DATA: 'No posee usuarios creados',
        SUCCESS: "Usuario creado correctamente",
        SUCCESS_EDIT: "Usuario editado correctamente",
        ERRORS: {
          NAME: 'Debe ingresar un nombre',
          NAME_NOT_NUMBER: 'El nombre no permite números',
          EMAIL: 'Debe ingresar un mail',
          VALID_EMAIL_ERROR: 'Debe ingresar una dirección de e-mail válida',
          SECTOR_ID: 'Debe seleccionar un sector'
        }
      }
    },
    PEOPLE: {
      CREATE_EDIT: {
        SUCCESS: "Persona creada correctamente",
        SUCCESS_EDIT: "Persona editada correctamente",
      }
    },
    LEVEL_INSTANCE: {
      NEW: 'Nuevo',
      EDIT: 'Editar',
      DETAIL: 'Detalle',
      RISE: 'Importación masiva de niveles',
      ASSOCIATED_EMAILS: 'E-mails externos asociados',
      ASSOCIATED_ACTIONS: 'Acciones vinculadas',
      HISTORY: "Historial",
      PAGINATED: {
        REQUESTS: {
          ID: 'Número',
          TYPE: 'Tipo de solicitud',
          STATUS: 'Estado',
          URGENCY: 'Urgencia'
        },
        SURVEYS: {
          ID: 'Número',
          NAME: changeSurveyBySector('Nombre del periodico', 'Nombre de la auditoría'),
          STATUS: 'Estado'
        },
        EXPIRATIONS: {
          ID: 'Número',
          NAME: 'Nombre del vencimiento',
          STATUS: 'Estado'
        }
      }
    },
    DIVISIONS: {
      VIEW: {
        NAME_COLUMN: "Nombre",
        SECTOR_COLUMN: "Sector",
        NO_DATA_MESSAGE: "No posee divisiones creadas",
        CREATE_DATA_MESSAGE: "Por favor, cree una nueva división.",
        ACTIVES: 'Habilitadas',
        INACTIVES: 'Deshabilitadas',
        LEVEL: "Nivel",
      },
      CREATE_EDIT: {
        NAME: "Nombre de la división",
        SECTOR: "Sector",
        SELECT_SECTOR: "Seleccionar sector",
        LEVEL: "Nivel",
        SELECT_LEVEL: "Seleccionar nivel",
        ADD_LEVELS: "Añadir niveles",
        REQUESTS: "Solicitudes",
        EXPIRATIONS: "Vencimientos",
        AUDITS: changeSurveyBySector("Periodicos", "Auditorías"),
        COURSES: "Registro de Capacitaciones - Cursos",
        SELECT_REQUESTS: "Seleccionar solicitudes",
        ROLES: "Roles",
        ROLES_TRAININGS: "Roles de Registro de Capacitaciones",
        INVENTORY: "Inventario",
        WAREHOUSE: "Almacén",
        ADD_USERS: "Añadir usuarios",
        SELECT_WAREHOUSE: "Seleccionar Almacén",
        SELECT_EXPIRATION_REQUESTS: "Seleccionar vencimientos",
        SELECT_AUDITS: changeSurveyBySector("Seleccionar periodicos", "Seleccionar auditorías"),
        SELECT_COURSES: "Seleccionar cursos",
        SUCCESS: "División creada correctamente",
        SUCCESS_EDIT: "División editada correctamente",
        CREATORS: "Creadores",
        PLANNERS: "Planificadores",
        READ_ONLY: "Sólo lectura",
        AUDITORS: "Auditores",
        ORGANIZERS: "Organizadores",
        CHIEFS: "Jefes",
        INSTRUCTORS: "Instructores",
        SELECT_ALL: 'Seleccionar todo',
        CLEAN_SEARCH: 'Limpiar búsqueda',
        ACTIVE: 'Habilitada',
        ERRORS: {
          NAME: 'Debe ingresar un nombre',
          EMAIL: 'Debe ingresar un mail',
          SECTOR: 'Debe elegir un sector',
          SELECT_LEVELS: 'Debe elegir un tipo de nivel',
          ADD_LEVELS: 'Debe añadir al menos un nivel',
          ADD_REQUESTS_OR_EXPIRATIONS: 'Debe añadir al menos una solicitud o vencimiento',
          ADD_USERS: 'Debe añadir al menos un usuario',
          ADD_AUDITS: changeSurveyBySector('Si elige un responsable de periodicos debe elegir al menos una periodico', 'Si elige auditores debe elegir al menos una auditoria'),
        }
      },
    },
    REQUESTS: {
      VIEW: {
        ID_COLUMN: 'ID',
        NUMBER_COLUMN: 'N°',
        REQUEST_COLUMN: 'Solicitud',
        LEVEL_COLUMN: 'Nivel',
        REQUEST_NUMBER: 'Número de solicitud',
        LEVEL_FK_COLUMN: 'Código de nivel',
        REQUEST_TYPE_COLUMN: 'Tipo de solicitud',
        DATE_COLUMN: 'Fecha de creación',
        DESCRIPTION_ONLY_COLUMN: 'Descripción',
        DESCRIPTION_COLUMN: 'Descripción y/o problema',
        STATUS_COLUMN: 'Estado',
        PHASE_COLUMN: 'Etapa',
        URGENCY_COLUMN: 'Urgencia',
        ASSOCIATED_SURVEY_COLUMN: changeSurveyBySector('Periodicos vinculados', 'Auditoría vinculada'),
        ASSOCIATED_TOOLS_COLUMN: 'Herramienta Relacionada',
        ITEM_RESPONSE_COLUMN: 'Respuesta de item',
        FINALIZATION_DATE_COLUMN: 'Fecha de Finalización',
        FINALIZATION_USER_COLUMN: 'Usuario finalizador',
        CREATOR_USER_COLUMN: 'Usuario creador',
        ACTIVE_FILTERS: 'Filtros activos',
        CLEAR_ALL_FILTERS: 'Limpiar todos los filtros',
        ORDER_OF_ARRIVAL: 'Orden de llegada:',
        CREATED_BY: 'Creado por:',
        CREATION: 'Creación',
        FINALIZATION: 'Finalización',
        REQUESTS_TO_COMPLETE: 'Solicitudes_para_completar',
        PDF_DOWNLOAD: 'Descarga de .PDF',
        MASSIVE: 'Masiva',
        EXPIRED_SCHEDULING: 'Programación vencida',
        PLACEHOLDERS: {
          REQUEST_NUMBER: 'N° de solicitud',
          CHOOSE_LEVEL: 'Elegir nivel',
          DATE_FROM_CREATION: 'Fecha de Creación',
          DATE_TO: 'Fecha Hasta',
          STAGES: 'Etapas',
          FINALIZATION_USERS: 'Usuarios finalizadores',
        },
        ATTRIBUTES: {
          ATTRIBUTES: 'Atributos',
          FILTER_ATTRIBUTES: 'Filtrar atributos',
          ATTRIBUTE_FILTER: 'Filtro de Atributos',
          APPLY_FILTERS: 'Aplicar Filtros',
          RETURN_TO_LIST: 'Volver al listado'
        },
        EXCEL: {
          FROM_TO: (from, to) => `Desde ${from} hasta ${to}`,
          FROM_UNTIL_TODAY: (from) => `Desde ${from} hasta hoy`,
          EXPORT_TO_EXCEL: 'Exportar a Excel',
          SELECT_VERSION: 'Seleccione la versión que desea descargar:',
          FILTER_FIRST: 'Primero filtre por tipo de solicitud',
          EXPORT: 'Exportar'
        },
        DELETION: {
          DELETION: "Eliminación",
          DELETE_SECTION: "Eliminar sección",
          INDIVIDUAL: "Individual",
          MASSIVE: "Masiva",
          DELETE_REQUEST: "Eliminar",
          DELETE_REQUEST_MESSAGE: "¿Desea eliminar esta Solicitud?",
          DELETE_REQUESTS_MESSAGE: "¿Desea eliminar las solicitudes seleccionadas?",
          DELETE_REQUEST_MESSAGE_WARNING: "Esta acción no se puede deshacer",
          DELETE_REQUEST_SUCCESS: "¡Solicitud/es eliminada/s exitosamente!",
        },
        URGENCIES: {
          HIGH: 'Alta',
          MEDIUM: 'Media',
          LOW: 'Baja'
        },
        STATUS: {
          PENDING: 'Pendiente',
          FINALIZED: 'Finalizada',
          REJECTED: 'Rechazada'
        },
        ORDER: {
          DEFAULT: 'Predeterminado',
          NEWEST: 'Mas recientes',
          OLDEST: 'Mas antiguas'
        },
        MYPENDINGS: 'Mis pendientes',
        MYFINISHES: 'Mis finalizadas',
        ALL: 'Todas',
        WITHOUT_ASSIGN: 'Sin asignación',
      },
      VIEW_DETAIL: {
        ID: 'ID',
        REQUEST: 'Solicitud',
        GENERAL_INFO: "Información general",
        STATUS: "Estado",
        REQUEST_TYPE: 'Tipo de solicitud',
        DATE: 'Fecha de creación',
        PROGRAMED_DATE: 'Fecha de programación',
        REQUEST_NUMBER: 'Número de solicitud',
        CREATOR_NAME: 'Nombre del creador',
        DOWNLOAD: "Descargar",
        REJECTION_MOTIVE: "Motivo de rechazo",
        APPROVE: 'Aprobar',
        REJECT: 'Rechazar',
        APPROVED: "Aprobó",
        REJECTED: "Rechazó",
        EDIT_STAGE: 'Editar',
        FINISH_EDIT_STAGE: 'Terminar edición',
        EREQUEST: 'Motivo de edición',
        EREQUEST_DESCRIPTION: '¿Qué cambios realizó?',
        EREQUEST_ACCEPT: 'Aceptar',
        REFERENCE: "Referencia",
        ASSOCIATED_INSPECTION: { 
          TITLE: changeSurveyBySector("Periodico Asociado", "Auditoría Asociada"),
          INSPECTION_NAME: changeSurveyBySector("Nombre del Periodico", "Nombre de la Auditoría"),
          ID: "ID",
          GO_TO_DETAIL:  changeSurveyBySector("Ir al detalle del periodico", "Ir al detalle de auditoría"),
        },
        ASSOCIATED_ACC_INC: {
          TITLE: "Accidente e incidente Asociado",
          ACCDIENT_TYPE: "Tipo de accidente",
          ID: "ID",
          CREATED_FROM: "Creado desde",
          GO_TO_DETAIL: "Ir al detalle"
        },
        ASSOCIATED_RISK_WARNING: {
          TITLE: "Aviso de Riesgo Asociado",
          RISK_TYPE: "Tipo de riesgo",
          ID: "ID",
          POTENTIAL_RISK: "Riesgo potencial",
          GO_TO_DETAIL: "Ir al detalle"
        },
        IMMEDIATE_COMPLETION: {
          TITLE: "Finalización Inmediata",
          COMPLETION_MOTIVE: "Motivo de finalización inmediata",
          ALERT: <>Recuerde que esta acción es <b>irreversible.</b></>,
          FINISH: "Finalizar"
        },
        APPROVE_POPUP_TITLE: "Aprobar solicitud",
        DO_YOU_WANT_TO_APPROVE: "¿Desea aprobar esta solicitud?",
        REJECT_POPUP_TITLE: "Rechazo de solicitud",
        REJECTION_MOTIVE: "Motivo de rechazo",
        REVERT_MOTIVE: "Motivo de devolución",
        REVERT_OBSERVATION: "Esta accion eliminará la informacion de la etapa actual y volverá a la etapa anterior, se perderan los cambios realizados. ¿Desea continuar?",
        DERIVE_POPUP_TITLE: "Derivar solicitud",
        SELECT_DIVISION_TO_DERIVE: "Seleccione a que división de su sector desea derivar la solicitud",
        SELECT_DIVISION_PLACEHOLDER: "Seleccionar división",
        SELECT_USER_TO_DERIVE: "Seleccione a quien desea derivar la solicitud",
        SELECT_USER_PLACEHOLDER: "Seleccionar usuario",
        EMAIL_NOTIFICATION_POPUP_TITLE: "Enviar notificación por e-mail",
        SELECT_USERS_EMAIL: "Seleccione a que usuarios desea enviar las notificaciones",
        SELECT_PAYROLL_USERS_PLACEHOLDER: "Seleccione usuarios de la nómina",
        TYPE_HERE: "Escriba aquí...",
        TYPE_EXTERNAL_EMAILS: "Ingrese e-mail de usuario o usuarios externos",
        EXTERNAL_EMAILS_HELP: "Por favor, escriba punto y coma luego de cada e-mail ingresado",
        EMAIL_BODY: "Cuerpo del e-mail",
        STEPS: "Flujo de trabajo",
        EMAIL_POPUP_NOTIFICATION_MESSAGE: "Este envío adjuntará el .pdf con el detalle de la solicitud",
        RESOLVE_PHASE: "Resolver",
        FINALIZE_PHASE: "Finalizar",
        DERIVE: "Derivar",
        REVERT: "Volver a la etapa anterior",
        PLANNED_REQUEST: "Programación",
        HISTORY: "Historial",
        PHASE_SUCCESS: "¡Se avanzó de etapa exitosamente!",
        DERIVATION_SUCCESS: "¡Solicitud derivada exitosamente!",
        REVERT_SUCCESS: "¡Etapa revertida exitosamente!",
        EMAIL_SUCCESS: "¡E-mails enviados correctamente!",
        SCHEDULE_SUCCESS: "¡Solicitud programada correctamente!",
        IMMEDIATE_COMPLETION_SUCCESS: "¡Solicitud finalizada correctamente!",
        RESCHULE: "Re-Programar",
        RESCHULE_REQUEST: "Reprogramar solicitud",
        RESCHULE_DESCRIPTION: "Motivo de reprogramación",
        RESCHULE_WARNING1: <>Esta acción solicitará <b>al planificador reprogramar la solicitud.</b></>,
        RESCHULE_WARNING2: "Para resolverla deberá aguardar la reprogramación. ¿Desea continuar?",
        CORRECTLY: "correctamente",
        PDF: {
          DIGITAL: "Digital",
          PRINT: "Impresión",
          TO_COMPLETE: "A completar"
        },
        ERRORS: {
          REJECTION_MOTIVE: 'Por favor, complete el motivo de rechazo.',
          DIVISION: 'Debe elegir una división',
          USER: 'Debe elegir un usuario',
          REVERT_OBSERVATION: 'Por favor, complete con una observación.',
        }
      },
      CREATE_EDIT: {
        TITLE: "Crear nueva solicitud",
        CHOOSE_REQUEST_MODEL: "Por favor, seleccione que solicitud desea crear",
        LEVEL: "Nivel",
        INFO: "Información",
        VIEW_INFO: "Ver información",
        ASSOCIATED_REQUESTS: "Solicitudes asociadas",
        NUMBER_OF_POSITIONS_TOBE_FILLED: 'Cantidad de puestos a cubrir',
        ASSOCIATED_REQUESTS_PLACEHOLDER: "Cantidad en números",
        DESCRIPTION: "Descripción y/o problema",
        URGENCY: "Urgencia",
        SIGNATURE: "Firma",
        ATTACH: "Adjuntar",
        FILES: "Archivos",
        LOCATION: "Ubicación",
        NO_LOCATION: "No se ha tomado la ubicación de la solicitud por haber sido realizada desde la web.",
        CREATE_REQUEST: "Crear",
        LINKED_REQUEST: "Datos de solicitud vinculada",
        SUCCESS: "Solicitud creada correctamente",
        SUCCESS_EDIT: "Solicitud editada correctamente",
        TOTAL: "Total",
        UNSUBSCRIBE: "Dar de baja / fuera de uso",
        SCHEDULE_AND_CREATION_SUCCESS: "Solicitud creada y programada correctamente",
        FROM: "Desde",
        TO: "Hasta",
        USERS: "Usuarios",
        SCHEDULE_LATER: "Programar luego",
        SCHEDULE_NOW: "Programar ahora",
        YES: "Si",
        NO: "No",
        STAGES: {
          IN_PROGRESS: "En proceso",
          AWAITING: "En espera",
          SINGLE_STAGE: "Única etapa",
          COMPLETE_WORKFLOW: "Ver flujo de trabajo completo",
          OMITTED: "Omitido"
        },
        PREVIEW_POPUP: {
          TITLE: "Vista previa",
          TOTAL: "Total",
          SUBTOTALS: "Subtotales",
          MAX_LIMIT: "Ha superado el máximo esperado por el sistema (Un quintillón)",
          CLOSE: "Cerrar"
        },
        LEVEL_INFORMATION: "Información del nivel",
        INVENTORY: {
          DELETE_MATERIALS: "Eliminar materiales",
          SEARCH_BY_NAME: "Buscar por nombre",
          QUANTITY: "Cantidad",
          ADD: "Agregar",
          DELETE_MATERIALS_WARNING: <>¿Está seguro que quiere eliminar <b>todos los materiales</b> de la lista?</>,
          CHOSEN_MATERIALS: "Materiales elegidos",
          DELETE_ALL: "Eliminar todos",
          CODE: "Código",
          QUANTITY: "Cantidad",
          NAME: "Nombre"
        },
        ROLES: {
          APPROVERS: "Aprobadores",
          EDITORS: "Editables",
          FINISHERS: "Finalizadores",
          RESOLVER: "Resolutor",
        },
        ERRORS: {
          PARENT_LEVEL: 'Debe elegir un nivel',
          LEVEL: 'Debe elegir una instancia de nivel',
          ASSOCIATED_REQUESTS: 'Debe indicar un número',
          DESCRIPTION: 'Debe escribir una descripción',
          URGENCY: 'Debe elegir un tipo de urgencia',
          SIGNATURE: 'Debe ingresar una firma',
          FILES: 'Debe elegir al menos un archivo',
          FILE_NOT_VALID: 'no es un archivo válido',
          FILE_SIZE: "El archivo supera el tamaño máximo permitido",
          AMOUNT_NOT_VALID: 'El monto total no es válido para esta nota de crédito'
        },
        PROGRAM_DATE: {
          ALERT_MESSAGE: 'En caso de asignar a dos o más usuarios quedará registrada la primer respuesta'
        }
      },
      NO_DATA_MESSAGE: "No posee solicitudes creadas",
      CREATE_DATA_MESSAGE: "Por favor, cree una nueva solicitud."
    },
    NUMBERS_TO_LETTERS: {
      UNITS: {
        ONE: "un",
        TWO: "dos",
        THREE: "tres",
        FOUR: "cuatro",
        FIVE: "cinco",
        SIX: "seis",
        SEVEN: "siete",
        EIGHT: "ocho",
        NINE: "nueve"
      },
      DOZENS: {
      }
    },
    EXPIRATIONS: {
      VIEW: {
        NUMBER_COLUMN: 'N°',
        CREATION_DATE_COLUMN: 'Fecha de creación',
        LEVEL_COLUMN: 'Nivel',
        LEVEL_FK_COLUMN: 'Código de nivel',
        EXPIRATION_DATE_COLUMN: 'Vencimiento',
        REQUEST_TYPE_COLUMN: 'Tipo de vencimiento',
        STATUS_COLUMN: 'Estado',
        STATUS: {
          VALID: 'Vigente',
          VALID_PENDING: 'Vigente en tramite',
          EXPIRED: 'Vencido',
          EXPIRED_PENDING: 'Vencido en tramite',
          REJECTED: 'Rechazado',
          INACTIVE: 'Dado de baja'
        },
      },
      CREATE_EDIT: {
        CHOOSE_EXPIRATION_MODEL: "Por favor, seleccione que vencimiento desea crear",
        GENERAL_INFO: "Información general",
        STATUS: "Estado",
        LEVEL: "Nivel",
        DESCRIPTION: "Descripción",
        DESCRIPTION_PLACEHOLDER: "Escribir aqui...",
        EXPIRATION_DATE: "Fecha de vencimiento",
        SIGNATURE: "Firma",
        ATTACH: "Adjuntar",
        ATTACH_NOTE: 'Se puede cargar un máximo de 2Mb por documento (pdf, docx, doc, xls, xlsx) o imagen (png, jpeg)',
        FILES: "Archivos",
        DOWNLOAD: "Descargar",
        REMOVE: "Borrar",
        REJECTION_MOTIVE: "Motivo de rechazo",
        APPROVED: "Aprobó",
        REJECTED: "Rechazó",
        CREATE_EXPIRATION: "Crear vencimiento",
        SUCCESS: "Vencimiento creado correctamente",
        SUCCESS_EDIT: "Vencimiento editado correctamente",
        SELECT_USERS_EMAIL: "Destinatarios de alarmas",
        SELECT_USER_PLACEHOLDER: "Seleccionar usuario",
        SELECT_PAYROLL_USERS_PLACEHOLDER: "Seleccione usuarios de la nómina",
        TYPE_HERE: "Escriba aquí",
        TYPE_EXTERNAL_EMAILS: "Ingrese e-mail de usuario o usuarios externos",
        EXTERNAL_EMAILS_HELP: "Por favor, escriba punto y coma luego de cada e-mail ingresado",
        ERRORS: {
          PARENT_LEVEL: 'Debe elegir un nivel',
          LEVEL: 'Debe elegir una instancia de nivel',
          RELATED_REQUESTS: 'Debe indicar un número',
          DESCRIPTION: 'Debe escribir una descripción',
          URGENCY: 'Debe elegir un tipo de urgencia',
          SIGNATURE: 'Debe ingresar una firma',
          FILES: 'Debe elegir al menos un archivo',
          FILE_NOT_VALID: 'no es un archivo válido',
        }
      },
      VIEW_DETAIL: {
        RELEASE_DATE: 'Fecha de alta',
        EXPIRATION_DATE: 'Fecha de vencimiento',
        REQUEST_NUMBER: 'Número de solicitud',
        PROVIDER: 'Proveedor',
        DESCRIPTION: 'Descripción',
        APPROVE: 'Aprobar',
        REJECT: 'Rechazar',
        APPROVE_POPUP_TITLE: "Aprobar vencimiento",
        DO_YOU_WANT_TO_APPROVE: "¿Desea aprobar esta vencimiento?",
        REJECT_POPUP_TITLE: "Rechazo de vencimiento",
        REJECTION_MOTIVE: "Motivo de rechazo",
        REVERT_POPUP_TITLE: "Revertir etapa",
        REVERT_MOTIVE: "Observación",
        DERIVE_POPUP_TITLE: "Derivar vencimiento",
        SELECT_DIVISION_TO_DERIVE: "Seleccione a que división de su sector desea derivar el vencimiento",
        SELECT_DIVISION_PLACEHOLDER: "Seleccionar división",
        SELECT_USER_TO_DERIVE: "Seleccione a quien desea derivar el vencimiento",
        SELECT_USER_PLACEHOLDER: "Seleccionar usuario",
        EMAIL_NOTIFICATION_POPUP_TITLE: "Enviar notificación por e-mail",
        SELECT_USERS_EMAIL: "Seleccione a que usuarios desea enviar las notificaciones",
        SELECT_PAYROLL_USERS_PLACEHOLDER: "Seleccione usuarios de la nómina",
        TYPE_HERE: "Escriba aquí",
        TYPE_EXTERNAL_EMAILS: "Ingrese e-mail de usuario o usuarios externos",
        EXTERNAL_EMAILS_HELP: "Por favor, escriba punto y coma luego de cada e-mail ingresado",
        EMAIL_BODY: "Cuerpo del e-mail",
        RESOLVE_PHASE: "Resolver etapa",
        DERIVE: "Derivar",
        REVERT_TO: "Revertir a",
        HISTORY: "Historial",
        PHASE_SUCCESS: "¡Se avanzó de etapa exitosamente!",
        DERIVATION_SUCCESS: "Vencimiento derivado exitosamente!",
        REVERT_SUCCESS: "¡Etapa revertida exitosamente!",
        EMAIL_SUCCESS: "¡E-mails enviados correctamente!",
        ERRORS: {
          REJECTION_MOTIVE: 'Por favor, complete el motivo de rechazo.',
          DIVISION: 'Debe elegir una división',
          USER: 'Debe elegir un usuario',
          REVERT_OBSERVATION: 'Por favor, complete con una observación.',
        }
      },
      NO_DATA_MESSAGE: "No posee vencimientos creados",
      CREATE_DATA_MESSAGE: "Por favor, cree un nuevo vencimiento."
    },
    CHECKLISTS: {
      VIEW: {
        NAME_COLUMN: 'Nombre de la planilla',
        LAST_UPDATE_COLUMN: 'Última fecha de actualización',
        SECTOR: "Sector",
        DUPLICATE_CHECKLIST: 'Duplicar planilla',
      },
      CREATE_EDIT: {
        CHECKLIST_CONFIGURATION: "Configuración de la planilla",
        GENERAL_CONFIGURATION: "Configuración general",
        SECTION_CONFIGURATION: "Configuración de Sección",
        ITEMS_GENERAL_CONFIGURATION: "Configuración general de items",
        ITEMS_FUNCTION_CONFIGURATION: "Configuración de función del Item",
        SECTOR_INFO: "Puede modificar el sector principal de la Planilla desde el botón que indica el sector actual, ubicado en la barra superior.",
        CHECKLIST_NAME: "Nombre:",
        SECTOR: "Sectores que pueden acceder:",
        ACTIVE: "Habilitada:",
        HAS_WEIGHT: "Con Peso:",
        SHARE_WITH: "Compartir con",
        WISE_CHECKLIST: "Planilla WISE",
        URGENCY_ITEM: "Items con Urgencia/Riesgo",
        URGENCY_RISK: "Urgencia/Riesgo",
        URGENCY_NOMENCLATURE: "Nomenclatura",
        ADD_GENERAL_IMAGE: "Adjuntar imagen general",
        SECTION: "Sección",
        OBLIGATORY_COMMENT: '"No Cumple" exige Comentario Obligatorio:',
        OBLIGATORY_IMAGE: '"No Cumple" exige Imagen Obligatoria:',
        TERNARY_OBLIGATORY_COMMENT: '"Necesita Mejorar" exige Comentario Obligatorio:',
        TERNARY_OBLIGATORY_IMAGE: '"Necesita Mejorar" exige Imagen Obligatoria:',
        OBLIGATORY_GENERATE_REQUEST: "Los items no conformes generan incidencias.",
        WITH_WEIGHT: "Planilla con peso:",
        SECTION_NAME: "Nombre de la sección",
        SECTION_REFERENCE: "Referencia:",
        SECTION_ATTACH_IMAGE: "Adjuntar imagen a sección",
        ADD_SECTION: 'Agregar sección',
        DELETE_SECTION: 'Eliminar sección',
        DUPLICATE_SECTION: 'Duplicar sección',
        REORDER_SECTIONS: 'Reordenar secciones',
        ADD_ITEM: 'Agregar item',
        DELETE_ITEM: 'Eliminar item',
        REORDER_ITEMS: 'Reordenar items',
        ITEM_NAME: "Nombre del item",
        DUPLICATE: 'Duplicar',
        OPTION_NAME: "Nombre de opción",
        ITEM_TYPE: "Tipo:",
        ITEM_REFERENCE: "Texto de referencia",
        ITEM_TYPE_PLACEHOLDER: "Seleccionar tipo",
        WEIGHT: "Configuración de Peso",
        ATTACH_IMAGE: "Adjuntar imagen",
        ADDITIONAL_COMMENT: "Comentario adicional",
        OBLIGATORY_COMMENT_OPTION: "Comentarios obligatorios",
        OBLIGATORY_IMAGE_OPTION: "Toma de imagen obligatoria",
        OPTIONS: "Opciones",
        GENERATE_REQUEST: "Genera incidencia",
        PREVIEW: "Vista previa",
        SUCCESS: "Planilla creada correctamente",
        SUCCESS_EDIT: "Planilla editada correctamente",
        ADD_OPTION: "Agregar opción",
        SELECT_URGENCY: "Urgencia/Riesgo",
        DEACTIVATE_CHECKLIST: "Deshabilitar planilla",
        EDIT_COMMENT: 'Guardar edición de planilla',
        COMMENT: 'Motivo de edición',
        SAVE: 'Guardar',
        CHANGE_LOG: "Historial de cambios",
        VERSION_NUMBER: "Número de versión",
        ARE_YOU_SURE_DEACTIVATE: "¿Está seguro que desea {activateDeactivate} esta planilla?",
        ACTIVATE: "activar",
        DEACTIVATE: "desactivar",
        EDITED_BY: "Editó",
        FIELD_TYPES: {
          TEXT: "Texto corto",
          NUMERIC: "Numerico",
          YES_NO: "Cumple/No Cumple",
          YES_NO_NEEDS_IMPROVEMENT: "Cumple/No Cumple/Necesita Mejorar",
          DATE: "Fecha",
          HOUR: "Hora",
          OPTIONS: "Parametrizable",
          LONG_TEXT: "Texto largo",
          LEVEL: "Nivel",
          NUMERIC_RANGE: "Rango Numérico Cumple / No cumple"
        },
        FIELD_TYPE_YES: "Cumple (+)",
        FIELD_TYPE_NO: "No Cumple (-%)",
        FIELD_TYPE_NEEDS_IMPROVEMENT: "Necesita Mejorar (+)",
        ERRORS: {
          NAME: 'Debe ingresar un nombre',
          NAME_LENGTH: 'El nombre no puede superar los 100 caracteres',
          FIELD_TYPE: 'Debe elegir un tipo de item',
          ALERT_TEXT: 'Debe escribir el mensaje de alerta',
          SECTOR: 'Debe elegir un sector',
        },
        SAVE_AND_CONFIG: "Guardar y Configurar función"
      },
      NO_DATA_MESSAGE: "No posee planillas creadas",
      CREATE_DATA_MESSAGE: "Por favor, cree una nueva planilla.",
    },
    AUDITS: {
      AUDITS: {
        VIEW: {
          ID_COLUMN: 'Nº',
          ID_FILTER: changeSurveyBySector('Nº de periodico', 'Nº de auditoría'),
          NAME_COLUMN: changeSurveyBySector('Tipo de periodico', 'Tipo de auditoría'),
          LEVEL_INSTANCE_COLUMN: 'Nivel',
          SECTOR_COLUMN: 'Sector',
          EXECUTION_DATE_COLUMN: 'Fecha de ejecución',
          CREATION_DATE_COLUMN: 'Fecha de creación',
          AUDIT_USER: 'Auditado por',
          TAGS_COLUMN: 'Etiquetas',
          NONCONFORMITY: "No conformidad",
          NONINCIDENCE: "Incidencias",
          FINALIZATION_TYPE: "Finalización",
          PENDINGS: 'Pendientes',
          FINALIZED: 'Finalizadas',
          CHECKLIST_NAME: 'Nombre de la planilla',
          EXECUTION_DATE: 'Fecha de ejecución desde - hasta',
          CREATION_DATE: 'Fecha de Creación - Fecha Hasta',
          LEVEL: 'Nivel',
          SCORE: 'Puntaje',
        },
        VIEW_DETAIL: {
          REQUEST_TYPE: 'Tipo de solicitud',
          SURVEY_TYPE: changeSurveyBySector('Tipo de Periodico', 'Tipo de Auditoría'),
          DATE: 'Fecha',
          REQUEST_NUMBER: 'Número de solicitud',
          CREATOR_NAME: 'Nombre del creador',
          LEVEL: 'Nivel',
          RELATED_REQUESTS: 'Solicitudes asociadas',
          NUMBER_OF_POSITIONS_TOBE_FILLED: 'Cantidad de puestos a cubrir',
          DESCRIPTION: 'Descripción',
          URGENCY: 'Urgencia',
          APPROVE: 'Aprobar',
          REJECT: 'Rechazar',
          APPROVE_POPUP_TITLE: "Aprobar solicitud",
          DO_YOU_WANT_TO_APPROVE: "¿Desea aprobar esta solicitud?",
          REJECT_POPUP_TITLE: "Rechazo de solicitud",
          REJECTION_MOTIVE: "Motivo de rechazo",
          REVERT_POPUP_TITLE: "Revertir etapa",
          REVERT_MOTIVE: "Observación",
          DERIVE_POPUP_TITLE: "Derivar solicitud",
          SELECT_DIVISION_TO_DERIVE: "Seleccione a que división de su sector desea derivar la solicitud",
          SELECT_DIVISION_PLACEHOLDER: "Seleccionar división",
          SELECT_USER_TO_DERIVE: "Seleccione a quien desea derivar la solicitud",
          SELECT_USER_PLACEHOLDER: "Seleccionar usuario",
          EMAIL_NOTIFICATION_POPUP_TITLE: "Enviar notificación por e-mail",
          SELECT_USERS_EMAIL: "Seleccione a que usuarios desea enviar las notificaciones",
          PDF_NOTIFICATION_POPUP: changeSurveyBySector("Este envío adjuntará el .pdf con el detalle de la periodico", "Este envío adjuntará el .pdf con el detalle de la auditoría"),
          PDF_NOTIFICATION_POPUP_REQUEST: "Este envío adjuntará el .pdf con el detalle de la solicitud",
          SELECT_PAYROLL_USERS_PLACEHOLDER: "Seleccione usuarios de la nómina",
          TYPE_HERE: "Escriba aquí",
          TYPE_EXTERNAL_EMAILS: "Ingrese e-mail de usuario o usuarios externos",
          EXTERNAL_EMAILS_HELP: "Por favor, escriba punto y coma luego de cada e-mail ingresado",
          EMAIL_BODY: "Cuerpo del e-mail",
          RESOLVE_PHASE: "Resolver etapa",
          DERIVE: "Derivar",
          REVERT_TO: "Revertir a",
          DELETE_AUDIT: changeSurveyBySector("Eliminar Periodico", "Eliminar Auditoría"),
          DELETE_AUDIT_MESSAGE: changeSurveyBySector("¿Desea eliminar este Periodico?", "¿Desea eliminar esta Auditoría?"),
          DELETE_AUDITS_MESSAGE: changeSurveyBySector("¿Desea eliminar los Periodicos seleccionadas?", "¿Desea eliminar las Auditorías seleccionadas?"),
          DELETE_AUDIT_MESSAGE_WARNING: "Esta acción no se puede deshacer",
          DELETE_AUDIT_SUCCESS: changeSurveyBySector("¡Periodico eliminado exitosamente!", "¡Auditoría eliminada exitosamente!"), 
          DELETE_MASSIVE_AUDITS_SUCCESS: changeSurveyBySector("¡Periodicos eliminados exitosamente!", "¡Auditorías eliminadas exitosamente!"), 
          HISTORY: "Historial",
          PHASE_SUCCESS: "¡Se avanzó de etapa exitosamente!",
          DERIVATION_SUCCESS: "¡Solicitud derivada exitosamente!",
          REVERT_SUCCESS: "¡Etapa revertida exitosamente!",
          EMAIL_SUCCESS: "¡E-mails enviados correctamente!",
          EXPIRED_AUDIT: "Se ha cerrado automáticamente por no ser finalizada en el período de tiempo asignado",
          INSTANCE: "Instancia",
          ERRORS: {
            REJECTION_MOTIVE: 'Por favor, complete el motivo de rechazo.',
            DIVISION: 'Debe elegir una división',
            USER: 'Debe elegir un usuario',
            REVERT_OBSERVATION: 'Por favor, complete con una observación.',
            EXPIRED_AUDIT:  changeSurveyBySector("Periodico Expirado", "Auditoría Expirada"), 
          },
          RESPONSE: "Respuesta",
          FIELD_TYPE_YES: "Cumple",
          FIELD_TYPE_NO: "No Cumple",
          FIELD_TYPE_NEEDS_IMPROVEMENT: "Necesita Mejorar",
          GENERAL_INFORMATION: "Información general",
          SCHEDULE_DATE: "Fecha de programación",
          ASSIGNMENT_DATE: "Fecha de asignación",
          RESOLUTION_DATE: "Fecha de resolución",
          ASSIGNED_USERS: "Usuario/s Asignado/s",
          PLANNER: "Planificador",
          EXECUTOR_USER: "Usuario Ejecutor",
          AUDITOR_USER: "Nombre del auditor",
          SURVEY_COUNT: "Cantidad de incidencias",
          NONCONFORMITY_COUNT: "Cantidad de No Cumple",
          NEED_IMPROVEMENT_COUNT: "Cantidad de Necesita Mejorar",
          TOTAL_PERCENTAGE: "Porcentaje total",
          TOTAL_PERCENTAGE: "Porcentaje total",
          REFERENCE_IMAGE: "IMAGEN DE REFERENCIA",
          COMMENT_DESCRIPTION: "Comentario y/o descripción",
          NO_CONFORMITY: "Datos sobre la incidencia",
          DOWNLOAD: "Descargar",
          FILE: "Archivo/s adjunto/s",
          FINAL_RESOLUTION: "Resolución final",
          CREATE_ACTION: {
            DATE: "Fecha de Creación",
            ASSOCIATED_REQUEST: "Solicitud Asociada",
            DESCRIPTION: "Descripción",
            STATUS: "Estado"
          }
        },
        CREATE_EDIT: {
          SELECT_INSTANCE: "Elegí que vas a auditar",
          CHOOSE_REQUEST_MODEL: "Por favor, seleccione que solicitud desea crear",
          GENERAL_INFO: "Información general",
          STATUS: "Estado",
          LEVEL: "Nivel",
          RELATED_REQUESTS: "Solicitudes asociadas",
          NUMBER_OF_POSITIONS_TOBE_FILLED: 'Cantidad de puestos a cubrir',
          RELATED_REQUESTS_PLACEHOLDER: "Cantidad en números",
          DESCRIPTION: "Descripción",
          DESCRIPTION_PLACEHOLDER: "Escribir aqui...",
          URGENCY: "Urgencia",
          SIGNATURE: "Firma",
          ATTACH: "Adjuntar",
          ATTACH_NOTE: 'Se puede cargar un máximo de 2Mb por documento (pdf, docx, doc, xls, xlsx) o imagen (png, jpeg)',
          FILES: "Archivos",
          DOWNLOAD: "Descargar",
          REMOVE: "Borrar",
          REJECTION_MOTIVE: "Motivo de rechazo",
          APPROVED: "Aprobó",
          REJECTED: "Rechazó",
          CREATE_REQUEST: "Crear solicitud",
          SUCCESS: "Solicitud creada correctamente",
          SUCCESS_EDIT: "Solicitud editada correctamente",
          TOTAL: "Total",
          COMMENT_DESCRIPTION: "Comentario y/o descripción",
          ERRORS: {
            PARENT_LEVEL: 'Debe elegir un nivel',
            LEVEL: 'Debe elegir una instancia de nivel',
            RELATED_REQUESTS: 'Debe indicar un número',
            DESCRIPTION: 'Debe escribir una descripción',
            URGENCY: 'Debe elegir un tipo de urgencia',
            SIGNATURE: 'Debe ingresar una firma',
            FILES: 'Debe elegir al menos un archivo',
            FILE_NOT_VALID: 'no es un archivo válido',
            FILE_SIZE: "El archivo supera el tamaño máximo permitido",
            AMOUNT_NOT_VALID: 'El monto total no es válido para esta nota de crédito'
          }
        },
        NO_DATA_MESSAGE: changeSurveyBySector("No posee periodicos creados", "No posee auditorias creadas"),
        CREATE_DATA_MESSAGE: changeSurveyBySector("Por favor, cree un nuevo periodico.", "Por favor, cree una nueva auditoria."),
      },
      CONFIGURATION: {
        VIEW: {
          NAME_COLUMN: changeSurveyBySector('Nombre del periodico', 'Nombre de la auditoria'),
          LEVEL_INSTANCE_COLUMN: 'Instancia de nivel',
          SECTOR_COLUMN: 'Sector',
          UPDATE_DATE_COLUMN: 'Última fecha de actualización',
          NAME: changeSurveyBySector('Nombre del periodico', 'Nombre de la auditoria'),
          LEVEL: 'Nivel',
          CHECKLIST_NAME: 'Nombre de la planilla',
          CREATE_DATA_MESSAGE: changeSurveyBySector("Por favor, cree una nueva configuración de periodico." , "Por favor, cree una nueva configuración de auditoria." ),
        },
        // VIEW_DETAIL: {
        //   GENERAL_INFO: "Información general",
        //   AUDIT_NAME: "Nombre de la auditoria",
        //   TAGS: 'Etiquetas',
        //   TAGS_HELP: "Para separar las etiquetas,  presione la tecla de “coma” (,)",
        //   TYPE_HERE: "Escribir aqui",
        //   ASSOCIATE_CHECKLIST: "Asociar planilla",
        //   NO_CHECKLISTS_AVAILABLE: "No hay planillas disponibles",
        //   ATTACH_PDF_FILES: "Adjuntar archivos .PDF",
        //   PERIODICITY: "Periodicidad",
        //   START_DATE: "Fecha de inicio",
        //   PERIODICITY_TYPE: 'Tipo de periodicidad',
        //   ASSIGNATION: "Asignación",
        //   ON_DEMAND: "A demanda",
        //   WITH_ASSIGNATION: "Con asignación",
        //   ASSIGN_LEVEL: "Asignar nivel",
        //   ASSIGN_LEVEL_PLACEHOLDER: "Seleccionar nivel",
        //   ASSIGN_LEVEL_INSTANCE: "Asignar Instancias de nivel",
        //   ASSIGN_LEVEL_INSTANCE_PLACEHOLDER: "Buscar instancias de nivel",
        //   AUDIT_STATE: "Estado de la auditoría",
        //   CREATE: "Crear",
        //   PERIODICITY_TYPES: {
        //     HOURLY: "Hora",
        //     DAILY: "Diario",
        //     WEEKLY: "Semanal",
        //     MONTHLY_DAYS: "Mensual (Fijar día)",
        //     MONTHLY_ORDER: "Mensual (Fijar orden)",
        //     NO_PERIODICITY: "Sin periodicidad",
        //     ONCE: "Por única vez"
        //   },
        //   HOURLY_LABEL: "¿Cada cuántas horas desea ejecutar la auditoría?",
        //   DAILY_LABEL: "¿Cada cuantos días desea ejecutar la auditoría?",
        //   WEEKLY_LABEL: "¿Cada cuantas semanas desea ejecutar la auditoría?",
        //   DAYS_A_WEEK_LABEL: "¿Qué días de la semana desea ejecutar la auditoría?",
        //   DAYS_LABEL: "¿Qué días desea ejecutar la auditoría?",
        //   MONTHLY_ORDER: {
        //     FIRST: "Primer",
        //     SECOND: "Segundo",
        //     THIRD: "Tercero",
        //     FOURTH: "Cuarto",
        //     LAST: "Último",
        //   },
        //   MONTH_LABEL: "¿En qué mes desea ejecutar la auditoría?",
        //   WHEN_LABEL: "¿Cuándo desea ejecutar la auditoría?",
        //   ONCE_LABEL: "¿En qué fecha desea ejecutar la auditoría?",
        //   SELECT_DAYS_PLACEHOLDER: "Seleccionar día/días",
        //   SELECT_MONTHS_PLACEHOLDER: "Seleccionar mes/meses",
        //   HOURS: 'horas',
        //   DAYS: 'días',
        //   WEEKS: 'semanas',
        //   AUDIT_STATES: {
        //     PENDING: "La auditoría quedará pendiente en caso de no realizarse",
        //     EXPIRED: "Expirado el tiempo de su periodicidad, la auditoría quedará cerrada"
        //   },
        // },
        CREATE_EDIT: {
          GENERAL_INFO: "Información general",
          AUDIT_NAME: changeSurveyBySector("Nombre del periodico", "Nombre de la auditoria"),
          CONFIRATION_CHECKLIST_SCORE: "Configuración de Planillas con Puntaje",
          TAGS: 'Etiquetas',
          TAGS_HELP: "Para separar las etiquetas,  presione la tecla de “coma” (,)",
          TYPE_HERE: "Escribir aqui",
          ASSOCIATE_CHECKLIST: "Asociar planilla",
          ASSOCIATE_CHECKLIST_DETAIL: "Planillas asociadas",
          NO_CHECKLISTS_AVAILABLE: "No hay planillas disponibles",
          ATTACH_PDF_FILES: "Adjuntar archivo (.PDF)",
          ATTACH_PDF_FILES_DETAIL: "Archivo .PDF",
          PERIODICITY: "Periodicidad",
          START_DATE: "Fecha de inicio",
          PERIODICITY_TYPE: 'Tipo de periodicidad',
          SELECT_DAYS: 'Seleccionar días',
          WEEKDAY: 'Día de la semana',
          MOUTH: 'Mes/es',
          ASSIGNATION: "Asignación",
          ON_DEMAND: "A demanda",
          LAST_CREATED_SWITCH: "Cuando el item no cumple vincular la solicitud de no conformidad a la última creada. (Podrá crear nuevas solicitudes opcionalmente)",
          PROGRAMMABLE: "Programable",
          ITEMS: "Items",
          ACTIVATE_DEFAULT_RESPONSE: "Activar respuesta predeterminada",
          ACTIVATE_DEFAULT_RESPONSE_SWITCH_INFO: 'Para items de tipo “Cumple/No Cumple”, “Cumple/No Cumple/Necesita Mejorar” la respuesta será “CUMPLE”, para items de tipo opción será la primer respuesta diponible.',
          WITH_ASSIGNATION: "Con asignación",
          AUTOMATIC: "Automatica",
          ASSIGN_LEVEL: "Asignar nivel",
          ASSIGN_LEVEL_PLACEHOLDER: "Seleccionar nivel",
          ASSIGN_LEVEL_INSTANCE: "Asignar Instancias de nivel",
          ASSIGN_LEVEL_INSTANCE_PLACEHOLDER: "Buscar instancias de nivel",
          ASSIGN_DIVISION: "Asignar división",
          ASSIGN_AUDITORS: "Auditores",
          ASSIGN_DIVISION_PLACEHOLDER: "Seleccionar división",
          ASSIGN_AUDITORS_PLACEHOLDER: "Buscar auditores",
          AUDIT_STATE: changeSurveyBySector("Estado del periodico", "Estado de la auditoría"), 
          PERIODICITY_ON: "Activar periodicidad al momento de ejecución",
          CREATE: "Crear",
          SAVE: "Guardar",
          SECTOR: "Sector",
          ASSIGNED_LEVEL: "Nivel asignado",
          ASSIGNED_LEVEL_INSTANCES: "Instancias de nivel asignadas",
          ACTIVE: "Habilitada",
          MONTHLY: "Mensual",
          PERIODICITY_TYPES: {
            HOURLY: "Hora",
            DAILY: "Diario",
            DAILY_SELECT_DAYS: "Diario (Fijar dias)",
            WEEKLY: "Semanal",
            MONTHLY_DAYS: "Mensual (Fijar día)",
            MONTHLY_ORDER: "Mensual (Fijar orden)",
            NO_PERIODICITY: "Sin periodicidad",
            ONCE: "Por única vez"
          },
          HOURLY_LABEL: changeSurveyBySector("¿Cada cuántas horas desea ejecutar el periodico?", "¿Cada cuántas horas desea ejecutar la auditoría?"), 
          DAILY_LABEL: changeSurveyBySector("¿Cada cuantos días desea ejecutar el periodico?", "¿Cada cuantos días desea ejecutar la auditoría?"),
          WEEKLY_LABEL: changeSurveyBySector("¿Cada cuantas semanas desea ejecutar el periodico?", "¿Cada cuantas semanas desea ejecutar la auditoría?"), 
          DAYS_A_WEEK_LABEL: changeSurveyBySector("¿Qué días de la semana desea ejecutar el periodico?", "¿Qué días de la semana desea ejecutar la auditoría?"), 
          DAYS_LABEL: changeSurveyBySector("¿Qué días desea ejecutar el periodico?", "¿Qué días desea ejecutar la auditoría?"), 
          MONTHLY_ORDER: {
            FIRST: "Primer",
            SECOND: "Segundo",
            THIRD: "Tercero",
            FOURTH: "Cuarto",
            LAST: "Último",
          },
          MONTH_LABEL: changeSurveyBySector("¿En qué mes desea ejecutar el periodico?", "¿En qué mes desea ejecutar la auditoría?"), 
          WHEN_LABEL:  changeSurveyBySector( "¿Cuándo desea ejecutar el periodico?",  "¿Cuándo desea ejecutar la auditoría?"), 
          ONCE_LABEL:  changeSurveyBySector( "¿En qué fecha desea ejecutar el periodico?",  "¿En qué fecha desea ejecutar la auditoría?"),
          SELECT_DAYS_PLACEHOLDER: "Seleccionar día/días",
          SELECT_MONTHS_PLACEHOLDER: "Seleccionar mes/meses",
          HOURS: 'horas',
          DAYS: 'días',
          WEEKS: 'semanas',
          AUDIT_STATES: {
            PENDING: changeSurveyBySector( "El periodico quedará pendiente en caso de no realizarse",  "La auditoría quedará pendiente en caso de no realizarse"),
            EXPIRED:  changeSurveyBySector( "Expirado el tiempo de su periodicidad, el periodico quedará cerrado",  "Expirado el tiempo de su periodicidad, la auditoría quedará cerrada"),
          },
          SUCCESS_EDIT: changeSurveyBySector( "¡Configuración de periodico editado exitosamente!",  "¡Configuración de auditoría editada exitosamente!"),
          SUCCESS:  changeSurveyBySector( "¡Configuración del periodico creado exitosamente!",  "¡Configuración de auditoría creada exitosamente!"),
          ERRORS: {
            NAME: 'Debe ingresar un nombre',
            TAGS: 'Debe ingresar etiquetas',
            TAGS_MAX: 'No puede ingresar mas de 3 etiquetas',
            CHECKLISTS: 'Debe asociar al menos una planilla',
            START_DATE: 'Debe elegir una fecha de inicio',
            URGENCY: 'Debe elegir un tipo de urgencia',
            PERIODICITY_TYPE: 'Debe elegir un tipo de periodicidad',
            REQUIRED: 'Este campo es obligatorio',
            LEVEL: 'Debe elegir un nivel',
            LEVEL_INSTANCE: "Debe elegir una instancia de nivel",
            AUDIT_STATE: changeSurveyBySector('Debe elegir un estado de periodico', 'Debe elegir un estado de auditoria'),
            ASSIGN_AUDITOR: changeSurveyBySector( 'Debe elegir al menos un responsable de periodico',  'Debe elegir al menos un auditor'),
          }
        },
        NO_DATA_MESSAGE: "No posee solicitudes creadas",
        CREATE_DATA_MESSAGE: "Por favor, cree una nueva solicitud."
      },
    },
    INVENTORY: {
      WAREHOUSES: {
        VIEW: {
          CODE_COLUMN: 'Código',
          WAREHOUSE_COLUMN: 'Almacén',
          WAREHOUSE_TYPE_COLUMN: 'Tipo de Almacén',
          SECTOR_COLUMN: 'Sector',
        },
        EDIT_MATERIALS_WAREHOUSE: {
          GENERAL_INFORMATION: "Información general",
          CODE: "Código de Almacén",
          NAME: "Nombre",
          TYPE: "Tipo de Almacén",
          SECTOR: "Sector/es",
          ADD_MATERIALS: "Añadir Materiales",
          WRITE_CODE: "Escribir código",
          MATERIAL_NAME: "Nombre del material",
          AVAILABLE_STOCK: "Stock disponible",
          QUANTITY: "Cantidad",
          MATERIAL: "Material",
          TOTAL_STOCK: "Stock total",
          DELETED_ALL: "Eliminar todos",
        },
        CREATE_EDIT: {
          CODE: "Código",
          WAREHOUSE_NAME: "Nombre de Almacén",
          WAREHOUSE_TYPE: "Tipo de Almacén",
          SECTORS: "Sector/es",
          SECTOR_ITEMS: {
            OWN_SECTOR: "Sector propio",
            OTHER: "Otro"
          },
          ALARM_MAILS: "Usuarios para alarma",
          SUCCESS: "¡Almacén creado correctamente!",
          SUCCESS_EDIT: "¡Almacén editado correctamente!",
          ERRORS: {
            NAME: "Debe ingresar un nombre",
            FUNCTIONAL_KEY: "Debe ingresar un código",
            STOCK: "Debe ingresar el stock",
            COST: "Debe ingresar el costo",
            SECTOR_TYPE: "Debe elegir un tipo de almacen",
            SECTORS: "Debe elegir al menos un sector",
          },
          RENDER_MATERIALS: {
            TITLE: "Materiales",
            CODE: "Código",
            NAME: "Nombre",
            TOTAL_STOCK: "Stock total",
            QUANTITY_IN_STOCK: "Cantidad en almacén",
          }
        },
        NO_DATA_MESSAGE: "No posee almacenes creados",
        CREATE_DATA_MESSAGE: "Por favor, cree un nuevo almacén."
      },
      MATERIALS: {
        VIEW: {
          CODE: "Código",
          MATERIAL: "Material",
          STOCK: "Stock",
          COST: "Costo",
          FILTERS: {
            CODE: "Escribir código aquí",
            NAME: "Nombre del material"
          },
          NO_DATA_MESSAGE: "No posee materiales creados",
          CREATE_DATA_MESSAGE: "Por favor, cree un nuevo material."
        },
        CREATE: {
          CODE: "Código",
          NAME: "Nombre",
          STOCK: "Stock",
          COST: "Costo",
          CURRENCY: "Moneda",
          VALIDATIONS: {
            CODE: "Debe ingresar un código",
            NAME: "Debe ingresar un nombre",
            STOCK: "Debe ingresar el stock",
            COST: "Debe ingresar el costo"
          },
          MESSAGE_BANNERS: {
            SUCCESS: "¡Material creado correctamente!",
          },
        },
        DETAIL: {
          GENERAL_INFORMATION: "Información general",
          CODE: "Código de Material",
          NAME: "Nombre",
          STOCK: "Stock",
          STOCK_ALARM: "Límite de stock para alarma",
          COST: "Costo",
          CURRENCY: "Moneda",
          HISTORY: "Historial",
          MOVEMENTS: "Movimientos",
          SUB_TEXT_MOVEMENTS: "Cantidad actual de material por Almacén"
        },
        EDIT: {
          SAVE: "Guardar cambios",
          MESSAGE_BANNERS: {
            SUCCESS: "¡Material editado correctamente!",
          },
        }
      }
    },
    SCHEDULE: {
      FILTERS: {
        DIVISION: 'División',
        USERS: 'Usuarios',
        SEARCH: 'Buscar...',
        SELECT_ALL: 'Seleccionar todos',
        REQUESTS: 'Solicitudes',
        AUDITS: changeSurveyBySector('Periodicos', 'Auditorías'),
        ASIGN: 'Asignar',
        EXPIRATIONS: 'Vencimientos'
      },
      COLUMNS: {
        NAME: 'Nombre',
        REQUEST_NUMBER: 'Número',
        REQUEST_TYPE: 'Tipo de Solicitud',
        PENDING_PHASE: 'Etapa pendiente',
        ISSUE_TYPE: 'Tipo de tarea',
        PROGRAM_DATE: 'Fecha de programación',
        CREATION_DATE: 'Fecha de Creación',
        ESTIMATED_DATE: 'Fecha Estimada',
        DESCRIPTION: 'Descripción',
        DETAIL: 'Detalle',
        LEVEL: 'Nivel',
        LEVEL_INSTANCE: 'Instancia de Nivel',
        TAGS: 'Etiquetas',
        STATE: 'Estado',
        URGENCY: 'Urgencia',
      },
    }
  },
  TRAININGS: {
    CATEGORIES_VIEW: {
      NUMBER_COLUMN: 'N°',
      CATEGORIES_COLUMN: 'Categorías',
      DESCRIPTION_COLUMN: 'Descripción'
    }
  },
  COURSES: {
    COURSES_VIEW: {
      NUMBER_COLUMN: 'N°',
      CATEGORIES_COLUMN: 'Categoría',
      COURSE_COLUMN: 'Curso',
      TYPE_COLUMN: 'Tipo',
      FINALIZED_TRAINGINGS: 'Capacitaciones realizadas',
      EXPECTED_HOURS: 'Horas esperadas',
      PERFORMED_HOURS: 'Horas realizadas',
      PENDING_ASSISTANCE: 'Pendiente de Asistencia'
    }
  },
  POSITIONS: {
    POSITIONS_VIEW: {
      CODE: 'Código',
      POSITION: 'Puesto',
      COURSE_TAKE: 'Cursos que debe realizar',
    }
  },
  TRAININGS_TRAININGS: {
    TRAININGS_VIEW: {
      NUMBER_COLUMN: 'N°',
      LEVEL_COLUMN: 'Nivel',
      CATEGORIES_COLUMN: 'Categoría',
      COURSE_COLUMN: 'Curso',
      TYPE_COLUMN: 'Tipo',
      DATE_COLUMN: 'Fecha',
      INSTRUCTOR_COLUMN: 'Instructor/a',
      PEOPLE_VIEW: 'Persona',
      POSITION_VIEW: 'Puesto',
      DNI_OR_FILE: 'DNI/Legajo',
      TYPE_OF_PEOPLE: 'Tipo de persona',
      SIGNATURE_AND_IMAGE: 'Firma e imagen',
      NOTE: 'Nota',
    }
  },
  WISE: {
    VIEW_CONFIGURATION: {
      NO_DATA_MESSAGE: 'No hay configuraciones'
    },
    CREATE_CONFIGURATION: {
      BUSINESS: 'Negocio',
      ADMINISTRATOR: 'Administrador',
      NAME: 'Nombre y apellido',
      EMAIL: 'E-mail',
      MY_TEAM: 'Mi equipo',
      GROUPER: 'Agrupador',
      SCHEMA: 'Esquema',
      LEVELS_SCHEMA: 'Esquema de niveles',
      NEW_LEVELS_SCHEMA: 'Nuevo esquema de niveles',
      RISK_NOTICE: 'Aviso de riesgo',
      ASSOCIATED_REQUESTS: 'Solicitudes asociadas',
      ACCIDENT_AND_INCIDENT: 'Accidente e incidente',
      SECTOR: 'Sector de sector médico',
      SITE: 'Sitio',
      SUBLOCATION: 'Sublocación',
      SUCCESS: {
        CREATE_WISE_CONFIGURATION: '¡Configuración creada correctamente!',
        EDIT_WISE_CONFIGURATION: '¡Configuración editada correctamente!',
      },
      ERRORS: {
        NAME: 'Debe ingresar un nombre válido',
        EMAIL: 'Debe ingresar un e-mail válido',
        BUSINESS: 'Debe seleccionar un negocio válido',
        GROUPER: 'Debe seleccionar un agrupador válido',
        ASSOCIATED_REQUESTS: 'Debe seleccionar al menos una solicitud asociada válida',
        SECTOR: 'Debe seleccionar un sector válido',
        LEVELS_SCHEMA: 'Debe seleccionar uno o más esquemas válidos',
      }
    }
  }
}
