import { handleActions } from 'redux-actions'
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersError,
  fetchUserCompleteStart,
  fetchUserCompleteSuccess,
  fetchUserCompleteError,
  fetchAllDivisionsStart,
  fetchAllDivisionsSuccess,
  fetchAllDivisionsError,
  fetchAllTeamsStart,
  fetchAllTeamsSuccess,
  fetchAllTeamsError,
  fetchBlacklistedStart,
  fetchBlacklistedSuccess,
  fetchBlacklistedError,
  resetUsers
} from '../actions/user'

export const defaultState = {
  users: [],
  blacklisted: [],
  userComplete: {},
  divisions: [],
  teams: [],
  error: null,
  isFetching: false,
  isFetchingUsers: false,
}

const reducer = handleActions(
  {
    [fetchUsersStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchUsersSuccess]: (state, { payload: { values } }) => ({
      ...state,
      users: values,
      isFetching: false,
      error: null
    }),
    [fetchUsersError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchUserCompleteStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchUserCompleteSuccess]: (state, { payload: { values } }) => ({
      ...state,
      userComplete: values,
      isFetching: false,
      error: null
    }),
    [fetchUserCompleteError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchAllDivisionsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllDivisionsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      divisions: values,
      isFetching: false,
      error: null
    }),
    [fetchAllDivisionsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchAllTeamsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchAllTeamsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      teams: values,
      isFetching: false,
      error: null
    }),
    [fetchAllTeamsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchBlacklistedStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchBlacklistedSuccess]: (state, { payload: { values } }) => ({
      ...state,
      blacklisted: values,
      isFetching: false,
      error: null
    }),
    [fetchBlacklistedError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [resetUsers]: (state) => ({
      ...state,
      users: [],
      userComplete: {},
      divisions: [],
      teams: []
    }),
  },
  defaultState
)

export default reducer
