import React from 'react'
import { i18n } from '@tops/services'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ViewBusiness from './ViewBusiness'
import CreateBusiness from './CreateBusiness'
import EditBusiness from './EditBusiness'

const Business = ({ savedInfo }) => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <ViewBusiness {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_BUSINESS.LINK}`}
          render={(props) => <CreateBusiness {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_BUSINESS.LINK}/:id`}
          render={(props) => <EditBusiness {...props} savedInfo={savedInfo} />}
        />
      </Switch>
    </>
  )
}

export default Business
