import styled, { keyframes } from 'styled-components'
import { DatePicker } from 'antd'

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
`

const fadeInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`

export const Container = styled.div`
  display:flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  justify-content: space-between;
  font-weight: 500;
  font-size: 14px;
`

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledDatePicker = styled(DatePicker)`
  height: 34px;
  max-width: 100%;
  width: ${({ width }) => width || '300px'};
  color: ${({ theme }) => theme.colors.darkGray};
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.05);
  border:${({ theme, color, error }) => error ? `1px solid ${theme.colors.red}` : `1px solid ${theme.colors.gray}`};
  transition: border 0.1s linear;
  ${({ disabled }) => disabled && `background: #f5f5f5 !important; border-color: #f5f5f5 !important;`}

  input {
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: 13px;
  }

  input > disabled {
    color: inherit;
    cursor: not-allowed;
  }
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.gray};
    border-right-width: 1px !important;
  }

  & > .ant-picker-active-bar {
    background: ${({ color }) => color};
  }
`

export const Label = styled.label`
  font-family: ${({ theme }) => theme.fonts.lato};
  color: ${({ theme }) => theme.colors.darkGray};
  line-height: 40px;
  font-weight: ${({ bold }) => bold ? '600' : '500'};
  ${({ horizontal }) => horizontal && "margin-right: 20px"}
`

export const ErrorMessage = styled.div`
  min-height: 24px;
  padding-top: 0px;
  color: ${({ theme }) => theme.colors.red};
  margin-top: 5px;
  font-size: 14px;
  animation:${({ hasError }) => hasError ? fadeInDown : fadeInUp} 0.3s ease;
`
