import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'
import { Menu } from 'antd'

export const Container = styled.div`
  position: relative;
  height: 52px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  z-index: 999;
  box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.05);
`

export const ContentWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RightSection = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 600px){
    display: none;
  }
`

export const LeftSection = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Divider = styled.span`
  color: black;
  fontSize: 20;
  padding: 0 10px;
  fontWeight: 300;
  ${mediaQueries.mobile`
    display:none;
    padding: 0;
  `}
`

export const LogOutButton = styled.div`
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
`

export const LogOutIcon = styled.button`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  padding-bottom: 5px;
  box-shadow: 0px 6px 10px -4px rgba(0,0,0,0.56);
  margin-left: 8px;
  ${mediaQueries.mobile`
    padding-bottom: 2px;
  `}
`

export const MenuMobile = styled.div`
  height: 52px;
  flex: 0 0 52px;
  border-left: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media (max-width: 600px){
    display: flex;
  }
`

export const OverlayBackground = styled.div`
  height: ${({ show }) => show ? "100vh" : "0"};
  width: 100%;
  background: rgba(0,0,0,0.56);
  position: absolute;
  top: 0;
  opacity: ${({ show }) => show ? "1" : "0"};
  z-index: 10000;
  transition: opacity 0.3s linear;
`

export const StyledMenu = styled(Menu)`
  border: 1px solid ${({ theme }) => theme.colors.gray};
  box-shadow: 0px 6px 10px -4px rgba(0,0,0,0.56);
  z-index: 100000;
  ${mediaQueries.tablet`
    position: absolute;
    top: 0;
    right: 0;
    width: 190px;
`}
`

export const StyledMenuItem = styled(Menu.Item)`
  font-family: ${({ theme }) => theme.fonts.lato};
`

export const Hamburger = styled.div`
  height: 52px;
  flex: 0 0 52px;
  border-right: 1px solid #f0f0f0;
  justify-content: center;
  align-items: center;
  display: none;
  @media (max-width: 1365px){
    display: flex;
  }
`

export const LogoContainer = styled.div`
  width: 184px;
  display: flex;
  justify-content: center;
  ${mediaQueries.tablet`
    width: auto;
    padding: 0 20px;
  `}
`

export const Logo = styled.img`
  max-width: 120px;
  max-height: 32px;
  ${mediaQueries.tablet`
    max-width: 105px;
    max-height: 28px;
  `}
`