const IconNames = {
  Add: 'Add',
  Remove: 'Remove',
  Back: 'Back',
  SmallBack: 'SmallBack',
  Close: 'Close',
  Edit: 'Edit',
  LogOut: 'LogOut',
  Search: 'Search',
  User: 'User',
  LoginUser: 'LoginUser',
  LoginLock: 'LoginLock',
  EyeShow: 'EyeShow',
  EyeHide: 'EyeHide',
  NewEyeHide: 'NewEyeHide',
  NewEyeShow: 'NewEyeShow',
  Help: 'Help',
  HelpActive: 'HelpActive',
  Info: 'Info',
  Info180: 'Info180',
  Home: 'Home',
  RequestsActive: 'RequestsActive',
  Requests: 'Requests',
  ScheduleActive: 'ScheduleActive',
  Schedule: 'Schedule',
  ExpirationsActive: 'ExpirationsActive',
  Expirations: 'Expirations',
  ChecklistsActive: 'ChecklistsActive',
  Checklists: 'Checklists',
  InventoryActive: 'InventoryActive',
  Inventory: 'Inventory',
  AuditsActive: 'AuditsActive',
  Audits: 'Audits',
  LevelsActive: 'LevelsActive',
  Levels: 'Levels',
  SH: 'SH',
  Wise: 'Wise',
  WiseBig: 'WiseBig',
  UsersActive: 'UsersActive',
  Users: 'Users',
  DivisionsActive: 'DivisionsActive',
  Divisions: 'Divisions',
  DashboardActive: 'DashboardActive',
  Dashboard: 'Dashboard',
  BreadcrumbSeparator: 'BreadcrumbSeparator',
  Check: 'Check',
  Error: 'Error',
  Success: 'Success',
  Notification: 'Notification',
  Filter: 'Filter',
  TrashBin: 'TrashBin',
  Configuration: 'Configuration',
  Disable: 'Disable',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  MoreOptions: 'MoreOptions',
  Star: 'Star',
  Download: 'Download',
  ArrowDown: 'ArrowDown',
  ArrowRight: 'ArrowRight',
  ArrowLeft: 'ArrowLeft',
  Derive: 'Derive',
  Mail: 'Mail',
  forward: 'forward',
  Save: 'Save',
  Camera: 'Camera',
  Attach: 'Attach',
  Image: 'Image',
  Execute: 'Execute',
  Calendar: 'Calendar',
  Change: 'Change',
  Comment: 'Comment',
  Surveys: 'Surveys',
  ClockWarning: 'ClockWarning',
  PdfButton: 'PdfButton',
  Pdf: 'Pdf',
  Cross: 'Cross',
  MultipleSelect: 'MultipleSelect',
  CrossBackground: 'CrossBackground',
  Attach_new: 'Attach_new',
  RiskNotice: 'RiskNotice',
  Assign: 'Assign',
  Cancel: 'Cancel',
  SuccessWB: 'SuccessWB',
  ErrorWB: 'ErrorWB',
  Improve: 'Improve',
  LockActive: 'LockActive',
  LockInactive: 'LockInactive',
  FilterRequests: 'FilterRequests',
  Formulary: 'Formulary',
  QR: 'QR',
  Graph: 'Graph',
  Flag: 'Flag',
  OnOff: 'OnOff',
  Alert: 'Alert',
  AdditionalComment: 'AdditionalComment',
  BlockingItem: 'BlockingItem',
  BlockingItem2: 'BlockingItem2',
  Delete: 'Delete',
  LitArrow: 'LitArrow',
  ObligatoryComment: 'ObligatoryComment',
  Duplicate: 'Duplicate',
  Reorder: 'Reorder',
  Arrows: 'Arrows',
  Hamburger: 'Hamburger',
  Ranking: 'Ranking',
  RankingActive: 'RankingActive',
  ConfigSurveys: 'ConfigSurveys',
  ConfigSurveysActive: 'ConfigSurveysActive',
  Excel: 'Excel',
  Omitted: 'Omitted',
  Revert: 'Revert',
  EnableMail: 'EnableMail',
  Rise: 'Rise',
  TrainingsActive: 'TrainingsActive',
  Trainings: 'Trainings',
  SharedDevice: 'SharedDevice',
  OnlyCross: 'OnlyCross',
  LinkQr: 'LinkQr',
  Microsoft: 'Microsoft'
}

export default IconNames
