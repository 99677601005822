import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { theme } from '@tops/global_config'
import { List, IconButton, ListItem, PageTitleDashboard, Loader, Pagination, NoData, Button } from '@tops/components'

import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWiseConfigurations } from '../../../../actions/wiseConfiguration'

const ViewWiseConfiguration = ({ history, savedInfo }) => {
  const { url } = useRouteMatch()

  const [currentPage, setCurrentPage] = useState(1)
  const [error, setError] = useState(false)
  const [active, setActive] = useState(true)

  const dispatch = useDispatch()

  const { values, isFetching } = useSelector(state => state.wiseConfiguration)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchWiseConfigurations(savedInfo.id, active))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
  }, [currentPage, active])

  if (isFetching) {
    return <Loader />
  }

  const handleActive = () => {
    setActive(!active)
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[savedInfo.wiseName]}>
        <IconButton size={35} background={theme.colors.primary} icon={'Add'} text={i18n('SIDEBAR_LINKS').WISE_CREATE_CONFIGURATION.LABEL} bold="true"
          textSize={14} onClick={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').WISE_CREATE_CONFIGURATION.LINK}`)} />
      </PageTitleDashboard>
      <span>
        <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
        <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
      </span>
      <div style={{ marginTop: 16 }}>
        {values.length ? <>
          <List>
            {
              values.map((item) => <ListItem
                item={item}
                key={item.configuration_id}
                name={item.business_name}
                action={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').WISE_EDIT_CONFIGURATION.LINK}/${item.configuration_id}`)}
              />)
            }
          </List>
          <Pagination currentPage={currentPage} itemsLength={values.length}
            onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} /></>
          : <NoData message={i18n('WISE').VIEW_CONFIGURATION.NO_DATA_MESSAGE} />
        }
      </div>
    </>
  )
}

export default ViewWiseConfiguration
