import { handleActions } from 'redux-actions'
import {
  saveSearchCache
} from '../actions/searchCache'

export const defaultState = {
  search: {},
}

const reducer = handleActions(
  {
    [saveSearchCache]: (state, { payload: { values } }) => ({
      ...state,
      search: { [values.page]: { filters: values.filters, currentPage: values.currentPage, businessId: values.businessId } },
      isFetching: false,
      error: null
    }),
  },
  defaultState
)

export default reducer