import styled from 'styled-components'
// import { mediaQueries } from '@tops/global_config'

export const StyledButton = styled.button`
  background-color: ${({ theme, disabled, background }) => disabled ? theme.colors.gray : background ? background : theme.colors.lilac};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  color: ${({ theme, disabled, color }) => disabled ? theme.colors.darkGray : color ? color : theme.colors.darkLilac};
  font-family: ${({ theme }) => theme.fonts.lato};
  border: none;
  min-width: ${({ small }) => (small ? 'auto' : '100%')};
  height: auto;
  font-size: ${({ small }) => (small ? '14' : '13')}px;
  font-weight: 900;
  padding: ${({ small, training }) => (small ? training ? '3px 10px': '5px 10px' : '8px 30px')};
  border-radius: ${({ square }) => (square ? '5px' : '30px')};
  outline: none;
  box-shadow: ${({ selected }) => (selected ? 'inset' : '')} 0px 2px 4px 1px rgba(0,0,0,0.06);
  margin: 0px 5px;
  align-self: center;
  transition: all 0.5s ease; 
  display:inline-flex;
  justify-content: center;
  align-items: center;
`

export const StyledButtonback = styled.button`
  background-color: ${({ theme, disabled, background }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'inherit' : 'pointer')};
  color: ${({ theme, disabled, color }) => color ? color : disabled ? theme.colors.darkGray : theme.colors.darkLilac};
  font-family: ${({ theme }) => theme.fonts.lato};
  border: none;
  min-width: ${({ small }) => (small ? '130px' : '100%')};
  height: auto;
  font-size: ${({ small }) => (small ? '14' : '13')}px;
  font-weight: 900;
  padding: ${({ small }) => (small ? '6px 18px' : '8px 30px')};
  border-radius: ${({ square }) => (square ? '5px' : '30px')};
  outline: none;
  margin: 0px 5px;
  align-self: flex-start;
  transition: all 0.5s ease;
  display: inline-flex;
  justify-content: center;
`