import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
${mediaQueries.mobile`
flex-direction: column;
`};
`

export const FormContainer = styled.form`
display: flex;
height: 60%;
justify-content: center;
`

export const Image = styled.img`
padding-bottom: 50px;
width: 100%;
${mediaQueries.mobile`
display: none;
`};
`
export const LogoWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
`

export const Logo = styled.img`
  width: 190px;
`

export const LoginWrapper = styled.div`
  position: relative;
  min-width: 400px;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 30px;
  ${mediaQueries.mobile`
  min-width: 100%;
`};
`

export const SideImagesWrapper = styled.div`
  min-width: 25%;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 35px;
  ${mediaQueries.mobile`
  margin-bottom: 15px;
`};
`