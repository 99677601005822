import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, StyledCheckbox, DashboardContainer, DashboardInput, StyledSwitch, Select,
  AddFieldsOnRequestTypeButton, AddFieldsOnExpirationRequestTypeButton, Button, SmallIconButton, MessageError, Loader, MessageBanner,
} from '@tops/components'
import { theme } from '@tops/global_config'
import { submitExpirationRequestType, fetchAllLevelsBySector } from '../../../../actions/expirationRequestTypes'
import { fetchSectorsByBusiness } from '../../../../actions/sectors'
import { fetchAllBusiness } from '../../../../actions/business'
import { fetchRolesSimple } from '../../../../actions/roles'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Divider } from 'antd'
import { Formik, FieldArray, getIn } from 'formik'
import * as yup from 'yup'

const CreateExpirationRequestType = ({ history, savedInfo }) => {

  const [error, setError] = useState(false)
  const [validateError, setValidateError] = useState(false)
  const [publicCheck, setPublicCheck] = useState(true)
  const [success, setSuccess] = useState(false)
  const [stages, setStages] = useState([])
  const [stagesFiltered, setStagesFiltered] = useState([])

  const dispatch = useDispatch()

  const business = useSelector(state => state.business)
  const sectors = useSelector(state => state.sectors)
  const levels = useSelector(state => state.requestTypes)
  const expirationRequestTypes = useSelector(state => state.expirationRequestTypes)
  const roles = useSelector(state => state.roles)

  useEffect(() => {
    const fetchData = async () => {
      try {
        //savedInfo.id es accountID
        dispatch(fetchAllBusiness(savedInfo.id))
      } catch (error) {
        setError(true)
      }
    }

    fetchData()
    return () => {
    }
  }, [])


  const handleSwitch = (val, fun) => fun(!val)

  const aprroversDropDown = [{ id: 0, name: i18n('APPROVAL_TYPE_NPlus1') }, { id: 1, name: i18n('APPROVAL_TYPE_ROLES') }]

  const handlePublicPrivate = () => { setPublicCheck(!publicCheck) }

  const validationSchema = yup.object().shape({
    name: yup.string().trim().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.NAME_ERROR),
    businessSelected: yup.string().ensure().required(i18n('ERRORS').REQUIRED_FIELD),
    sectorOwnerSelected: yup.string().ensure().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.SECTOR_ERROR),
    version_dto: yup.object().shape({
      entity_configuration_ids: yup.array().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.LEVELS_ERROR),
      phases_dto: yup.array().of(yup.object().shape({
        name: yup.string().trim().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.STAGE_NAME_ERROR),
        has_approval: yup.boolean(),
        approval_type: yup.mixed().when('has_approval', {
          is: true,
          then: yup.mixed().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.APPROVAL_TYPE_ERROR),
        }),
        roles: yup.array().nullable().when('approval_type', {
          is: 1,
          then: yup.array().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.ROLE_ERROR),
        }),
        has_solver: yup.boolean(),
        resolution_role_id: yup.mixed().when('has_solver', {
          is: true,
          then: yup.mixed().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.RESOLUTOR_ERROR),
        }),
        has_alarm: yup.boolean(),
        alarms_dto: yup.array().of(yup.object().shape({
          quantity: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
          model_phase_alarm_units: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD)
        })),
        phase_sections_dto: yup.array().of(yup.object().shape({
          name: yup.string().trim().required(i18n('ERRORS').EXPIRATION_REQUEST_TYPE.SECTION_NAME_ERROR),
          field_section_dto: yup.array().of(yup.object().shape({
            new_field_dto: yup.object().shape({
              name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
              field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
              // ternary_option_name: yup.string().trim().required(),
              entity_configuration_id: yup.mixed().when('field_type', {
                is: 7,
                then: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
              }),
              parameters: yup.mixed().when('field_type', {
                is: 6,
                then: yup.mixed().required(i18n('ERRORS').REQUIRED_FIELD),
              })
            })
          }))
        }))
      }))

    })
  })

  const onSubmit = async (values) => {
    const accountID = savedInfo.id;
    const sectorID = values.sectorOwnerSelected;
    await dispatch(submitExpirationRequestType(values, accountID, sectorID)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  const nameInput = (handleChange, errors) => (
    <div style={{ marginBottom: "15px" }}>
      <DashboardInput label={i18n('NAME')} error={errors.name} horizontal="true" border="true" width="350px" name="name" onChange={handleChange} />
    </div>
  )

  const businessSelect = (setFieldValue, errors) => (
    <div style={{ marginBottom: "15px" }}>
      <Select items={business.values} error={errors.businessSelected} label={i18n('BUSINESS')} horizontal="true" width="350px" border="true"
        name="businessSelected" onChange={(e) => { setFieldValue("businessSelected", e); dispatch(fetchSectorsByBusiness(savedInfo.id, e)) }} />
    </div>
  )

  const sectorOwnerSelect = (handleChange, setFieldValue, errors) => (
    <Select items={sectors.allSectorsByBusiness} error={errors.sectorOwnerSelected} label={i18n('OWNER_SECTOR')} horizontal="true" width="350px" border="true"
      name="sectorOwnerSelected" onChange={(e) => {
        setFieldValue("sectorOwnerSelected", e);
        dispatch(fetchAllLevelsBySector(e));
        dispatch(fetchRolesSimple(e));
      }} />
  )

  const publicPrivateButtons = (setFieldValue, values) => (
    <div style={{ marginTop: "15px", marginBottom: "15px", display: "flex" }}>
      <Button type="button" square small background={theme.colors.accent} selected={!publicCheck}
        onClick={(e) => { setFieldValue("is_public", true); setFieldValue("allowed_sectors_ids", []); handlePublicPrivate() }}>{i18n('PUBLIC')}</Button>
      <Button type="button" square small background={theme.colors.accent} selected={publicCheck}
        onClick={(e) => { setFieldValue("is_public", false); handlePublicPrivate() }}>{i18n('PRIVATE')}</Button>
    </div>
  )

  const allowedSectorsIdsCheckboxes = (setFieldValue, values) => (
    <FieldArray name="allowed_sectors_ids">
      {
        arrayHelpers => (
          <div style={{ display: "flex", flexDirection: "column", maxHeight: "200px", flexWrap: "wrap" }}>
            {
              sectors.allSectorsByBusiness.map((sector, i) => {
                if (sector.id !== values.sectorOwnerSelected) {
                  return <StyledCheckbox key={i} value={sector.id} checked={!publicCheck ? true : values.allowed_sectors_ids.includes(sector.id)}
                    name={"allowed_sectors_ids"} disabled={!publicCheck && true} onChange={e => {
                      if (e.target.checked) {
                        arrayHelpers.push(sector.id);
                      }
                      else {
                        const idx = values.allowed_sectors_ids.indexOf(sector.id);
                        arrayHelpers.remove(idx)
                      }
                    }
                    }>{sector.name}</StyledCheckbox>
                }
              })
            }
          </div>
        )}
    </FieldArray>
  )

  const entityConfigurationIdsCheckboxes = (values, errors) => (<>
    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
      <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('LEVELS'))}</span>
    </div>
    <MessageError errorname="version_dto.entity_configuration_ids" />
    <FieldArray name={`version_dto.entity_configuration_ids`}>
      {
        arrayHelpers => (
          <div style={{ display: "flex", flexDirection: "column", maxHeight: "100px", flexWrap: "wrap" }}>
            {
              levels.allLvlsBySector.length == 0 ? i18n('NOT_LEVELS_TO_OWNER_SECTOR') :
                levels.allLvlsBySector.map((level, i) =>
                  <StyledCheckbox key={i} value={level.id} checked={values.version_dto.entity_configuration_ids.includes(level.id)}
                    name={`version_dto.entity_configuration_ids`} onChange={e => {
                      if (e.target.checked) {
                        arrayHelpers.push(level.id);
                      }
                      else {
                        const idx = values.version_dto.entity_configuration_ids.indexOf(level.id);
                        arrayHelpers.remove(idx)
                      }
                    }
                    }>
                    {level.name}</StyledCheckbox>
                )
            }
          </div>
        )}
    </FieldArray>
  </>
  )

  const stageSection = (handleChange, setFieldValue, values, errors) => (<>
    {/* STAGE */}
    <FieldArray name={`version_dto.phases_dto`}>
      {
        arrayHelpers => (<div>
          {
            values.version_dto.phases_dto.map((phase, i) =>
              <div key={i}>
                <div style={{ marginBottom: "15px" }}>
                  <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('STAGE')) + " " + (i + 1)}</span>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <DashboardInput id={"createReq_stage_" + i + "_name"} error={getIn(errors, `version_dto.phases_dto[${i}].name`)}
                    name={`version_dto.phases_dto[${i}].name`} label={i18n('NAME')} horizontal="true" border="true" width="350px"
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].name`, e.target.value)} />
                </div>

                {/* SECTION */}
                <FieldArray name={`version_dto.phases_dto[${i}].phase_sections_dto`}>
                  {arrayHelpers => (
                    <div style={{ marginLeft: 40, marginBottom: 20 }}>
                      <div style={{ marginBottom: "15px" }}>
                        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('SECTIONS'))}</span>
                      </div>
                      {phase.phase_sections_dto.map((section, j) => <>
                        <div style={{ marginBottom: "20px", width: 510 }}>
                          <DashboardInput id={"createPhaseSection" + i + "_" + j + "_name"} error={getIn(errors, `version_dto.phases_dto[${i}].phase_sections_dto[${j}].name`)}
                            name={`version_dto.phases_dto[${i}].phase_sections_dto[${j}].name`} label={i18n('SECTION_NAME')} horizontal="true" border="true" width="350px"
                            onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].phase_sections_dto[${j}].name`, e.target.value)} />
                        </div>
                        <div style={{ width: 700 }}>
                          <AddFieldsOnRequestTypeButton data={section.field_section_dto} levelOptions={levels.allLvlsBySector} expirationsTrue={true}
                            dataStructure={`version_dto.phases_dto[${i}].phase_sections_dto[${j}].field_section_dto`} />
                        </div>
                        {(j > 0 || (j > 0 && i === 0) || (j === 0 && i > 0)) &&
                          <>
                            <div style={{ float: "right", padding: "20px 0 20px 40px", marginTop: "-60px" }} >
                              <SmallIconButton icon={"TrashBin"} size={21} text={i18n('DELETE_SECTION')} bold="true" iconColor="white" background="#b9a5fd"
                                onClick={() => arrayHelpers.remove(j)} />
                            </div>
                            <Divider style={{ width: 700 }} />
                          </>
                        }
                      </>
                      )}
                      <SmallIconButton icon={'Add'} bold="true" size={21} text={i18n('ADD_SECTION')}
                        onClick={() => arrayHelpers.push(
                          {
                            name: '',
                            has_multiple_values: false,
                            field_section_dto: [
                              {
                                is_required: false,
                                existing_field_id: null,
                                new_field_dto: {
                                  field_type: null,
                                  name: "",
                                  ternary_option_name: "", // Joaco 10/6/20: Si vas a usar el tipo de campo si/no/nm aca iria el nombre de la tercera opcion, esto viene de un problema mayor: el tipo de campo si/no/nm no se deberia llamar asi en el pasaje de tiendacheck a tops se definio que en vez de ser "si / no / necesita mejorar" la opcion "necesita mejorar" deberia tener su propio nombre, de ahi que en el back se llama ternario, si va a usar ese tipo de campo tenes que enviar un valor sino podes dejarlo en nulo o vacio.
                                  entity_configuration_id: null,
                                  parameters: []
                                }
                              }
                            ],
                          }
                        )} />
                    </div>
                  )}
                </FieldArray>

                <div style={{ marginBottom: "25px" }}>
                  {/* Aprobador */}
                  <StyledSwitch id={"createReq_stage_" + i + "_has_approval"} checked={values.version_dto.phases_dto[i].has_approval}
                    label={i18n('APPROVAL')} horizontal="true" name={`version_dto.phases_dto[${i}].has_approval`}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].has_approval`, e.target.checked);
                      if (e.target.checked === true) {
                        setFieldValue(`version_dto.phases_dto[${i}].has_solver`, false)
                        setFieldValue(`version_dto.phases_dto[${i}].resolution_role_id`, null)
                        setFieldValue(`version_dto.phases_dto[${i}].cannot_force_reject`, false)
                      }
                    }} />
                  {phase.has_approval &&
                    <div style={{ margin: "25px 0" }}>
                      <Select items={aprroversDropDown} error={getIn(errors, `version_dto.phases_dto[${i}].approval_type`)} label={i18n('APPROVAL_TYPE')}
                        horizontal="true" width="350px" border="true"
                        onChange={(e) => {
                          setFieldValue(`version_dto.phases_dto[${i}].approval_type`, e)
                          if (e === 0) setFieldValue(`version_dto.phases_dto[${i}].roles`, null)
                        }
                        } />
                      {
                        phase.approval_type === 1 ?
                          <Select mode="multiple" items={roles.simple_values} label={i18n('SOLVER_ROLES')} horizontal="true"
                            width="350px" border="true" placeholder={i18n('SOLVER_ROLES_PLACEHOLDER')} error={getIn(errors, `version_dto.phases_dto[${i}].roles`)}
                            onChange={(e) => { setFieldValue(`version_dto.phases_dto[${i}].roles`, e) }
                            } />
                          : ''}
                    </div>}

                  {/* Resolutor */}
                  <StyledSwitch id={"createReq_stage_" + i + "_has__solver"} checked={values.version_dto.phases_dto[i].has_solver}
                    label={i18n('SOLVER')} horizontal="true" name="has_solver" name={`version_dto.phases_dto[${i}].has_solver`}
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].has_solver`, e.target.checked)
                      if (e.target.checked === true) {
                        setFieldValue(`version_dto.phases_dto[${i}].approval_type`, null)
                        setFieldValue(`version_dto.phases_dto[${i}].has_approval`, false)
                        setFieldValue(`version_dto.phases_dto[${i}].roles`, null)
                      }
                      else {
                        setFieldValue(`version_dto.phases_dto[${i}].resolution_role_id`, null)
                        setFieldValue(`version_dto.phases_dto[${i}].cannot_force_reject`, false)
                      }
                    }} />
                  {
                    phase.has_solver &&
                    <>
                      <div style={{ margin: "25px 0" }}>
                        <Select items={roles.simple_values} label={i18n('SOLVER_ROLES')} horizontal="true"
                          width="350px" border="true" placeholder={i18n('SOLVER_ROLES_ONE_PLACEHOLDER')}
                          error={getIn(errors, `version_dto.phases_dto[${i}].resolution_role_id`)}
                          onChange={(e) => {
                            setFieldValue(`version_dto.phases_dto[${i}].approval_type`, null)
                            setFieldValue(`version_dto.phases_dto[${i}].resolution_role_id`, e)
                          }} />
                      </div>
                      <div style={{ margin: "25px 0" }}>
                        <StyledSwitch id={"createReq_stage_" + i + "_cannot_force_reject"} checked={values.version_dto.phases_dto[i].cannot_force_reject}
                          label={i18n('SOLVER_CANNOT_FORCE_REJECT')} horizontal="true" name={`version_dto.phases_dto[${i}].cannot_force_reject`}
                          onChange={(e) => {
                            setFieldValue(`version_dto.phases_dto[${i}].cannot_force_reject`, e.target.checked);
                          }} />
                      </div>
                    </>
                  }

                  <StyledSwitch id={"createReq_stage_" + i + "_has_alarm"} name={`version_dto.phases_dto[${i}].has_alarm`}
                    label={i18n('ALARM')} horizontal="true" name="has_alarm"
                    onChange={(e) => {
                      setFieldValue(`version_dto.phases_dto[${i}].has_alarm`, e.target.checked)
                      e.target.checked === false && setFieldValue(`version_dto.phases_dto[${i}].alarms_dto`, [])
                    }} />
                  {
                    phase.has_alarm &&
                    <AddFieldsOnExpirationRequestTypeButton data={phase.alarms_dto} dataStructure={`version_dto.phases_dto[${i}].alarms_dto`} />
                  }

                  <StyledSwitch id={"createReq_stage_" + i + "_has_files"} name={`version_dto.phases_dto[${i}].has_files`}
                    label={i18n('FILE')} horizontal="true" name="has_files"
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_files`, e.target.checked)} />

                  <StyledSwitch id={"createReq_stage_" + i + "_has_signature"} name={`version_dto.phases_dto[${i}].has_signature`}
                    label={i18n('SIGNATURE')} horizontal="true" name="has_signature"
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_signature`, e.target.checked)} />

                  {/* Programación - No se encuentra habilitado en la primer etapa*/}
                  <StyledSwitch id={"createReq_stage_" + i + "_can_be_planned"} name={`version_dto.phases_dto[${i}].can_be_planned`}
                    label={i18n('PLANNED_REQUEST')} horizontal="true" disabled={i === 0 ? true : false}
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].can_be_planned`, e.target.checked)} />

                  <StyledSwitch id={"createReq_stage_" + i + "_has_location"} name={`version_dto.phases_dto[${i}].has_location`}
                    label={i18n('LOCATION')} horizontal="true" name="has_location"
                    onChange={(e) => setFieldValue(`version_dto.phases_dto[${i}].has_location`, e.target.checked)} />

                </div>
                {i > 0 &&
                  <>
                    <div style={{ float: "right", padding: "20px 0 20px 40px" }} >
                      <SmallIconButton icon={"TrashBin"} size={21} text={i18n('DELETE_STAGE')} bold="true" iconColor="white" background="#b9a5fd"
                        onClick={() => {
                          arrayHelpers.remove(i)
                          stagesFiltered.pop()
                        }} />
                    </div>
                    <Divider />
                  </>
                }
              </div>
            )
          }

          <SmallIconButton bold="true" icon={'Add'} size={21} text={i18n('ADD_STAGE')}
            onClick={() => {
              arrayHelpers.push(
                {
                  name: '',
                  cannot_force_reject: false,
                  can_be_derived: false,
                  derivation_option: null,
                  can_be_reversed: false,
                  reversion_target_model_phase_index: null,
                  checklist_id: null, // Joaco (5/6/20): Por ahora no mandes este campo o mandalo en null, va a ser para cuando se pueda conectar con checklists, todavia no hace nada.
                  has_approval: false,
                  has_alarm: false,
                  has_files: false,
                  has_signature: false,
                  has_location: false,
                  resolution_role_id: null,
                  approval_type: null,
                  phase_sections_dto: [],
                  alarms_dto: [],
                  roles: null,
                  can_be_planned: false
                }
              )
              setStages(prev => {
                return [...prev, {
                  id: arrayHelpers.form.values.version_dto.phases_dto.length,
                  name: i18n('STAGE') + " " + (arrayHelpers.form.values.version_dto.phases_dto.length + 1)
                }]
              }
              )
              setStagesFiltered(stages.filter(item => item.id !== arrayHelpers.form.values.version_dto.phases_dto.length))
            }}
          />
        </div>
        )}
    </FieldArray>
  </>)

  const initialValues = {
    name: "",
    businessSelected: "",
    sectorOwnerSelected: "",
    is_public: false,
    allowed_sectors_ids: [],
    description: "",
    notifies_state_changes: false,
    is_periodic: false,
    can_be_planned: false,
    hasRelatedRequest: false,
    related_request_model_id: null,
    has_urgency: true,
    has_expiration_date: false,
    is_expiration_model: true,
    version_dto: {
      phases_dto: [{
        name: '',
        cannot_force_reject: false,
        can_be_derived: false,
        derivation_option: null,
        can_be_reversed: false,
        reversion_target_model_phase_index: null,
        checklist_id: null,
        has_approval: false,
        has_alarm: false,
        has_files: false,
        has_signature: false,
        has_location: false,
        can_be_planned: false,
        resolution_role_id: null, // porque es uno solo
        approval_type: null, // n+1 => 0 y si es roles => 1
        phase_sections_dto: [
          {
            name: '',
            has_multiple_values: false,
            field_section_dto: [
              {
                is_required: false,
                existing_field_id: null,
                new_field_dto: {
                  field_type: null,
                  name: "",
                  ternary_option_name: "", // Joaco 10/6/20: Si vas a usar el tipo de campo si/no/nm aca iria el nombre de la tercera opcion, esto viene de un problema mayor: el tipo de campo si/no/nm no se deberia llamar asi en el pasaje de tiendacheck a tops se definio que en vez de ser "si / no / necesita mejorar" la opcion "necesita mejorar" deberia tener su propio nombre, de ahi que en el back se llama ternario, si va a usar ese tipo de campo tenes que enviar un valor sino podes dejarlo en nulo o vacio.
                  entity_configuration_id: null,
                  parameters: []
                }
              }
            ]
          }
        ],
        alarms_dto: [],
        roles: null // si elijo roles cargo data, si no elijo nada es null
      }],
      entity_configuration_ids: [],
    },
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').EXPIRATION_REQUEST_TYPE.LABEL, i18n('SIDEBAR_LINKS').CREATE_EXPIRATION_REQUEST_TYPE.LABEL]} >
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').CREATE_EXPIRATION_REQUEST_TYPE} />
        <MessageBanner visible={error} status="error" message={expirationRequestTypes.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {expirationRequestTypes.isSubmitting && <Loader />}
      <Formik initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="650px">
                {nameInput(handleChange, errors)}
                {businessSelect(setFieldValue, errors)}
                {sectorOwnerSelect(handleChange, setFieldValue, errors)}
                {publicPrivateButtons(setFieldValue, values)}
                {allowedSectorsIdsCheckboxes(setFieldValue, values, errors)}
                {entityConfigurationIdsCheckboxes(values, errors)}
                <div style={{ marginTop: "25px" }}>
                  {stageSection(handleChange, setFieldValue, values, errors)}
                </div>
              </DashboardContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default CreateExpirationRequestType