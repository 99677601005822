import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const PageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.fonts.lato};
  color:  ${({ theme }) => theme.colors.darkGray};
  font-size: 14px;
  background: ${({ theme, background }) => background || theme.colors.accent};
  overflow-y: auto;

`
export const PageWrapper = styled.div`
  height: auto;
  padding: 0 30px;

  ${mediaQueries.mobile`
  padding: 0 18px;
  `}
`

export default PageWrapper
