import { handleActions } from 'redux-actions'
import {
  fetchSectorsStart, fetchSectorsSuccess, fetchSectorsError,
  submitSectorStart, submitSectorSuccess, submitSectorError,
  editSectorStart, editSectorSuccess, editSectorError,
  fetchSectorsByAccountStart, fetchSectorsByAccountSuccess, fetchSectorsByAccountError,
  fetchSectorsByBusinessStart, fetchSectorsByBusinessSuccess, fetchSectorsByBusinessError,
} from '../actions/sectors'

export const defaultState = {
  values: [],
  allSectorsByAccount: [],
  allSectorsByBusiness: [],
  error: null,
  isFetching: false,
}

const reducer = handleActions(
  {
    [fetchSectorsStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchSectorsSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchSectorsError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitSectorStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitSectorSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitSectorError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editSectorStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editSectorSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isSubmitting: false,
    }),
    [editSectorError]: (state, { payload: { error } }) => ({
      ...state,
      hasMore: false,
      isSubmitting: false,
      error
    }),
    [fetchSectorsByAccountStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchSectorsByAccountSuccess]: (state, { payload: { allSectorsByAccount } }) => ({
      ...state,
      allSectorsByAccount,
      isFetching: false,
      error: null
    }),
    [fetchSectorsByAccountError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [fetchSectorsByBusinessStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchSectorsByBusinessSuccess]: (state, { payload: { allSectorsByBusiness } }) => ({
      ...state,
      allSectorsByBusiness,
      isFetching: false,
      error: null
    }),
    [fetchSectorsByBusinessError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
  },
  defaultState
)

export default reducer
