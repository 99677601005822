import styled from 'styled-components'
import { mediaQueries } from '@tops/global_config'

export const Title = styled.div`
  font-size: 20px;
  padding-bottom: 10px;
  font-weight: bold;
`

export const Subtitle = styled.div`
  font-size: 14px;
`

export const Wrapper = styled.div`
  // text-align:center;
  margin-top: 15px;
`
export const Table = styled.table`
  width: 100%;
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => theme.fonts.lato};
  font-size: 13px;
`
export const TitleHead = styled.thead`
  font-weight:500;
 `

export const TitleTable = styled.th`
  padding: 10px 20px;
  font-size: 14px;
 `

export const TableRow = styled.tr`
  width: 100%;
  opacity: 1;

  ${mediaQueries.mobile`
  padding: 15px 0;`}
`

export const TableBody = styled.tbody`
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray}`};
`
export const TableDetail = styled.td`
  padding: 10px 20px;
  font-weight: normal;
  border-right: ${({ theme }) => `1px solid ${theme.colors.gray}`};
  vertical-align: top;
  &: last-child { 
    border-right: none;
   }
 `