import { handleActions } from 'redux-actions'
import {
  getUpstreamTicketIdStart,
  getUpstreamTicketIdSuccess,
  getUpstreamTicketIdError,
  getUpstreamTicketFileStart,
  getUpstreamTicketFileSuccess,
  getUpstreamTicketFileError,
} from '../actions/files'

export const defaultState = {
  isSubmitting: false,
  values: [],
  error: null
}

const reducer = handleActions(
  {
    [getUpstreamTicketIdStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [getUpstreamTicketIdSuccess]: state => ({
      ...state,
      isSubmitting: false,
      error: null
    }),
    [getUpstreamTicketIdError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [getUpstreamTicketFileStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [getUpstreamTicketFileSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isSubmitting: false,
      error: null
    }),
    [getUpstreamTicketFileError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    })
  },
  defaultState
)

export default reducer