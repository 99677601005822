import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { ConfirmationButton, CancelButton, PageTitleDashboard, Select, DashboardContainer, DashboardInput, StyledSwitch, AddFieldsButton, Loader, MessageBanner, EditFieldsButton } from '@tops/components'
import { Form } from 'antd'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { editAccountLevel, fetchAccountLevelsFields } from '../../../../actions/accountLevels'
import * as yup from 'yup'
import { editRole } from '../../../../actions/roles'
import { fetchAllBusiness } from '../../../../actions/business'

const EditRoles = ({ history, location, savedInfo }) => {
  const [check, setCheck] = useState(false)
  const [error, setError] = useState(false)
  const [editInfo, setEditInfo] = useState({})
  const [load, setLoad] = useState(false)
  const [success, setSuccess] = useState(false)
  const [businessName, setBusinessName] = useState()
  const [validateError, setValidateError] = useState(false)

  const { id } = useParams()

  const handleSwitch = () => setCheck(!check)

  const dispatch = useDispatch()
  const roles = useSelector(state => state.roles)
  const business = useSelector(state => state.business)

  useEffect(() => {
    try {
      dispatch(fetchAllBusiness(savedInfo.id))
    } catch (error) {
      setError(true)
    }

    if (business.values.length !== 0) {
      business.values.forEach((item) => {
        if (item.id === location.state.business) {
          setBusinessName(item.name)
        }
      })
    }

    if (roles.values.length !== 0) {
      roles.values.forEach((item) => {
        if (item.id === parseFloat(id)) {
          setEditInfo(item)
        }
      })
    } else {
      history.push(`/dashboard/${savedInfo.name.toLowerCase()}/${i18n('SIDEBAR_LINKS').ROLES.LINK}`)
    }
  }, [])

  const initial = {
    id: id,
    name: editInfo.name || '',
    is_active: editInfo.is_active
  }


  const onSubmit = async (values) => {

    const filtered = Object.fromEntries(Object.entries(values).filter(([key, value]) => {
      if (key === 'id') {
        return values[key]
      }
      return values[key] !== initial[key]
    }))

    await dispatch(editRole(filtered)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  const validationSchema = yup.object({
    name: yup.string().trim().required(i18n('ERRORS').NAME_ERROR),
  })

  if (roles.isFetching) {
    return <Loader />
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').ROLES.LABEL, i18n('SIDEBAR_LINKS').EDIT_ROLE.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').EDIT_LEVEL} />
        <MessageBanner visible={error} status="error" message={roles.error} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {roles.isSubmitting && <Loader />}
      <Formik
        initialValues={initial}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >

        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
        <>
          {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
          <Form onFinish={handleSubmit}>
            <DashboardContainer width="500px">
              <div style={{ marginBottom: "15px" }}>
                <StyledSwitch id="roles_isActive" onChange={() => setFieldValue('is_active', !values.is_active)} checked={values.is_active} name="is_active" label={i18n('ACTIVE')} horizontal="true" />
              </div>
              <DashboardInput label={i18n('NAME')} horizontal="true" isRequired={true} error={errors.name} border="true" name="name" value={values.name} onChange={handleChange} width="350px" />
              <DashboardInput width="350px" label={i18n('BUSINESS')} disabled={true} value={businessName} horizontal="true" />
              <DashboardInput width="350px" label={i18n('SECTOR')} disabled={true} value={editInfo.sector_name} horizontal="true" />
            </DashboardContainer>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
              <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
            </div>
          </Form>
        </>
        )}
      </Formik>
    </>
  )
}

export default EditRoles
