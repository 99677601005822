import React from 'react'
import { i18n } from '@tops/services'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ViewSectors from './ViewSectors'
import CreateSector from './CreateSector'
import EditSector from './EditSector'


const Sectors = ({ savedInfo }) => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={(props) => <ViewSectors {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').CREATE_SECTOR.LINK}`}
          render={(props) => <CreateSector {...props} savedInfo={savedInfo} />}
        />
        <Route
          exact
          path={`${path}/${i18n('SIDEBAR_LINKS').EDIT_SECTOR.LINK}/:id`}
          render={(props) => <EditSector {...props} savedInfo={savedInfo} />}
        />
      </Switch>
    </>
  )
}

export default Sectors
