import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, Select, DashboardContainer, DashboardInput, StyledSwitch, Loader, MessageBanner,
  EditFieldsButton
} from '@tops/components'
import { Formik } from 'formik'
import { Form, Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editBusinessLevel, fetchBusinessLevelsFields, fetchAllBusinessLevels } from '../../../../actions/businessLevels'
import * as yup from 'yup'
import { updatedDiff } from '@tops/utils'

const EditBusinessLevels = ({ history, savedInfo, location }) => {
  const [fields, setFields] = useState([])
  const [error, setError] = useState(false)
  const [editInfo, setEditInfo] = useState({})
  const [success, setSuccess] = useState(false)
  const [haveWise, setHaveWise] = useState(-1)
  const [showMessage, setShowMessage] = useState(false)
  const [validateError, setValidateError] = useState(false)
  const [load, setLoad] = useState(true)




  const { id } = useParams()

  const dispatch = useDispatch()
  const business = useSelector(state => state.business)
  const businessLevels = useSelector(state => state.businessLevels)

  useEffect(() => {
    try {
      dispatch(fetchAllBusinessLevels(business.businessId, false, true))
    } catch (error) {
      setError(true)
    }
    dispatch(fetchBusinessLevelsFields(id))
    setFields(businessLevels.fields)

    if (businessLevels.values.length !== 0) {
      businessLevels.values.forEach((item) => {
        if (item.id === parseFloat(id)) {
          setEditInfo(item)
        }
        if (item.is_wise_level) {
          setHaveWise(item.id);
        }
        setLoad(false);
      })
    } else {
      history.push(`/dashboard/${savedInfo.name.toLowerCase()}/niveles-de-negocio`)
    }
  }, [])

  const onChangeWiseLevel = (values, setFieldValue) => {
    if (haveWise !== parseInt(id) && haveWise !== -1) {
      setShowMessage(true);
      setFieldValue("is_wise_level", values.is_wise_level)
    }
    else {
      setFieldValue("is_wise_level", !values.is_wise_level)
    }
  }

  const onSubmit = async (values) => {

    const filtered = Object.fromEntries(Object.entries(values).filter(([key, value]) => {
      if (key === 'id') {
        return values[key]
      }
      return values[key] !== initial[key]
    }))



    // filtered.update_custom_fields = filtered.update_custom_fields.map((item, i) => Object.fromEntries(Object.entries({ ...initial.update_custom_fields[i], ...item })
    //   .filter(([key, value]) => !Object.is(item[key], value)))
    // )

    // filtered.update_custom_fields = filtered.update_custom_fields.map((item, i) => {
    //   return Object.fromEntries(Object.entries(item)
    //     .flatMap(([key, value]) => {
    //       if (item[key] !== initial.update_custom_fields[i][key]) {
    //         return { id: item.id, ...item }
    //       } else {
    //         return []
    //       }
    //     }))
    // }
    // )


    if (values.description !== editInfo.description)
      filtered.should_update_description = true

    await dispatch(editBusinessLevel(filtered)).then(
      () => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      },
      () => setError(true)
    )
  }

  const validationSchema = yup.object({
    name: yup.string().trim().required(i18n('ERRORS').NAME_ERROR),
    functional_key_name: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    description: yup.string().trim().required(i18n('ERRORS').REQUIRED_FIELD),
    add_custom_fields: yup.array().of(yup.object().shape({
      name: yup.string().trim().required((i18n('ERRORS').REQUIRED_FIELD)),
      field_type: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
      enum_values: yup.array().when('field_type', {
        is: 6,
        then: yup.array().of(yup.string().trim()
          .min(1, i18n('ERRORS').PARAM_MIN).max(100, i18n('ERRORS').PARAM_MAX)).required(i18n('ERRORS').NEED_CONFIGURATION),
      })
    }))
  })

  if (businessLevels.isFetching || load) {
    return <Loader />
  }

  const initial = {
    id: id,
    name: editInfo.name || '',
    functional_key_name: editInfo.functional_key_name || '',
    description: editInfo.description || '',
    parent_id: editInfo.parent_id || null,
    has_location: false,
    has_image: false,
    is_active: location.state.isActive,
    is_wise_level: editInfo.is_wise_level,
    remove_custom_field_ids: [],
    update_custom_fields: [],
    add_custom_fields: [],
    is_payroll_level: editInfo.is_payroll_level
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').BUSINESS_LEVELS.LABEL, i18n('SIDEBAR_LINKS').EDIT_LEVEL.LABEL]}>
        <MessageBanner visible={success} status="success" message={i18n('SUCCESS').EDIT_LEVEL} />
        <MessageBanner visible={error} status="error" message={businessLevels.error} onAccept={() => setError(false)} />
        <MessageBanner visible={showMessage} onAccept={() => setShowMessage(false)} status="error" message={i18n('ERROR_HAVE_LEVEL_WISE')} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {businessLevels.isSubmitting && <Loader />}
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={initial}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit}>
              <DashboardContainer width="600px">
                <div style={{ marginBottom: "20px" }}>
                  <StyledSwitch id="editBusinessLevels_isActive" onChange={() => setFieldValue('is_active', !values.is_active)}
                    checked={values.is_active} name="is_active" label={i18n('ACTIVE')} horizontal="true" />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <DashboardInput label={i18n('NAME')} isRequired={true} error={errors.name} horizontal="true" border="true"
                    name="name" value={values.name} onChange={handleChange} width="350px" />
                </div>

                <div style={{ marginBottom: "15px", fontWeight: '700' }}>
                  <StyledSwitch id="editBusinessLevels_hasParentLevel" checked={values.parent_id !== null ? true : false}
                    label={i18n('HAS_PARENT_LEVEL')} horizontal="true" name="has_parent" disabled={true} style={{ fontWeight: '700' }} />
                </div>
                {values.parent_id !== null &&
                  <div style={{ marginBottom: "15px" }}>
                    <Select items={businessLevels.all} label={i18n('WHICH')} name="parent_id" selected={values.parent_id}
                      value={values.parent_id} horizontal="true" width="350px" border="true" disabled={true} style={{ fontWeight: '700' }} />
                  </div>
                }

                <div style={{ marginBottom: "20px" }}>
                  <StyledSwitch id="editBusinessLevels_IsWiseLevel" style={{ fontWeight: '700' }} onChange={() => onChangeWiseLevel(values, setFieldValue)}
                    checked={values.is_wise_level} name="is_wise_level" label={i18n('LEVEL_WISE')} horizontal="true" disabled={editInfo.is_payroll_level ? true : false} />
                </div>
                 <div style={{ marginBottom: "20px" }}>
                  <StyledSwitch id="is_payroll_level" label={i18n('ACTIVATE_PAYROLL')} horizontal="true"
                    name="is_payroll_level" onChange={() =>
                      setFieldValue('is_payroll_level', !values.is_payroll_level)
                    } checked={values.is_payroll_level} style={{ fontWeight: '700' }} disabled={values.parent_id ? true : false} />
                  <span>El nivel duplicara los usuarios como valores, solo se puede activar un nivel <br /> nómina por negocio. No tendrá nivel padre.</span>
                </div>
                <Divider style={{ width: 700 }} />
                <div style={{ fontWeight: "600", marginBottom: 15 }}>{i18n('FIELDS')}</div>
                <div style={{ marginBottom: "15px" }}>
                  <DashboardInput width="330px" isRequired={true} error={errors.functional_key_name} value={values.functional_key_name}
                    name="functional_key_name" label={i18n('FUNCTIONAL_KEY')} onChange={handleChange} horizontal="true" />
                </div>
                <DashboardInput width="330px" isRequired={true} error={errors.description} value={values.description}
                  name="description" label={i18n('FUNCTIONAL_KEY_IDENTIFIER')} onChange={handleChange} horizontal="true" />
              </DashboardContainer>
              <div style={{ width: 730, padding: '0 15px' }}>
                <EditFieldsButton data={businessLevels.fields} addStructure={'add_custom_fields'} updateStructure={'update_custom_fields'} removeStructure={'remove_custom_field_ids'} />
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default EditBusinessLevels
