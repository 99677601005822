import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchAccountLevelsStart,
  fetchAccountLevelsSuccess,
  fetchAccountLevelsError,
  submitAccountLevelStart,
  submitAccountLevelSuccess,
  submitAccountLevelError,
  editAccountLevelStart,
  editAccountLevelSuccess,
  editAccountLevelError,
  fetchAllAccountLevelsStart,
  fetchAllAccountLevelsSuccess,
  fetchAllAccountLevelsError,
  fetchAccountLevelsFieldsStart,
  fetchAccountLevelsFieldsSuccess,
  fetchAccountLevelsFieldsError, } = createActions({
    FETCH_ACCOUNT_LEVELS_START: () => { },
    FETCH_ACCOUNT_LEVELS_SUCCESS: values => ({ values }),
    FETCH_ACCOUNT_LEVELS_ERROR: error => ({ error }),
    SUBMIT_ACCOUNT_LEVEL_START: () => { },
    SUBMIT_ACCOUNT_LEVEL_SUCCESS: data => ({ data }),
    SUBMIT_ACCOUNT_LEVEL_ERROR: error => ({ error }),
    EDIT_ACCOUNT_LEVEL_START: () => { },
    EDIT_ACCOUNT_LEVEL_SUCCESS: data => ({ data }),
    EDIT_ACCOUNT_LEVEL_ERROR: error => ({ error }),
    FETCH_ALL_ACCOUNT_LEVELS_START: () => { },
    FETCH_ALL_ACCOUNT_LEVELS_SUCCESS: values => ({ values }),
    FETCH_ALL_ACCOUNT_LEVELS_ERROR: error => ({ error }),
    FETCH_ACCOUNT_LEVELS_FIELDS_START: () => { },
    FETCH_ACCOUNT_LEVELS_FIELDS_SUCCESS: values => ({ values }),
    FETCH_ACCOUNT_LEVELS_FIELDS_ERROR: error => ({ error }),
  })

const fetchAccountLevels = (page, accountId, active) => {
  return async dispatch => {

    dispatch(fetchAccountLevelsStart())

    const api = getApi(API_ROUTES)

    const status = active ? 'paginated' : 'inactive'

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/${status}/${page}/${10}/${accountId}`)
        .then((response) => {
          dispatch(fetchAccountLevelsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAccountLevelsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}
const fetchAllAccountLevels = (accountId, includeInactives) => {
  return async dispatch => {

    dispatch(fetchAllAccountLevelsStart())

    const api = getApi(API_ROUTES)

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/simple/account/${accountId}?include-inactives=${includeInactives}`)
        .then((response) => {
          dispatch(fetchAllAccountLevelsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAllAccountLevelsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchAccountLevelsFields = (levelId) => {
  return async dispatch => {

    dispatch(fetchAccountLevelsFieldsStart())

    const api = getApi(API_ROUTES)

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/fields/${levelId}`)
        .then((response) => {
          dispatch(fetchAccountLevelsFieldsSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAccountLevelsFieldsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const submitAccountLevel = accountLevel => {
  return async dispatch => {
    dispatch(submitAccountLevelStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(API_ROUTES.LEVELS, accountLevel)
        .then((response) => {
          dispatch(submitAccountLevelSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitAccountLevelError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editAccountLevel = (account) => {
  return async dispatch => {
    dispatch(editAccountLevelStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.LEVELS, account)
        .then(() => {
          dispatch(editAccountLevelSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editAccountLevelError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}


export {
  fetchAccountLevels,
  fetchAccountLevelsStart,
  fetchAccountLevelsSuccess,
  fetchAccountLevelsError,
  fetchAllAccountLevels,
  fetchAllAccountLevelsStart,
  fetchAllAccountLevelsSuccess,
  fetchAllAccountLevelsError,
  fetchAccountLevelsFields,
  fetchAccountLevelsFieldsStart,
  fetchAccountLevelsFieldsSuccess,
  fetchAccountLevelsFieldsError,
  submitAccountLevel,
  submitAccountLevelStart,
  submitAccountLevelSuccess,
  submitAccountLevelError,
  editAccountLevel,
  editAccountLevelStart,
  editAccountLevelSuccess,
  editAccountLevelError,
}
