import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Divider } from 'antd'
import { Formik, FieldArray, getIn } from 'formik'
import * as yup from 'yup'
import { editWiseConfiguration, fetchWiseConfiguration } from '../../../../actions/wiseConfiguration'
import { fetchAllBusiness } from '../../../../actions/business'
import { fetchAllBusinessLevels, fetchBusinessLevelsByParentId } from '../../../../actions/businessLevels'
import { fetchAssociatedRequests } from '../../../../actions/requestTypes'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, DashboardContainer, DashboardInput,
  Select, StyledSwitch, Loader, MessageBanner, NoData, IconButton, Collapse, Button, SmallIconButton
} from '@tops/components'
import { useRouteMatch } from 'react-router-dom'
import { theme } from '@tops/global_config'

const EditWiseConfiguration = ({ history, savedInfo }) => {
  const { id: configurationId } = useParams()

  const business = useSelector(state => state.business)
  const businessLevels = useSelector(state => state.businessLevels)
  const associatedRequests = useSelector(state => state.requestTypes.associatedRequests)
  const { item, isFetching, isSubmitting } = useSelector(state => state.wiseConfiguration)
  const wiseConfigurationError = useSelector(state => state.wiseConfiguration.error)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [businessLevelsSelectorsData, setBusinessLevelsSelectorsData] = useState([])
  const [validateError, setValidateError] = useState(false)

  const dispatch = useDispatch()
  const { url } = useRouteMatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchWiseConfiguration(configurationId)).catch(error => setError(`Wise - ${error}`))
        dispatch(fetchAllBusiness(savedInfo.id)).catch(error => setError(`Negocios - ${error}`))
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (item) {
      dispatch(fetchAllBusinessLevels(item.business_id, true, false))
      dispatch(fetchAssociatedRequests(item.business_id))

      let newBusinessLevelsSelectorsData = []
      item.entity_configuration_scheme.forEach((schema, schemaIndex) => {
        schema.forEach((level, levelIndex) => {
          dispatch(fetchBusinessLevelsByParentId(item.business_id, level))
            .then(({ data }) => {
              newBusinessLevelsSelectorsData[schemaIndex] = [...(newBusinessLevelsSelectorsData[schemaIndex] || []).slice(0, levelIndex), data]
            })
        })
      })
      setBusinessLevelsSelectorsData(newBusinessLevelsSelectorsData)
    }
  }, [item])

  const validationSchema = yup.object().shape({
    associatedRequests: yup.array().of(yup.number()).required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.ASSOCIATED_REQUESTS),
    associated_investigation_model_request_ids: yup.array().of(yup.number()).required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.ASSOCIATED_REQUESTS),
    associated_vct_request_ids: yup.array().of(yup.number()).required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.ASSOCIATED_REQUESTS),
  })

  const onSubmit = (values) => {
    const bodyRequest = {
      id: values.id,
      is_active: values.is_active,
      associated_request_ids: values.associatedRequests,
      associated_investigation_model_request_ids: values.associated_investigation_model_request_ids,
      associated_vct_request_ids: values.associated_vct_request_ids,
      update_risk_warning_configuration_concrete_dto: values.update_risk_warning_configuration_concrete_dto,
      update_accident_and_incident_configuration_concrete_dto: values.update_accident_and_incident_configuration_concrete_dto,
      update_audit_configuration_concrete_dto: values.update_audit_configuration_concrete_dto,
      update_post_vct_configuration_concrete_dto: values.update_post_vct_configuration_concrete_dto,
      update_progressive_discipline_configuration_concrete_dto: values.update_progressive_discipline_configuration_concrete_dto,
      update_positive_agreement_configuration_concrete_dto: values.update_positive_agreement_configuration_concrete_dto,
    }

    dispatch(editWiseConfiguration(bodyRequest))
      .then(() => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const activeSwitch = (setFieldValue, values) => (
    <div style={{ marginBottom: "30px" }}>
      <StyledSwitch id="editConfigWise_isActive" onChange={() => setFieldValue('is_active', !values.is_active)} checked={values.is_active}
        name="is_active" label={i18n('ACTIVE')} horizontal="true" />
    </div>
  )

  const businessSelect = (setFieldValue, values, errors) => (
    <Select name="business" label={i18n('WISE').CREATE_CONFIGURATION.BUSINESS} horizontal="true" width="350px" border="true" isRequired
      disabled items={business.values} value={values.business} error={errors.business} onChange={(value) => {
        dispatch(fetchAllBusinessLevels(value, true, false))
        dispatch(fetchAssociatedRequests(value))
        setFieldValue("business", value)
      }
      } />
  )

  const nameInput = (handleChange, values, errors) => (
    <>
      <Divider />
      <div style={{ marginBottom: "24px" }}>
        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.ADMINISTRATOR)}</span>
      </div>
      <DashboardInput label={i18n('WISE').CREATE_CONFIGURATION.NAME} horizontal="true" border="true" width="350px" name="name" isRequired
        disabled value={values.name} error={errors.name} onChange={handleChange} />
    </>
  )

  const emailInput = (handleChange, values, errors) => (
    <DashboardInput label={i18n('WISE').CREATE_CONFIGURATION.EMAIL} horizontal="true" border="true" width="350px" name="email" isRequired
      disabled value={values.email} error={errors.email} onChange={handleChange} />
  )

  const grouperSelect = (setFieldValue, values, errors) => (
    <>
      <Divider />
      <div style={{ marginBottom: "24px" }}>
        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.MY_TEAM)}</span>
      </div>
      <Select name="grouper" label={i18n('WISE').CREATE_CONFIGURATION.GROUPER} horizontal="true" width="350px" border="true" isRequired
        disabled items={grouperSchema} value={values.grouper} error={errors.grouper} onChange={(v) => setFieldValue("grouper", v)} />
    </>
  )

  const levelsSchemaSelects = (setFieldValue, values, errors) => (<>
    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
      <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.LEVELS_SCHEMA)}</span>
    </div>
    <FieldArray name={`levelsSchema`}>
      {
        arrayHelpers => (
          <div>
            {
              values.levelsSchema.map((schema, schemaIndex) => (
                <div key={schemaIndex} style={{ marginBottom: "16px" }}>
                  <div style={{ marginBottom: "16px" }}>
                    <span style={{ fontWeight: 600 }}>{`${capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.SCHEMA)} ${schemaIndex + 1}`}</span>
                  </div>
                  {
                    Array.apply(null, { length: 7 }).map((_, levelIndex) => (
                      <Select key={levelIndex} name={`levelsSchema[${schemaIndex}][${levelIndex}]`} label={levelIndex === 0 ? i18n('WISE').CREATE_CONFIGURATION.SITE : `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} ${levelIndex}`}
                        horizontal="true" width="350px" border="true" isRequired={levelIndex < values.grouper + 1} disabled
                        items={levelIndex === 0 ? businessLevels.all : (businessLevelsSelectorsData[schemaIndex] && businessLevelsSelectorsData[schemaIndex][levelIndex - 1] || [])} value={schema[levelIndex]}
                        error={getIn(errors, `levelsSchema[${schemaIndex}][${levelIndex}]`)} onChange={(value) => {
                          const newValue = [
                            ...(values.levelsSchema[schemaIndex] || []).slice(0, levelIndex),
                            value
                          ]
                          setFieldValue(`levelsSchema[${schemaIndex}]`, newValue)
                          dispatch(fetchBusinessLevelsByParentId(values.business, value))
                            .then(({ data }) => {
                              let newBusinessLevelsSelectorsData = [...businessLevelsSelectorsData]
                              newBusinessLevelsSelectorsData[schemaIndex] = [...(newBusinessLevelsSelectorsData[schemaIndex] || []).slice(0, levelIndex), data]
                              setBusinessLevelsSelectorsData(newBusinessLevelsSelectorsData)
                            })
                        }} />
                    ))
                  }
                </div>
              ))
            }
          </div>
        )}
    </FieldArray>
  </>
  )

  /*   const associatedRequestsSelect = (setFieldValue, values, errors) => (
      <>
        <Divider />
        <div style={{ marginBottom: "24px" }}>
          <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.RISK_NOTICE)}</span>
        </div>
        <Select name="associatedRequests" label={i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} horizontal="true" width="350px" border="true" mode="multiple" isRequired
          items={associatedRequests} value={values.associatedRequests} error={errors.associatedRequests} onChange={(v) => setFieldValue("associatedRequests", v)} />
      </>
    )
  
  
    const associatedRequestsInvestigationModelSelect = (setFieldValue, values, errors) => (
      <Select name="associated_investigation_model_request_ids" label={i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} horizontal="true" width="350px" border="true" mode="multiple" isRequired
        items={associatedRequests} value={values.associated_investigation_model_request_ids} error={errors.associated_investigation_model_request_ids} onChange={(v) => setFieldValue("associated_investigation_model_request_ids", v)} />
    ) */

  const itemsToMails = [ /*No se usa grouperSchema porque se necesitan los ID's +1*/
    { id: 1, name: "Sitio" },
    { id: 2, name: "Sublocación 1" },
    { id: 3, name: "Sublocación 2" },
    { id: 4, name: "Sublocación 3" },
    { id: 5, name: "Sublocación 4" },
    { id: 6, name: "Sublocación 5" },
    { id: 7, name: "Sublocación 6" },
  ]

  const associatedRequestsSelect = (setFieldValue, values, errors) => (
    <div style={{ width: "55vw", height: "auto", border: "1px solid #b8c1ca", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold style={{ fontWeight: "bold" }} size={25}
          panels={[{
            title: "Aviso de Riesgo", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Aviso de Riesgo"} error={false} onChange={{}} disabled={true} />
                  <div style={{ marginTop: 20 }}>
                    <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>{i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} <div style={{ color: "red" }}>*</div></div>
                    <Select name="associatedRequests" horizontal="true" width="350px" border="true" mode="multiple" isRequired
                      items={associatedRequests} value={values.associatedRequests} error={errors.associatedRequests} onChange={(v) => setFieldValue("associatedRequests", v)} />
                  </div>
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="update_risk_warning_configuration_concrete_dto.type_a_email_grouping_sublocation" horizontal="true" width="350px" border="true" disabled={false} label={"A"}
                    items={itemsToMails} value={values.update_risk_warning_configuration_concrete_dto.type_a_email_grouping_sublocation} error={false}
                    onChange={(val) => setFieldValue("update_risk_warning_configuration_concrete_dto.type_a_email_grouping_sublocation", val)} />
                  <Select name="update_risk_warning_configuration_concrete_dto.type_b_email_grouping_sublocation" horizontal="true" width="350px" border="true" disabled={false} label={"B"}
                    items={itemsToMails} value={values.update_risk_warning_configuration_concrete_dto.type_b_email_grouping_sublocation} error={false}
                    onChange={(val) => setFieldValue("update_risk_warning_configuration_concrete_dto.type_b_email_grouping_sublocation", val)} />
                  <Select name="update_risk_warning_configuration_concrete_dto.type_c_email_grouping_sublocation" horizontal="true" width="350px" border="true" disabled={false} label={"C"}
                    items={itemsToMails} value={values.update_risk_warning_configuration_concrete_dto.type_c_email_grouping_sublocation} error={false}
                    onChange={(val) => setFieldValue("update_risk_warning_configuration_concrete_dto.type_c_email_grouping_sublocation", val)} />
                </div>
                <div style={{ width: "45%" }}>
                  <StyledSwitch label={"Foto obligatoria"} horizontal="true" checked={values.update_risk_warning_configuration_concrete_dto.obligatory_picture} style={{ fontWeight: "bold" }}
                    onChange={(e) => setFieldValue("update_risk_warning_configuration_concrete_dto.obligatory_picture", e.target.checked)} />
                </div>
              </div>
            </>
          }]} />
      </div>
    </div>
  )

  const associatedRequestsInvestigationModelSelect = (setFieldValue, values, errors) => (
    <div style={{ width: "55vw", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Accidente e Incidente", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Accidente e Incidente"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="update_accident_and_incident_configuration_concrete_dto.email_grouping_sublocation" horizontal="true" width="350px" border="true"
                    items={itemsToMails} value={values.update_accident_and_incident_configuration_concrete_dto.email_grouping_sublocation} error={false}
                    onChange={(val) => setFieldValue("update_accident_and_incident_configuration_concrete_dto.email_grouping_sublocation", val)} />
                </div>
                <div style={{ width: "50%" }}>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>{i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} <div style={{ color: "red" }}>*</div></div>
                  <Select name="associated_investigation_model_request_ids" horizontal="true" width="350px" border="true" mode="multiple" isRequired
                    items={associatedRequests} value={values.associated_investigation_model_request_ids} error={errors.associated_investigation_model_request_ids} onChange={(v) => setFieldValue("associated_investigation_model_request_ids", v)} />
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <div style={{ width: "65%" }}>
                      <StyledSwitch label={"Clasificación de Servicio Médico Wise"} horizontal="true" checked={values.update_accident_and_incident_configuration_concrete_dto.medical_service_danone_classification} style={{ fontWeight: "bold" }}
                        onChange={(e) => setFieldValue("update_accident_and_incident_configuration_concrete_dto.medical_service_danone_classification", e.target.checked)} />
                    </div>
                    <div style={{ width: "45%" }}>
                      <StyledSwitch label={"Campo 12 Básicos"} horizontal="true" checked={values.update_accident_and_incident_configuration_concrete_dto.should_display_twelve_basic} style={{ fontWeight: "bold" }}
                        onChange={(e) => setFieldValue("update_accident_and_incident_configuration_concrete_dto.should_display_twelve_basic", e.target.checked)} />
                    </div>
                    <div>
                      <Button square small background={theme.colors.white} color={theme.colors.darkGray} style={{ width: 350, border: "1px solid #e7e8ea" }} type="buttpn"
                      onClick={() => history.push(`${url}/configuración-avanzada-de-accidente`, { businessId: item.business_id })} >
                        <SmallIconButton type="button" icon={'Configuration'} bold="true" size={34} color={"#49494E"} background={"transparent"} onClick={() => {}} /> Configuración Avanzada
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }]} />
      </div>
    </div>
  )

  const associatedProgressiveDiscipline = (setFieldValue, values, errors) => (
    <div style={{ width: "55vw", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Disciplina Progresiva", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Disciplina Progresiva"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Tipo de desvío</div>
                  <Select name="tipo_de_desvio" horizontal="true" width="350px" border="true" mode="multiple" isRequired disabled
                    items={[]} value={values.tipo_de_desvio} error={errors.tipo_de_desvio} onChange={(v) => setFieldValue("tipo_de_desvio", v)} />
                </div>
              </div>
            </>
          }]} />
      </div>
      <div style={{ borderTop: "1px solid #B8C1CA" }}>
        <div style={{ padding: "5px 10px 5px 10px" }}>
          <div style={{ width: "21%" }}>
            <StyledSwitch id={1} label={"Habilitada"} horizontal="true" checked={values.update_progressive_discipline_configuration_concrete_dto.is_active} name="update_progressive_discipline_configuration_concrete_dto.is_active" style={{ fontWeight: "bold" }}
              onChange={(e) => setFieldValue("update_progressive_discipline_configuration_concrete_dto.is_active", e.target.checked)} />
          </div>
        </div>
      </div>
    </div>
  )

  const associatedAudits = (setFieldValue, values, errors) => (
    <div style={{ width: "55vw", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Auditorías", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Auditorías"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="update_audit_configuration_concrete_dto.email_grouping_sublocation" horizontal="true" width="350px" border="true"
                    items={itemsToMails} value={values.update_audit_configuration_concrete_dto.email_grouping_sublocation} error={false}
                    onChange={(val) => setFieldValue("update_audit_configuration_concrete_dto.email_grouping_sublocation", val)} />
                </div>
              </div>
            </>
          }]} />
      </div>
    </div>
  )

  const associatedVct = (setFieldValue, values, errors) => (
    <>
      <div style={{ width: "55vw", height: "auto", border: "1px solid #b8c1ca", borderRadius: 5, marginTop: 10 }}>
        <div style={{ padding: 10 }}>
          <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25} panels={[{
            title: "VCT", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"VCT"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="update_post_vct_configuration_concrete_dto.vct_email_grouping_sublocation" horizontal="true" width="350px" border="true"
                    items={itemsToMails} value={values.update_post_vct_configuration_concrete_dto.vct_email_grouping_sublocation} error={false}
                    onChange={(val) => setFieldValue("update_post_vct_configuration_concrete_dto.vct_email_grouping_sublocation", val)} />
                </div>
              </div>
              <div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Solicitudes Asociadas <div style={{ color: "red" }}>*</div></div>
                  <Select name="associated_vct_request_ids" horizontal="true" width="350px" border="true" placeholder="Seleccionar Solicitudes" mode="multiple"
                    items={associatedRequests} value={values.associated_vct_request_ids} error={errors.associated_vct_request_ids} onChange={(v) => setFieldValue("associated_vct_request_ids", v)} />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: "#49494E", fontWeight: "bold" }}>Configurar Estandar</div>
                  <IconButton type="button" icon={'Configuration'} bold="true" size={34}
                    color={"#49494E"} background={"transparent"}
                    onClick={() => history.push(`${url}/configuracion-estandar`, { businessId: item.business_id })} />
                </div>
              </div>
            </>
          }]} />
        </div>
        <div style={{ borderTop: "1px solid #B8C1CA" }}>
          <div style={{ padding: "5px 10px 5px 10px" }}>
            <div style={{ width: "21%" }}>
              <StyledSwitch id={2} label={"Habilitada"} horizontal="true" checked={values.update_post_vct_configuration_concrete_dto.is_active} name="update_post_vct_configuration_concrete_dto.is_active" style={{ fontWeight: "bold" }}
                onChange={(e) => setFieldValue("update_post_vct_configuration_concrete_dto.is_active", e.target.checked)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const associatedPositiveAgreement = (setFieldValue, values, errors) => (
    <div style={{ width: "55vw", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Acuerdo Positivo", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Acuerdo Positivo"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="test.test" horizontal="true" width="350px" border="true" disabled={true}
                    items={[]} value={values.test} error={false} /**Queda para futuro */
                    onChange={(val) => setFieldValue("test", val)} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>{i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} <div style={{ color: "red" }}>*</div></div>
                  <Select name="associated_investigation_model_request_ids" horizontal="true" width="350px" border="true" mode="multiple" isRequired
                    items={associatedRequests} value={values.associated_investigation_model_request_ids} error={errors.associated_investigation_model_request_ids} onChange={(v) => setFieldValue("associated_investigation_model_request_ids", v)} />
                </div>
              </div>
            </>
          }]} />
      </div>
      <div style={{ borderTop: "1px solid #B8C1CA" }}>
        <div style={{ padding: "5px 10px 5px 10px" }}>
          <div style={{ width: "21%" }}>
            <StyledSwitch id={4} label={"Habilitada"} horizontal="true" checked={values.update_positive_agreement_configuration_concrete_dto.is_active} name="update_positive_agreement_configuration_concrete_dto.is_active" style={{ fontWeight: "bold" }}
              onChange={(e) => setFieldValue("update_positive_agreement_configuration_concrete_dto.is_active", e.target.checked)} />
          </div>
        </div>
      </div>
    </div>
  )


  const grouperSchema = [
    { id: 0, name: i18n('WISE').CREATE_CONFIGURATION.SITE },
    { id: 1, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 1` },
    { id: 2, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 2` },
    { id: 3, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 3` },
    { id: 4, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 4` },
    { id: 5, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 5` },
    { id: 6, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 6` }
  ]

  const initialValues = {
    id: item?.id || '', // number
    is_active: item?.is_active || false, // boolean
    email: item?.user?.email || '', // string
    name: item?.user?.name || '', // string
    associatedRequests: item?.associated_request_ids || [], // array of numbers
    associated_investigation_model_request_ids: item?.associated_investigation_model_request_ids || [], // array of numbers
    business: item?.business_id || undefined, // number
    grouper: item?.count_minimum_level - 1, // number
    levelsSchema: item?.entity_configuration_scheme || [[]], // array of array of numbers
    associated_vct_request_ids: item?.associated_vct_request_ids || [],
    update_risk_warning_configuration_concrete_dto: {
      id: item?.risk_warning_configuration_dto?.id,
      is_active: item?.risk_warning_configuration_dto?.is_active,
      type_a_email_grouping_sublocation: item?.risk_warning_configuration_dto?.type_a_email_grouping_sublocation,
      type_b_email_grouping_sublocation: item?.risk_warning_configuration_dto?.type_b_email_grouping_sublocation,
      type_c_email_grouping_sublocation: item?.risk_warning_configuration_dto?.type_c_email_grouping_sublocation,
      obligatory_picture: item?.risk_warning_configuration_dto?.obligatory_picture,
    },
    update_accident_and_incident_configuration_concrete_dto: {
      id: item?.accident_and_incident_configuration_dto?.id,
      is_active: item?.accident_and_incident_configuration_dto?.is_active,
      email_grouping_sublocation: item?.accident_and_incident_configuration_dto?.email_grouping_sublocation,
      should_display_twelve_basic: item?.accident_and_incident_configuration_dto?.should_display_twelve_basic,
      medical_service_danone_classification: item?.accident_and_incident_configuration_dto?.medical_service_danone_classification,
    },
    update_progressive_discipline_configuration_concrete_dto: {
      is_active: item?.progressive_discipline_configuration_dto?.is_active,
      id: item?.progressive_discipline_configuration_dto?.id,
    },
    update_audit_configuration_concrete_dto: {
      id: item?.wise_survey_configuration_dto?.id,
      is_active: item?.wise_survey_configuration_dto?.is_active,
      email_grouping_sublocation: item?.wise_survey_configuration_dto?.email_grouping_sublocation,
    },
    update_post_vct_configuration_concrete_dto: {
      vct_email_grouping_sublocation: item?.vct_configuration_dto?.email_grouping_sublocation,
      id: item?.vct_configuration_dto?.id,
      is_active: item?.vct_configuration_dto?.is_active,
    },
    update_positive_agreement_configuration_concrete_dto: {
      is_active: item?.positive_agreement_configuration_dto?.is_active,
      id: item?.positive_agreement_configuration_dto?.id,
    }
  }

  if (business.isFetching || businessLevels.isFetching || associatedRequests.isFetching || isFetching) {
    return <Loader />
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').WISE.LABEL, i18n('SIDEBAR_LINKS').WISE_EDIT_CONFIGURATION.LABEL]} >
        <MessageBanner visible={success} status="success" message={i18n('WISE').CREATE_CONFIGURATION.SUCCESS.EDIT_WISE_CONFIGURATION} />
        <MessageBanner visible={error} status="error" message={wiseConfigurationError} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {isSubmitting && <Loader />}
      <Formik initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="600px">
                {activeSwitch(setFieldValue, values)}
                {businessSelect(setFieldValue, values, errors)}
                {nameInput(handleChange, values, errors)}
                {emailInput(handleChange, values, errors)}
                {grouperSelect(setFieldValue, values, errors)}
                {levelsSchemaSelects(setFieldValue, values, errors)}

                {associatedRequestsSelect(setFieldValue, values, errors)}
                {associatedRequestsInvestigationModelSelect(setFieldValue, values, errors)}
                {associatedProgressiveDiscipline(setFieldValue, values, errors)}
                {associatedAudits(setFieldValue, values, errors)}
                {associatedVct(setFieldValue, values, errors)}
                {associatedPositiveAgreement(setFieldValue, values, errors)}
              </DashboardContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default EditWiseConfiguration
