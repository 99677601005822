import styled, { keyframes } from 'styled-components'
import { Calendar } from "react-multi-date-picker"

export const Container = styled.div`

`

// export const DatePickerContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `

export const StyledCalendar = styled(Calendar)`
  box-shadow: none;
  padding: 0;
  border: 1px solid #f0f0f0;

  .rmdp-week-day {
    font-size: 10px;
    font-weight: 600;
    color: #3c6374;
  }

  .rmdp-day span {
    font-size: 11px;
    font-weight: normal;
    border-radius: 5px;
  }

  .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: transparent;
    color: inherit;
  }

  .rmdp-day, .rmdp-week-day {
    height: 25px;
  }

  .rmdp-arrow-container:hover {
    background-color: transparent;
    box-shadow: none;
  }

  .rmdp-day.rmdp-deactive, .rmdp-day.rmdp-disabled {
    color: #e5e5e5;
  }

  .rmdp-range, .rmdp-day.rmdp-selected span:not(.highlight) {
    box-shadow: none;
    background-color: ${({ colorTheme }) => colorTheme.primary};
  }

  .rmdp-range-hover.end, .rmdp-range.end {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  .rmdp-range-hover.start, .rmdp-range.start {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .rmdp-arrow,  .rmdp-arrow-container:hover .rmdp-arrow {
    border-color: ${({ colorTheme }) => colorTheme.primary};
  }

  .rmdp-header {
    font-size: 13px;
    margin: 0;
    padding: 5px 0;
  }
`