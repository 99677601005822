import React, { useState, useEffect } from 'react';
import { Container, Panel, Header, Title, Rotator, Content, Body } from './styled';
import { Icon } from '@tops/components';

export const Collapse = ({ panels, defaultOpenedPanels, singlePanel, color, size, bold, style, accidentAndIncidentDetailPdf }) => {
  const [opened, setOpened] = useState({});

  useEffect(() => {
    let initialState = {};
    panels.forEach((_, index) => {
      initialState[index] = !!defaultOpenedPanels.includes(index)
    })
    setOpened(initialState)
  }, [])

  const handleTogglePanel = (id) => {
    if (opened[id]) {
      setOpened(prev => ({ ...(!singlePanel && prev), [id]: false }))
    } else {
      setOpened(prev => ({ ...(!singlePanel && prev), [id]: true }))
    }
  }

  return (
    <Container style={style}>
      {panels.map((panel, index) => (
        <Panel key={index}>
          <Header onClick={() => handleTogglePanel(index)}>
            <Title style={{fontSize: accidentAndIncidentDetailPdf && '18px'}} bold={bold}>{panel.title}</Title>
            { accidentAndIncidentDetailPdf === false && <Rotator open={accidentAndIncidentDetailPdf ? true : opened[index]}>
              <Icon name={"ArrowDown"} color={color} size={size} />
            </Rotator>}
          </Header>
          <Content open={accidentAndIncidentDetailPdf ? true : opened[index]}><Body open={accidentAndIncidentDetailPdf ? true : opened[index]}>{panel.content}</Body></Content>
        </Panel>
      ))}
    </Container>
  )
}
