import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const {
    submitAccidentAndIncidentConfigurationStart,
    submitAccidentAndIncidentConfigurationSuccess,
    submitAccidentAndIncidentConfigurationError,
    fetchAccidentAndIncidentConfigurationStart,
    fetchAccidentAndIncidentConfigurationSuccess,
    fetchAccidentAndIncidentConfigurationError,
} = createActions({
    SUBMIT_ACCIDENT_AND_INCIDENT_CONFIGURATION_START: () => { },
    SUBMIT_ACCIDENT_AND_INCIDENT_CONFIGURATION_SUCCESS: data => ({ data }),
    SUBMIT_ACCIDENT_AND_INCIDENT_CONFIGURATION_ERROR: error => ({ error }),
    FETCH_ACCIDENT_AND_INCIDENT_CONFIGURATION_START: () => { },
    FETCH_ACCIDENT_AND_INCIDENT_CONFIGURATION_SUCCESS: values => ({ values }),
    FETCH_ACCIDENT_AND_INCIDENT_CONFIGURATION_ERROR: error => ({ error }),
})

const submitAccidentAndIncidentConfiguration = (values) => {
    return async dispatch => {
      dispatch(submitAccidentAndIncidentConfigurationStart())
      const api = getApi(API_ROUTES)
      return new Promise((resolve, reject) => {
        api.post(`${API_ROUTES.ACCIDENT_VERSION}/accident-version`, values)
          .then((response) => {
            dispatch(submitAccidentAndIncidentConfigurationSuccess(response.data))
            resolve()
          }).catch((error) => {
            dispatch(submitAccidentAndIncidentConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
            reject()
          })
      }
      )
    }
}

const fetchAccidentAndIncidentConfiguration = (businessId) => {
    return async dispatch => {
      dispatch(fetchAccidentAndIncidentConfigurationStart())
      const api = getApi(API_ROUTES)
      return new Promise((resolve, reject) => {
        api.get(`${API_ROUTES.ACCIDENT_VERSION}/version-detail/${businessId}`)
          .then((response) => {
            dispatch(fetchAccidentAndIncidentConfigurationSuccess(response.data))
            resolve(response)
          }).catch((error) => {
            dispatch(fetchAccidentAndIncidentConfigurationError(error.response !== undefined ? error.response.data.business_errors : error.message))
            reject(error)
          })
      })
    }
}

export {
    submitAccidentAndIncidentConfiguration,
    submitAccidentAndIncidentConfigurationStart,
    submitAccidentAndIncidentConfigurationSuccess,
    submitAccidentAndIncidentConfigurationError,
    fetchAccidentAndIncidentConfiguration,
    fetchAccidentAndIncidentConfigurationStart,
    fetchAccidentAndIncidentConfigurationSuccess,
    fetchAccidentAndIncidentConfigurationError,
}