import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, Input, StyledCheckbox, SmallIconButton, Loader, MessageBanner, StyledSwitch, Popup, RadioSelect, Select
} from '@tops/components'
import { theme } from '@tops/global_config'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FieldArray, getIn, Form, validateYupSchema, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import { submitAccidentAndIncidentConfiguration, fetchAccidentAndIncidentConfiguration } from '../../../../actions/accidentAndIncident'
import { TitleHead, TableData } from './styled';


const AdvancedAccidentSettings = ({ history, savedInfo, location }) => {

    const [error, setError] = useState(false)
    const [load, setLoad] = useState(true)
    const [validateError, setValidateError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [seePopupDropdowns, setSeePopupDropdowns] = useState(false)

    const businessId = location.state.businessId
    const dispatch = useDispatch()
    const accIncDetail = useSelector(state => state?.accidentAndIncident)
    const haveDetail = accIncDetail.values.length === 0 ? false : true
    console.log("Valores del detalle: ", accIncDetail.values) //WIP
    console.log("Tiene detalle: ", haveDetail)

    useEffect(() => {
        const fetchData = async () => {
            try {
            dispatch(fetchAccidentAndIncidentConfiguration(businessId)).then(() => setLoad(false)).catch((error) => {
                setError(error)
                setLoad(false)
            })
            } catch (error) {
                setError(error)
                setLoad(false)
            }
        }
        fetchData()
    }, [])

    const onSubmit = async (values) => {
    setLoad(true)
    dispatch(submitAccidentAndIncidentConfiguration(values)).then(
        () => {
        setLoad(false)
        setSuccess(true)
        setTimeout(() => setLoad(true), 1000)
        setTimeout(() => {
            history.goBack()
        }, 1000)
        },
        () => {
        setLoad(false)
        setError(true)
        }
    )
    }

    const validationSchema = yup.object().shape({
        concrete_add_custom_accident_types: yup.array().of(yup.object().shape({
            custom_name: yup.string().trim().required(i18n('ERRORS').NAME_ERROR),
            risk_matrix_cutoff: yup.number().transform(v => isNaN(v) ? null : v).nullable().when('requires_second_step_b', {
                is: true,
                then: yup.number().transform(v => isNaN(v) ? null : v).nullable().test('second_step_a_options', i18n('ERRORS').REQUIRED_FIELD,
                function (val) {
                    return val !== null || (val === null && this.options.context.second_step_a_options === 0);
                }),
            }),
            drop_down_cutoff: yup.mixed().when('requires_second_step_b', {
                is: true,
                then: yup.mixed().test('second_step_a_options', i18n('ERRORS').REQUIRED_FIELD,
                function (val) {
                    return val.length > 0 || (val.length === 0 && this.options.context.second_step_a_options === 1);
                }),
            })
        }))
    })

    const initialValues = {
        business_id: businessId, // -> Id de negocio
        has_potential: haveDetail ? accIncDetail?.values?.has_potential : true, // -> Acc tiene potencial
        second_step_a_options: haveDetail ? accIncDetail?.values?.second_step_a_options : 1, // -> Enum de segundo paso A. 0 = dropdown (listado), 1 = riskmatrix
        has_investigation_model: haveDetail ? accIncDetail?.values?.has_investigation_model : true, // -> tiene modelo de investigacion en 2do paso
        has_five_reasons: haveDetail ? accIncDetail?.values?.has_five_reasons : true, // -> tiene 5 porque en 2do paso
        concrete_add_custom_accident_types: haveDetail ? accIncDetail?.values?.custom_accident_type_dtos : [ // -> objeto tipo de accidente custom
            {
                custom_name: "", // -> Nombre de tipo de accidente
                related_to_person: true, // -> Es relacionado con persona
                automatically_ends_accident: true, // -> Finalizacion inmediata
                prompts_medical_investigation: true, // -> Tiene servicio medico
                requires_second_step_b: true, // -> Necesita segundo paso
                risk_matrix_cutoff: null, // -> puntaje de corte de matriz que genera segundo paso
                drop_down_cutoff: [ ], // ->selector de opciones de dropdown (listado) que genera segundo paso
                should_send_mails: true // -> deberia mandar mails
            }
        ],
        concrete_add_custom_dropdown_dto: haveDetail ? accIncDetail?.values?.dropdown_dtos : [  ] // {  custom_name: ""} -> Nombre de opciones de dropdown (listado)
    }

    const generalConfiguration = (setFieldValue, values, errors) => {
        return(
            <div style={{marginBottom: 15}}>
                <span style={{fontWeight: "bold"}}>General</span>
                <div>
                    <StyledSwitch id="hasPotential" onChange={() => setFieldValue('has_potential', !values.has_potential)} checked={values.has_potential}
                    label="Alto potencial" horizontal="true" style={{ fontWeight: 'bold', width: 200 }} />
                </div>
            </div>
        )
    }

    const phaseTwoOptions = [
        {value: 1, label: "Matriz de riesgo"},
        {value: 0, label: "Desplegable"}
    ]

    const dropdownsOptionsPopup = (setFieldValue, dropValues, tableDatas) => {

        const dropInitialValues = {concrete_add_custom_dropdown_dto: dropValues}

        const dropValidationSchema =  yup.object().shape({
            concrete_add_custom_dropdown_dto: yup.array().of(yup.object().shape({
                custom_name: yup.string().trim().required(i18n('ERRORS').NAME_ERROR),
            }))
        })

        return <Popup colorTheme={theme.colors.user.wise.primary} title={"Paso 2a Opciones de Desplegable"} scrolleable onClose={() => setSeePopupDropdowns(false)}>
                <Formik initialValues={dropInitialValues} validationSchema={dropValidationSchema} enableReinitialize={true}  validateOnChange={false} onSubmit={(values) => {
                    setSeePopupDropdowns(false)
                    setFieldValue("concrete_add_custom_dropdown_dto", values.concrete_add_custom_dropdown_dto)
                }}>
                    {({ values, errors, handleChange, handleSubmit }) => (<>
                    <div style={{ width: "auto", height: "auto", display: "flex", justifyContent: "center"}}>
                        <FieldArray name="concrete_add_custom_dropdown_dto">
                            {(arrayHelper) => {
                                return <div style={{ }}>
                                    {values.concrete_add_custom_dropdown_dto.map((item, i) => {
                                        return <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 15}}>
                                            <Input name={`concrete_add_custom_dropdown_dto[${i}].custom_name`} width="350px" placeholder={`Opción ${i+1}`} disabled={false}
                                            value={item.custom_name} error={getIn(errors, `concrete_add_custom_dropdown_dto[${i}].custom_name`)}
                                            onChange={handleChange} horizontal="true" style={{ marginBottom: 0 }}/>
                                            {i > 0 &&
                                            <SmallIconButton icon={"TrashBin"} size={30} iconColor={theme.colors.darkGray} background="transparent"
                                            onClick={() => {arrayHelper.remove(i)}}/> }
                                        </div>
                                    })}
                                    <div style={{ marginTop: 15, width: 135 }}>
                                    <SmallIconButton bold text="Agregar opción" size={25} background="#b9a5fd" icon={'Add'} iconColor={theme.colors.white} textSize={14} type="button"
                                    onClick={() => {arrayHelper.push({custom_name: ""})}} />
                                    </div>
                                </div>
                            }}
                        </FieldArray>
                    </div>
                    <div style={{display: "flex", justifyContent: "center", marginTop: 20}}>
                        <CancelButton small type="button" onClick={() => setSeePopupDropdowns(false)}>{i18n('CANCEL')}</CancelButton>
                        <ConfirmationButton type="submit" small="true" background={"#dfd6ff"} color={"#6247c0"}
                        onClick={handleSubmit}>
                            {i18n("SAVE")}
                        </ConfirmationButton>
                    </div>
                    </>)}
                </Formik>
        </Popup>
    }

    const phaseTwoA = (setFieldValue, values, errors) => {
        return(
            <div style={{marginBottom: 15}}>
                <span style={{fontWeight: "bold"}}>Paso 2a</span>
                <div style={{ display: "flex"}}>
                    <RadioSelect name="second_step_a_options" options={phaseTwoOptions} value={values.second_step_a_options}
                     onChange={(e) => {
                        setFieldValue("second_step_a_options", e.target.value)
                        setFieldValue("concrete_add_custom_dropdown_dto", [])
                        values.concrete_add_custom_accident_types.map((item, i) => {
                            setFieldValue(`concrete_add_custom_accident_types[${i}].drop_down_cutoff`, [])
                            setFieldValue(`concrete_add_custom_accident_types[${i}].risk_matrix_cutoff`, null)
                        })
                    }}/>
                    {values.second_step_a_options === 0 &&
                    <div style={{ display: "flex", alignItems: "center", cursor: "pointer", width: 120, marginTop: 25 }} onClick={() => setSeePopupDropdowns(true)} >
                        <SmallIconButton type="button" icon={'Configuration'} bold="true" size={34} color={"#49494E"} background={"transparent"} onClick={() => setSeePopupDropdowns(true)} />
                        <div style={{ color: "#49494E", fontWeight: "bold" }}>Configurar</div>
                    </div>}
                </div>
            </div>
        )
    }

    const phaseTwoB = (setFieldValue, values, errors) => {
        return(
            <div style={{marginBottom: 15}}>
                 <span style={{fontWeight: "bold"}}>Paso 2b</span>
                 <div style={{display: "flex", alignItems: "center"}}>
                    <StyledCheckbox color={theme.colors.user.wise} checked={values.has_investigation_model} onChange={() => {setFieldValue("has_investigation_model", !values.has_investigation_model)}}/> <span>TASC</span>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <StyledCheckbox color={theme.colors.user.wise} checked={values.has_five_reasons} onChange={() => {setFieldValue("has_five_reasons", !values.has_five_reasons)}}/> <span>5 Porqué</span>
                </div>
            </div>
        )
    }

    const customDropdown = (value) => value.map((item, i) => ({...item, id: i, name: item.custom_name}))

    const newOptionTableData =  {custom_name: "", related_to_person: true, automatically_ends_accident: true, prompts_medical_investigation: true, requires_second_step_b: true, risk_matrix_cutoff: null, drop_down_cutoff: [ ], should_send_mails: true}

    const accidentsFlow = (setFieldValue, values, errors, handleChange) => {
        return(
            <div style={{marginBottom: 15}}>
                <FieldArray name="concrete_add_custom_accident_types">
                    {(dataTableArrayHelper) => (<>
                        <span style={{fontWeight: "bold"}}>Flujo de Accidentes<span style={{color: "red"}}>*</span></span>
                        <div>
                            <table style={{}}>
                                <tr>
                                    <TitleHead>Tipo de Accidente</TitleHead>
                                    <TitleHead>Incluir datos de la persona</TitleHead>
                                    <TitleHead>Servicio Médico</TitleHead>
                                    <TitleHead>Paso 2b Obligatorio</TitleHead>
                                    <TitleHead>Condición de obligatoriedad</TitleHead>
                                    <TitleHead>Único paso</TitleHead>
                                    <TitleHead>Envía mail</TitleHead>
                                    <TitleHead></TitleHead>
                                </tr>
                                {values.concrete_add_custom_accident_types.map((item, i) => {
                                    return <tr>
                                        <TableData>
                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                <Input name={`concrete_add_custom_accident_types[${i}].custom_name`} width="15vw" placeholder={`Opción ${i+1}`} disabled={false}
                                                value={item.custom_name} onChange={handleChange} horizontal="true" style={{ marginBottom: 0 }}
                                                error={getIn(errors, `concrete_add_custom_accident_types[${i}].custom_name`)}/>
                                            </div>
                                        </TableData>
                                        <TableData>
                                            <div style={{display: "flex", justifyContent: "center"}}> {/*Incluir datos de persona */}
                                                <StyledCheckbox noSpace color={theme.colors.user.wise} checked={values.concrete_add_custom_accident_types[i].related_to_person}
                                                onChange={() => {setFieldValue(`concrete_add_custom_accident_types[${i}].related_to_person`, !values.concrete_add_custom_accident_types[i].related_to_person)}}/>
                                            </div>
                                        </TableData>
                                        <TableData>
                                            <div style={{display: "flex", justifyContent: "center"}}> {/*Servicio Médico*/}
                                                <StyledCheckbox noSpace color={theme.colors.user.wise} checked={values.concrete_add_custom_accident_types[i].prompts_medical_investigation}
                                                onChange={() => {setFieldValue(`concrete_add_custom_accident_types[${i}].prompts_medical_investigation`, !values.concrete_add_custom_accident_types[i].prompts_medical_investigation)}}/>
                                            </div>
                                        </TableData>
                                        <TableData>
                                            <div style={{display: "flex", justifyContent: "center"}}> {/*Paso 2b Obligatorio */}
                                                <StyledCheckbox noSpace color={theme.colors.user.wise} checked={values.concrete_add_custom_accident_types[i].requires_second_step_b}
                                                onChange={() => {setFieldValue(`concrete_add_custom_accident_types[${i}].requires_second_step_b`, !values.concrete_add_custom_accident_types[i].requires_second_step_b)}}/>
                                            </div>
                                        </TableData>
                                        <TableData>
                                            {values.second_step_a_options === 1 &&
                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                <Input type="number" name={`concrete_add_custom_accident_types[${i}].risk_matrix_cutoff`} width="15vw" placeholder={"Valor para matriz"} disabled={false}
                                                value={item.risk_matrix_cutoff} onChange={handleChange} horizontal="true" style={{ marginBottom: 0 }} min={1} max={4000}
                                                error={getIn(errors, `concrete_add_custom_accident_types[${i}].risk_matrix_cutoff`)} />
                                            </div>}
                                            {values.second_step_a_options === 0 &&
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <Select mode="tags" items={customDropdown(values.concrete_add_custom_dropdown_dto)} horizontal="true" width="15vw" border="true"
                                                placeholder={values.concrete_add_custom_dropdown_dto.length === 0 ? "Configure el desplegable del Paso 2a" : "Seleccionar..."}
                                                error={getIn(errors, `concrete_add_custom_accident_types[${i}].drop_down_cutoff`)} name={`concrete_add_custom_accident_types[${i}].drop_down_cutoff`} disabled={values.concrete_add_custom_dropdown_dto.length === 0}
                                                onChange={(val) => {
                                                    if(Array.isArray(val)){
                                                        setFieldValue(`concrete_add_custom_accident_types[${i}].drop_down_cutoff`, val)
                                                    }
                                                }}/>
                                            </div>}
                                        </TableData>
                                        <TableData>
                                            <div style={{display: "flex", justifyContent: "center"}}> {/*Único paso */}
                                                <StyledCheckbox noSpace color={theme.colors.user.wise} checked={values.concrete_add_custom_accident_types[i].automatically_ends_accident}
                                                onChange={() => {setFieldValue(`concrete_add_custom_accident_types[${i}].automatically_ends_accident`, !values.concrete_add_custom_accident_types[i].automatically_ends_accident)}}/>
                                            </div>
                                        </TableData>
                                        <TableData>
                                            <div style={{display: "flex", justifyContent: "center"}}> {/*No envía mail */}
                                                <StyledCheckbox noSpace color={theme.colors.user.wise} checked={values.concrete_add_custom_accident_types[i].should_send_mails}
                                                onChange={() => {setFieldValue(`concrete_add_custom_accident_types[${i}].should_send_mails`, !values.concrete_add_custom_accident_types[i].should_send_mails)}}/>
                                            </div>
                                        </TableData>
                                        <TableData>
                                            {i > 0 &&
                                            <div style={{display: "flex", justifyContent: "center"}}>
                                                <SmallIconButton icon={"TrashBin"} size={30} iconColor={theme.colors.darkGray} background="transparent" onClick={() => {dataTableArrayHelper.remove(i)}}/>
                                            </div>}
                                        </TableData>
                                    </tr>
                                })}
                            </table>
                            <div style={{ marginTop: 15, width: 135 }}>
                                <SmallIconButton bold text="Agregar opción" size={25} background="#b9a5fd" icon={'Add'} iconColor={theme.colors.white} textSize={14} type="button"
                                onClick={() => {dataTableArrayHelper.push(newOptionTableData)}} />
                            </div>
                        </div>
                    </>)}
                </FieldArray>
            </div>
        )
    }

    if (accIncDetail.isFetching) {
        return <Loader />
    }

    return (<>
        <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[savedInfo.wiseName, "Accidente e Incidente", "Configuración Avanzada"]} >
            <MessageBanner visible={success} status="success" message={haveDetail ? "¡Configuración modificada exitosamente!" : "¡Configuración creada exitosamente!"} />
            <MessageBanner visible={error} status="error" message={accIncDetail.error || "Hubo un error en la aplicación, por favor contáctese con soporte."} onAccept={() => setError(false)} />
            <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
        </PageTitleDashboard>
        {(accIncDetail.isSubmitting || load) && <Loader />}
        <Formik initialValues={initialValues} enableReinitialize={true} validateOnChange={false} onSubmit={(values) => onSubmit(values)}
        validate={(value) => {
            try {
            validateYupSchema(value, validationSchema, true, value);
            } catch (err) {
            return yupToFormErrors(err);
            }
            return {};
        }}>
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
        <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
                <div style={{borderTop: "1px solid #ededed", paddingTop: 20, marginBottom: 20}}>
                    <span style={{fontWeight: "bold"}}>Configuración de Pasos<span style={{color: "red"}}>*</span></span>
                </div>
                {generalConfiguration(setFieldValue, values, errors)}
                {phaseTwoA(setFieldValue, values, errors)}
                {phaseTwoB(setFieldValue, values, errors)}
                {accidentsFlow(setFieldValue, values, errors, handleChange)}
                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginBottom: 5}}>
                    <div style={{width: "56vw", maxHeight: 45}}>
                        <span><b>Versionado: </b> Recuerde que al realizar modificaciones se generarán <b>nuevas versiones de Accidente e Incidente,</b> las mismas se verán reflejadas en la descarga de excel.</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <CancelButton small="true" type="button" onClick={() => history.goBack()}>{i18n('CANCEL')} </CancelButton>
                        <ConfirmationButton background={"#dfd6ff"} color={"#6247c0"}
                        small="true" type="submit">{i18n('SAVE')}</ConfirmationButton>
                    </div>
                </div>
            </Form>
            {seePopupDropdowns && dropdownsOptionsPopup(setFieldValue, values.concrete_add_custom_dropdown_dto, values.concrete_add_custom_accident_types)}
        </>
        )}
        </Formik>
    </>)
}

export default AdvancedAccidentSettings