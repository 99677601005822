import React, { useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { Page, PageBlock, StyledMenu, Side, Loader } from '@tops/components'
import TerrandLogo from '../../assets/images/terrand_logo.svg'
import Main from './Main'
import SIDEBAR_LINKS from '../../config/sidebarMenuLinks'
import { logout } from '../../actions/auth'
import { useDispatch } from 'react-redux';
import Wise from './Wise/index';
import { i18n } from '@tops/services';
import UserDetail from './Main/UserDetail'

const Dashboard = (props) => {
  const { path, url } = useRouteMatch()
  const { name } = useParams()
  const dispatch = useDispatch()

  const [key, setKey] = useState('')

  const savedInfo = JSON.parse(localStorage.getItem('account'))

  useEffect(() => {
    if (savedInfo.name.toLowerCase() !== name.split('_').join(' ').toLowerCase()) {
      props.history.push('/')
      localStorage.removeItem('account')
    }

    const checkSelectedItem = (loc) => {
      SIDEBAR_LINKS.forEach((item) => {

        if (key === "" && loc.pathname.includes(`${url}/${item.pathName}`)) {
          setKey(`${url}/${item.pathName}`)
        }
      })
    }

    checkSelectedItem(props.location)

    var unlisten = props.history.listen((location) => {
      checkSelectedItem(location)
    });

    return () => {
      unlisten()
    }
  }, [])

  const handleKeyChange = (item) => {
    setKey(item.key)
  }

  return (
    <Layout style={{ height: '100%' }}>
      <Side breakpoint="md"
        collapsedWidth="0"
        logo={TerrandLogo}
        onBack={() => props.history.push('/cuentas')}
      >
        <StyledMenu mode="inline" selectedKeys={[key]} onClick={handleKeyChange}>
          {
            SIDEBAR_LINKS.map(({ label, pathName }) => <Menu.Item key={`${url}/${pathName}`} onClick={() => props.history.push(`${url}/${pathName}`)}>
              <span >{label}</span>
            </Menu.Item>)
          }
          {savedInfo.wiseActive &&
            <Menu.Item key={`${url}/${i18n('SIDEBAR_LINKS').WISE.LINK}`} onClick={() => props.history.push(`${url}/${i18n('SIDEBAR_LINKS').WISE.LINK}`)}>{savedInfo.wiseName}</Menu.Item>
          }
        </StyledMenu>
      </Side>
      <Layout>
        <Layout.Content >
          <Page withHeader logout={() => dispatch(logout())}>
            <PageBlock>
              <Switch>
                <Route
                  exact
                  path={`${path}`}
                  render={(props) => <Main savedInfo={savedInfo} {...props} />}
                />
                <Route
                  path={`${path}/user-detail/:id`}
                  render={(props) => <UserDetail {...props} savedInfo={savedInfo} />}
                />
                {
                  SIDEBAR_LINKS.map((route) => <Route key={route.pathName} path={`${path}/${route.pathName}`} render={() => <route.component savedInfo={savedInfo} />} />)
                }
                {savedInfo.wiseActive && <Route key={i18n('SIDEBAR_LINKS').WISE.LINK} path={`${path}/${i18n('SIDEBAR_LINKS').WISE.LINK}`} render={() => <Wise savedInfo={savedInfo} />} />}
              </Switch>
            </PageBlock>
          </Page>
        </Layout.Content>
      </Layout>
    </Layout>
  )

}

export default Dashboard