import React, { useState, useEffect } from 'react'
import { i18n } from '@tops/services'
import { theme } from '@tops/global_config'
import { IconButton, ListItem, PageTitleDashboard, Button, Loader, MessageBanner, Container, Select, ListWithDetails, Pagination } from '@tops/components'

import { useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectSector, fetchSectorsByAccount, fetchSectorsByBusiness } from '../../../../actions/sectors'
import { selectBusiness, fetchAllBusiness, selectedBusiness } from '../../../../actions/business'
import { fetchRoles } from '../../../../actions/roles'

const ViewRoles = ({ history, savedInfo }) => {
  const { url } = useRouteMatch()

  const sectors = useSelector(state => state.sectors)
  const business = useSelector(state => state.business)
  const roles = useSelector(state => state.roles)

  const [error, setError] = useState(false)
  const [active, setActive] = useState(true)
  const [businessSelected, setBusinessSelected] = useState(null)
  const [sectorSelected, setSectorSelected] = useState(null)
  const [hasSearched, setHasSearched] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchSectorsByAccount(savedInfo.id))
        dispatch(fetchAllBusiness(savedInfo.id))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
  }, [])


  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchRoles(currentPage, sectorSelected, active))
      } catch (error) {
        setError(true)
      }
    }
    fetchData()
  }, [currentPage, active])

  const handleActive = () => {
    setActive(!active)
  }

  const handleBusinessChange = (value) => {
    setSectorSelected(null)
    setBusinessSelected(value)
    dispatch(fetchSectorsByBusiness(savedInfo.id, value))
    dispatch(selectSector(value))
  }

  const handleSectorChange = (value) => {
    setSectorSelected(value)
  }

  const submitSearch = () => {
    dispatch(fetchRoles(currentPage, sectorSelected, active))
    setHasSearched(true)
    !active && setActive(true)
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[i18n('SIDEBAR_LINKS').ROLES.LABEL]}>
        <IconButton size={35} background={theme.colors.primary} icon={'Add'} text={i18n('SIDEBAR_LINKS').CREATE_ROLE.LABEL} bold="true" textSize={14} onClick={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').CREATE_ROLE.LINK}`)} />
        <MessageBanner visible={error} status="error" message={roles.error} onAccept={() => setError(false)} />
      </PageTitleDashboard>
      <Container flexDirection="row" justifyContent="flex-start">
        <Select items={business.values} placeholder={i18n('SELECT_BUSINESS')} onChange={handleBusinessChange} width="250px"
          value={businessSelected} border="true" name="businessSelected" />
        <div style={{ marginLeft: "20px", marginRight: "20px" }}>
          <Select items={sectors.allSectorsByBusiness} placeholder={i18n('SELECT_SECTOR')} onChange={handleSectorChange} width="250px"
            value={sectorSelected} border="true" name="sectorSelected" />
        </div>
        <Button small="true" style={{ marginTop: "-12px" }}  onClick={() => submitSearch()} disabled={businessSelected === null || sectorSelected === null}>Filtrar</Button>
      </Container>

      <Container justifyContent="flex-end">
        {(hasSearched) && <div>
          <Button square small background={theme.colors.accent} selected={active} onClick={handleActive}>{i18n('ACTIVES')}</Button>
          <Button square small background={theme.colors.accent} selected={!active} onClick={handleActive}>{i18n('INACTIVES')}</Button>
        </div>}

      </Container>
      {hasSearched ? roles.isFetching ?
        <Loader /> : roles.values.length === 0 ?
          <div style={{ fontSize: 15, marginTop: 20 }}>{i18n('NO_DATA_AVAILABLE')}</div> :
          <ListWithDetails details={[{ name: i18n('NAME') }, { name: i18n('SECTOR') }]}>
            {
              roles.values.map((item) => <ListItem
                key={item.id}
                disabled={!active}
                item={item}
                name={item.name}
                details={[{ name: item.sector_name }]}
                action={() => history.push(`${url}/${i18n('SIDEBAR_LINKS').EDIT_ROLE.LINK}/${item.id}`, { isActive: active, business: businessSelected })}
              />)
            }
            <Pagination currentPage={currentPage} itemsLength={roles.values.length}
              onNext={() => setCurrentPage(currentPage + 1)} onPrev={() => setCurrentPage(currentPage - 1)} />
          </ListWithDetails> : <div></div>
      }
    </>
  )
}

export default ViewRoles
