import { createActions } from 'redux-actions'
import API_ROUTES from '../config/apiRoutes'
import { getApi } from '@tops/services'

const { fetchRequestTypeStart, fetchRequestTypeSuccess, fetchRequestTypeError,
  fetchRequestTypeByIdStart, fetchRequestTypeByIdSuccess,fetchRequestTypeByIdError,
  submitRequestTypeStart, submitRequestTypeSuccess, submitRequestTypeError,
  editRequestTypeStart, editRequestTypeSuccess, editRequestTypeError,
  fetchAllLevelsBySectorStart, fetchAllLevelsBySectorSuccess, fetchAllLevelsBySectorError,
  fetchAssociatedRequestsStart, fetchAssociatedRequestsSuccess, fetchAssociatedRequestsError,
  fetchAllRelatedRequestsBySectorStart, fetchAllRelatedRequestsBySectorSuccess, fetchAllRelatedRequestsBySectorError,
  fetchAllRequestModelsStart, fetchAllRequestModelsSuccess, fetchAllRequestModelsError,
  fetchRequestExcelStart, fetchRequestExcelSuccess, fetchRequestExcelError, 
} = createActions({
    FETCH_REQUEST_TYPE_START: () => { },
    FETCH_REQUEST_TYPE_SUCCESS: values => ({ values }),
    FETCH_REQUEST_TYPE_ERROR: error => ({ error }),
    FETCH_REQUEST_TYPE_BY_ID_START: () => { },
    FETCH_REQUEST_TYPE_BY_ID_SUCCESS: values => ({ values }),
    FETCH_REQUEST_TYPE_BY_ID_ERROR: error => ({ error }),
    SUBMIT_REQUEST_TYPE_START: () => { },
    SUBMIT_REQUEST_TYPE_SUCCESS: data => ({ data }),
    SUBMIT_REQUEST_TYPE_ERROR: error => ({ error }),
    EDIT_REQUEST_TYPE_START: () => { },
    EDIT_REQUEST_TYPE_SUCCESS: data => ({ data }),
    EDIT_REQUEST_TYPE_ERROR: error => ({ error }),
    FETCH_ALL_LEVELS_BY_SECTOR_START: () => { },
    FETCH_ALL_LEVELS_BY_SECTOR_SUCCESS: allLvlsBySector => ({ allLvlsBySector }),
    FETCH_ALL_LEVELS_BY_SECTOR_ERROR: error => ({ error }),
    FETCH_ASSOCIATED_REQUESTS_START: () => { },
    FETCH_ASSOCIATED_REQUESTS_SUCCESS: associatedRequests => ({ associatedRequests }),
    FETCH_ASSOCIATED_REQUESTS_ERROR: error => ({ error }),
    FETCH_ALL_RELATED_REQUESTS_BY_SECTOR_START: () => { },
    FETCH_ALL_RELATED_REQUESTS_BY_SECTOR_SUCCESS: allRelatedRequestsBySector => ({ allRelatedRequestsBySector }),
    FETCH_ALL_RELATED_REQUESTS_BY_SECTOR_ERROR: error => ({ error }),
    FETCH_ALL_REQUEST_MODELS_START: () => { },
    FETCH_ALL_REQUEST_MODELS_SUCCESS: values => ({ values }),
    FETCH_ALL_REQUEST_MODELS_ERROR: error => ({ error }),
    FETCH_REQUEST_EXCEL_START: () => { },
    FETCH_REQUEST_EXCEL_SUCCESS: values => ({ values }),
    FETCH_REQUEST_EXCEL_ERROR: error => ({ error }),
  })

const fetchRequestTypeWithoutResults = (page, accountId, active, isExpirationModel,sectorId, businessId) => {
  return async dispatch => {
    dispatch(fetchRequestTypeStart())
    const sectorId = 141414
    const api = getApi(API_ROUTES)
    const status = active ? 'paginated' : 'inactive'
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/${status}/${page}/${10}/${accountId}/${isExpirationModel}${businessId ? `?business-id=${businessId}` : ''}${businessId && sectorId ? `&sectorId=${sectorId}` : (!businessId && sectorId ? `?sectorId=${sectorId}` : '')}`)
        .then((response) => {
          dispatch(fetchRequestTypeSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}
const fetchRequestType = (page, accountId, active, isExpirationModel,sectorId, businessId) => {
  return async dispatch => {
    dispatch(fetchRequestTypeStart())
    const api = getApi(API_ROUTES)
    const status = active ? 'paginated' : 'inactive'
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/${status}/${page}/${10}/${accountId}/${isExpirationModel}${businessId ? `?business-id=${businessId}` : ''}${businessId && sectorId ? `&sectorId=${sectorId}` : (!businessId && sectorId ? `?sectorId=${sectorId}` : '')}`)
        .then((response) => {
          dispatch(fetchRequestTypeSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}
const fetchRequestTypeByBuisness = (page, accountId, active, isExpirationModel,businessId, sectorId) => {
  return async dispatch => {
    dispatch(fetchRequestTypeStart())
    const api = getApi(API_ROUTES)
    const status = active ? 'paginated' : 'inactive'
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/${status}/${page}/${10}/${accountId}/${isExpirationModel}${businessId ? `?business-id=${businessId}` : ''}${businessId && sectorId ? `&sectorId=${sectorId}` : (!businessId && sectorId ? `?sectorId=${sectorId}` : '')}`)
        .then((response) => {
          dispatch(fetchRequestTypeSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchRequestTypeById = (requestTypeId, isExpirationModel) => {
  return async dispatch => {
    dispatch(fetchRequestTypeByIdStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/complete/${requestTypeId}/${isExpirationModel}`)
        .then((response) => {
          dispatch(fetchRequestTypeByIdSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchRequestTypeByIdError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    })
  }
}


const submitRequestType = (values, accountId, sectorId) => {
  return async dispatch => {
    dispatch(submitRequestTypeStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.post(`${API_ROUTES.REQUEST_TYPE}/add/${accountId}/${sectorId}`, values )
        .then((response) => {
          dispatch(submitRequestTypeSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(submitRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const editRequestType = (requestType) => {
  return async dispatch => {
    dispatch(editRequestTypeStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.put(API_ROUTES.REQUEST_TYPE, requestType)
        .then(() => {
          dispatch(editRequestTypeSuccess())
          resolve()
        }).catch((error) => {
          dispatch(editRequestTypeError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchAllLevelsBySector = (accountId) => {
  return async dispatch => {
    dispatch(fetchAllLevelsBySectorStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.LEVELS}/simple/sector/${accountId}?include-inactives=false`)
        .then((response) => {
          dispatch(fetchAllLevelsBySectorSuccess(response.data))
          resolve()
        }).catch((error) => {
          dispatch(fetchAllLevelsBySectorError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )

  }
}

const fetchAllRelatedRequestsBySector = (sectorId) => {
  return async dispatch => {
    dispatch(fetchAllRelatedRequestsBySectorStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/simple/${sectorId}`)
        .then((response) => {
          dispatch(fetchAllRelatedRequestsBySectorSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchAllRelatedRequestsBySectorError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject(error)
        })
    }
    )

  }
}


const fetchAssociatedRequests = (businessIs) => {
  return async dispatch => {
    dispatch(fetchAssociatedRequestsStart())
    const api = getApi(API_ROUTES)
    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/simple-business/${businessIs}`)
        .then((response) => {
          dispatch(fetchAssociatedRequestsSuccess(response.data))
          resolve(response)
        }).catch((error) => {
          dispatch(fetchAssociatedRequestsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject(error)
        })
    }
    )

  }
}

const fetchAllRequestModels = (sectorId) => {
  return async dispatch => {
    dispatch(fetchAllRequestModelsStart())
    const api = getApi(API_ROUTES)

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE}/simple/${sectorId}`)
        .then((response) => {
          dispatch(fetchAllRequestModelsSuccess(response.data))
          resolve(response.data)
        }).catch((error) => {
          dispatch(fetchAllRequestModelsError(error.response !== undefined ? error.response.data.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

const fetchRequestExcel = (sectorId, page, filters) => {
  return async dispatch => {
    dispatch(fetchRequestExcelStart())
    const api = getApi(API_ROUTES)

    const take = 1000

    const query = []
    Object.entries(filters).forEach((filter) => filter[1] !== '' && filter[1] !== null && query.push(`${filter[0]}=${filter[1]}`))

    return new Promise((resolve, reject) => {
      api.get(`${API_ROUTES.REQUEST_TYPE_ADMIN}/request-excel-export-by-admin/${sectorId}?page=${page}&take=${take}${`&is-request-expiration=false`}${query.length ? `&${query.join('&')}` : ''}`, { responseType: 'arraybuffer' })
        .then((response) => {
          dispatch(fetchRequestExcelSuccess(response.data))
          resolve(response.data)
        }).catch((error) => {
          let decodedString = String.fromCharCode.apply(null, new Uint8Array(error.response.data))
          let obj = JSON.parse(decodedString)
          dispatch(fetchRequestExcelError(error.response !== undefined ? obj.business_errors : error.message))
          reject()
        })
    }
    )
  }
}

export {
  fetchRequestType, fetchRequestTypeStart, fetchRequestTypeSuccess,fetchRequestTypeError,
  fetchRequestTypeById, fetchRequestTypeByIdStart, fetchRequestTypeByIdSuccess, fetchRequestTypeByIdError,
  submitRequestType, submitRequestTypeStart, submitRequestTypeSuccess, submitRequestTypeError,
  editRequestType, editRequestTypeStart, editRequestTypeSuccess, editRequestTypeError,
  fetchAllLevelsBySector, fetchAllLevelsBySectorStart, fetchAllLevelsBySectorSuccess, fetchAllLevelsBySectorError,
  fetchAssociatedRequests,  fetchAssociatedRequestsStart,  fetchAssociatedRequestsSuccess,  fetchAssociatedRequestsError,
  fetchAllRelatedRequestsBySector, fetchAllRelatedRequestsBySectorStart, fetchAllRelatedRequestsBySectorSuccess, fetchAllRelatedRequestsBySectorError,
  fetchAllRequestModels, fetchAllRequestModelsStart, fetchAllRequestModelsSuccess, fetchAllRequestModelsError,
  fetchRequestExcel, fetchRequestExcelStart, fetchRequestExcelSuccess, fetchRequestExcelError,fetchRequestTypeByBuisness,fetchRequestTypeWithoutResults
}
