import { handleActions } from 'redux-actions'
import { authStart, authSuccess, authError, authSessionStart, authSessionSuccess, authSessionError, authChallengeStart, authChallengeSuccess,
  authChallengeError } from '../actions/auth'

export const defaultState = {
  isFetching: false,
  isSubmitting: false,
  isUserLogged: null,
  SuccessChallenge: false,
  hasSession: null,
  error: null
}

const reducer = handleActions(
  {
    [authStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [authSuccess]: (state, { payload: { isUserLogged } }) => ({
      ...state,
      isUserLogged,
      isFetching: false,
      error: null
    }),
    [authError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [authSessionStart]: state => ({
      ...state,
      isFetching: true,
      error: null
    }),
    [authSessionSuccess]: (state, { payload: { values } }) => ({
      ...state,
      hasSession: values,
      isFetching: false,
      error: null
    }),
    [authSessionError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [authChallengeStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [authChallengeSuccess]: (state, { payload: { values } }) => ({
      ...state,
      SuccessChallenge: true,
      isSubmitting: false,
      error: null
    }),
    [authChallengeError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    })
  },
  defaultState
)

export default reducer
