import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Divider } from 'antd'
import { Formik, FieldArray, getIn } from 'formik'
import * as yup from 'yup'
import { submitWiseConfiguration } from '../../../../actions/wiseConfiguration'
import { fetchAllBusiness } from '../../../../actions/business'
import { fetchAllBusinessLevels, fetchBusinessLevelsByParentId } from '../../../../actions/businessLevels'
import { fetchAssociatedRequests } from '../../../../actions/requestTypes'
import { i18n } from '@tops/services'
import { capitalizeFirstLetter } from '@tops/utils'
import {
  ConfirmationButton, CancelButton, PageTitleDashboard, DashboardContainer, DashboardInput, Select,
  SmallIconButton, MessageError, Loader, MessageBanner, IconButton, Collapse, StyledSwitch
} from '@tops/components'
import { useRouteMatch } from 'react-router-dom'
import { theme } from '@tops/global_config'

const CreateWiseConfiguration = ({ history, savedInfo }) => {
  const business = useSelector(state => state.business)
  const businessLevels = useSelector(state => state.businessLevels)
  const associatedRequests = useSelector(state => state.requestTypes.associatedRequests)
  const { isSubmitting, error: requestError } = useSelector(state => state.wiseConfiguration)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [businessLevelsSelectorsData, setBusinessLevelsSelectorsData] = useState([])
  const [validateError, setValidateError] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(fetchAllBusiness(savedInfo.id))
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [])

  const validationSchema = yup.object().shape({
    business: yup.number().required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.BUSINESS),
    name: yup.string().trim().required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.NAME),
    email: yup.string().email().required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.EMAIL),
    grouper: yup.number().required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.GROUPER),
    levelsSchema: yup.array().when('grouper', (grouper, schema) =>
      schema.of(
        yup
          .array()
          .required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.LEVELS_SCHEMA)
          .test(
            'len',
            i18n('WISE').CREATE_CONFIGURATION.ERRORS.LEVELS_SCHEMA,
            (val) => val.length >= grouper + 1
          )
          .of(yup.number())
      )
    ),
    associatedRequests: yup.array().of(yup.number()).required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.ASSOCIATED_REQUESTS),
    associated_investigation_model_request_ids: yup.array().of(yup.number()).required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.ASSOCIATED_REQUESTS),
    associated_vct_request_ids: yup.array().of(yup.number()).required(i18n('WISE').CREATE_CONFIGURATION.ERRORS.ASSOCIATED_REQUESTS),
    post_risk_warning_configuration_dt: yup.object().shape({
      type_a_email_grouping_sublocation: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
      type_b_email_grouping_sublocation: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
      type_c_email_grouping_sublocation: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
    }),
    post_accident_and_incident_configuration_dto: yup.object().shape({
      email_grouping_sublocation: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
    }),
    post_wise_survey_configuration_dt: yup.object().shape({
      email_grouping_sublocation: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
    }),
    post_concrete_vct_configuration_dto: yup.object().shape({
      vct_email_grouping_sublocation: yup.mixed().required((i18n('ERRORS').REQUIRED_FIELD)),
    }),
  })

  const onSubmit = (values) => {
    const bodyRequest = {
      business_id: values.business,
      post_wise_admin_dto: {
        email: values.email,
        name: values.name
      },
      minimun_count: (values.grouper + 1),
      entity_configuration_scheme: values.levelsSchema.map(schema => Object.keys(schema).map(key => schema[key])),
      associated_request_ids: values.associatedRequests,
      associated_investigation_model_request_ids: values.associated_investigation_model_request_ids,
      associated_vct_request_ids: values.associated_vct_request_ids,
      post_risk_warning_configuration_dt: values.post_risk_warning_configuration_dt,
      post_accident_and_incident_configuration_dto: values.post_accident_and_incident_configuration_dto,
      post_wise_survey_configuration_dt: values.post_wise_survey_configuration_dt,
      post_concrete_vct_configuration_dto: values.post_concrete_vct_configuration_dto,
      post_progressive_discipline_configuration_dto: values.post_progressive_discipline_configuration_dto,
      post_positive_agreement_configuration_dto: values.post_positive_agreement_configuration_dto,
    }
    dispatch(submitWiseConfiguration(bodyRequest))
      .then(() => {
        setSuccess(true)
        setTimeout(() => {
          history.goBack()
        }, 1000)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const businessSelect = (setFieldValue, values, errors) => (
    <Select name="business" label={i18n('WISE').CREATE_CONFIGURATION.BUSINESS} horizontal="true" width="350px" border="true" isRequired
      items={business.values} value={values.business} error={errors.business} onChange={(value) => {
        dispatch(fetchAllBusinessLevels(value, true, false))
        dispatch(fetchAssociatedRequests(value))
        setFieldValue("business", value)
      }
      } />
  )

  const nameInput = (handleChange, values, errors) => (
    <>
      <Divider />
      <div style={{ marginBottom: "24px" }}>
        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.ADMINISTRATOR)}</span>
      </div>
      <DashboardInput label={i18n('WISE').CREATE_CONFIGURATION.NAME} horizontal="true" border="true" width="350px" name="name" isRequired
        value={values.name} error={errors.name} onChange={handleChange} />
    </>
  )

  const emailInput = (handleChange, values, errors) => (
    <DashboardInput label={i18n('WISE').CREATE_CONFIGURATION.EMAIL} horizontal="true" border="true" width="350px" name="email" isRequired
      value={values.email} error={errors.email} onChange={handleChange} />
  )

  const grouperSelect = (setFieldValue, values, errors) => (
    <>
      <Divider />
      <div style={{ marginBottom: "24px" }}>
        <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.MY_TEAM)}</span>
      </div>
      <Select name="grouper" label={i18n('WISE').CREATE_CONFIGURATION.GROUPER} horizontal="true" width="350px" border="true" isRequired
        items={grouperSchema} value={values.grouper} error={errors.grouper} onChange={(v) => setFieldValue("grouper", v)} />
    </>
  )

  const levelsSchemaSelects = (setFieldValue, values, errors) => (<>
    <div style={{ marginTop: "25px", marginBottom: "15px" }}>
      <span style={{ fontWeight: 600 }}>{capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.LEVELS_SCHEMA)}</span>
    </div>
    <FieldArray name={`levelsSchema`}>
      {
        arrayHelpers => (
          <div>
            {
              values.levelsSchema.map((schema, schemaIndex) => (
                <div key={schemaIndex} style={{ marginBottom: "16px" }}>
                  <div style={{ marginBottom: "16px" }}>
                    <span style={{ fontWeight: 600 }}>{`${capitalizeFirstLetter(i18n('WISE').CREATE_CONFIGURATION.SCHEMA)} ${schemaIndex + 1}`}</span>
                  </div>
                  {
                    Array.apply(null, { length: 7 }).map((_, levelIndex) => (
                      <Select key={levelIndex} name={`levelsSchema[${schemaIndex}][${levelIndex}]`} label={levelIndex === 0 ? i18n('WISE').CREATE_CONFIGURATION.SITE : `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} ${levelIndex}`}
                        horizontal="true" width="350px" border="true" isRequired={levelIndex < values.grouper + 1}
                        items={levelIndex === 0 ? businessLevels.all : (businessLevelsSelectorsData[schemaIndex] && businessLevelsSelectorsData[schemaIndex][levelIndex - 1] || [])} value={schema[levelIndex]}
                        error={levelIndex <= values.grouper ? getIn(errors, `levelsSchema[${schemaIndex}]`) : ''} onChange={(value) => {
                          const newValue = [
                            ...(values.levelsSchema[schemaIndex] || []).slice(0, levelIndex),
                            value
                          ]
                          setFieldValue(`levelsSchema[${schemaIndex}]`, newValue)
                          dispatch(fetchBusinessLevelsByParentId(values.business, value))
                            .then(({ data }) => {
                              let newBusinessLevelsSelectorsData = [...businessLevelsSelectorsData]
                              newBusinessLevelsSelectorsData[schemaIndex] = [...(newBusinessLevelsSelectorsData[schemaIndex] || []).slice(0, levelIndex), data]
                              setBusinessLevelsSelectorsData(newBusinessLevelsSelectorsData)
                            })
                        }} />
                    ))
                  }
                  {schemaIndex > 0 &&
                    <>
                      <div style={{ float: "right", padding: "20px 0 20px 40px" }} >
                        <SmallIconButton icon={"TrashBin"} size={21} text="Borrar esquema" bold="true" iconColor="white" background="#b9a5fd"
                          onClick={() => arrayHelpers.remove(schemaIndex)} />
                      </div>
                      <Divider style={{ width: 550 }} />
                    </>
                  }
                </div>
              ))
            }
            <div style={{ marginTop: 24 }}>
              <SmallIconButton type="button" icon={'Add'} bold="true" size={21} text={i18n('WISE').CREATE_CONFIGURATION.NEW_LEVELS_SCHEMA}
                onClick={() => arrayHelpers.push([])} />
            </div>
          </div>
        )}
    </FieldArray>
  </>
  )

  const itemsToMails = [ /*No se usa grouperSchema porque se necesitan los ID's +1*/
    { id: 1, name: "Sitio" },
    { id: 2, name: "Sublocación 1" },
    { id: 3, name: "Sublocación 2" },
    { id: 4, name: "Sublocación 3" },
    { id: 5, name: "Sublocación 4" },
    { id: 6, name: "Sublocación 5" },
    { id: 7, name: "Sublocación 6" },
  ]

  const associatedRequestsSelect = (setFieldValue, values, errors) => (
    <div style={{ width: "135%", height: "auto", border: "1px solid #b8c1ca", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold style={{ fontWeight: "bold" }} size={25}
          panels={[{
            title: "Aviso de Riesgo", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Aviso de Riesgo"} error={false} onChange={{}} disabled={true} />
                  <div style={{ marginTop: 20 }}>
                    <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>{i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} <div style={{ color: "red" }}>*</div></div>
                    <Select name="associatedRequests" horizontal="true" width="350px" border="true" mode="multiple" isRequired
                      items={associatedRequests} value={values.associatedRequests} error={errors.associatedRequests} onChange={(v) => setFieldValue("associatedRequests", v)} />
                  </div>
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="post_risk_warning_configuration_dt.type_a_email_grouping_sublocation" horizontal="true" width="350px" border="true" disabled={false} label={"A"}
                    items={itemsToMails} value={values.post_risk_warning_configuration_dt.type_a_email_grouping_sublocation} error={getIn(errors, "post_risk_warning_configuration_dt.type_a_email_grouping_sublocation")}
                    onChange={(val) => setFieldValue("post_risk_warning_configuration_dt.type_a_email_grouping_sublocation", val)} />
                  <Select name="post_risk_warning_configuration_dt.type_b_email_grouping_sublocation" horizontal="true" width="350px" border="true" disabled={false} label={"B"}
                    items={itemsToMails} value={values.post_risk_warning_configuration_dt.type_b_email_grouping_sublocation} error={getIn(errors, "post_risk_warning_configuration_dt.type_b_email_grouping_sublocation")}
                    onChange={(val) => setFieldValue("post_risk_warning_configuration_dt.type_b_email_grouping_sublocation", val)} />
                  <Select name="post_risk_warning_configuration_dt.type_c_email_grouping_sublocation" horizontal="true" width="350px" border="true" disabled={false} label={"C"}
                    items={itemsToMails} value={values.post_risk_warning_configuration_dt.type_c_email_grouping_sublocation} error={getIn(errors, "post_risk_warning_configuration_dt.type_c_email_grouping_sublocation")}
                    onChange={(val) => setFieldValue("post_risk_warning_configuration_dt.type_c_email_grouping_sublocation", val)} />
                </div>
                <div style={{ width: "45%" }}>
                  <StyledSwitch label={"Foto obligatoria"} horizontal="true" checked={values.post_risk_warning_configuration_dt.obligatory_picture} style={{ fontWeight: "bold" }}
                    onChange={(e) => setFieldValue("post_risk_warning_configuration_dt.obligatory_picture", e.target.checked)} />
                </div>
              </div>
            </>
          }]} />
      </div>
    </div>
  )

  const associatedRequestsInvestigationModelSelect = (setFieldValue, values, errors) => (
    <div style={{ width: "135%", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Accidente e Incidente", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Accidente e Incidente"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="post_accident_and_incident_configuration_dto.email_grouping_sublocation" horizontal="true" width="350px" border="true"
                    items={itemsToMails} value={values.post_accident_and_incident_configuration_dto.email_grouping_sublocation} error={getIn(errors, "post_accident_and_incident_configuration_dto.email_grouping_sublocation")}
                    onChange={(val) => setFieldValue("post_accident_and_incident_configuration_dto.email_grouping_sublocation", val)} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>{i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} <div style={{ color: "red" }}>*</div></div>
                  <Select name="associated_investigation_model_request_ids" horizontal="true" width="350px" border="true" mode="multiple" isRequired
                    items={associatedRequests} value={values.associated_investigation_model_request_ids} error={errors.associated_investigation_model_request_ids} onChange={(v) => setFieldValue("associated_investigation_model_request_ids", v)} />
                  <div style={{ width: "45%" }}>
                    <StyledSwitch label={"Campo 12 Básicos"} horizontal="true" checked={values.post_accident_and_incident_configuration_dto.should_display_twelve_basic} style={{ fontWeight: "bold" }}
                      onChange={(e) => setFieldValue("post_accident_and_incident_configuration_dto.should_display_twelve_basic", e.target.checked)} />
                  </div>
                </div>
              </div>
              <div style={{ width: "44%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                  <div style={{ width: "90%" }}>
                    <StyledSwitch label={"Clasificación de Servicio Médico Wise"} horizontal="true" checked={values.post_accident_and_incident_configuration_dto.medical_service_danone_classification} style={{ fontWeight: "bold" }}
                      onChange={(e) => setFieldValue("post_accident_and_incident_configuration_dto.medical_service_danone_classification", e.target.checked)} />
                  </div>
                </div>
              </div>
            </>
          }]} />
      </div>
    </div>
  )

  const associatedProgressiveDiscipline = (setFieldValue, values, errors) => (
    <div style={{ width: "135%", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Disciplina Progresiva", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Disciplina Progresiva"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Tipo de desvío</div>
                  <Select name="tipo_de_desvio" horizontal="true" width="350px" border="true" mode="multiple" isRequired disabled
                    items={[]} value={values.tipo_de_desvio} error={errors.tipo_de_desvio} onChange={(v) => setFieldValue("tipo_de_desvio", v)} />
                </div>
              </div>
            </>
          }]} />
      </div>
      <div style={{ borderTop: "1px solid #B8C1CA" }}>
        <div style={{ padding: "5px 10px 5px 10px" }}>
          <div style={{ width: "21%" }}>
            <StyledSwitch id={1} label={"Habilitada"} horizontal="true" checked={values.post_progressive_discipline_configuration_dto.is_active} name="post_progressive_discipline_configuration_dto.is_active" style={{ fontWeight: "bold" }}
              onChange={(e) => setFieldValue("post_progressive_discipline_configuration_dto.is_active", e.target.checked)} />
          </div>
        </div>
      </div>
    </div>
  )

  const associatedAudits = (setFieldValue, values, errors) => (
    <div style={{ width: "135%", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Auditorías", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Auditorías"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="post_wise_survey_configuration_dt.email_grouping_sublocation" horizontal="true" width="350px" border="true"
                    items={itemsToMails} value={values.post_wise_survey_configuration_dt.email_grouping_sublocation} error={getIn(errors, "post_wise_survey_configuration_dt.email_grouping_sublocation")}
                    onChange={(val) => setFieldValue("post_wise_survey_configuration_dt.email_grouping_sublocation", val)} />
                </div>
              </div>
            </>
          }]} />
      </div>
    </div>
  )

  const associatedVct = (setFieldValue, values, errors) => (
    <>
      <div style={{ width: "135%", height: "auto", border: "1px solid #b8c1ca", borderRadius: 5, marginTop: 10 }}>
        <div style={{ padding: 10 }}>
          <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
            panels={[{
              title: "VCT", content: <>
                <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                  <div>
                    <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                    <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                      value={"VCT"} error={false} onChange={{}} disabled={true} />
                  </div>
                  <div>
                    <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                    <Select name="post_concrete_vct_configuration_dto.vct_email_grouping_sublocation" horizontal="true" width="350px" border="true"
                      items={itemsToMails} value={values.post_concrete_vct_configuration_dto.vct_email_grouping_sublocation} error={getIn(errors, "post_concrete_vct_configuration_dto.vct_email_grouping_sublocation")}
                      onChange={(val) => setFieldValue("post_concrete_vct_configuration_dto.vct_email_grouping_sublocation", val)} />
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Solicitudes Asociadas <div style={{ color: "red" }}>*</div></div>
                    <Select name="associated_vct_request_ids" horizontal="true" width="350px" border="true" placeholder="Seleccionar Solicitudes" mode="multiple"
                      items={associatedRequests} value={values.associated_vct_request_ids} error={errors.associated_vct_request_ids} onChange={(v) => setFieldValue("associated_vct_request_ids", v)} />
                  </div>
                </div>
              </>
            }]} />
        </div>
        <div style={{ borderTop: "1px solid #B8C1CA" }}>
          <div style={{ padding: "5px 10px 5px 10px" }}>
            <div style={{ width: "21%" }}>
              <StyledSwitch id={2} label={"Habilitada"} horizontal="true" checked={values.post_concrete_vct_configuration_dto.is_active} name="post_concrete_vct_configuration_dto.is_active" style={{ fontWeight: "bold" }}
                onChange={(e) => setFieldValue("post_concrete_vct_configuration_dto.is_active", e.target.checked)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  const associatedPositiveAgreement = (setFieldValue, values, errors) => (
    <div style={{ width: "135%", height: "auto", border: "1px solid #B8C1CA", borderRadius: 5, marginTop: 10 }}>
      <div style={{ padding: 10 }}>
        <Collapse defaultOpenedPanels={[0]} color={theme.colors.darkGray} bold size={25}
          panels={[{
            title: "Acuerdo Positivo", content: <>
              <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Nomenclatura <div style={{ color: "red" }}>*</div></div>
                  <DashboardInput horizontal="true" border="true" width="350px" name="test" coloredDisabled
                    value={"Acuerdo Positivo"} error={false} onChange={{}} disabled={true} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>Agrupador para envío de mails</div>
                  <Select name="test.test" horizontal="true" width="350px" border="true" disabled={true}
                    items={[]} value={values.test} error={false} /**Queda para futuro */
                    onChange={(val) => setFieldValue("test", val)} />
                </div>
                <div>
                  <div style={{ color: "#49494E", display: "flex", fontWeight: "bold" }}>{i18n('WISE').CREATE_CONFIGURATION.ASSOCIATED_REQUESTS} <div style={{ color: "red" }}>*</div></div>
                  <Select name="test_acuerdo_positivo" horizontal="true" width="350px" border="true" mode="multiple" disabled
                    items={associatedRequests} value={values.test_acuerdo_positivo} error={errors.test_acuerdo_positivo} onChange={(v) => setFieldValue("test_acuerdo_positivo", v)} />
                </div>
              </div>
            </>
          }]} />
      </div>
      <div style={{ borderTop: "1px solid #B8C1CA" }}>
        <div style={{ padding: "5px 10px 5px 10px" }}>
          <div style={{ width: "21%" }}>
            <StyledSwitch id={4} label={"Habilitada"} horizontal="true" checked={values.post_positive_agreement_configuration_dto.is_active} name="post_positive_agreement_configuration_dto.is_active" style={{ fontWeight: "bold" }}
              onChange={(e) => setFieldValue("post_positive_agreement_configuration_dto.is_active", e.target.checked)} />
          </div>
        </div>
      </div>
    </div>
  )

  const grouperSchema = [
    { id: 0, name: i18n('WISE').CREATE_CONFIGURATION.SITE },
    { id: 1, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 1` },
    { id: 2, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 2` },
    { id: 3, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 3` },
    { id: 4, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 4` },
    { id: 5, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 5` },
    { id: 6, name: `${i18n('WISE').CREATE_CONFIGURATION.SUBLOCATION} 6` }
  ]

  const initialValues = {
    email: '', // string
    name: '', // string
    associatedRequests: [], // array of numbers
    associated_investigation_model_request_ids: [], // array of numbers
    business: undefined, // number
    grouper: undefined, // number
    levelsSchema: [[]], // array of array of numbers
    associated_vct_request_ids: [],
    post_risk_warning_configuration_dt: {
      type_a_email_grouping_sublocation: null,
      type_b_email_grouping_sublocation: null,
      type_c_email_grouping_sublocation: null,
      obligatory_picture: false,
    },
    post_accident_and_incident_configuration_dto: {
      email_grouping_sublocation: null,
      should_display_twelve_basic: true,
      medical_service_danone_classification: true,
    },
    post_progressive_discipline_configuration_dto: {
      is_active: false,
    },
    post_wise_survey_configuration_dt: {
      email_grouping_sublocation: null
    },
    post_concrete_vct_configuration_dto: {
      vct_email_grouping_sublocation: null,
      is_active: false,
    },
    post_positive_agreement_configuration_dto: {
      is_active: false,
    }
  }

  return (
    <>
      <PageTitleDashboard title={savedInfo.name.toUpperCase()} subtitle={[savedInfo.wiseName, i18n('SIDEBAR_LINKS').WISE_CREATE_CONFIGURATION.LABEL]} >
        <MessageBanner visible={success} status="success" message={i18n('WISE').CREATE_CONFIGURATION.SUCCESS.CREATE_WISE_CONFIGURATION} />
        <MessageBanner visible={error} status="error" message={requestError} onAccept={() => setError(false)} />
        <MessageBanner visible={validateError} status="error" message={i18n('ERRORS').VALIDATE_ERROR} />
      </PageTitleDashboard>
      {isSubmitting && <Loader />}
      <Formik initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <>
            {Object.keys(errors).length > 0 ? setValidateError(true) : setValidateError(false)}
            <Form onFinish={handleSubmit} autoComplete="off">
              <DashboardContainer width="600px">
                {businessSelect(setFieldValue, values, errors)}
                {nameInput(handleChange, values, errors)}
                {emailInput(handleChange, values, errors)}
                {grouperSelect(setFieldValue, values, errors)}
                {levelsSchemaSelects(setFieldValue, values, errors)}

                {associatedRequestsSelect(setFieldValue, values, errors)}
                {associatedRequestsInvestigationModelSelect(setFieldValue, values, errors)}
                {associatedProgressiveDiscipline(setFieldValue, values, errors)}
                {associatedAudits(setFieldValue, values, errors)}
                {associatedVct(setFieldValue, values, errors)}
                {associatedPositiveAgreement(setFieldValue, values, errors)}
              </DashboardContainer>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <CancelButton small type="button" onClick={() => history.goBack()}>{i18n('CANCEL')}</CancelButton>
                <ConfirmationButton small type="submit">{i18n('SAVE')}</ConfirmationButton>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default CreateWiseConfiguration
