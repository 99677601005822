import { handleActions } from 'redux-actions'
import {
  fetchBusinessStart,
  fetchBusinessSuccess,
  fetchBusinessError,
  submitBusinessStart,
  submitBusinessSuccess,
  submitBusinessError,
  editBusinessStart,
  editBusinessSuccess,
  editBusinessError,
  selectedBusiness
} from '../actions/business'

export const defaultState = {
  values: [],
  error: null,
  isFetching: false,
  isSubmitting: false,
  businessId: undefined
}

const reducer = handleActions(
  {
    [fetchBusinessStart]: state => ({
      ...state,
      isFetching: true,
    }),
    [fetchBusinessSuccess]: (state, { payload: { values } }) => ({
      ...state,
      values,
      isFetching: false,
      error: null
    }),
    [fetchBusinessError]: (state, { payload: { error } }) => ({
      ...state,
      isFetching: false,
      error
    }),
    [submitBusinessStart]: state => ({
      ...state,
      isSubmitting: true,
      error: null
    }),
    [submitBusinessSuccess]: (state, { payload: { data } }) => ({
      ...state,
      values: [...state.values, data],
      isSubmitting: false,
      error: null,
    }),
    [submitBusinessError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [editBusinessStart]: state => ({
      ...state,
      isSubmitting: true,
    }),
    [editBusinessSuccess]: (state) => ({
      ...state,
      isSubmitting: false,
    }),
    [editBusinessError]: (state, { payload: { error } }) => ({
      ...state,
      isSubmitting: false,
      error
    }),
    [selectedBusiness]: (state, { payload: { value } }) => ({
      ...state,
      businessId: value
    })
  },
  defaultState
)

export default reducer
